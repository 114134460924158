/* eslint-disable */
import { useState, useEffect, useRef } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import Select from "react-select";
import { useTranslation } from "react-i18next";

// Import CKEditor here (both required).
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

//import time calculation
import { getCurrentTime } from "helper/TimeCalculation";

/*------ import url and method ------*/
import * as url from "helper/UrlHelper";
import {
  postData,
  getData,
  putData,
  uploadSingleFile,
  uploadMultipleFile,
} from "utils/Gateway";

import fileDownloadHandler from "helper/DownloadFile";
import { assetImages } from "constants";
import { ecosystemSlug } from "Config/Config";
import CurrencyOptions from "data/Prod/Currency.json";
import productStatusOptions from "data/Prod/ProductStatus.json";
import vatOptions from "data/Invoice/VAT.json";

import TabsHeader from "components/Common/TabsHeader/TabsHeader";
import BreadCrumb from "components/Common/BreadCrumb/BreadCrumb";
import downloadFileHandler from "helper/DownloadFile";

import { productDetailsHeaderLinks } from "helper/ProductHelper/ProductHelper";
import AfterSaveProductModal from "../Modals/AfterSaveProductModal";
import AlertNotification from "components/Common/AlertNotification/AlertNotification";

const ProductInfoBody = () => {
  const dateOfToday = new Date().toISOString().split("T")[0];

  const { t } = useTranslation(); //for translation

  const params = useParams();
  const history = useHistory();

  // -------- tabs header object --------------------
  const commonHeaderObject = productDetailsHeaderLinks(params.id, t);

  const breadCrumbText = [
    { title: t("Event"), link: "/admin/events/list" },
    { title: t("Event Info") },
  ];

  /*---- CKEditor classic editor configuration start ----- */
  const editorConfig = {
    toolbar: ["bold", "italic", "link", "bulletedList", "numberedList"],
    ckfinder: {
      // Upload the images to the server using the CKFinder QuickUpload command.
      //   uploadUrl: url.URL_TEXT_EDITOR_IMAGE_UPLOAD_2,
    },
    placeholder: t("Type details here..."),
  };
  /*---- CKEditor classic editor configuration end ----- */

  const imageFileInputRef = useRef(null);

  const loadingCircle = [1, 2, 3, 4, 5, 6]; //for upload image

  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const moduleAccess = localStorage.getItem("moduleaccess");

  const [productTypeList, setproductTypeList] = useState([]);

  const [companyInput, setCompanyInput] = useState("");
  const [companyList, setCompanyList] = useState([]);

  const [productTypeValue, setproductTypeValue] = useState(null);
  const [selectedProductTypeId, setselectedProductTypeId] = useState(null);
  const [companyValue, setcompanyValue] = useState(null);
  const [selectedCompanyId, setselectedCompanyId] = useState(null);
  const [name, setname] = useState("");
  const [summary, setsummary] = useState("");
  const [details, setdetails] = useState("");
  const [price, setprice] = useState("");
  const [currencyValue, setcurrencyValue] = useState(null);
  const [selectedCurrency, setselectedCurrency] = useState("");
  const [sku, setSku] = useState("");
  const [taxPercent, settaxPercent] = useState("");
  const [unit, setUnit] = useState("");
  const [weight, setweight] = useState("");
  const [length, setlength] = useState("");
  const [width, setwidth] = useState("");
  const [height, setheight] = useState("");
  const [startDate, setstartDate] = useState(dateOfToday || "");
  const [endDate, setendDate] = useState(dateOfToday || "");
  const [productStatusValue, setproductStatusValue] = useState(null);
  const [productStatus, setproductStatus] = useState("");
  const [uploadedPreviewImage, setUploadedPreviewImage] = useState("");
  const [uploadedImageId, setUploadedImageId] = useState(null); // use state for uploaded image id
  const [isUploading, setIsUploading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const [savedProductId, setSavedProductId] = useState(null);

  //alert requirements
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [messageType, setMessageType] = useState("");

  /* hook for validation */
  const [validation, setValidation] = useState({
    typeWarning: false,
    companyWarning: false,
    nameWarning: false,
    skuWarning: false,
    priceWarning: false,
    vatWarning: false,
    unitWarning: false,
    imageWarning: false,
  });

  /* function to validate form */
  const validationHandler = () => {
    let isValid = true;

    if (selectedProductTypeId === null) {
      setValidation((prevState) => ({ ...prevState, typeWarning: true }));
      isValid = false;
    }

    if (selectedCompanyId === null) {
      setValidation((prevState) => ({ ...prevState, companyWarning: true }));
      isValid = false;
    }

    if (name === "") {
      setValidation((prevState) => ({ ...prevState, nameWarning: true }));
      isValid = false;
    }

    if (sku === "") {
      setValidation((prevState) => ({ ...prevState, skuWarning: true }));
      isValid = false;
    }

    if (price === "") {
      setValidation((prevState) => ({ ...prevState, priceWarning: true }));
      isValid = false;
    }

    if (taxPercent === "") {
      setValidation((prevState) => ({ ...prevState, vatWarning: true }));
      isValid = false;
    }

    if (unit === "") {
      setValidation((prevState) => ({ ...prevState, unitWarning: true }));
      isValid = false;
    }

    if (uploadedImageId === null) {
      setValidation((prevState) => ({ ...prevState, imageWarning: true }));
      isValid = false;
    }

    return isValid;
  };

  /* ---- bread crumb text ---- */
  const breadcrumbText = [
    { title: "Product", link: "/admin/marketplace/products/list" },
    { title: t("Vital Info") },
  ];

  //get all product types
  const getAllProductType = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_ALL_PRODUCT_TYPE +
        `?ecosystemslug=${ecosystemSlug}`;

      const response = await getData(requestUrl);

      // console.log(response);

      if (response.status) {
        setproductTypeList(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for get all contacts
  const getAllCompanyList = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_ALL_COMPANY_LIST +
        `?token=${token}&filtername=${companyInput}`;

      console.log("url of app user list------>", requestUrl);

      const response = await getData(requestUrl);

      console.log("response in app user list------>", response);

      if (response.status) {
        setCompanyList(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for open image imput
  const openImageFileInput = () => {
    imageFileInputRef.current.click();
  };

  //function for image upload
  const imageUploadHandler = async (e) => {
    setValidation((prevState) => ({ ...prevState, imageWarning: false }));

    const file = e.target.files[0];
    setIsUploading(true);
    try {
      const requestUrl =
        url.API_BASE_URL + url.API_SINGLE_FILE_UPLOAD + `?token=${token}`;

      const response = await uploadSingleFile(requestUrl, file);

      console.log("upload response-------->", response);

      if (response.status) {
        setIsUploading(false);
        setUploadedPreviewImage(response.data.path);
        setUploadedImageId(response.data._id);
        resetImageFile();
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  //function for close image file
  const closeImageFileHandler = () => {
    setUploadedPreviewImage("");
    setUploadedImageId(null);
    resetImageFile();
  };

  // function for clear file value
  const resetImageFile = () => {
    const file = document.getElementById("uploadedImageFile");
    if (file) {
      file.value = null;
    } else {
      return;
    }
  };

  //function for select product type
  const productTypeSelectionHandler = (val) => {
    setValidation((prevState) => ({ ...prevState, typeWarning: false }));
    if (val) {
      setproductTypeValue(val);
      setselectedProductTypeId(val.value);
    } else {
      setproductTypeValue(null);
      setselectedProductTypeId(null);
    }
  };

  //function for select company
  const companySelectionHandler = (val) => {
    if (val) {
      setcompanyValue(val);
      setselectedCompanyId(val.value);
    } else {
      setcompanyValue(null);
      setselectedCompanyId(null);
      setCompanyInput("");
      setCompanyList([]);
    }
  };

  //function for select currency
  const currencySelectionHandler = (val) => {
    if (val) {
      setcurrencyValue(val);
      setselectedCurrency(val.value);
    } else {
      setcurrencyValue(null);
      setselectedCurrency("");
    }
  };

  //function for select product status
  const productStatusSelectionHandler = (val) => {
    if (val) {
      setproductStatusValue(val);
      setproductStatus(val.value);
    } else {
      setproductStatusValue(null);
      setproductStatus("");
    }
  };

  //function for get product details
  const getProductDetails = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_PRODUCT_DETAILS +
        `/${params.id}?token=${token}`;

      const response = await getData(requestUrl);

      console.log("response in product details------>", response);

      if (response.status) {
        const data = response.data;

        if (data.producttypevalue) {
          productTypeSelectionHandler(data.producttypevalue);
        }

        if (data.companyvalue) {
          companySelectionHandler(data.companyvalue);
        }

        if (data.currencyValue) {
          currencySelectionHandler(data.currencyValue);
        }

        if (data.productstatusvalue) {
          productStatusSelectionHandler(data.productstatusvalue);
        }

        setname(data.name);
        setSku(data.sku);
        setsummary(data.summary);
        setdetails(data.description);
        setprice(data.price);
        setUnit(data.unit);
        setweight(data.weight);
        setlength(data.length);
        setwidth(data.width);
        setheight(data.height);
        setstartDate(data.startdate);
        setendDate(data.enddate);
        setUploadedImageId(data.productimage);
        setUploadedPreviewImage(data.productimagepath);
        settaxPercent(data.taxpercent);
      } else {
        setAlertMessage(response.message);
        setMessageType("error");
        setShowAlert(true);
      }
    } catch (error) {
      setAlertMessage(error.message);
      setMessageType("error");
      setShowAlert(true);
    }
  };

  //function for save product info
  const saveProductInfoHandler = async () => {
    if (validationHandler()) {
      try {
        setIsSaving(true);

        let productData = {
          companyid: selectedCompanyId,
          producttypeid: selectedProductTypeId,
          productimage: uploadedImageId,
          name,
          summary,
          description: details,
          price,
          unit,
          currency: selectedCurrency,
          sku,
          taxpercent: taxPercent,
          stock: unit,
          weight,
          length,
          width,
          height,
          startdate: startDate,
          enddate: endDate,
          productstatus: productStatus,
        };

        let requestUrl = url.API_BASE_URL;

        let response = {};

        if (params.id) {
          requestUrl += url.API_UPDATE_PRODUCT + `/${params.id}?token=${token}`;

          response = await putData(requestUrl, productData);
        } else {
          productData.gallery = [uploadedImageId];

          requestUrl += url.API_ADD_PRODUCT + `?token=${token}`;

          response = await postData(requestUrl, productData);
        }

        setIsSaving(false);

        if (response.status) {
          setSavedProductId(response.data._id);
          setMessageType("success");

          setTimeout(() => {
            const bootstrapModal = new bootstrap.Modal(
              document.getElementById("saveProductinfo")
            );
            bootstrapModal.show();
          }, 1500);
        } else {
          setMessageType("error");
        }
        setAlertMessage(response.message);
      } catch (error) {
        setAlertMessage(error.message);
        setMessageType("error");
      }
    } else {
      setMessageType("error");
      setAlertMessage("Please fill all the required fields");
    }
    setShowAlert(true);
  };

  //function for close afterSaveEventModal
  const afterSaveModalClose = (pageName = "") => {
    if (pageName == "details") {
      history.push(`/admin/marketplace/product/details/${savedProductId}`);
    } else {
      history.push("/admin/marketplace/products/list");
    }
  };

  useEffect(() => {
    if (params.id) {
      getProductDetails();
    }
  }, [params.id]);

  useEffect(() => {
    if (companyInput.length > 1) {
      getAllCompanyList();
    }
  }, [companyInput]);

  useEffect(() => {
    getAllProductType();

    currencySelectionHandler(CurrencyOptions[0]);
    productStatusSelectionHandler(productStatusOptions[0]);
  }, []);

  const onAlertClose = () => {
    setShowAlert(false);
    setAlertMessage("");
    setMessageType("");
  };

  if (
    userInfo.role.slug === "ADMIN" ||
    userInfo.role.slug === "SUPER_ADMIN" ||
    moduleAccess.includes("MOD_MARKETPLACE")
  ) {
    return (
      <div id="content_wrapper">
        <section className="marketplace-wrapper bg-white">
          {/* ---- event details common header ---- */}

          {params.id && (
            <TabsHeader
              commonHeaderObject={commonHeaderObject}
              activeOption={t("Vital Info")}
            />
          )}

          <div className="container-fluid px-lg-5 py-4 py-lg-5">
            {/* <BreadCrumb breadCrumbText={breadcrumbText} bottom={true} /> */}
            <div className="p-3 p-lg-4 border border-gray-300 rounded-10 shadow-sm">
              <form onSubmit={(e) => e.preventDefault()}>
                <div className="form-group mb-3 mb-sm-4">
                  <div className="row">
                    <div className="col-lg-6 mb-3 mb-sm-4 mb-lg-0">
                      <label
                        htmlFor="name"
                        className="d-flex align-items-center gap-1 fs-sm fw-semibold mb-2"
                      >
                        {t("Product Type")}{" "}
                        <span className="text-danger fw-bold">*</span>
                      </label>
                      <Select
                        isClearable
                        placeholder={t("Select Product type")}
                        options={productTypeList}
                        value={productTypeValue}
                        onChange={productTypeSelectionHandler}
                      />
                      {validation.typeWarning && (
                        <div className="error-message mt-2">
                          <p className="d-flex align-items-center gap-1 text-danger">
                            <span className="material-symbols-outlined">
                              warning
                            </span>
                            <span>{t("Please select product type")}!</span>
                          </p>
                        </div>
                      )}
                    </div>
                    <div className="col-lg-6">
                      <label
                        htmlFor="companyStore"
                        className="d-flex align-items-center gap-1 fs-sm fw-semibold mb-2"
                      >
                        <span className="d-block">{t("Company / Store")}</span>
                        <span className="text-danger fw-bold">*</span>
                      </label>
                      <div className="d-flex align-items-center gap-3">
                        <Select
                          isClearable
                          className="w-100"
                          placeholder={t("Select Company")}
                          options={companyList}
                          value={companyValue}
                          onChange={companySelectionHandler}
                          onInputChange={(val) => {
                            setValidation((prevState) => ({
                              ...prevState,
                              companyWarning: false,
                            }));
                            setCompanyInput(val);
                          }}
                        />
                        <Link
                          to="/admin/companies/info"
                          target="_blank"
                          className="btn btn-primary d-flex align-items-center"
                        >
                          <span className="d-bloc material-symbols-outlined icon-fill">
                            add
                          </span>
                        </Link>
                      </div>
                      {validation.companyWarning && (
                        <div className="error-message mt-2">
                          <p className="d-flex align-items-center gap-1 text-danger">
                            <span className="material-symbols-outlined">
                              warning
                            </span>
                            <span>{t("Please select company")}!</span>
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="form-group mb-3 mb-sm-4">
                  <div className="row">
                    <div className="col-lg-6">
                      <label
                        htmlFor="summary"
                        className="d-flex align-items-center gap-1 fs-sm fw-semibold mb-2"
                      >
                        <span className="d-block">{t("Name")}</span>
                        <span className="text-danger fw-bold">*</span>
                      </label>
                      <input
                        type="text"
                        id="name"
                        className="form-control fs-sm shadow-none"
                        placeholder={t("Enter name")}
                        value={name}
                        onChange={(e) => {
                          setname(e.target.value);
                          setValidation((prevState) => ({
                            ...prevState,
                            nameWarning: false,
                          }));
                        }}
                      />
                      {validation.nameWarning && (
                        <div className="error-message mt-2">
                          <p className="d-flex align-items-center gap-1 text-danger">
                            <span className="material-symbols-outlined">
                              warning
                            </span>
                            <span>{t("Please enter name")}!</span>
                          </p>
                        </div>
                      )}
                    </div>
                    <div className="col-lg-6">
                      <label
                        htmlFor="summary"
                        className="d-flex align-items-center gap-1 fs-sm fw-semibold mb-2"
                      >
                        <span className="d-block">{t("SKU")}</span>
                        <span className="text-danger fw-bold">*</span>
                      </label>
                      <input
                        type="text"
                        id="name"
                        className="form-control fs-sm shadow-none"
                        placeholder={t("Enter sku")}
                        value={sku}
                        onChange={(e) => {
                          setSku(e.target.value);
                          setValidation((prevState) => ({
                            ...prevState,
                            skuWarning: false,
                          }));
                        }}
                      />
                      {validation.skuWarning && (
                        <div className="error-message mt-2">
                          <p className="d-flex align-items-center gap-1 text-danger">
                            <span className="material-symbols-outlined">
                              warning
                            </span>
                            <span>{t("Please enter sku")}!</span>
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="form-group mb-3 mb-sm-4">
                  <label
                    htmlFor="summary"
                    className="d-flex align-items-center gap-1 fs-sm fw-semibold mb-2"
                  >
                    <span className="d-block">{t("Summary")}</span>
                  </label>
                  <textarea
                    name="summary"
                    id="summary"
                    cols="30"
                    rows="3"
                    className="form-control fs-sm shadow-none"
                    placeholder={t("Event Summary")}
                    value={summary}
                    onChange={(e) => setsummary(e.target.value)}
                  ></textarea>
                </div>
                <div className="form-group mb-3 mb-sm-4">
                  <label
                    htmlFor="details"
                    className="d-flex align-items-center gap-1 fs-sm fw-semibold mb-2"
                  >
                    <span className="d-block">{t("Details")}</span>
                  </label>
                  <CKEditor
                    className="form-control fs-sm shadow-none"
                    editor={ClassicEditor}
                    config={editorConfig}
                    data={details}
                    onReady={(editor) => {
                      editor.editing.view.change((writer) => {
                        writer.setStyle(
                          "height",
                          "200px",
                          editor.editing.view.document.getRoot()
                        );
                      });
                    }}
                    onChange={(event, editor) => {
                      let editorContent = editor.getData();
                      setdetails(editorContent);
                    }}
                  />
                </div>
                <div className="form-group mb-3 mb-sm-4">
                  <div className="row">
                    <div className="col-lg mb-3 mb-sm-4 mb-lg-0">
                      <label
                        htmlFor="price"
                        className="d-block fs-sm fw-semibold mb-2"
                      >
                        {t("Price")}
                        <span className="text-danger fw-bold">*</span>
                      </label>
                      <input
                        type="number"
                        min={0}
                        id="price"
                        className="form-control fs-sm shadow-none"
                        placeholder={t("Enter Price")}
                        value={price}
                        onChange={(e) => {
                          setprice(e.target.value);
                          setValidation((prevState) => ({
                            ...prevState,
                            priceWarning: false,
                          }));
                        }}
                      />
                      {validation.priceWarning && (
                        <div className="error-message mt-2">
                          <p className="d-flex align-items-center gap-1 text-danger">
                            <span className="material-symbols-outlined">
                              warning
                            </span>
                            <span>{t("Please enter price")}!</span>
                          </p>
                        </div>
                      )}
                    </div>
                    <div className="col-lg mb-3 mb-sm-4 mb-lg-0">
                      <label
                        htmlFor="currency"
                        className="d-block fs-sm fw-semibold mb-2"
                      >
                        {t("Currency")}
                      </label>

                      <Select
                        isClearable
                        placeholder={t("Select Currency")}
                        options={CurrencyOptions}
                        value={currencyValue}
                        onChange={currencySelectionHandler}
                      />
                    </div>
                    <div className="col-lg mb-3 mb-sm-4 mb-lg-0">
                      <label
                        htmlFor="vat"
                        className="d-block fs-sm fw-semibold mb-2"
                      >
                        {t("VAT %")}
                        <span className="text-danger fw-bold">*</span>
                      </label>

                      <input
                        type="number"
                        min={0}
                        id="price"
                        className="form-control fs-sm shadow-none"
                        placeholder={t("Enter Vat")}
                        value={taxPercent}
                        onChange={(e) => {
                          settaxPercent(e.target.value);
                          setValidation((prevState) => ({
                            ...prevState,
                            vatWarning: false,
                          }));
                        }}
                      />

                      {validation.vatWarning && (
                        <div className="error-message mt-2">
                          <p className="d-flex align-items-center gap-1 text-danger">
                            <span className="material-symbols-outlined">
                              warning
                            </span>
                            <span>{t("Please enter vat")}!</span>
                          </p>
                        </div>
                      )}
                    </div>
                    <div className="col-lg mb-3 mb-sm-4 mb-lg-0">
                      <label
                        htmlFor="stock"
                        className="d-block fs-sm fw-semibold mb-2"
                      >
                        {t("Stock")}
                        <span className="text-danger fw-bold">*</span>
                      </label>
                      <input
                        min={0}
                        type="number"
                        id="stock"
                        className="form-control fs-sm shadow-none"
                        placeholder={t("Enter unit")}
                        value={unit}
                        onChange={(e) => {
                          setUnit(e.target.value);
                          setValidation((prevState) => ({
                            ...prevState,
                            unitWarning: false,
                          }));
                        }}
                      />
                      {validation.unitWarning && (
                        <div className="error-message mt-2">
                          <p className="d-flex align-items-center gap-1 text-danger">
                            <span className="material-symbols-outlined">
                              warning
                            </span>
                            <span>{t("Please enter stock")}!</span>
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="form-group mb-3 mb-sm-4">
                  <div className="row">
                    <div className="col-lg mb-3 mb-sm-4 mb-lg-0">
                      <label
                        htmlFor="price"
                        className="d-block fs-sm fw-semibold mb-2"
                      >
                        {t("Weight")}
                      </label>
                      <input
                        min={0}
                        type="number"
                        id="weight"
                        className="form-control fs-sm shadow-none"
                        placeholder={t("Enter weight")}
                        value={weight}
                        onChange={(e) => setweight(e.target.value)}
                      />
                    </div>
                    <div className="col-lg mb-3 mb-sm-4 mb-lg-0">
                      <label
                        htmlFor="currency"
                        className="d-block fs-sm fw-semibold mb-2"
                      >
                        {t("Length")}
                      </label>

                      <input
                        min={0}
                        type="number"
                        id="weight"
                        className="form-control fs-sm shadow-none"
                        placeholder={t("Enter length")}
                        value={length}
                        onChange={(e) => setlength(e.target.value)}
                      />
                    </div>
                    <div className="col-lg mb-3 mb-sm-4 mb-lg-0">
                      <label
                        htmlFor="vat"
                        className="d-block fs-sm fw-semibold mb-2"
                      >
                        {t("Width")}
                      </label>
                      <input
                        min={0}
                        type="number"
                        id="sku"
                        className="form-control fs-sm shadow-none"
                        placeholder={t("Enter width")}
                        value={width}
                        onChange={(e) => setwidth(e.target.value)}
                      />
                    </div>
                    <div className="col-lg mb-3 mb-sm-4 mb-lg-0">
                      <label
                        htmlFor="stock"
                        className="d-block fs-sm fw-semibold mb-2"
                      >
                        {t("Height")}
                      </label>
                      <input
                        min={0}
                        type="number"
                        id="stock"
                        className="form-control fs-sm shadow-none"
                        placeholder={t("Enter height")}
                        value={height}
                        onChange={(e) => setheight(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div
                  className="accordion mb-3 mb-sm-4"
                  id="accordionPanelsStayOpenExample"
                >
                  <div className="accordion-item bg-transparent border-0">
                    <h3 className="accordion-header">
                      <button
                        className="accordion-button bg-transparent p-0 shadow-none"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseOne"
                        aria-expanded="true"
                        aria-controls="panelsStayOpen-collapseOne"
                      >
                        {t("Advance Info")}
                      </button>
                    </h3>
                    <div
                      id="panelsStayOpen-collapseOne"
                      className="accordion-collapse collapse show"
                    >
                      <div className="accordion-body p-0 pt-4">
                        <div className="form-group mb-3 mb-sm-4">
                          <div className="row">
                            <div className="col-lg-4 mb-3 mb-sm-4 mb-lg-0">
                              <label
                                htmlFor="displayStartFrom"
                                className="d-block fs-sm fw-semibold mb-2"
                              >
                                {t("Display Start From")}
                              </label>
                              <input
                                type="date"
                                className="form-control fs-sm shadow-none"
                                value={startDate}
                                onChange={(e) => setstartDate(e.target.value)}
                              />
                            </div>
                            <div className="col-lg-4">
                              <label
                                htmlFor="displayEndFrom"
                                className="d-block fs-sm fw-semibold mb-2"
                              >
                                {t("Display Ends On")}
                              </label>
                              <input
                                type="date"
                                className="form-control fs-sm shadow-none"
                                value={endDate}
                                onChange={(e) => setendDate(e.target.value)}
                              />
                            </div>
                            <div className="col-lg-4">
                              <label
                                htmlFor="displayEndFrom"
                                className="d-block fs-sm fw-semibold mb-2"
                              >
                                {t("Product Status")}
                              </label>
                              <Select
                                isClearable
                                placeholder={t("Select Status")}
                                options={productStatusOptions}
                                value={productStatusValue}
                                onChange={productStatusSelectionHandler}
                              />
                            </div>
                          </div>
                        </div>

                        {/* ------ uploaded image section start ------ */}
                        <div className="form-group">
                          <div className="row">
                            <div className="col-lg-12">
                              <div className="form-group">
                                <div className="field-container d-flex align-items-center px-3 body-bg rounded-10 mb-3">
                                  <label
                                    htmlFor="uploadDp"
                                    className="text-nowrap me-3"
                                  >
                                    {t("Product Image")}
                                    <span className="ms-1 text-danger fw-bold">
                                      *
                                    </span>
                                  </label>
                                  <p className="form-control text-gray-600 border-0 rounded-0 shadow-none">
                                    JPG, PNG, JPEG
                                  </p>
                                  <Link
                                    to="#"
                                    className="upload-file"
                                    data-target="uploadResume"
                                    onClick={openImageFileInput}
                                  >
                                    <span className="d-block material-symbols-outlined icon-lg">
                                      upload
                                    </span>
                                  </Link>
                                  <input
                                    type="file"
                                    data-id="uploadResume"
                                    id="uploadedImageFile"
                                    className="d-none"
                                    ref={imageFileInputRef}
                                    onChange={imageUploadHandler}
                                  />
                                </div>

                                {validation.imageWarning && (
                                  <div className="error-message my-1">
                                    <p className="d-flex align-items-center gap-1 text-danger">
                                      <span className="material-symbols-outlined">
                                        warning
                                      </span>
                                      <span>{t("Please upload image")}!</span>
                                    </p>
                                  </div>
                                )}

                                <ul className="preview-uploaded-files d-flex flex-wrap gap-2">
                                  {isUploading ? (
                                    <li>
                                      <div className="d-flex align-items-center gap-1 ms-2 mb-1">
                                        <p className="fw-bold">
                                          {t("Please wait while uploading")}
                                        </p>{" "}
                                        {loadingCircle.map((count, index) => {
                                          return (
                                            <div
                                              key={index}
                                              className="spinner-grow spinner-grow-sm"
                                              role="status"
                                              style={{
                                                height: "5px",
                                                width: "5px",
                                              }}
                                            >
                                              <span className="visually-hidden">
                                                Loading...
                                              </span>
                                            </div>
                                          );
                                        })}
                                      </div>
                                    </li>
                                  ) : (
                                    <li className="file border border-gray rounded-8 overflow-hidden position-relative">
                                      <Link
                                        to="#"
                                        onClick={closeImageFileHandler}
                                      >
                                        <span className="d-block material-symbols-outlined text-white icon-lg position-absolute top-0 end-0 mt-2 me-1">
                                          delete
                                        </span>
                                      </Link>
                                      <img
                                        src={
                                          uploadedPreviewImage == ""
                                            ? "https://placehold.co/150x150"
                                            : url.SERVER_URL +
                                              uploadedPreviewImage
                                        }
                                        alt="Uploaded"
                                        className="w-100 h-100 object-fit-cover object-center"
                                      />
                                    </li>
                                  )}
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* ------ uploaded image section end ------ */}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-check d-flex align-items-center gap-2 fs-sm p-0 m-0 mb-3 mb-sm-4">
                  <input
                    type="checkbox"
                    id="agree"
                    className="form-check-input bg-transparent border-2 border-gray-600 shadow-none m-0"
                  />
                  <label className="form-check-label" htmlFor="agree">
                    {t("Agree with the")}{" "}
                    <Link to="#" className="text-primary">
                      {t("Legal Notice")}
                    </Link>{" "}
                    &amp;{" "}
                    <Link to="#" className="text-primary">
                      {t("Privacy Policy")}
                    </Link>
                  </label>
                </div>
                <div className="action d-flex flex-wrap gap-3 align-items-center justify-content-between">
                  <Link
                    to="/admin/marketplace/products/list"
                    className="btn btn-outline-primary"
                  >
                    {t("Cancel")}
                  </Link>
                  <button
                    type="submit"
                    className="btn btn-primary"
                    style={{
                      cursor: isSaving ? "not-allowed" : "pointer",
                    }}
                    disabled={isSaving ? true : false}
                    onClick={saveProductInfoHandler}
                  >
                    {t("Save Now")}
                    {isSaving && (
                      <div
                        className="mx-2 spinner-border spinner-border-sm"
                        role="status"
                      >
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
          {/* ----- after save event modal ----- */}
          <AfterSaveProductModal afterSaveModalClose={afterSaveModalClose} />

          {showAlert && (
            <AlertNotification
              showAlert={showAlert}
              message={alertMessage}
              alertType={messageType}
              onClose={onAlertClose}
            />
          )}
        </section>
      </div>
    );
  } else {
    return (
      <div id="content_wrapper">
        <section className="crm-wrapper bg-white pb-5">
          <div className="empty_access text-center">
            <div className="empty_pic mb-4">
              {" "}
              <img src={assetImages.emptyVector} alt="" />
            </div>
            <div className="empty_text">
              <p className="fs-lg text-gray fw-semibold mb-4">
                {t("Sorry....! You don't have privilege to see this content")}
              </p>
            </div>
          </div>
        </section>
      </div>
    );
  }
};

export default ProductInfoBody;
