/* eslint-disable */
import React, { useContext, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";

//import context
import { GlobalProvider } from "context/GlobalContext";

/*import url and gateway methods */
import { getData, postData, putData } from "utils/Gateway";
import * as url from "helper/UrlHelper";

//import images
import CommonHeader from "components/MarketplaceComponents/MarketplaceHeader/CommonHeader";
import { assetImages } from "constants";

import { useTranslation } from "react-i18next";
import SaveAddressModal from "../Modal/SaveAddressModal";

import currencyOptions from "data/Prod/Currency.json";
import languageOptions from "data/Prod/LanguageOptions.json";
import paymentOptions from "data/Prod/PaymentOptions.json";
import { on } from "events";
import AlertNotification from "components/Common/AlertNotification/AlertNotification";

const SettingsBody = () => {
  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const moduleAccess = localStorage.getItem("moduleaccess");
  const { t, i18n } = useTranslation(); //for translation

  const [storeName, setstoreName] = useState("");
  const [contactEmail, setcontactEmail] = useState("");
  const [contactNumber, setcontactNumber] = useState("");
  const [currencyValue, setcurrencyValue] = useState(null);
  const [selectedCurrency, setselectedCurrency] = useState([]);
  const [languageValue, setlanguageValue] = useState(null);
  const [selectedLanguages, setselectedLanguages] = useState([]);
  const [selectedPaymentMethods, setSelectedPaymentMethods] = useState([]);

  const [addressData, setAddressData] = useState({
    name: "",
    address: "",
    city: "",
    state: "",
    country: "",
    zipcode: "",
    mapurl: "",
  });

  const [addressBlock, setaddressBlock] = useState([]);
  const [addressIndex, setaddressIndex] = useState(null);
  const [deletedAddressBlocks, setDeletedAddressBlocks] = useState([]);

  const [isSaving, setIsSaving] = useState(false);

  //alert requirements
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [messageType, setMessageType] = useState("");

  const [validation, setValidation] = useState({
    _id: null,
    storeNameWarning: false,
    contactEmailWarning: false,
    contactNumberWarning: false,
    currencyWarning: false,
    languageWarning: false,
    paymentMethodWarning: false,
    addressBlockWarning: false,
  });

  const validationHandler = () => {
    let isValid = true;

    if (storeName.trim() === "") {
      setValidation((prevState) => ({ ...prevState, storeNameWarning: true }));
      isValid = false;
    }

    if (contactEmail.trim() === "") {
      setValidation((prevState) => ({
        ...prevState,
        contactEmailWarning: true,
      }));
      isValid = false;
    }

    if (contactNumber.trim() === "") {
      setValidation((prevState) => ({
        ...prevState,
        contactNumberWarning: true,
      }));
      isValid = false;
    }

    if (currencyValue === null) {
      setValidation((prevState) => ({ ...prevState, currencyWarning: true }));
      isValid = false;
    }

    if (languageValue === null) {
      setValidation((prevState) => ({ ...prevState, languageWarning: true }));
      isValid = false;
    }

    if (selectedPaymentMethods.length === 0) {
      setValidation((prevState) => ({
        ...prevState,
        paymentMethodWarning: true,
      }));
      isValid = false;
    }

    if (addressBlock.length === 0) {
      setValidation((prevState) => ({
        ...prevState,
        addressBlockWarning: true,
      }));
      isValid = false;
    }

    return isValid;
  };

  //function for select currency
  const currencySelectionHandler = (val) => {
    setValidation((prevState) => ({ ...prevState, currencyWarning: false }));
    setcurrencyValue(val);
    setselectedCurrency(val.map((item) => item.value));
  };

  //function for select language
  const languageSelectionHandler = (val) => {
    setValidation((prevState) => ({ ...prevState, languageWarning: false }));
    setlanguageValue(val);
    setselectedLanguages(val.map((item) => item.value));
  };

  //function for payment selection
  const paymentSelectionHandler = (e) => {
    setValidation((prevState) => ({
      ...prevState,
      paymentMethodWarning: false,
    }));

    const value = e.target.value;
    const isChecked = e.target.checked;

    setSelectedPaymentMethods((prev) => {
      if (isChecked) {
        return [...prev, value];
      } else {
        return prev.filter((method) => method !== value);
      }
    });
  };

  //function for edit address block
  const updateAddressBlock = (addressData, index) => {
    setAddressData(addressData);
    setaddressIndex(index);
  };

  //delete address block
  const onDeleteAddressBlock = (index, item) => {
    const filteredBlock = [...addressBlock];
    filteredBlock.splice(index, 1);
    setaddressBlock(filteredBlock);

    if (item._id) {
      setDeletedAddressBlocks((prev) => [...prev, item._id]);
    }
  };

  //function for view tech exp blocks
  const getAddressBlock = (addressList = []) => {
    const addressArr = addressList.map((addressData) => {
      let savedData = {
        _id: addressData?._id,
        name: addressData?.name,
        address: addressData?.address,
        city: addressData?.city,
        state: addressData?.state,
        country: addressData?.country,
        zipcode: addressData?.zipcode,
        mapurl: addressData?.mapurl,
      };
      return savedData;
    });
    setaddressBlock(addressArr);
  };

  //function for get settings
  const getSettingsDetails = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL + url.API_GET_SETTING_DETAILS + `?token=${token}`;

      const response = await getData(requestUrl);

      console.log("response ----->", response);

      if (response.status) {
        setMessageType("success");
        const data = response.data;

        if (data) {
          setstoreName(data.storename);
          setcontactEmail(data.contactemail);
          setcontactNumber(data.contactphone);
          setSelectedPaymentMethods(data.paymentmethods);

          const filteredCurrencies = currencyOptions.filter((currency) =>
            data.currency.includes(currency.value)
          );

          currencySelectionHandler(filteredCurrencies);

          const filterLanguages = languageOptions.filter((language) =>
            data.languages.includes(language.value)
          );

          languageSelectionHandler(filterLanguages);

          getAddressBlock(data.warehouseaddresses);
        }
      } else {
        setMessageType("error");
      }
      setAlertMessage(response.message);
    } catch (error) {
      setMessageType("error");
      setAlertMessage(error.message);
    }
    setShowAlert(true);
  };

  //function for save settings
  const saveSettingsHandler = async () => {
    if (validationHandler()) {
      setIsSaving(true);
      try {
        let settingsData = {
          storename: storeName,
          contactemail: contactEmail,
          contactphone: contactNumber,
          currency: selectedCurrency,
          languages: selectedLanguages,
          paymentmethods: selectedPaymentMethods,
          addresses: addressBlock,
          deletedaddressblocks: deletedAddressBlocks,
        };

        // console.log("settings data ----->", settingsData);

        let requestUrl =
          url.API_BASE_URL + url.API_CREATE_SETTING + `?token=${token}`;

        const response = await postData(requestUrl, settingsData);

        // console.log("response ----->", response);

        setIsSaving(false);

        if (response.status) {
          setDeletedAddressBlocks([]);
          setMessageType("success");

          setTimeout(() => {
            getSettingsDetails();
          }, 1500);
        } else {
          setMessageType("error");
        }

        setAlertMessage(response.message);
      } catch (error) {
        console.log(error.message);
      }
    } else {
      setAlertMessage("Please fill all the required fields.");
      setMessageType("error");
    }
    setShowAlert(true);
  };

  useEffect(() => {
    getSettingsDetails();
  }, []);

  const onAlertClose = () => {
    setShowAlert(false);
    setAlertMessage("");
    setMessageType("");
  };

  if (
    userInfo.role.slug === "ADMIN" ||
    userInfo.role.slug === "SUPER_ADMIN" ||
    moduleAccess.includes("MOD_MARKETPLACE")
  ) {
    return (
      <div id="content_wrapper">
        <section className="crm-wrapper bg-white pb-5">
          {/* ---- common header ---- */}
          <CommonHeader activeOption="Settings" />

          <div className="container-fluid px-lg-5 pt-4">
            <div className="p-3 p-lg-4 border border-gray-300 rounded-10 shadow-sm">
              {/* Mail sending configuration for this user ---------------------- */}
              <h3>{t("General Settings")}</h3>

              {/* store name  */}
              <div className="form-group mb-3 mb-sm-4">
                <div className="row align-items-center">
                  <div className="col-lg-6 mb-3 mb-sm-4 mb-lg-0">
                    <p className="fs-md fw-semibold">
                      {t("Store Name")}{" "}
                      <span className="text-danger fw-bold ms-1">*</span>
                    </p>
                  </div>
                  <div className="col-lg-6">
                    <input
                      type="text"
                      className="form-control fs-sm shadow-none"
                      placeholder={t("Add your store name")}
                      value={storeName}
                      onChange={(e) => {
                        setstoreName(e.target.value);
                        setValidation((prevState) => ({
                          ...prevState,
                          storeNameWarning: false,
                        }));
                      }}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6"></div>
                  <div className="col-lg-6">
                    {validation.storeNameWarning && (
                      <div className="error-message mt-2">
                        <p className="d-flex align-items-center gap-1 text-danger">
                          <span className="material-symbols-outlined">
                            warning
                          </span>
                          <span>{t("Please enter store name")}!</span>
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              {/* email  */}
              <div className="form-group mb-3 mb-sm-4">
                <div className="row align-items-center">
                  <div className="col-lg-6 mb-3 mb-sm-4 mb-lg-0">
                    <p className="fs-md fw-semibold">
                      {t("Contact Email")}{" "}
                      <span className="text-danger fw-bold ms-1">*</span>
                    </p>
                  </div>
                  <div className="col-lg-6">
                    <input
                      type="text"
                      className="form-control fs-sm shadow-none"
                      placeholder={t("Grivance Email")}
                      value={contactEmail}
                      onChange={(e) => {
                        setcontactEmail(e.target.value);
                        setValidation((prevState) => ({
                          ...prevState,
                          contactEmailWarning: false,
                        }));
                      }}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6"></div>
                  <div className="col-lg-6">
                    {validation.contactEmailWarning && (
                      <div className="error-message mt-2">
                        <p className="d-flex align-items-center gap-1 text-danger">
                          <span className="material-symbols-outlined">
                            warning
                          </span>
                          <span>{t("Please enter contact email")}!</span>
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              {/* contact number  */}
              <div className="form-group mb-3 mb-sm-4">
                <div className="row align-items-center">
                  <div className="col-lg-6 mb-3 mb-sm-4 mb-lg-0">
                    <p className="fs-md fw-semibold">
                      {t("Customer care number")}{" "}
                      <span className="text-danger fw-bold ms-1">*</span>
                    </p>
                  </div>
                  <div className="col-lg-6">
                    <input
                      type="text"
                      className="form-control fs-sm shadow-none"
                      placeholder={t("Call customer care")}
                      value={contactNumber}
                      onChange={(e) => {
                        setcontactNumber(e.target.value);
                        setValidation((prevState) => ({
                          ...prevState,
                          contactNumberWarning: false,
                        }));
                      }}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6"></div>
                  <div className="col-lg-6">
                    {validation.contactNumberWarning && (
                      <div className="error-message mt-2">
                        <p className="d-flex align-items-center gap-1 text-danger">
                          <span className="material-symbols-outlined">
                            warning
                          </span>
                          <span>{t("Please enter contact number")}!</span>
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              {/* Currency */}
              <div className="form-group mb-3 mb-sm-4">
                <div className="row align-items-center">
                  <div className="col-lg-6 mb-3 mb-sm-4 mb-lg-0">
                    <p className="fs-md fw-semibold">
                      {t("Currency")} (<i>{t("USD / EURO ")}</i> ){" "}
                      <span className="text-danger fw-bold ms-1">*</span>
                    </p>
                  </div>
                  <div className="col-lg-6">
                    <Select
                      isMulti
                      options={currencyOptions}
                      value={currencyValue}
                      onChange={currencySelectionHandler}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6"></div>
                  <div className="col-lg-6">
                    {validation.currencyWarning && (
                      <div className="error-message mt-2">
                        <p className="d-flex align-items-center gap-1 text-danger">
                          <span className="material-symbols-outlined">
                            warning
                          </span>
                          <span>{t("Please select currency")}!</span>
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              {/* language  */}
              <div className="form-group mb-3 mb-sm-4">
                <div className="row align-items-center">
                  <div className="col-lg-6 mb-3 mb-sm-4 mb-lg-0">
                    <p className="fs-md fw-semibold">
                      {t("Language")}
                      <span className="text-danger fw-bold ms-1">*</span>{" "}
                    </p>
                  </div>
                  <div className="col-lg-6">
                    <Select
                      isMulti
                      options={languageOptions}
                      value={languageValue}
                      onChange={languageSelectionHandler}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6"></div>
                  <div className="col-lg-6">
                    {validation.languageWarning && (
                      <div className="error-message mt-2">
                        <p className="d-flex align-items-center gap-1 text-danger">
                          <span className="material-symbols-outlined">
                            warning
                          </span>
                          <span>{t("Please select language")}!</span>
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <hr />

              {/* payment methods  */}
              <h3>
                {t("Payment Methods")}{" "}
                <span className="text-danger fw-bold ms-1">*</span>
              </h3>

              <div className="form-group mb-3 mb-sm-4">
                <div className="row align-items-center">
                  {paymentOptions.map((item, index) => {
                    return (
                      <div className="col-lg-4 mb-3" key={index}>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value={item.value}
                            onChange={paymentSelectionHandler}
                            checked={selectedPaymentMethods.includes(
                              item.value
                            )}
                          />
                          <label className="form-check-label ms-2">
                            {item.label}
                          </label>
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div className="row">
                  <div className="col-lg-6"></div>
                  <div className="col-lg-6">
                    {validation.paymentMethodWarning && (
                      <div className="error-message mt-2">
                        <p className="d-flex align-items-center gap-1 text-danger">
                          <span className="material-symbols-outlined">
                            warning
                          </span>
                          <span>{t("Please select payment method")}!</span>
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <hr />

              <h3>
                {t("Wearhouses")}{" "}
                <span className="text-danger fw-bold ms-1">*</span>
              </h3>

              <div className="fields-group  mb-3">
                {/* ------ Address Section Start ------ */}
                <div
                  className={
                    addressBlock.length > 0
                      ? "fields d-flex flex-column gap-3"
                      : "d-none"
                  }
                >
                  {/* each row  */}
                  {addressBlock.map((item, index) => {
                    return (
                      <div
                        className="field rounded-5 border border-gray-300 p-3"
                        key={index}
                      >
                        <div className="form-group">
                          {/* ------ Name & Buttons in Same Row ------ */}
                          <div className="d-flex justify-content-between gap-3 mb-3">
                            {/* Name (Left Side) */}
                            <div className="mb-3 mb-lg-0 w-100">
                              <div className="field-container d-flex align-items-center px-3 body-bg rounded-10">
                                <label className="text-nowrap me-3">Name</label>
                                <p className="form-control border-0 shadow-none w-100">
                                  {item.name}
                                </p>
                              </div>
                            </div>
                            {/* Edit/Delete Buttons (Right Side) */}
                            <div className="d-flex align-items-center justify-content-end gap-2">
                              <Link
                                to="#"
                                data-bs-toggle="modal"
                                data-bs-target="#saveAddress"
                                className="d-flex justify-content-center align-items-center"
                                onClick={() => {
                                  updateAddressBlock(item, index);
                                }}
                              >
                                <span className="material-symbols-outlined">
                                  edit
                                </span>
                              </Link>
                              <Link
                                to="#"
                                className="d-flex justify-content-center align-items-center"
                                onClick={() => {
                                  onDeleteAddressBlock(index, item);
                                }}
                              >
                                <span className="material-symbols-outlined">
                                  delete
                                </span>
                              </Link>
                            </div>
                          </div>

                          {/* ------ Address ------ */}
                          <div className="row mb-3">
                            <div className="col-12">
                              <div className="field-container d-flex align-items-center px-3 body-bg rounded-10">
                                <label className="text-nowrap me-3">
                                  Address
                                </label>
                                <p className="form-control border-0 shadow-none">
                                  {item.address}
                                </p>
                              </div>
                            </div>
                          </div>

                          {/* ------ City, State, Country ------ */}
                          <div className="row mb-3">
                            <div className="col-lg-4 mb-3 mb-lg-0">
                              <div className="field-container d-flex align-items-center px-3 body-bg rounded-10">
                                <label className="text-nowrap me-3">City</label>
                                <p className="form-control border-0 shadow-none">
                                  {item.city}
                                </p>
                              </div>
                            </div>
                            <div className="col-lg-4 mb-3 mb-lg-0">
                              <div className="field-container d-flex align-items-center px-3 body-bg rounded-10">
                                <label className="text-nowrap me-3">
                                  State
                                </label>
                                <p className="form-control border-0 shadow-none">
                                  {item.state}
                                </p>
                              </div>
                            </div>
                            <div className="col-lg-4">
                              <div className="field-container d-flex align-items-center px-3 body-bg rounded-10">
                                <label className="text-nowrap me-3">
                                  Country
                                </label>
                                <p className="form-control border-0 shadow-none">
                                  {item.country}
                                </p>
                              </div>
                            </div>
                          </div>

                          {/* ------ Zipcode & Map URL ------ */}
                          <div className="row">
                            <div className="col-lg-6 mb-3 mb-lg-0">
                              <div className="field-container d-flex align-items-center px-3 body-bg rounded-10">
                                <label className="text-nowrap me-3">
                                  Zipcode
                                </label>
                                <p className="form-control border-0 shadow-none">
                                  {item.zipcode}
                                </p>
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="field-container d-flex align-items-center px-3 body-bg rounded-10">
                                <label className="text-nowrap me-3">
                                  Map URL
                                </label>
                                <p className="form-control border-0 shadow-none">
                                  {item.mapurl}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div className="d-flex mt-3">
                  <Link
                    to="#"
                    data-bs-toggle="modal"
                    data-bs-target="#saveAddress"
                    className="btn btn-outline-primary d-flex align-items-center gap-1 "
                  >
                    <span className="d-block material-symbols-outlined">
                      add
                    </span>
                    <span className="d-block">Add New Address</span>
                  </Link>
                </div>
              </div>

              <div className="action d-flex justify-content-end">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={saveSettingsHandler}
                  style={{
                    cursor: isSaving ? "not-allowed" : "pointer",
                  }}
                  disabled={isSaving ? true : false}
                >
                  {t("Save Settings")}
                  {isSaving && (
                    <div
                      className="mx-2 spinner-border spinner-border-sm"
                      role="status"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  )}
                </button>
              </div>
            </div>
          </div>

          {/* modal  */}
          <SaveAddressModal
            setValidation={setValidation}
            addressIndex={addressIndex}
            addressData={addressData}
            setAddressData={setAddressData}
            addressBlock={addressBlock}
            setaddressBlock={setaddressBlock}
            setaddressIndex={setaddressIndex}
          />

          {showAlert && (
            <AlertNotification
              showAlert={showAlert}
              message={alertMessage}
              alertType={messageType}
              onClose={onAlertClose}
            />
          )}
        </section>
      </div>
    );
  } else {
    return (
      <div id="content_wrapper">
        <section className="crm-wrapper bg-white pb-5">
          <div className="empty_access text-center">
            <div className="empty_pic mb-4">
              {" "}
              <img src={assetImages.emptyVector} alt="" />
            </div>
            <div className="empty_text">
              <p className="fs-lg text-gray fw-semibold mb-4">
                {t("Sorry....! You don't have privilege to see this content")}
              </p>
            </div>
          </div>
        </section>
      </div>
    );
  }
};

export default SettingsBody;
