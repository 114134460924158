/* eslint-disable */
import Header from "components/Common/Header/Header";
import ProductOffersBody from "components/MarketplaceComponents/ProductComponents/ProductDetails/ProductOffers/ProductOffersBody";
import { useEffect } from "react";

const ProductOffers = () => {
  useEffect(() => {
    document.title = "Marketplace";
  }, []);

  return (
    <main id="app">
      {/* ---- common header ---- */}
      <Header moduleName="marketplace" />
      <ProductOffersBody />
    </main>
  );
};

export default ProductOffers;
