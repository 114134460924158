/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Select from "react-select";

/*------ import url and method ------*/
import * as url from "helper/UrlHelper";
import { getData, postData } from "utils/Gateway";

import { assetImages } from "constants/index";

//--- dnd-kit ---------------------
import {
  DndContext,
  DragOverlay,
  closestCorners,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";

import { arrayMove, sortableKeyboardCoordinates } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import Container from "./BoardColumn"; // Import Column component
import ColumnItem from "./ColumnItem"; // Import Item component

//--- dnd-kit ends ---------------------

import { getCurrentDateInString } from "helper/getcurrentdatestring";

import TabsHeader from "components/Common/TabsHeader/TabsHeader";
import AlertNotification from "components/Common/AlertNotification/AlertNotification";
import { formatNumber } from "helper/Common/CommonHelper";

//import common header objects
import { crmCommonHeaderLinkLists } from "helper/CrmHelper/CrmHelper";
import CrmLeadKanbanHeader from "../Header/CrmLeadKanbanHeader";
import SaveColumnModal from "components/Common/Modal/SaveColumnModal";
import SaveActivityProcessPopup from "components/Common/Popup/ActivityProcessPopup/SaveActivityProcessPopup";
import AddProcessFromTemplateModal from "components/Common/Modal/AddProcessFromTemplateModal";
import SaveLeadPopup from "components/CRMComponents/CRMLeadListComponents/Popup/SaveLeadPopup";
import ConversationNewMailPopup from "components/Common/Popup/ConversationNewMailPopup";
import CrmLeadKanbanFilterPopup from "../Popup/CrmLeadKanbanFilterPopup";

// ------------- dnd-kit ----------------------------

const defaultAnnouncements = {
  onDragStart(id) {
    console.log(`Picked up draggable item ${id}.`);
  },
  onDragOver(id, overId) {
    if (overId) {
      console.log(
        `Draggable item ${id} was moved over droppable area ${overId}.`
      );
      return;
    }

    console.log(`Draggable item ${id} is no longer over a droppable area.`);
  },
  onDragEnd(id, overId) {
    if (overId) {
      console.log(
        `Draggable item ${id} was dropped over droppable area ${overId}`
      );
      return;
    }

    console.log(`Draggable item ${id} was dropped.`);
  },
  onDragCancel(id) {
    console.log(`Dragging was cancelled. Draggable item ${id} was dropped.`);
  },
};
// ---------------------

const CrmLeadKanbanKitBody = () => {
  const moduleSlug = "MOD_LEAD";
  const moduleName = "Lead";

  const { t } = useTranslation();

  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const moduleAccess = localStorage.getItem("moduleaccess");

  const commonHeaderObject = crmCommonHeaderLinkLists(t);

  const [showEmptyProcessMessage, setShowEmptyProcessMessage] = useState(false);
  const [processListLoading, setprocessListLoading] = useState(false);
  const [processOwnerValue, setprocessOwnerValue] = useState(null);
  const [isProcessOwner, setisProcessOwner] = useState(false);
  const [moderatorName, setModeratorName] = useState("");
  const [processList, setProcessList] = useState([]);

  const [kanbanData, setKanbanData] = useState([]);

  const [processDetailsLoading, setProcessDetailsLoading] = useState(false);

  //selected process
  const [selectedProcessvalue, setSelectedProcessvalue] = useState(null);
  const [selectedProcessId, setSelectedProcessId] = useState(null);

  // save lead popup, add componentOrderId, get lead or tag details from there
  const [selectedTagId, setSelectedTagId] = useState(null); // component order id
  const [selectedComponentId, setSelectedComponentId] = useState(null); // lead id

  //filter
  const [filterStartDate, setfilterStartDate] = useState("");
  const [filterEndDate, setfilterEndDate] = useState("");
  const [filterLeadName, setFilterLeadName] = useState("");
  const [filterEmail, setFilterEmail] = useState("");
  const [filterPhone, setFilterPhone] = useState("");
  const [filterLocation, setFilterLocation] = useState("");
  const [filterCompany, setFilterCompany] = useState("");
  const [filterPosition, setFilterPosition] = useState("");
  const [filterTransactionModule, setFilterTransactionModule] = useState("");
  const [filterTransactionElement, setFilterTransactionElement] = useState("");
  const [filterSkills, setFilterSkills] = useState([]);
  const [filterMinSalary, setFilterMinSalary] = useState("");
  const [filterMaxSalary, setFilterMaxSalary] = useState("");
  const [filterLabels, setFilterLabels] = useState([]);
  const [filterSubsribeStatus, setFilterSubsribeStatus] = useState("");
  const [isFilterReset, setIsFilterReset] = useState(false);

  const [reloadData, setReloadData] = useState(false);

  const [collapseColumnTags, setCollapseColumnTags] = useState([]); // collapse columns with these tags

  // KPI's to show in kanban selected process
  const [followersCount, setFollowersCount] = useState(0);
  const [cardItemCount, setCardItemCount] = useState(0);
  const [cardValueCount, setCardValueCount] = useState(0);

  //alert requirements
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [messageType, setMessageType] = useState("");

  // selected emails
  const [selectedItemEmails, setSelectedItemEmails] = useState([]);

  //get all process
  const getAllProcess = async () => {
    try {
      setprocessListLoading(true);
      let requestURL =
        url.API_BASE_URL +
        url.API_GET_ALL_PROCESS +
        `?token=${token}&ismod=${true}&istemplate=${false}&moduleslug=${moduleSlug}`;

      const response = await getData(requestURL);

      setprocessListLoading(false);

      console.log("response user all process----->", response);

      if (response.status) {
        // if (response.data.length === 0) {
        //   setShowEmptyProcessMessage(true);
        // } else {
        //   setShowEmptyProcessMessage(false);
        // }

        const processOptions = response.data.map((item) => ({
          label: `${item.title} ${item.isowner ? "(Owned)" : ""}`,
          value: item._id,
        }));

        // if (response.lastsavedprocessid) {
        //   proecessSelectionHandler(
        //     processOptions.find(
        //       (item) => item.value === response.lastsavedprocessid
        //     )
        //   );
        // } else {
        //   proecessSelectionHandler(processOptions[processOptions.length - 1]);
        // }

        console.log("processOptions", processOptions);

        setProcessList(processOptions);
      } else {
        setMessageType("error");
        setAlertMessage(response.message);
        setShowAlert(true);
      }
    } catch (error) {
      setMessageType("error");
      setAlertMessage(error.message);
      setShowAlert(true);
    }
  };

  //select process
  const processSelectionHandler = (val) => {
    if (val) {
      setSelectedProcessvalue(val);
      //getProcessDetails(val.value);
      setSelectedProcessId(val.value);
    } else {
      setSelectedProcessvalue(null);
      //getProcessDetails(null);
      setSelectedProcessId(null);
    }
  };

  // when add or edit board --------
  const addEditBoardHandler = () => {
    console.log("addEditBoardHandler");

    getAllProcess();

    if (selectedProcessId) {
      getProcessTags();
    }
  };

  useEffect(() => {
    getAllProcess();
  }, []);

  //function for close process template modal
  const afterProcessModalClose = () => {
    getAllProcess();
  };

  //function for reset filter
  const resetFilterData = () => {
    setReloadData(true);
  };

  const onAlertClose = () => {
    setShowAlert(false);
    setAlertMessage("");
    setMessageType("");
  };

  useEffect(() => {
    if (reloadData) {
      setIsFilterReset(true);
      getProcessTags(selectedProcessId);
      setReloadData(false);
    }
  }, [reloadData]);

  // ----------------------------------------------------------------------
  // new callbacks for dnd-kit --------------------------------------------
  // ----------------------------------------------------------------------
  const [isDragging, setIsDragging] = useState(false);

  const [addNewBoard, setAddNewBoard] = useState(false); // if true, open the modal to add a new board
  // if false, open modal to edit the selected board
  const [requestBoardUpdate, setRequestBoardUpdate] = useState(false); // if true, update the board data

  const afterEmailClose = () => {
    setSelectedItemEmails([]);
  };

  // open the modal to edit the selected board
  const editSelectedBoardHandler = () => {
    console.log("editSelectedBoardHandler");
    setAddNewBoard(false);

    setRequestBoardUpdate(true);

    console.log("selectedProcessId", selectedProcessId);

    const offcanvas = new bootstrap.Offcanvas("#activityProcessOffCanvas");
    offcanvas.show();
  };

  // open the modal to add a new board
  const createNewBoardHandler = () => {
    console.log("createNewBoardHandler");
    setAddNewBoard(true);

    const offcanvas = new bootstrap.Offcanvas("#activityProcessOffCanvas");
    offcanvas.show();
  };

  // copy from template
  const copyFromTemplateHandler = () => {
    console.log("copyFromTemplateHandler");
    setAddNewBoard(false);

    const offcanvas = new bootstrap.Offcanvas("#kanbanExample");
    offcanvas.show();
  };

  // dnd-kit ------------------------------------------------------------------

  const [processTags, setProcessTags] = useState([]);

  const [items, setItems] = useState([]);

  // sample item structure
  // const [items, setItems] = useState([
  //       {
  //         id: "root",
  //         label: "ROOT",
  //         items: [
  //           { id: "1", value: "Value1", label: "Label1" },
  //           { id: "2", value: "Value2", label: "Label2" },
  //           { id: "3", value: "Value3", label: "Label3" },
  //           { id: "11", value: "Value11", label: "Label11" },
  //           { id: "12", value: "Value12", label: "Label12" },
  //           { id: "13", value: "Value13", label: "Label13" }
  //         ]
  //       },
  //       {
  //         id: "container1",
  //         label: "CON 1",
  //         items: [
  //           { id: "4", value: "Value4", label: "Label4" },
  //           { id: "5", value: "Value5", label: "Label5" },
  //           { id: "6", value: "Value6", label: "Label6" }
  //         ]
  //       },
  //       {
  //         id: "container2",
  //         label: "CON 2",
  //         items: [
  //           { id: "7", value: "Value7", label: "Label7" },
  //           { id: "8", value: "Value8", label: "Label8" },
  //           { id: "9", value: "Value9", label: "Label9" }
  //         ]
  //       },
  //       {
  //         id: "container3",
  //         label: "CON 3",
  //         items: []
  //       }
  //     ]);

  // get process tags i.e column names and items in the column ************
  const getProcessTags = async () => {
    try {
      setProcessDetailsLoading(true);

      setProcessTags([]);

      let requestURL =
        url.API_BASE_URL +
        url.API_GET_PROCESS_TAGS +
        `/${selectedProcessId}?token=${token}`;

      const response = await getData(requestURL);

      console.log("response getProcessTags----->", response);

      if (response.status) {
        if (response.data.length > 0) {
          const boardColumns = await Promise.all(
            response.data.map(async (item) => {
              // set item.id in processTags
              setProcessTags((prev) => [...prev, item.value]);

              // set json data for each column ---------------------
              const columnItemsURL =
                url.API_BASE_URL +
                url.API_GET_COMPONENTORDER_LEAD_BY_TAGS +
                `/${item.value}?token=${token}`;

              console.log("columnItemsURL", columnItemsURL);

              const columnItemsResponse = await getData(columnItemsURL);

              console.log("columnItemsResponse", columnItemsResponse);

              // column items with leads data and component order record id
              const columnItems =
                columnItemsResponse.data.length > 0
                  ? columnItemsResponse.data.map((itemData) => {
                      return {
                        id: itemData._id,
                        order: itemData.order,
                        value: itemData.lead._id,
                        label:
                          itemData.lead?.name ??
                          "" + " " + itemData.lead?.surname ??
                          "",
                        lead: itemData.lead,
                        created: itemData.createdAt,
                        updated: itemData.updatedAt,
                      };
                    })
                  : [];

              // column tag data
              return {
                id: item.value, // tag id
                label: item.label, // tag label
                items: columnItems, // leads with the tag
              };
            })
          );

          console.log("boardColumns", boardColumns);

          setItems(boardColumns);
        }

        setProcessDetailsLoading(false);
      }
    } catch (error) {
      console.log("error", error.message);
    }
  };

  useEffect(() => {
    if (selectedProcessId) {
      getProcessTags();
    } else {
      setItems([]);
    }
  }, [selectedProcessId]);

  const [activeId, setActiveId] = useState();

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  // functions to handle the drag and drop and manage the items in frontend -----------------------------------
  // --------------------------------------
  function findContainer(id) {
    return (
      items.find((container) =>
        container.items.some((item) => item.id === id)
      ) || items.find((container) => container.id === id)
    );
  }

  // ------------------------------------------------
  function handleDragStart(event) {
    console.log("onDragStart");

    const { active } = event;
    const { id } = active;

    setActiveId(id);
  }

  // -------------------------------------------------------
  function handleDragOver(event) {
    console.log("onDragOver");

    const { active, over, draggingRect } = event;
    const { id } = active;
    const { id: overId } = over;

    const activeContainer = findContainer(id);
    const overContainer = findContainer(overId);

    if (
      !activeContainer ||
      !overContainer ||
      activeContainer === overContainer
    ) {
      return;
    }

    setItems((prev) => {
      const activeItems = activeContainer.items;
      const overItems = overContainer.items;

      const activeIndex = activeItems.findIndex((item) => item.id === id);
      const overIndex = overItems.findIndex((item) => item.id === overId);

      let newIndex;
      if (overContainer.items.length === 0) {
        newIndex = 0;
      } else {
        const isBelowLastItem =
          over &&
          over.rect &&
          draggingRect &&
          overIndex === overItems.length - 1 &&
          draggingRect.offsetTop > over.rect.offsetTop + over.rect.height;

        const modifier = isBelowLastItem ? 1 : 0;

        newIndex = overIndex >= 0 ? overIndex + modifier : overItems.length + 1;
      }

      return prev.map((container) => {
        if (container.id === activeContainer.id) {
          return {
            ...container,
            items: [...container.items.filter((item) => item.id !== active.id)],
          };
        } else if (container.id === overContainer.id) {
          return {
            ...container,
            items: [
              ...container.items.slice(0, newIndex),
              activeItems[activeIndex],
              ...container.items.slice(newIndex, container.items.length),
            ],
          };
        } else {
          return container;
        }
      });
    });
  }

  // ----------------------------------------------------------
  const handleDragEnd = async (event) => {
    if (!isDragging) {
      console.log("drag end -- not dragging");
      return;
    }

    setIsDragging(false);

    //console.log('drag end', event);

    const { active, over } = event;

    if (!active || !over) {
      return;
    }

    const activeId = active.id;
    const overId = over.id;

    const activeContainer = findContainer(activeId);
    const overContainer = findContainer(overId);

    if (!activeContainer || !overContainer) {
      return;
    }

    const activeContainerId = activeContainer.id;
    const overContainerId = overContainer.id;

    let updatedItemsDetails = overContainer.items;

    if (activeContainerId !== overContainerId) {
      // Dragging between different containers
      console.log("Dragging between different containers");

      setItems((prevItems) =>
        prevItems.map((container) => {
          if (container.id === activeContainerId) {
            return {
              ...container,
              items: container.items.filter((item) => item.id !== activeId),
            };
          } else if (container.id === overContainerId) {
            return {
              ...container,
              items: [
                ...container.items,
                activeContainer.items.find((item) => item.id === activeId),
              ],
            };
          } else {
            return container;
          }
        })
      );

      updatedItemsDetails = overContainer.items;
    } else {
      // Dragging within the same container
      console.log("Dragging within the same container");
      const activeIndex = activeContainer.items.findIndex(
        (item) => item.id === activeId
      );
      const overIndex = activeContainer.items.findIndex(
        (item) => item.id === overId
      );

      if (activeIndex !== overIndex) {
        const updatedItems = arrayMove(
          activeContainer.items,
          activeIndex,
          overIndex
        );

        setItems((prevItems) =>
          prevItems.map((container) =>
            container.id === activeContainerId
              ? { ...container, items: updatedItems }
              : container
          )
        );

        updatedItemsDetails = updatedItems;
      }
    }

    console.log("activeId", activeId);
    console.log("containerId", overContainerId);
    console.log("itemDetails", updatedItemsDetails);

    // updat component order ------------------------------------
    // Prepare the data to send in the API request
    const updatedOrder = {
      tagids: processTags, // The list of tags in the board
      activeItemId: activeId, // The ID of the dragged item
      containerDetails: {
        containerId: overContainerId, // The ID of the container where the item is dropped
        itemIds: updatedItemsDetails.map((item) => item.id), // The list of item IDs in the new container
      },
    };

    try {
      // API request to update the component order
      const requestURL =
        url.API_BASE_URL +
        url.API_POST_UPDATE_COMPONENTORDER_LEAD +
        `?token=${token}`;

      const response = await postData(requestURL, updatedOrder);

      console.log("Update Data -- response", response);

      if (response.status) {
        setMessageType("success");
        setAlertMessage(response.message);
        setShowAlert(true);
      }
    } catch (error) {
      console.error("Error updating order:", error);
    }

    // update component order ends ------------------------------------
  };

  // --------- manage state to trigger dragEng API call ----------------------------------------
  const handleDragMove = async (event) => {
    console.log("drag move");
    setIsDragging(true);
  };

  // functions ends -----------------------------------

  useEffect(() => {
    console.log("selectedItemEmails", selectedItemEmails);
  }, [selectedItemEmails]);

  return (
    <div id="content_wrapper">
      <section className="crm-wrapper bg-white pb-5">
        <TabsHeader
          commonHeaderObject={commonHeaderObject}
          activeOption={t("Leads")}
        />
        <div className="container-fluid px-lg-5">
          <CrmLeadKanbanHeader reloadLeadList={resetFilterData} />

          <div className="project-tasks-container">
            {/* KANBAN BOARD --- OPTIONS -- CREATE/UPDATE/MAIL ITEMS */}
            <div className="challenges-process-row d-flex align-items-center justify-content-between gap-3 mb-1">
              <div className="left-process-select d-flex align-items-center gap-3">
                <Select
                  // className="form-select fs-sm shadow-none"
                  isClearable
                  placeholder="Select process"
                  options={processList}
                  value={selectedProcessvalue}
                  onChange={(val) => processSelectionHandler(val)}
                />

                <div className="d-flex align-items-center gap-2">
                  <span>
                    <b>Moderator:</b> {moderatorName}{" "}
                  </span>
                  <span>
                    <b>Team:</b> {followersCount}{" "}
                  </span>
                  <span>
                    <b>Op:</b> {cardItemCount}{" "}
                  </span>
                  <div className="d-flex align-items-center gap-1">
                    <b>Total:</b> {formatNumber(cardValueCount)}{" "}
                    <span className="d-block material-symbols-outlined icon-sm">
                      euro
                    </span>
                  </div>
                </div>
              </div>

              <div className="right-process-btn d-flex align-items-center gap-2">
                {selectedItemEmails.length > 0 && (
                  <Link
                    className="btn btn-primary d-flex align-items-center gap-1"
                    to="#"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasNewMail"
                  >
                    <span className="d-block material-symbols-outlined icon-sm">
                      email
                    </span>
                    <span className="d-block">{t("Send Mail")}</span>
                  </Link>
                )}

                {selectedProcessId && (
                  <Link
                    to="#"
                    className="btn btn-primary d-flex align-items-center gap-1"
                    onClick={() => editSelectedBoardHandler()}
                  >
                    <span className="d-block material-symbols-outlined icon-md">
                      add
                    </span>
                    <span className="d-block">Edit Board</span>
                  </Link>
                )}

                <div className="dropdown flex-fill flex-grow-sm-0">
                  <button
                    className="btn btn-primary text-start w-100 dropdown-toggle"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    {t("Add New Board")}
                  </button>
                  <ul className="dropdown-menu w-100 bg-white fs-sm border-0 rounded-10 shadow-sm">
                    <li>
                      <Link
                        className="dropdown-item d-flex align-items-center gap-1"
                        to="#"
                        onClick={() => createNewBoardHandler()}
                      >
                        <span className="d-block material-symbols-outlined icon-sm">
                          add
                        </span>
                        <span className="d-block">
                          {t("Create a new board")}
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="#kanbanExample"
                        className="dropdown-item d-flex align-items-center gap-1"
                        data-bs-toggle="modal"
                        data-bs-target="#kanbanExample"
                      >
                        <span className="d-block material-symbols-outlined icon-sm">
                          view_kanban
                        </span>
                        <span className="d-block">
                          {t("Copy from template")}
                        </span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            {/* KANBAN BOARD -------------  */}

            {selectedProcessId ? (
              processDetailsLoading ? (
                // show placeholder of loading
                <div className="board_outer">
                  <div className="project-tasks-container d-flex overflow-x-auto pb-3 grid-view gap-2">
                    {[1, 2, 3, 4].map((item, index) => {
                      return (
                        <div
                          className="col-lg-3 d-flex flex-column gap-2"
                          key={index}
                        >
                          <div className="card-title gradient-light p-3 rounded-10 d-flex justify-content-between align-items-center mb-2">
                            <h3 className="lh-1 mb-0">
                              <span
                                className="d-block placeholder text-gray"
                                style={{ width: "15rem", height: "0.5rem" }}
                              ></span>
                            </h3>
                          </div>

                          <div className="single-card p-3 border border-gray-300 rounded-10 mb-2">
                            <div className="title d-flex align-items-center gap-3 justify-content-between">
                              <p className="fs-md fw-semibold">
                                <span
                                  className="d-block placeholder text-gray"
                                  style={{ width: "15rem", height: "0.5rem" }}
                                ></span>
                              </p>
                              <div className="d-flex ms-auto">
                                <span
                                  className="d-block placeholder text-gray"
                                  style={{
                                    width: "0.1rem",
                                    height: "0.5rem",
                                  }}
                                ></span>
                              </div>
                            </div>
                            <p className="fs-sm text-gray d-flex align-items-center gap-1 my-3">
                              <span className="d-block material-symbols-outlined icon-sm">
                                calendar_month
                              </span>
                              <span
                                className="d-block placeholder"
                                style={{ width: "5rem", height: "0.5rem" }}
                              ></span>
                              <span
                                className="d-block placeholder ms-2"
                                style={{ width: "5rem", height: "0.5rem" }}
                              ></span>
                            </p>
                            <div className="d-flex align-items-center gap-3">
                              <div className="profile d-flex align-items-center gap-2">
                                <div
                                  className="avatar rounded-circle overflow-hidden"
                                  style={{
                                    width: "22px",
                                    height: "22px",
                                  }}
                                >
                                  <img
                                    src={assetImages.defaultUser}
                                    alt="User"
                                    className="w-100 h-100 object-fit-cover object-center"
                                  />
                                </div>
                                <p className="fs-sm">
                                  <span
                                    className="d-block placeholder text-gray"
                                    style={{
                                      width: "5rem",
                                      height: "0.5rem",
                                    }}
                                  ></span>
                                </p>
                              </div>
                              <p className="d-flex align-items-center gap-1">
                                <span className="d-block material-symbols-outlined icon-sm">
                                  schedule
                                </span>
                                <span
                                  className="d-block fs-sm placeholder text-gray"
                                  style={{ width: "5rem", height: "0.5rem" }}
                                ></span>
                              </p>
                              <span
                                className="d-inline-block fs-xs lh-1 text-white px-2 py-1 bg-primary rounded-90 placeholder"
                                style={{ width: "5rem", height: "1rem" }}
                              ></span>
                            </div>
                          </div>
                          <div className="single-card p-3 border border-gray-300 rounded-10 mb-2">
                            <div className="title d-flex align-items-center gap-3 justify-content-between">
                              <p className="fs-md fw-semibold">
                                <span
                                  className="d-block placeholder text-gray"
                                  style={{ width: "15rem", height: "0.5rem" }}
                                ></span>
                              </p>
                              <div className="d-flex ms-auto">
                                <span
                                  className="d-block placeholder text-gray"
                                  style={{
                                    width: "0.1rem",
                                    height: "0.5rem",
                                  }}
                                ></span>
                              </div>
                            </div>
                            <p className="fs-sm text-gray d-flex align-items-center gap-1 my-3">
                              <span className="d-block material-symbols-outlined icon-sm">
                                calendar_month
                              </span>
                              <span
                                className="d-block placeholder"
                                style={{ width: "5rem", height: "0.5rem" }}
                              ></span>
                              <span
                                className="d-block placeholder ms-2"
                                style={{ width: "5rem", height: "0.5rem" }}
                              ></span>
                            </p>
                            <div className="d-flex align-items-center gap-3">
                              <div className="profile d-flex align-items-center gap-2">
                                <div
                                  className="avatar rounded-circle overflow-hidden"
                                  style={{
                                    width: "22px",
                                    height: "22px",
                                  }}
                                >
                                  <img
                                    src={assetImages.defaultUser}
                                    alt="User"
                                    className="w-100 h-100 object-fit-cover object-center"
                                  />
                                </div>
                                <p className="fs-sm">
                                  <span
                                    className="d-block placeholder text-gray"
                                    style={{
                                      width: "5rem",
                                      height: "0.5rem",
                                    }}
                                  ></span>
                                </p>
                              </div>
                              <p className="d-flex align-items-center gap-1">
                                <span className="d-block material-symbols-outlined icon-sm">
                                  schedule
                                </span>
                                <span
                                  className="d-block fs-sm placeholder text-gray"
                                  style={{ width: "5rem", height: "0.5rem" }}
                                ></span>
                              </p>
                              <span
                                className="d-inline-block fs-xs lh-1 text-white px-2 py-1 bg-primary rounded-90 placeholder"
                                style={{ width: "5rem", height: "1rem" }}
                              ></span>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              ) : (
                // show kanban board
                <div className="board_outer">
                  <div className="project-tasks-container d-flex overflow-x-auto pb-3 grid-view gap-2">
                    <DndContext
                      announcements={defaultAnnouncements}
                      sensors={sensors}
                      collisionDetection={closestCorners}
                      onDragStart={handleDragStart}
                      onDragOver={handleDragOver}
                      onDragEnd={handleDragEnd}
                      onDragMove={handleDragMove}
                    >
                      {items.map((container) => (
                        <Container
                          key={container.id}
                          id={container.id}
                          items={container.items}
                          columnlabel={container.label}
                          setReloadData={setReloadData}
                          collapseColumnTags={collapseColumnTags}
                          setCollapseColumnTags={setCollapseColumnTags}
                          setSelectedComponentId={setSelectedComponentId}
                          setSelectedTagId={setSelectedTagId}
                          selectedItemEmails={selectedItemEmails}
                          setSelectedItemEmails={setSelectedItemEmails}
                        />
                      ))}

                      <DragOverlay>
                        {activeId && findContainer(activeId) ? (
                          <ColumnItem
                            id={activeId}
                            item={findContainer(activeId).items.find(
                              (item) => item.id === activeId
                            )}
                          />
                        ) : null}
                      </DragOverlay>
                    </DndContext>
                  </div>
                </div>
              )
            ) : (
              // show empty process message
              <div className="challenges_empty text-center">
                <div className="empty_pic mb-4">
                  {" "}
                  <img src={assetImages.emptyVector} alt="" />
                </div>
                <div className="empty_text">
                  <p className="fs-lg text-gray fw-semibold mb-4">
                    Select and existing process or create a new one
                  </p>
                  <div className="d-flex align-items-center justify-content-center gap-2">
                    <Link
                      to="#kanbanExample"
                      className="btn btn-outline-primary d-flex align-items-center gap-1"
                      data-bs-toggle="modal"
                      data-bs-target="#kanbanExample"
                      role="button"
                      aria-controls="saveProcess"
                    >
                      <span className="d-block material-symbols-outlined icon-md">
                        add
                      </span>
                      <span className="d-block">
                        Add Process From Templates
                      </span>
                    </Link>
                    <Link
                      to="#activityProcessOffCanvas"
                      data-bs-toggle="offcanvas"
                      role="button"
                      aria-controls="saveProcess"
                      className="btn btn-primary d-inline-flex align-items-center gap-1"
                    >
                      <span className="d-block material-symbols-outlined icon-md">
                        add
                      </span>
                      <span className="d-block">Add Process</span>
                    </Link>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </section>

      {/* save or udpate lead and add notes */}
      <SaveLeadPopup
        selectedTagId={selectedTagId}
        setSelectedTagId={setSelectedTagId}
        selectedComponentId={selectedComponentId} // lead id
        setSelectedComponentId={setSelectedComponentId}
        setReloadData={setReloadData}
      />

      {/*Add or update custom process or board  */}

      {addNewBoard ? (
        <SaveActivityProcessPopup
          moduleSlug={moduleSlug}
          afterPopupClose={addEditBoardHandler}
          isTemplate={false}
          setShowAlert={setShowAlert}
          setAlertMessage={setAlertMessage}
          setMessageType={setMessageType}
          setRequestBoardUpdate={setRequestBoardUpdate}
        />
      ) : (
        <SaveActivityProcessPopup
          moduleSlug={moduleSlug}
          afterPopupClose={addEditBoardHandler}
          setSelectProcessId={() => {
            setSelectedProcessId;
          }}
          selectedProcessId={selectedProcessId}
          isTemplate={false}
          setShowAlert={setShowAlert}
          setAlertMessage={setAlertMessage}
          setMessageType={setMessageType}
          requestBoardUpdate={requestBoardUpdate}
          setRequestBoardUpdate={setRequestBoardUpdate}
        />
      )}

      {/* Add process from template */}
      <AddProcessFromTemplateModal
        moduleSlug={moduleSlug}
        afterProcessModalClose={afterProcessModalClose}
        setShowAlert={setShowAlert}
        setAlertMessage={setAlertMessage}
        setMessageType={setMessageType}
      />

      {/* ------ new mail popup render here ----- */}
      <ConversationNewMailPopup
        contactPersonEmail={selectedItemEmails.join(", ")}
        reloadList={afterEmailClose}
        setShowAlert={setShowAlert}
        setAlertMessage={setAlertMessage}
        setMessageType={setMessageType}
      />

      <CrmLeadKanbanFilterPopup
        moduleName={moduleName}
        filterSubsribeStatus={filterSubsribeStatus}
        setFilterSubsribeStatus={setFilterSubsribeStatus}
        filterStartDate={filterStartDate}
        setfilterStartDate={setfilterStartDate}
        filterEndDate={filterEndDate}
        setfilterEndDate={setfilterEndDate}
        filterLeadName={filterLeadName}
        setFilterLeadName={setFilterLeadName}
        filterEmail={filterEmail}
        setFilterEmail={setFilterEmail}
        filterPhone={filterPhone}
        setFilterPhone={setFilterPhone}
        filterLocation={filterLocation}
        setFilterLocation={setFilterLocation}
        filterCompany={filterCompany}
        setFilterCompany={setFilterCompany}
        filterPosition={filterPosition}
        setFilterPosition={setFilterPosition}
        //filterTransactionModule={filterTransactionModule}
        //setFilterTransactionModule={setFilterTransactionModule}
        //filterTransactionElement={filterTransactionElement}
        //setFilterTransactionElement={setFilterTransactionElement}
        filterSkills={filterSkills}
        setFilterSkills={setFilterSkills}
        filterMinSalary={filterMinSalary}
        setFilterMinSalary={setFilterMinSalary}
        filterMaxSalary={filterMaxSalary}
        setFilterMaxSalary={setFilterMaxSalary}
        filterLabels={filterLabels}
        setFilterLabels={setFilterLabels}
        isFilterReset={isFilterReset}
        setIsFilterReset={setIsFilterReset}
        //setIsNoDefaultFilter={setIsNoDefaultFilter}
      />

      {showAlert && (
        <AlertNotification
          showAlert={showAlert}
          message={alertMessage}
          alertType={messageType}
          onClose={onAlertClose}
        />
      )}
    </div>
  );
};

export default CrmLeadKanbanKitBody;
