/* eslint-disable */
import { useState, useEffect, useRef } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import Select from "react-select";
import { useTranslation } from "react-i18next";

import TabsHeader from "components/Common/TabsHeader/TabsHeader";
import { productDetailsHeaderLinks } from "helper/ProductHelper/ProductHelper";

import TreeView from "./TreeView";

const ProductCategoriesBody = () => {
  const { t } = useTranslation(); //for translation

  const params = useParams();
  const history = useHistory();

  const commonHeaderObject = productDetailsHeaderLinks(params.id, t);

  return (
    <div id="content_wrapper ">
      <section className="marketplace-wrapper bg-white">
        {/* ---- event details common header ---- */}
        {params.id && (
          <TabsHeader
            commonHeaderObject={commonHeaderObject}
            activeOption={t("Category")}
          />
        )}

        <TreeView />
      </section>
    </div>
  );
};

export default ProductCategoriesBody;
