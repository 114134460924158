/* eslint-disable */
import { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";

import TabsHeader from "components/Common/TabsHeader/TabsHeader";
import ChallengeResponseLeftPanel from "./LeftPanel/ChallengeResponseLeftPanel";
import ChallengeResponseRightPanel from "./RightPanel/ChallengeResponseRightPanel";

import ResponseCommentPopup from "../Common/Popup/ResponseCommentPopup";

import { getData, postData, putData } from "utils/Gateway";
import * as url from "helper/UrlHelper";

import {
  challengeHeaderLinks,
  challnegeBreadCrumbText,
  getJobResponseStatus,
} from "helper/ChallengeHelper/ChallengeHelper";

import { useTranslation } from "react-i18next";
import WinnerSeclectionModal from "./Modals/WinnerSeclectionModal";
import { getAllCategories } from "helper/CategoryHelper/CategoryHelper";
import { ecosystemSlug } from "Config/Config";
import BreadCrumb from "components/Common/BreadCrumb/BreadCrumb";

const ChallengeResponseDetailsBody = () => {
  const params = useParams();
  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const moduleAccess = localStorage.getItem("moduleaccess");
  const { t, i18n } = useTranslation(); //for translation

  // common header object, by challenge id
  const commonHeaderObject = challengeHeaderLinks(params.id, t);

  const challengeResponseId = params.rid; //challenge response id

  const [challengeResponseDetails, setChallengeResponseDetails] =
    useState(null);

  const [cvId, setCvId] = useState(null);

  // approval and status management
  const [approvalStatus, setapprovalStatus] = useState("");
  const [selectedHiringProcessId, setSelectedHiringProcessId] = useState(null);
  const [approveComments, setapproveComments] = useState("");

  const [reloadResponseDetails, setReloadResponseDetails] = useState(false);

  //template for kpis block
  const kpisBlockTemplate = {
    skillValue: null,
    selectedSkill: null,
    point: "",
  };

  const [kpisBlock, setKpisBlock] = useState([]);

  const [submittedById, setsubmittedById] = useState(null);
  const [challengeTitle, setChallengeTitle] = useState("");

  /* ---- bread crumb text ---- */
  const breadcrumbText = challnegeBreadCrumbText(
    t("Response"),
    challengeTitle,
    t
  );

  //add kpis block
  const addNewKpisBlock = () => {
    setKpisBlock([...kpisBlock, kpisBlockTemplate]);
  };

  //function for delete action link block
  const onDeleteKpisBlock = (index) => {
    const filteredKpisBlock = [...kpisBlock];
    filteredKpisBlock.splice(index, 1);
    setKpisBlock(filteredKpisBlock);
  };

  //select skillpoint
  const selectKpisSkills = (index, field, field2, value) => {
    const updatedFormValues = kpisBlock.map((block, i) =>
      index === i
        ? value
          ? Object.assign(block, { [field]: value, [field2]: value.value })
          : Object.assign(block, { [field]: null, [field2]: null })
        : block
    );
    setKpisBlock(updatedFormValues);
  };

  //change points
  const changeSkillPointInputs = (index, field, value) => {
    const updatedFormValues = kpisBlock.map((block, i) =>
      index == i ? Object.assign(block, { [field]: value }) : block
    );

    setKpisBlock(updatedFormValues);
  };

  //function for get project details
  const getChallengeResponseDetails = async () => {
    //setIsLoading(true);
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_CHALLENGE_RESPONSE_DETAILS +
        `/${challengeResponseId}?token=${token}`;

      const response = await getData(requestUrl);

      console.log("challenge response", response);

      if (response.status && response.data) {
        const data = response.data;
        setChallengeResponseDetails(data);

        setCvId(data.candidatecvid ? data.candidatecvid._id : null);

        setsubmittedById(data.submittedbyid);

        setChallengeTitle(data.challengeid.name);
      }

      //setIsLoading(false);
    } catch (error) {
      console.log(error.message);
    }
  };

  //approval action
  const approvalHandler = async () => {
    try {
      let approvalData = {
        approvalstatus: approvalStatus,
        hiringprocessid: selectedHiringProcessId || null,
        comments: approveComments,
        kpisblock: kpisBlock || [],
      };

      console.log("approvalData---->", approvalData);

      let requestUrl =
        url.API_BASE_URL +
        url.API_CHANGE_APPROVAL_STATUS +
        `/${challengeResponseId}?token=${token}`;

      const response = await putData(requestUrl, approvalData);

      console.log("Challange Activity Response", response);

      if (response.status) {
        getChallengeResponseDetails(challengeResponseId);
        setapproveComments("");
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //create chat
  const createContactChatHandler = async () => {
    if (submittedById && submittedById.toString() !== userInfo._id.toString()) {
      try {
        let requestURL =
          url.API_BASE_URL +
          url.API_SEARCH_CONTACT +
          `?token=${token}&member=${submittedById}`;

        const response = await getData(requestURL);

        console.log(response);

        if (response.status) {
          const externalUrl = `${url.FRONTEND_BASE_URL}/conversation/contact/${response.data._id}/MOD_CONTACT/Challenges/${challengeTitle}/${params.id}`; // Replace with your external URL

          window.open(externalUrl, "_blank", "noopener,noreferrer");
        }
      } catch (error) {
        console.log(error.message);
      }
    } else {
    }
  };

  useEffect(() => {
    if (reloadResponseDetails) {
      getChallengeResponseDetails(challengeResponseId);
      setReloadResponseDetails(false);
    }
  }, [reloadResponseDetails]);

  useEffect(() => {
    if (challengeResponseId) {
      getChallengeResponseDetails(challengeResponseId);
    }
  }, [challengeResponseId]);

  return (
    <div id="content_wrapper">
      <section className="crm-wrapper bg-white pb-5 pt-3">
        {/* ---- common header ---- */}
        {params.id && (
          <TabsHeader
            commonHeaderObject={commonHeaderObject}
            activeOption={t("Responses")}
          />
        )}

        <div className="container-fluid px-lg-5 pt-4">
          <BreadCrumb breadCrumbText={breadcrumbText} bottom={true} />
          <div className="row">
            {/* save or update the response if required */}
            <div className="col-lg-8 mb-4 mb-lg-0">
              {challengeResponseDetails && (
                <ChallengeResponseLeftPanel
                  challengeResponseDetails={challengeResponseDetails}
                  cvId={cvId}
                  setReloadResponseDetails={setReloadResponseDetails}
                  createContactChatHandler={createContactChatHandler}
                />
              )}
            </div>

            {/* show the information about the status of the response */}
            <div className="col-lg-4">
              {challengeResponseDetails && (
                <ChallengeResponseRightPanel
                  challengeResponseId={challengeResponseId}
                  challengeResponseDetails={challengeResponseDetails}
                  cvId={cvId}
                  setapprovalStatus={setapprovalStatus}
                  setSelectedHiringProcessId={setSelectedHiringProcessId}
                />
              )}
            </div>
          </div>
        </div>
      </section>

      {/* Reject status ------------------------- */}
      {challengeResponseDetails && (
        <ResponseCommentPopup
          selectedHiringProcessId={selectedHiringProcessId}
          setSelectedHiringProcessId={setSelectedHiringProcessId}
          kpisBlock={kpisBlock}
          approvalStatus={approvalStatus}
          setapproveComments={setapproveComments}
          approveComments={approveComments}
          setKpisBlock={setKpisBlock}
          addNewKpisBlock={addNewKpisBlock}
          onDeleteKpisBlock={onDeleteKpisBlock}
          selectKpisSkills={selectKpisSkills}
          changeSkillPointInputs={changeSkillPointInputs}
          approvalHandler={approvalHandler}
        />
      )}

      {challengeResponseDetails && (
        <WinnerSeclectionModal
          challengeResponseId={challengeResponseId}
          afterWinnerResults={getChallengeResponseDetails}
        />
      )}
    </div>
  );
};

export default ChallengeResponseDetailsBody;
