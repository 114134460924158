//function common header crm links
const projectDetailsHeaderLinks = (id, t) => {
  return [
    { title: t("Details"), link: `/admin/projectmanagement/details/${id}` },
    {
      title: t("Taxonomies"),
      link: `/admin/projectmanagement/taxonomies/${id}`,
    },
    { title: t("Members"), link: `/admin/projectmanagement/members/${id}` },
    { title: t("Tasks"), link: `/admin/projectmanagement/tasksection/${id}` },
    { title: t("Logs"), link: `/admin/projectmanagement/logs/${id}` },
  ];
};

export { projectDetailsHeaderLinks };
