/* eslint-disable */
import Header from "components/Common/Header/Header";
import OrderListBody from "components/MarketplaceComponents/OrderComponents/OrderListBody/OrderListBody";
import { useEffect } from "react";

const OrderList = () => {
  useEffect(() => {
    document.title = "Order List";
  }, []);

  return (
    <main id="app">
      {/* ---- common header ---- */}
      <Header moduleName="marketplace" />

      <OrderListBody />
    </main>
  );
};

export default OrderList;
