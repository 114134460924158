/* eslint-disable */
import React from "react";
import { Link } from "react-router-dom";

const TabsHeader = ({ commonHeaderObject, activeOption }) => {
  return (
    <div className="inner-menu-container bg-white shadow-sm">
      <div className="container-fluid px-lg-5">
        <ul className="nav secondary-menu mw-100 flex-nowrap gap-xl-5 flex-fill fs-sm fw-semibold text-center border-0 text-nowrap overflow-auto">
          {/* ------- map loop -------- */}
          {commonHeaderObject.map((item, index) => {
            return (
              <li key={index} className="nav-item flex-fill">
                <Link
                  to={item.link}
                  className={`d-block text-black px-2 px-sm-3 py-3 py-sm-4 position-relative ${
                    item.title === activeOption && "active"
                  } `}
                >
                  {item.title}
                </Link>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default TabsHeader;
