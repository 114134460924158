import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

import { assetImages } from "constants";

import * as url from "helper/UrlHelper";

import { createdAtToDateConverter } from "helper/Common/CommonHelper";
import { createContactChatHandler } from "helper/AppUserHelper/AppUserHelper";

import { getData } from "utils/Gateway";

const ColumnItem = (props) => {
  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const [itemEmail, setItemEmail] = useState("");
  const [itemName, setItemName] = useState("");
  const [isLead, setIsLead] = useState(false);
  const [isContact, setIsContact] = useState(false);

  const {
    id,
    item,
    setReloadData,
    setSelectedComponentId,
    setSelectedTagId,
    selectedItemEmails,
    setSelectedItemEmails,
  } = props;

  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    padding: 2,
    margin: 2,
    background: "#fff",
    //border: "1px solid #ccc"
  };

  // delete item
  const deleteComponentOrder = async (id) => {
    console.log("Delete Item: ", id);

    if (!window.confirm("Are you sure you want to delete this item?")) {
      return;
    }

    // set json data for each column ---------------------
    const columnItemsURL =
      url.API_BASE_URL +
      url.API_DELETE_COMPONENTORDER +
      `/${id}?token=${token}`;

    console.log("columnItemsURL", columnItemsURL);

    const response = await getData(columnItemsURL);

    console.log("deleteComponentOrderResponse", response);

    if (response.status) {
      setReloadData(true);
    }
  };

  const viewItemDetails = (itemid) => {
    console.log("viewItemDetails for id", itemid);

    setSelectedComponentId(itemid);
    setSelectedTagId(null);
  };

  const associateUserInfo = (item) => {
    setIsLead(true);

    let email = item.invoice?.leadDetails?.email ?? "";
    let associatedUserName = item.invoice
      ? item.invoice?.leadDetails
        ? `${item.invoice?.leadDetails?.name ?? ""} ${
            item.invoice?.leadDetails?.surname ?? ""
          }`
        : ""
      : "";

    // if not get email, check if contact
    let contactEmail = item.invoice?.contactDetails?.ownerDetails?.email;
    if (contactEmail) {
      setIsContact(true);
      setIsLead(false);

      if (
        item.invoice?.contactDetails?.ownerDetails?._id ===
        item.invoice?.moderatorDetails?._id
      ) {
        email = item.invoice?.contactDetails?.memberDetails?.email;
        associatedUserName =
          item.invoice?.contactDetails?.memberDetails?.name +
          " " +
          item.invoice?.contactDetails?.memberDetails?.surname;
      } else {
        email = item.invoice?.contactDetails?.ownerDetails?.email;
        associatedUserName =
          item.invoice?.contactDetails?.ownerDetails?.name +
          " " +
          item.invoice?.contactDetails?.ownerDetails?.surname;
      }
    }

    setItemEmail(email);
    setItemName(associatedUserName);
  };

  useEffect(() => {
    associateUserInfo(item);
  }, [item]);

  return (
    // Details about the lead - single card item
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      <div className="single-card p-3 border border-gray-300 rounded-10 mb-2">
        <div className="get-code-row d-flex align-items-center justify-content-between mb-2">
          <div className="d-flex align-items-center gap-2">
            <p
              className="fs-md fw-semibold mb-0 d-flex"
              onPointerDown={(e) => {
                e.stopPropagation();
              }}
            >
              <input
                type="checkbox"
                checked={
                  selectedItemEmails && selectedItemEmails.includes(itemEmail)
                }
                onChange={(e) => {
                  setSelectedItemEmails((prev) => {
                    if (e.target.checked) {
                      console.log("prev", prev);

                      if (itemEmail && !prev.includes(itemEmail)) {
                        return [...prev, itemEmail];
                      }
                      return prev;
                    } else {
                      return prev.filter((email) => email !== itemEmail);
                    }
                  });
                }}
              />
            </p>

            <p
              className="fs-md fw-semibold mb-0 d-flex"
              title="Referenece id"
              onPointerDown={(e) => {
                e.stopPropagation();
                viewItemDetails(item.invoice._id);
              }}
            >
              {item.invoice?.customid ?? ""}
              <span className="d-block material-symbols-outlined icon-md ms-2">
                edit
              </span>
            </p>
          </div>

          <div className="menu_info">
            <button
              className="p-0 bg-transparent border-0 ms-2"
              type="button"
              onPointerDown={(e) => {
                e.stopPropagation();
                deleteComponentOrder(item.id);
              }}
            >
              <span className="d-block material-symbols-outlined icon-md text-danger">
                delete
              </span>
            </button>
          </div>
        </div>

        <div className="card-user-info d-flex align-items-center gap-2 mb-2">
          <h4 className="mb-0 text-black">{item.invoice?.title ?? ""}</h4>
        </div>

        <div
          className={
            itemName === "" ? "d-none" : "d-flex align-items-center gap-2"
          }
        >
          <h4 className="mb-0 text-black">
            <span
              className={`d-block mt-1 d-flex ${
                isContact ? "text-primary" : "text-gray"
              } `}
              title={isContact ? "contact" : "lead"}
            >
              <i className="d-block material-symbols-outlined icon-md me-1">
                {isContact ? "contacts" : "attribution"}
              </i>
              {itemName}
            </span>
          </h4>
        </div>

        <div className={itemEmail === "" ? "d-none" : ""}>
          <Link to="#" className="d-flex align-items-center gap-1">
            <span className="d-block material-symbols-outlined icon-md text-gray">
              mail
            </span>
            <span className="d-block">{itemEmail}</span>
          </Link>
        </div>

        <div className="date-row d-flex align-items-center gap-2">
          <h5 className="d-flex align-items-center gap-1 fs-sm">
            <span className="d-block material-symbols-outlined icon-md text-gray">
              calendar_today
            </span>
            <span
              className="d-block"
              title="Date when the item is added in the board"
            >
              {item.created ? createdAtToDateConverter(item.created) : ""}
            </span>
          </h5>
          -
          <h5 className="d-flex align-items-center gap-1 fs-sm">
            <span className="d-block material-symbols-outlined icon-md text-gray">
              calendar_today
            </span>
            <span
              className="d-block"
              title="Date when the item is added in this column"
            >
              {item.updated ? createdAtToDateConverter(item.updated) : ""}
            </span>
          </h5>
        </div>

        <div className="d-flex align-items-center gap-2">
          <span className="fw-semibold" title="Moderator">
            M
          </span>{" "}
          :
          <div className="profile d-flex align-items-left">
            <i>
              {item.invoice?.moderatorDetails?.name}{" "}
              {item.invoice?.moderatorDetails?.surname}
            </i>

            {item.invoice?.moderatorDetails?._id?.toString() !==
            userInfo._id.toString() ? (
              <button
                title="click to chat"
                className="d-flex ms-auto border-0 bg-light"
                onClick={() => {
                  createContactChatHandler(
                    item.invoice?.moderatorDetails?._id,
                    "MOD_OPPORTUNITY",
                    item.invoice._id,
                    item.invoice.title
                  );
                }}
                onPointerDown={(e) => {
                  e.stopPropagation();
                }}
              >
                <span className="d-block material-symbols-outlined icon-md text-primary">
                  chat
                </span>
              </button>
            ) : (
              ""
            )}
          </div>
        </div>

        {item.invoice?.moderatorDetails?._id?.toString() ===
          userInfo._id.toString() &&
        item.invoice.followersDetails &&
        item.invoice.followersDetails.length > 0 ? (
          <div className="d-flex gap-2">
            <span className="fw-semibold" title="Followers">
              F
            </span>
            <span className="mx-1">:</span>
            <div className="profile d-flex flex-column align-items-left">
              {item.invoice.followersDetails.map((follower, index2) => {
                return (
                  <div className="profile d-flex" key={index2}>
                    <i>
                      {follower?.name} {follower?.surname}
                    </i>

                    {follower?._id?.toString() !== userInfo._id.toString() ? (
                      <button
                        title="click to chat"
                        className="d-flex ms-auto border-0 bg-light"
                        onClick={() => {
                          createContactChatHandler(
                            follower._id,
                            "MOD_OPPORTUNITY",
                            item.invoice._id,
                            item.invoice.title
                          );
                        }}
                        onPointerDown={(e) => {
                          e.stopPropagation();
                        }}
                      >
                        <span className="d-block material-symbols-outlined icon-md text-primary">
                          chat
                        </span>{" "}
                      </button>
                    ) : (
                      ""
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        ) : null}

        <p className="price fw-semibold text-primary my-1">
          {item.invoice?.amount ?? ""} &euro;{" "}
          <span className="text-gray ms-2">
            ({item.invoice?.promisepercent ?? ""} %)
          </span>
        </p>
      </div>
    </div>
  );
};

export default ColumnItem;
