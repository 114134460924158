import { Link } from "react-router-dom";
import Select from "react-select";

// translation --------------
import { useTranslation } from "react-i18next";

// Import CKEditor here (both required).
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import downloadFileHandler from "helper/DownloadFile";

import projectTypeOptions from "data/Prod/ProjectTypeOptions.json";

const ProjectForm = ({
  projectTypeValue,
  typeSelectionHandler,
  title,
  settitle,
  summary,
  setsummary,
  details,
  setdetails,
  startDate,
  setstartDate,
  endDate,
  setendDate,
  docFileInputRef,
  docFileUploading,
  uploadedDocFiles,
  openDocFileInput,
  docFileUploadHandler,
  closeDocFileHandler,
  saveProjectHandler,
  isSaving,
  resetHandler,
}) => {
  const { t } = useTranslation(); //for translation

  const loadingCircle = [1, 2, 3, 4, 5, 6]; //for upload doc
  /*---- CKEditor classic editor configuration start ----- */
  const editorConfig = {
    toolbar: ["bold", "italic", "link", "bulletedList", "numberedList"],
    ckfinder: {
      // Upload the images to the server using the CKFinder QuickUpload command.
      //   uploadUrl: url.URL_TEXT_EDITOR_IMAGE_UPLOAD_2,
    },
    placeholder: t("Type description here..."),
  };
  /*---- CKEditor classic editor configuration end ----- */

  return (
    <div className="create-lead-container p-3 p-md-4 border border-gray-300 rounded-10 shadow-sm">
      {/* select type  */}
      <div className="form-group mb-4">
        <label htmlFor="projectName" className="d-block fs-sm fw-semibold mb-2">
          {t("Project Type")}
        </label>
        <Select
          isClearable
          placeholder={t("Select Type")}
          options={projectTypeOptions}
          value={projectTypeValue}
          onChange={typeSelectionHandler}
        />
      </div>

      {/* title */}
      <div className="form-group mb-4">
        <label htmlFor="projectName" className="d-block fs-sm fw-semibold mb-2">
          {t("Project Name")}
        </label>
        <input
          type="text"
          id="projectName"
          className="form-control fs-sm shadow-none"
          placeholder={t("Name")}
          value={title}
          onChange={(e) => settitle(e.target.value)}
        />
      </div>

      {/* summary */}
      <div className="form-group mb-4">
        <label htmlFor="description" className="d-block fs-sm fw-semibold mb-2">
          {t("Summary")}
        </label>
        <textarea
          name="description"
          id="description"
          cols="30"
          rows="5"
          className="form-control fs-sm shadow-none"
          placeholder={t("Enter Summary")}
          value={summary}
          onChange={(e) => setsummary(e.target.value)}
        ></textarea>
      </div>

      {/* description */}
      <div className="form-group mb-4">
        <label htmlFor="description" className="d-block fs-sm fw-semibold mb-2">
          {t("Description")}
        </label>
        <CKEditor
          className="form-control border-0 shadow-none"
          editor={ClassicEditor}
          config={editorConfig}
          data={details ? details : ""}
          onReady={(editor) => {
            editor.editing.view.change((writer) => {
              writer.setStyle(
                "height",
                "200px",
                editor.editing.view.document.getRoot()
              );
            });
          }}
          onChange={(event, editor) => {
            let editorContent = editor.getData();
            setdetails(editorContent);
          }}
        />
      </div>

      {/* dates */}
      <div className="form-group mb-4">
        <div className="row">
          <div className="col-md-6 mb-4 mb-md-0">
            <label
              htmlFor="startDate"
              className="d-block fs-sm fw-semibold mb-2"
            >
              {t("Start Date")}
            </label>
            <input
              type="date"
              id="startDate"
              className="form-control fs-sm shadow-none"
              value={startDate}
              onChange={(e) => setstartDate(e.target.value)}
            />
          </div>
          <div className="col-md-6">
            <label htmlFor="endDate" className="d-block fs-sm fw-semibold mb-2">
              {t("End Date")}
            </label>
            <input
              type="date"
              id="endDate"
              className="form-control fs-sm shadow-none"
              value={endDate}
              onChange={(e) => setendDate(e.target.value)}
            />
          </div>
        </div>
      </div>

      {/* ====== documents section ======== */}
      <div className="form-group mb-3 mb-sm-4">
        <label htmlFor="documents" className="d-block fs-sm fw-semibold mb-2">
          {t("Upload Documents")}
        </label>

        <ul className="upload-files d-flex flex-wrap gap-3 fs-sm mb-3">
          <li className="file d-flex flex-column align-items-center justify-content-center p-3 border border-dashed rounded-8">
            <Link
              to="#"
              data-target="upload"
              className="upload-file text-center"
              onClick={openDocFileInput}
            >
              <span className="d-block material-symbols-outlined">upload</span>
              <span className="d-block fw-semibold">{t("Upload")}</span>
            </Link>
            <span className="fs-xs">Max 20mb doc/img/mp4</span>
            <input
              type="file"
              data-id="upload"
              id="uploadedProjectDoc"
              className="d-none"
              ref={docFileInputRef}
              onChange={docFileUploadHandler}
              multiple
            />
          </li>
        </ul>

        {docFileUploading ? (
          <div className="d-flex align-items-center mt-3">
            <p className="mb-0 me-1">{t("Uploading Files")}</p>
            {loadingCircle.map((circle) => {
              return (
                <div
                  key={circle}
                  className="spinner-grow mx-1"
                  style={{ height: "6px", width: "6px" }}
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              );
            })}
          </div>
        ) : (
          <ul className="uploaded-files d-flex flex-wrap gap-3 p-0 m-0 mt-3">
            {uploadedDocFiles.map((file, index) => {
              return (
                <li
                  className="file d-flex gap-3 align-items-center px-3 py-2 border border-gray rounded-3"
                  key={index}
                >
                  <Link to="#" onClick={() => downloadFileHandler(file)}>
                    <span className="d-block material-symbols-outlined">
                      download
                    </span>
                  </Link>
                  <p className="fw-medium">{file.name}</p>
                  <Link to="#" onClick={() => closeDocFileHandler(index)}>
                    <span className="d-block material-symbols-outlined">
                      close
                    </span>
                  </Link>
                </li>
              );
            })}
          </ul>
        )}
      </div>

      <div className="action d-flex flex-wrap align-items-center justify-content-between gap-2">
        <Link
          to="/admin/projectmanagement/list"
          className="btn btn-outline-primary"
        >
          {t("Cancel")}
        </Link>
        <button
          type="button"
          className="btn btn-primary"
          onClick={saveProjectHandler}
          style={{ cursor: isSaving ? "not-allowed" : "pointer" }}
          disabled={isSaving ? true : false}
        >
          {t("Save Now")}
          {isSaving && (
            <div
              className="mx-2 spinner-border spinner-border-sm"
              role="status"
            >
              <span className="visually-hidden">Loading...</span>
            </div>
          )}
        </button>
      </div>
    </div>
  );
};

export default ProjectForm;
