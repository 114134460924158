/* eslint-disable */
import React, { useEffect } from "react";
import Header from "components/Common/Header/Header";
import FaqListBody from "components/Faq/FaqListBody";

const FaqList = () => {
  useEffect(() => {
    document.title = "Faq";
  }, []);

  return (
    <main id="app">
      <Header moduleName="faq" />
      <FaqListBody />
    </main>
  );
};

export default FaqList;
