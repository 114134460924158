/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import {
  Card,
  CardContent,
  Typography,
  Grid,
  CardActions,
  Button,
} from "@mui/material";
import { assetImages } from "constants";
import { useTranslation } from "react-i18next";

const AdministrationBody = () => {
  const [currentUserRole, setCurrentUserRole] = useState("");

  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const { t } = useTranslation(); //for translation
  useEffect(() => {
    if (userInfo && userInfo.role) {
      setCurrentUserRole(userInfo.role.slug);
    }
  }, [userInfo]);

  if (userInfo.role.slug === "ADMIN" || userInfo.role.slug === "SUPER_ADMIN") {
    return (
      <div id="content_wrapper">
        <section className="dashboard-wrapper">
          {/* <div className="container-fluid px-lg-5">
          <h1>Administration</h1>
          <span className="material-icons-outlined"> hub </span>
        </div> */}

          {currentUserRole === "SUPER_ADMIN" ? (
            <Grid container spacing={1} margin={2}>
              {/* Ecosystems ----------------------------------- */}
              <Grid item xs={12} sm={6} md={3}>
                <Card>
                  <CardContent>
                    <Typography variant="h5" component="h2">
                      {t("Ecosystem")}
                    </Typography>
                    <Typography color="textSecondary">
                      {t("Show all Ecosystems associated with the app")}
                    </Typography>
                  </CardContent>

                  <CardActions>
                    <Button size="small">
                      <Link
                        to="/admin/administration/ecosystems"
                        className="fw-bold text-primary"
                      >
                        {t("Manage Ecosystems")}
                      </Link>
                    </Button>
                  </CardActions>
                </Card>
              </Grid>

              {/* App User Role ----------------------------------- */}
              <Grid item xs={12} sm={6} md={3}>
                <Card>
                  <CardContent>
                    <Typography variant="h5" component="h2">
                      {t("App Users Role")}
                    </Typography>
                    <Typography color="textSecondary">
                      {t("Show various roles of App Users")}
                    </Typography>
                  </CardContent>

                  <CardActions>
                    <Button size="small">
                      <Link
                        to="/admin/administration/roles"
                        className="fw-bold text-primary"
                      >
                        {t("Manage Roles")}
                      </Link>
                    </Button>
                  </CardActions>
                </Card>
              </Grid>

              {/* <!-- ========== Start action log Section ========== --> */}
              <Grid item xs={12} sm={6} md={3}>
                <Card>
                  <CardContent>
                    <Typography variant="h5" component="h2">
                      {t("Action Logs")}
                    </Typography>
                    <Typography color="textSecondary">
                      {t("Show various logs of this application")}
                    </Typography>
                  </CardContent>

                  <CardActions>
                    <Button size="small">
                      <Link
                        to="/admin/administration/actionlogs"
                        className="fw-bold text-primary"
                      >
                        {t("View Action logs")}
                      </Link>
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
              {/* <!-- ========== End action log Section ========== --> */}
            </Grid>
          ) : (
            ""
          )}

          {currentUserRole === "SUPER_ADMIN" ? (
            <Grid container spacing={1} margin={2}>
              {/* Modules ----------------------------------- */}
              <Grid item xs={12} sm={6} md={3}>
                <Card>
                  <CardContent>
                    <Typography variant="h5" component="h2">
                      {t("Modules")}
                    </Typography>
                    <Typography color="textSecondary">
                      {t("Show Ecosystem Modules")}
                    </Typography>
                  </CardContent>

                  <CardActions>
                    <Button size="small">
                      <Link
                        to="/admin/administration/modules"
                        className="fw-bold text-primary"
                      >
                        {t("Manage Modules")}
                      </Link>
                    </Button>
                  </CardActions>
                </Card>
              </Grid>

              {/* Module Role Privilege ----------------------------------- */}
              <Grid item xs={12} sm={6} md={3}>
                <Card>
                  <CardContent>
                    <Typography variant="h5" component="h2">
                      {t("Module Privileges By User Role")}
                    </Typography>
                    <Typography color="textSecondary">
                      {t("Module privileges for to app user by roles")}
                    </Typography>
                  </CardContent>

                  <CardActions>
                    <Button size="small">
                      <Link
                        to="/admin/administration/moduleroleprivilege"
                        className="fw-bold text-primary"
                      >
                        {t("Manage Module Privileges")}
                      </Link>
                    </Button>
                  </CardActions>
                </Card>
              </Grid>

              {/* <!-- ========== Start gpt prompt Section ========== --> */}
              <Grid item xs={12} sm={6} md={3}>
                <Card>
                  <CardContent>
                    <Typography variant="h5" component="h2">
                      {t("Gpt Prompt")}
                    </Typography>
                    <Typography color="textSecondary">
                      {t("Show gpt list of this application")}
                    </Typography>
                  </CardContent>

                  <CardActions>
                    <Button size="small">
                      <Link
                        to="/admin/administration/gptprompt"
                        className="fw-bold text-primary"
                      >
                        {t("View gpt prompts")}
                      </Link>
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
              {/* <!-- ========== End gpt prompt Section ========== --> */}
            </Grid>
          ) : (
            ""
          )}

          {currentUserRole === "ADMIN" || currentUserRole === "SUPER_ADMIN" ? (
            <Grid container spacing={1} margin={2}>
              {/* Categories ----------------------------------- */}
              {/* Module Roles ----------------------------------- */}
              <Grid item xs={12} sm={6} md={3}>
                <Card>
                  <CardContent>
                    <Typography variant="h5" component="h2">
                      {t("Module Roles")}
                    </Typography>
                    <Typography color="textSecondary">
                      {t("Add roles in different modules for access rights")}
                    </Typography>
                  </CardContent>

                  <CardActions>
                    <Button size="small">
                      <Link
                        to="/admin/administration/moduleroles"
                        className="fw-bold text-primary"
                      >
                        {t("Manage Module Roles")}
                      </Link>
                    </Button>
                  </CardActions>
                </Card>
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <Card>
                  <CardContent>
                    <Typography variant="h5" component="h2">
                      {t("Categories")}
                    </Typography>
                    <Typography color="textSecondary">
                      {t("Show all Categories added in this ecosystems")}
                    </Typography>
                  </CardContent>

                  <CardActions>
                    <Button size="small">
                      <Link
                        to="/admin/administration/categories/list"
                        className="fw-bold text-primary"
                      >
                        {t("Manage Categories")}
                      </Link>
                    </Button>
                  </CardActions>
                </Card>
              </Grid>

              {/* Plans and Subscription ----------------------------------- */}
              <Grid item xs={12} sm={6} md={3}>
                <Card>
                  <CardContent>
                    <Typography variant="h5" component="h2">
                      {t("Plans")}
                    </Typography>
                    <Typography color="textSecondary">
                      {t("Plans for subscription in current Ecosystem")}
                    </Typography>
                  </CardContent>

                  <CardActions>
                    <Button size="small">
                      <Link
                        to="/admin/administration/plans"
                        className="fw-bold text-primary"
                      >
                        {t("Manage Plans")}
                      </Link>
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            </Grid>
          ) : (
            ""
          )}

          {currentUserRole === "ADMIN" || currentUserRole === "SUPER_ADMIN" ? (
            <Grid container spacing={1} margin={2}>
              {/* <!-- ========== Start tag Section ========== --> */}
              <Grid item xs={12} sm={6} md={3}>
                <Card>
                  <CardContent>
                    <Typography variant="h5" component="h2">
                      {t("Tags")}
                    </Typography>
                    <Typography color="textSecondary">
                      {t("Manage tags for all users")}
                    </Typography>
                  </CardContent>

                  <CardActions>
                    <Button size="small">
                      <Link
                        to="/admin/administration/tags"
                        className="fw-bold text-primary"
                      >
                        {t("Manage Tags")}
                      </Link>
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
              {/* <!-- ========== End tag Section ========== --> */}

              {/* activity Process */}
              <Grid item xs={12} sm={6} md={3}>
                <Card>
                  <CardContent>
                    <Typography variant="h5" component="h2">
                      {t("Activity Process Template")}
                    </Typography>
                    <Typography color="textSecondary">
                      {t(
                        "Manage activity process template for organize kanban labels"
                      )}
                    </Typography>
                  </CardContent>

                  <CardActions>
                    <Button size="small">
                      <Link
                        to="/admin/administration/activityprocess/list"
                        className="fw-bold text-primary"
                      >
                        {t("Manage Activity Process Template")}
                      </Link>
                    </Button>
                  </CardActions>
                </Card>
              </Grid>

              {/* <!-- ========== Start mail template Section ========== --> */}
              <Grid item xs={12} sm={6} md={3}>
                <Card>
                  <CardContent>
                    <Typography variant="h5" component="h2">
                      {t("Mail Template")}
                    </Typography>
                    <Typography color="textSecondary">
                      {t("Manage mail template for organize templates")}
                    </Typography>
                  </CardContent>

                  <CardActions>
                    <Button size="small">
                      <Link
                        to="/admin/administration/mailtemplate/list"
                        className="fw-bold text-primary"
                      >
                        {t("Manage Mail Template")}
                      </Link>
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
              {/* <!-- ========== End mail template Section ========== --> */}
            </Grid>
          ) : (
            ""
          )}

          {/* {currentUserRole === "ADMIN" || currentUserRole === "SUPER_ADMIN" ? (
            <Grid container spacing={1} margin={2}>
              <Grid item xs={12} sm={6} md={3}>
                <Card>
                  <CardContent>
                    <Typography variant="h5" component="h2">
                      {t("Taxonomy Types")}
                    </Typography>
                    <Typography color="textSecondary">
                      {t("Manage taxonomy types for all projects")}
                    </Typography>
                  </CardContent>

                  <CardActions>
                    <Button size="small">
                      <Link
                        to="/admin/administration/taxonomytype/project"
                        className="fw-bold text-primary"
                      >
                        {t("Manage Taxonomy Types")}
                      </Link>
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            </Grid>
          ) : (
            ""
          )} */}
        </section>
      </div>
    );
  } else {
    return (
      <div id="content_wrapper">
        <section className="crm-wrapper bg-white pb-5">
          <div className="empty_access text-center">
            <div className="empty_pic mb-4">
              {" "}
              <img src={assetImages.emptyVector} alt="" />
            </div>
            <div className="empty_text">
              <p className="fs-lg text-gray fw-semibold mb-4">
                {t("Sorry....! You don't have privilege to see this content")}
              </p>
            </div>
          </div>
        </section>
      </div>
    );
  }
};

export default AdministrationBody;
