export const ecosystemSlug = localStorage.getItem("ecosystemslug") || "LOBEES";

export const prjectManageSlug = "PROJECT_ROLE_MANAGER";

export const invoiceOwnerEmail = "admin@mpforall.com";

export const invoiceBillingAddress = {
  address: "Calle de Embajadores, 9, 28012, Madrid, España",
  supportmail: "support@elpavonteatro.es",
  phone: "(+34) 615 43 94 22",
  website: "https://club.elpavonteatro.es/",
  logourl:
    "https://club.elpavonteatro.es/wp-content/uploads/2024/08/TeatroPavon-RGB-N-1.png",
};

export const joditEditorConfig = (editorHeight = null) => {
  return {
    readonly: false,
    placeholder: "",
    minHeight: editorHeight || 400,
    uploader: {
      insertImageAsBase64URI: false,
    },
    defaultActionOnPaste: "insert_as_html",
    defaultLineHeight: 1.5,
    enter: "div",
    statusbar: false,
    sizeLG: 900,
    sizeMD: 700,
    sizeSM: 400,
    toolbarAdaptive: false,
    disablePlugins: [
      "image",
      "video",
      "about",
      "classSpan",
      "file",
      "speechRecognize",
      "copyformat",
      "spellcheck",
    ],
  };
};

export const ckEditorConfig = (placeholderText = "") => {
  return {
    toolbar: [
      "bold",
      "italic",
      "|",
      "bulletedList",
      "numberedList",
      "|",
      "outdent",
      "indent",
      "|",
      "undo",
      "redo",
    ],
    placeholder: placeholderText,
    shouldNotGroupWhenFull: false,
  };
};

export const gptPromtSlug = "INVOICE_DOC";

export const reactSelectStyle = (
  padding = "0px",
  borderRadius = "0px",
  bgRequired = false,
  showBorder = true
) => {
  return {
    control: (styles, { isFocused }) => ({
      ...styles,
      backgroundColor: bgRequired ? "#ffffff" : "#ffffff", // Control background
      border: showBorder ? "1px solid #EE523C" : "none",
      borderRadius, // Border radius
      padding,
      boxShadow: "0 !important",
      "&:hover": {}, // Focus shadow effect
    }),
  };
};
