/* eslint-disable */
import React, { useEffect, useState, useMemo, useCallback } from "react";
import { Link, useParams } from "react-router-dom";
import Select from "react-select";

// import for excel download
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

// Material UI table
import { MaterialReactTable } from "material-react-table";
//Material UI components for rendring menu and others
import { MenuItem, Box, TextField, IconButton } from "@mui/material";
import { Clear } from "@mui/icons-material";

/*------ import url and method ------*/
import * as url from "helper/UrlHelper";
import { getData, putData } from "utils/Gateway";

import { assetImages } from "constants";
import AddLabelModal from "components/Common/Modal/AddLabelModal";
import TaskManagementPopup from "components/Common/Popup/TaskManagementPopup/TaskManagementPopup";
import MyTaskFilterPopup from "components/Common/Popup/MyTaskFilterPopup";
import MyTaskListHeader from "./Header/MyTaskListHeader";
import { getDateFormatInSpanish } from "helper/Common/CommonHelper";
import TaskStatusOptions from "data/Prod/TaskStatus.json";
import TaskPriority from "data/Prod/TaskPriority.json";
import AssignBulkTaskModal from "components/Common/Modal/AssignBulkTaskModal";
import SuccessBulkTaskModal from "components/Common/Modal/SuccessBulkTaskModal";
import DeleteTaskWarningModal from "components/Common/Modal/DeleteTaskWarningModal";
import AlertNotification from "components/Common/AlertNotification/AlertNotification";
import AddAlertModal from "components/Common/Modal/AddAlertModal";
import AddFollowerModal from "components/Common/Modal/AddFollowerModal";
import { useTranslation } from "react-i18next";
import UpdateTaskSchedulePopup from "components/Common/Popup/UpdateTaskSchedulePopup";
import { createContactChatHandler } from "helper/AppUserHelper/AppUserHelper";
import { createTaskChatHandler } from "helper/TaskHelper/TaskHelper";

const MyTaskListBody = () => {
  const todayValue = new Date().toISOString().split("T")[0];

  const params = useParams();

  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const moduleAccess = localStorage.getItem("moduleaccess");
  const { t, i18n } = useTranslation(); //for translation

  const [isLoading, setIsLoading] = useState(false);
  const [taskList, setTaskList] = useState([]);

  const [delayedTaskCount, setdelayedTaskCount] = useState(0);
  const [followingTaskCount, setfollowingTaskCount] = useState(0);
  const [inProgressTaskCount, setinProgressTaskCount] = useState(0);
  const [testingTaskCount, settestingTaskCount] = useState(0);
  const [pendingTaskCount, setpendingTaskCount] = useState(0);
  const [completedTaskCount, setcompletedTaskCount] = useState(0);
  const [totalTaskHours, settotalTaskHours] = useState(0);
  const [totalTaskLogHours, settotalTaskLogHours] = useState(0);

  //optionally, you can manage the row selection state yourself
  const [rowSelection, setRowSelection] = useState({});
  const [selectedTaskIds, setSelectedTaskIds] = useState([]);
  const [selectedTaskId, setSelectedTaskId] = useState(null);
  const [afterSavedTaskData, setAfterSavedTaskData] = useState(null); //store new saved task to prevent refresh

  const [selectedTaskModerator, setselectedTaskModerator] = useState(null);

  const [filterTaskDateRangeType, setFilterTaskDateRangeType] = useState("");
  const [filterTaskFromDate, setfilterTaskFromDate] = useState("");
  const [filterTaskToDate, setfilterTaskToDate] = useState("");
  const [filterTaskCustomIds, setfilterTaskCustomIds] = useState([]);
  const [filterTaskTitle, setfilterTaskTitle] = useState("");
  const [filterTaskProjects, setfilterTaskProjects] = useState([]);
  const [filterTaskAssignedToUsers, setfilterTaskAssignedToUsers] = useState(
    []
  );
  const [filterTaskAssignedByUsers, setfilterTaskAssignedByUsers] = useState(
    []
  );
  const [filterTaskStatus, setfilterTaskStatus] = useState([]);
  const [filterTaskPriorities, setfilterTaskPriorities] = useState([]);
  const [filterTaskLabels, setfilterTaskLabels] = useState([]);

  const [isNoDefaultFilter, setIsNoDefaultFilter] = useState(false);

  const [showTodaysTasks, setShowTodaysTasks] = useState(false);

  const [reloadData, setReloadData] = useState(false);
  const [isFilterReset, setIsFilterReset] = useState(false);

  const [bulkSavedTaskList, setBulkSavedTaskList] = useState([]);

  const [updateScheduleTaskUserList, setUpdateScheduleTaskUserList] = useState(
    []
  );

  // Pagination -------------------------------------------
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10, //customize the default page size
  });

  const [lastPagination, setLastPagination] = useState({
    pageIndex: 0,
    pageSize: 10, //customize the default page size
  });

  // paginatin ends -----------------------------------

  const [windowScreen, setwindowScreen] = useState(0);

  //alert requirements
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [messageType, setMessageType] = useState("");

  // download to excel
  const [excelData, setExcelData] = useState([]);

  //get all tasks
  const getAllTask = async () => {
    setSelectedTaskIds([]);
    setRowSelection({});

    try {
      setIsLoading(true);

      let isModerator = false;

      if (
        userInfo.role.slug === "ADMIN" ||
        userInfo.role.slug === "SUPER_ADMIN"
      ) {
        isModerator = true;
      }

      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_ALL_TASKS +
        `?token=${token}&ismoderator=${isModerator}`;

      if (filterTaskDateRangeType != "") {
        requestUrl =
          requestUrl + `&filterdaterangetype=${filterTaskDateRangeType}`;
      }

      if (filterTaskFromDate != "" || filterTaskToDate != "") {
        requestUrl =
          requestUrl +
          `&filterfromdate=${filterTaskFromDate}&filtertodate=${filterTaskToDate}`;
      }

      if (filterTaskCustomIds.length > 0) {
        requestUrl = requestUrl + `&filtertaskcustomids=${filterTaskCustomIds}`;
      }

      if (filterTaskTitle != "") {
        requestUrl = requestUrl + `&filtertitle=${filterTaskTitle}`;
      }

      if (filterTaskLabels.length > 0) {
        requestUrl = requestUrl + `&filtertags=${filterTaskLabels}`;
      }

      if (filterTaskStatus.length > 0) {
        requestUrl = requestUrl + `&filtercompletestatus=${filterTaskStatus}`;
      }

      if (filterTaskPriorities.length > 0) {
        requestUrl = requestUrl + `&filterpriorities=${filterTaskPriorities}`;
      }

      if (filterTaskProjects.length > 0) {
        requestUrl = requestUrl + `&filterprojects=${filterTaskProjects}`;
      }

      if (filterTaskAssignedByUsers.length > 0) {
        requestUrl =
          requestUrl + `&filtermoderators=${filterTaskAssignedByUsers}`;
      }

      if (filterTaskAssignedToUsers.length > 0) {
        requestUrl = requestUrl + `&filterusers=${filterTaskAssignedToUsers}`;
      } else if (params.userid) {
        requestUrl = requestUrl + `&filterusers=${params.userid}`;
      }

      console.log(`getAllTask requestUrl--->`, requestUrl);

      const response = await getData(requestUrl);

      console.log("response in task list------->", response);
      setIsLoading(false);

      if (response.status) {
        setMessageType("success");
        setTaskList(response.data);

        const delayedFilterTask = response.data.filter((task) => {
          return task.tasknumberofdaysleft < 0;
        });

        setdelayedTaskCount(delayedFilterTask.length);

        //get follwing tasks
        const followingTasks = response.data.filter(
          (task) => task.followers && task.followers.includes(userInfo._id)
        );

        setfollowingTaskCount(followingTasks.length);

        //get in progress tasks
        const inProgressTasks = response.data.filter(
          (task) => task.completedpercent === "1"
        );

        setinProgressTaskCount(inProgressTasks.length);

        //get testing tasks
        const testingTasks = response.data.filter(
          (task) => task.completedpercent === "2"
        );

        settestingTaskCount(testingTasks.length);

        //get pending tasks
        const pendingTasks = response.data.filter(
          (task) => task.completedpercent === "0"
        );

        setpendingTaskCount(pendingTasks.length);

        //get completed tasks
        const completedTasks = response.data.filter(
          (task) => task.completedpercent === "3"
        );

        setcompletedTaskCount(completedTasks.length);

        //get total planned hours
        const sumOfPlannedHours = response.data.reduce((acc, curr) => {
          return acc + curr.hours;
        }, 0);

        settotalTaskHours(sumOfPlannedHours);

        //get total dedicated hours
        const sumOfDedicatedHours = response.data.reduce((acc, curr) => {
          return acc + curr.loggedhours;
        }, 0);

        settotalTaskLogHours(sumOfDedicatedHours);
      } else {
        setMessageType("error");
      }

      setAlertMessage(response.message);
    } catch (error) {
      setAlertMessage(error.message);
      setMessageType("error");
    }

    setShowAlert(true);
    // if (windowScreen !== 0) {
    //   // Scroll smoothly to the bottom
    //   window.scrollTo({
    //     top: windowScreen,
    //     behavior: "smooth",
    //   });
    // }
  };

  //function for change rank
  const rankInputChangeHandler = (row, value) => {
    const updatedTaskList = [...taskList];

    const rowIndex = updatedTaskList.findIndex(
      (task) => task._id === row.original._id
    );

    if (rowIndex !== -1) {
      updatedTaskList[rowIndex].rank = value;
      setTaskList(updatedTaskList);
    }
  };

  //function for change complete percent
  const completePercentInputChangeHandler = (row, value) => {
    const updatedTaskList = [...taskList];

    const rowIndex = updatedTaskList.findIndex(
      (task) => task._id === row.original._id
    );

    if (rowIndex !== -1) {
      updatedTaskList[rowIndex].taskpercent = value;
      setTaskList(updatedTaskList);
    }
  };

  //function for change planed hours
  const plannedHoursInputChangeHandler = (row, value) => {
    const updatedTaskList = [...taskList];

    const rowIndex = updatedTaskList.findIndex(
      (task) => task._id === row.original._id
    );

    if (rowIndex !== -1) {
      updatedTaskList[rowIndex].hours = value;
      setTaskList(updatedTaskList);
    }
  };

  //function for change start date
  const startDateInputChangeHandler = (row, value) => {
    const updatedTaskList = [...taskList];

    const rowIndex = updatedTaskList.findIndex(
      (task) => task._id === row.original._id
    );

    if (rowIndex !== -1) {
      updatedTaskList[rowIndex].startdate = value;

      console.log(updatedTaskList);

      setTaskList(updatedTaskList);
    }
  };

  //function for change end date
  const endDateInputChangeHandler = (row, value) => {
    const updatedTaskList = [...taskList];

    const rowIndex = updatedTaskList.findIndex(
      (task) => task._id === row.original._id
    );

    if (rowIndex !== -1) {
      updatedTaskList[rowIndex].enddate = value;
      setTaskList(updatedTaskList);
    }
  };

  //function for update rank
  const updateBulkTaskHandler = async (e) => {
    if (selectedTaskIds.length > 0) {
      let responseArr = [];

      //run loop checkbox wise
      for (let updatedTaskId of selectedTaskIds) {
        //run loop of updated company
        for (let task of taskList) {
          let taskData = {};

          //check the cheked blog id match with changed rank filed blog id.If check then proceed
          if (task._id == updatedTaskId) {
            taskData.rank = task.rank;
            taskData.taskpercent = task.taskpercent;
            taskData.startdate = task.startdate;
            taskData.enddate = task.enddate;
            taskData.hours = task.hours;

            try {
              let requestUrl =
                url.API_BASE_URL +
                url.API_UPDATE_PROJECT_TASK +
                `/${updatedTaskId}` +
                `?token=${token}`;

              const response = await putData(requestUrl, taskData);

              // console.log(response);

              if (response.status) {
                setMessageType("success");
                responseArr.push(response.status);
              } else {
                setMessageType("error");
              }

              setAlertMessage(response.message);
            } catch (error) {
              setAlertMessage(error.message);
              setMessageType("error");
            }

            setShowAlert(true);
          }
        }
      }

      if (responseArr.length > 0) {
        setTimeout(() => {
          getAllTask();
        }, 2200);
      }
    } else {
      setMessageType("error");
      setAlertMessage("Please select atleast one task");
      setShowAlert(true);
    }
  };

  //delete multiple tasks
  const deActivateBulkTaskHandler = async (e) => {
    if (selectedTaskIds.length > 0) {
      let responseArr = [];

      //run loop checkbox wise
      for (let updatedTaskId of selectedTaskIds) {
        try {
          let requestUrl =
            url.API_BASE_URL +
            url.API_DEACTIVATE_PROJECT_TASK +
            `/${updatedTaskId}` +
            `?token=${token}`;

          const response = await putData(requestUrl);

          if (response.status) {
            setMessageType("success");
            responseArr.push(response.status);
          } else {
            setMessageType("error");
          }

          setAlertMessage(response.message);
        } catch (error) {
          setAlertMessage(error.message);
          setMessageType("error");
        }

        setShowAlert(true);
      }

      if (responseArr.length > 0) {
        setTimeout(() => {
          getAllTask();
        }, 1500);
      }
    } else {
      setMessageType("error");
      setAlertMessage("Please select atleast one task");
      setShowAlert(true);
    }
  };

  //update schedule to state
  const updateBulkTaskScheduleStateHandler = () => {
    if (selectedTaskIds.length > 0) {
      const selectedTasksList = taskList.filter((item) =>
        selectedTaskIds.includes(item._id)
      );

      let filteredTaskList = [];

      if (
        userInfo.role.slug === "ADMIN" ||
        userInfo.role.slug === "SUPER_ADMIN"
      ) {
        filteredTaskList = selectedTasksList;
      } else {
        filteredTaskList = selectedTasksList.filter(
          (item) => item.moderator.toString() === userInfo._id.toString()
        );
      }

      const transformedTasksArray = [];

      // Helper function to initialize weekly work days
      const initializeWeekly = () => ({
        Monday: false,
        Tuesday: false,
        Wednesday: false,
        Thursday: false,
        Friday: false,
        Saturday: false,
        Sunday: false,
      });

      // Group tasks by assigned user
      const userTasksMap = filteredTaskList.reduce((acc, task) => {
        const userId = task.assigneduser || null;
        if (!acc[userId]) {
          acc[userId] = {
            assigneduser: task.assigneduser || null,
            assignedusername: task.assignedusername || "",
            assigneduserimage: task.assigneduserimage || "",
            tasks: [],
            workhours: 0,
            ...initializeWeekly(),
          };
        }
        acc[userId].tasks.push(task);
        return acc;
      }, {});

      // Transform the map into the desired array format
      // for (const userId in userTasksMap) {
      //   transformedTasksArray.push(userTasksMap[userId]);
      // }
      for (const userId in userTasksMap) {
        // Sort tasks by priority: 1, 2, then 3
        userTasksMap[userId].tasks.sort((a, b) => {
          return Number(a.rank) - Number(b.rank);
        });

        transformedTasksArray.push(userTasksMap[userId]);
      }

      setUpdateScheduleTaskUserList(transformedTasksArray);

      if (filteredTaskList.length > 0) {
        let offCanvasPopup = document.querySelector("#UpdateTaskSchedule");
        let offCanvas = new bootstrap.Offcanvas(offCanvasPopup);
        offCanvas.show();
      } else {
        setMessageType("error");
        setAlertMessage("You are not authorized to perform this action");
        setShowAlert(true);
      }
    } else {
      setMessageType("error");
      setAlertMessage("Please select atleast one task");
      setShowAlert(true);
    }
  };

  //* Function for reset filter
  const resetFilterData = () => {
    setFilterTaskDateRangeType("");
    setfilterTaskFromDate("");
    setfilterTaskToDate("");
    setfilterTaskCustomIds([]);
    setfilterTaskTitle("");
    setfilterTaskLabels([]);
    setfilterTaskStatus([]);
    setfilterTaskPriorities([]);
    setfilterTaskProjects([]);
    setfilterTaskAssignedToUsers([]);
    setfilterTaskAssignedByUsers([]);

    setReloadData(true);
    // reset pagination to default
    setLastPagination({
      pageIndex: 0,
      pageSize: 10,
    });
    setPagination({
      pageIndex: 0,
      pageSize: 10,
    });
  };

  const afterCloseModalHandler = (isGetAllList) => {
    if (isGetAllList) {
      getAllTask();
    } else {
      setRowSelection({});
      setSelectedTaskIds([]);
    }
  };

  useEffect(() => {
    if (
      filterTaskDateRangeType != "" ||
      filterTaskFromDate != "" ||
      filterTaskToDate != "" ||
      filterTaskTitle != "" ||
      filterTaskLabels.length > 0 ||
      filterTaskCustomIds.length > 0 ||
      filterTaskPriorities.length > 0 ||
      filterTaskProjects.length > 0 ||
      filterTaskAssignedByUsers.length > 0 ||
      filterTaskAssignedToUsers.length > 0 ||
      filterTaskStatus.length > 0
    ) {
      // console.log(filterTaskDateRangeType);
      // console.log(filterTaskFromDate);
      // console.log(filterTaskToDate);
      // console.log(filterTaskTitle);
      // console.log(filterTaskLabels);
      // console.log(filterTaskCustomIds);
      // console.log(filterTaskPriorities);
      // console.log(filterTaskProjects);
      // console.log(filterTaskAssignedByUsers);
      // console.log(filterTaskAssignedToUsers);
      // console.log(filterTaskStatus);
      getAllTask();
    }
  }, [
    filterTaskDateRangeType,
    filterTaskFromDate,
    filterTaskToDate,
    filterTaskCustomIds,
    filterTaskTitle,
    filterTaskLabels,
    filterTaskStatus,
    filterTaskPriorities,
    filterTaskProjects,
    filterTaskAssignedByUsers,
    filterTaskAssignedToUsers,
  ]);

  useEffect(() => {
    if (params.userid) {
      getAllTask();
    }
  }, [params.userid]);

  useEffect(() => {
    if (isNoDefaultFilter) {
      getAllTask();
    }
  }, [isNoDefaultFilter]);

  // State to manage the filter values for each column
  const [columnFilterValues, setColumnFilterValues] = useState({});
  const [showGlobalFilters, setShowGlobalFilters] = useState(false);

  const globalFilterTextHandler = ({ column }) => (
    <Box>
      <TextField
        value={columnFilterValues[column.id] || ""}
        onChange={(e) => {
          // console.log(column);
          setColumnFilterValues({
            ...columnFilterValues,
            [column.id]: e.target.value,
          });

          column.setFilterValue(e.target.value || undefined); // Apply the filter to the table or remove filter if empty
        }}
        placeholder={`Filter by ${column.columnDef.header}`}
        variant="standard"
      />
      {columnFilterValues[column.id] && ( // Display clear filter button only if filter is applied
        <IconButton onClick={() => clearGlobalFilterhandler(column)}>
          <Clear />
        </IconButton>
      )}
    </Box>
  );

  const clearGlobalFilterhandler = (column) => {
    column.setFilterValue("");
    setColumnFilterValues({
      ...columnFilterValues,
      [column.id]: "", // Clear filter value for the specified column
    });
  };

  //material table columns array
  const initialTableColumns = [
    {
      accessorKey: "#",
      header: t("Actions"),
      Cell: ({ row }) => (
        <button
          className="action_btn_mui"
          data-bs-toggle="offcanvas"
          data-bs-target="#addTask_view"
          aria-controls="addTask_view"
          onClick={() => {
            setSelectedTaskId(row.original._id);
            setselectedTaskModerator(row.original.moderator);
          }}
        >
          <span className="d-block material-symbols-outlined horz_icon">
            more_horiz
          </span>
        </button>
      ),
      enableColumnActions: false, // Hides the column action icon
      enableColumnDragging: false, // Hides the move icon
      enableSorting: false,
    },
    {
      accessorKey: "customid",
      header: t("ID"),
      size: 100,
      // Filter: globalFilterTextHandler,
    },
    {
      accessorKey: "title",
      header: t("Title"),
      size: 200,
      Cell: ({ row }) => (
        <Box className="title border-bottom-0" sx={{ width: "200px" }}>
          <div className="d-flex align-items-center gap-4">
            {/* <p className="fs-md fw-semibold"></p> */}
            <Link
              to={`/admin/task/details/${row.original._id}`}
              className="d-flex align-items-center gap-1 fs-md"
              // data-bs-toggle="offcanvas"
              // role="button"
              // aria-controls="addTask_view"
              // onClick={() => {
              //   setwindowScreen(window.scrollY);
              //   setSelectedTaskId(row.original._id);
              //   setselectedTaskModerator(row.original.moderator._id);
              // }}
            >
              {row.original.title}
            </Link>
          </div>
        </Box>
      ),
      // Filter: globalFilterTextHandler,
    },
    {
      accessorKey: "rank",
      header: t("Rank"),
      Cell: ({ row }) => (
        <div className="ranking border-bottom-0">
          {row.original.moderator.toString() === userInfo._id.toString() ||
          userInfo.role.slug === "ADMIN" ||
          userInfo.role.slug === "SUPER_ADMIN" ? (
            <input
              type="number"
              className="form-control fs-sm text-gray bg-white py-2 border-gray-300 shadow-none"
              style={{ width: "100px" }}
              value={row.original.rank}
              onChange={(e) => rankInputChangeHandler(row, e.target.value)}
            />
          ) : (
            <p className="ps-3">{row.original.rank}</p>
          )}
        </div>
      ),
      // Filter: globalFilterTextHandler,
    },
    {
      accessorKey: "taskstatus",
      header: t("Task Status"),
      // Filter: globalFilterTextHandler,
    },
    {
      accessorKey: "taskpercent",
      header: t("Complete %"),
      Cell: ({ row }) => (
        <div className="ranking border-bottom-0">
          {userInfo.role.slug === "ADMIN" ||
          userInfo.role.slug === "SUPER_ADMIN" ||
          row.original.moderator.toString() === userInfo._id.toString() ? (
            <input
              type="number"
              className="form-control fs-sm text-gray bg-white py-2 border-gray-300 shadow-none"
              style={{ width: "100px" }}
              value={row.original.taskpercent}
              onChange={(e) =>
                completePercentInputChangeHandler(row, e.target.value)
              }
            />
          ) : (
            <p className="ps-3">{row.original.taskpercent}</p>
          )}
        </div>
      ),
      // Filter: globalFilterTextHandler,
    },
    {
      accessorKey: "projectname",
      header: t("Project"),
      size: 250,
      // Filter: globalFilterTextHandler,
    },
    {
      accessorKey: "taskpriority",
      header: t("Priority"),
      // Filter: globalFilterTextHandler,
    },
    {
      accessorKey: "hours",
      header: t("Planned Hours"),
      Cell: ({ row }) => (
        <div className="ranking border-bottom-0">
          {row.original.moderator.toString() === userInfo._id.toString() ||
          userInfo.role.slug === "ADMIN" ||
          userInfo.role.slug === "SUPER_ADMIN" ? (
            <input
              type="number"
              className="form-control fs-sm text-gray bg-white py-2 border-gray-300 shadow-none"
              style={{ width: "100px" }}
              value={row.original.hours}
              onChange={(e) =>
                plannedHoursInputChangeHandler(row, e.target.value)
              }
            />
          ) : (
            <p className="ps-3">{row.original.hours}</p>
          )}
        </div>
      ),
      // Filter: globalFilterTextHandler,
    },
    {
      accessorKey: "loggedhours",
      header: t("Dedicated Hours"),
      // Filter: globalFilterTextHandler,
    },
    {
      accessorKey: "balancehours",
      header: t("Balance Hours"),
      Cell: ({ row }) => (
        <div className="ranking border-bottom-0">
          <p
            className={`ps-3 ${
              row.original.balancehours < 0 ? "text-danger" : ""
            }`}
          >
            {row.original.balancehours}
          </p>
        </div>
      ),
      // Filter: globalFilterTextHandler,
    },
    {
      accessorKey: "startdate",
      header: t("Start Date"),
      Cell: ({ row }) => (
        <div className="ranking border-bottom-0">
          {userInfo.role.slug === "ADMIN" ||
          userInfo.role.slug === "SUPER_ADMIN" ||
          row.original.moderator.toString() === userInfo._id.toString() ? (
            <input
              type="date"
              className="form-control fs-sm bg-white py-2 border-gray-300 shadow-none"
              style={{ width: "140px" }}
              value={row.original.startdate}
              onChange={(e) => startDateInputChangeHandler(row, e.target.value)}
            />
          ) : (
            <p className="ps-2">{row.original.taskstartdate}</p>
          )}
        </div>
      ),
      // Filter: globalFilterTextHandler,
    },
    {
      accessorKey: "enddate",
      header: t("End Date"),
      Cell: ({ row }) => (
        <div className="ranking border-bottom-0">
          {userInfo.role.slug === "ADMIN" ||
          userInfo.role.slug === "SUPER_ADMIN" ||
          row.original.moderator.toString() === userInfo._id.toString() ? (
            <input
              type="date"
              className="form-control fs-sm bg-white py-2 border-gray-300 shadow-none"
              style={{ width: "140px" }}
              value={row.original.enddate}
              onChange={(e) => endDateInputChangeHandler(row, e.target.value)}
            />
          ) : (
            <p className="ps-2">{row.original.taskenddate}</p>
          )}
        </div>
      ),
      // Filter: globalFilterTextHandler,
    },
    {
      accessorKey: "tasknumberofdaysleft",
      header: t("Days Left"),
      Cell: ({ row }) => (
        <Box className="title border-bottom-0" sx={{ width: "200px" }}>
          <div className="d-flex align-items-center gap-4">
            <p
              className={
                row.original.tasknumberofdaysleft <= 3 ? "text-primary" : ""
              }
            >
              {" "}
              {row.original.tasknumberofdaysleft}
            </p>
          </div>
        </Box>
      ),
      // Filter: globalFilterTextHandler,
    },
    {
      accessorKey: "moderatorname",
      header: t("Assigned By"),
      size: 300,
      Cell: ({ row }) => (
        <div className="owner border-bottom-0 d-flex gap-1">
          <img
            className="rounded-circle"
            height={35}
            width={35}
            src={
              row.original.moderatorimage == ""
                ? assetImages.defaultUser
                : url.SERVER_URL + row.original.moderatorimage
            }
            alt="Babcock"
          />
          <p>
            <span className="d-block">{row.original.moderatorname}</span>
            <span className="d-block">{row.original.moderatoremail}</span>
          </p>
          <button
            onClick={() => {
              createTaskChatHandler(row.original._id);
            }}
            className={
              row.original.moderator.toString() === userInfo._id.toString()
                ? "d-none"
                : "d-flex align-items-center ms-auto border-0 bg-light"
            }
          >
            <span className="d-block material-symbols-outlined icon-md text-primary">
              chat
            </span>
          </button>
        </div>
      ),
      // Filter: globalFilterTextHandler,
    },
    {
      accessorKey: "assignedusername",
      header: t("Assigned To"),
      size: 300,
      Cell: ({ row }) => (
        <div className="owner border-bottom-0 d-flex gap-1">
          {row.original.assignedusername === "" ? null : (
            <img
              className="rounded-circle"
              height={35}
              width={35}
              src={
                row.original.assigneduserimage == ""
                  ? assetImages.defaultUser
                  : url.SERVER_URL + row.original.assigneduserimage
              }
              alt="Babcock"
            />
          )}
          <p>
            <span className="d-block">{row.original.assignedusername}</span>
            <span className="d-block">{row.original.assigneduseremail}</span>
          </p>
          <button
            onClick={() => {
              createTaskChatHandler(row.original._id);
            }}
            className={
              !row.original.assigneduser ||
              row.original.assigneduser === userInfo._id
                ? "d-none"
                : "d-flex align-items-center ms-auto border-0 bg-light"
            }
          >
            <span className="d-block material-symbols-outlined icon-md text-primary">
              chat
            </span>
          </button>
        </div>
      ),
      // Filter: globalFilterTextHandler,
    },
    {
      accessorKey: "followersnames",
      header: t("Followers"),
      size: 250,
      Cell: ({ row }) =>
        row.original.followerslist.map((follower, index) => (
          <div className="owner border-bottom-0 d-flex gap-1 mb-1" key={index}>
            <img
              className="rounded-circle"
              height={15}
              width={15}
              src={
                follower.image == ""
                  ? assetImages.defaultUser
                  : url.SERVER_URL + follower.image
              }
              alt="Babcock"
            />

            <p>
              <span className="d-block">{follower.fullname}</span>
            </p>
            <button
              onClick={() => {
                createTaskChatHandler(row.original._id);
              }}
              className={
                follower._id === userInfo._id
                  ? "d-none"
                  : "d-flex align-items-center ms-auto border-0 bg-light"
              }
            >
              <span className="d-block material-symbols-outlined icon-md text-primary">
                chat
              </span>
            </button>
          </div>
        )),
    },
    {
      accessorKey: "projectsectionname",
      header: t("Section"),
      size: 200,
      // Filter: globalFilterTextHandler,
    },
    {
      accessorKey: "tasklogupdatedperson",
      header: t("Log Updated By"),
      size: 300,
      Cell: ({ row }) => (
        <div className="owner border-bottom-0 d-flex gap-1">
          {row.original.tasklogupdatedpersonimage == "" ? null : (
            <img
              className="rounded-circle"
              height={35}
              width={35}
              src={url.SERVER_URL + row.original.tasklogupdatedpersonimage}
              alt="Babcock"
            />
          )}
          <p>
            <span className="d-block">
              {row.original.tasklogupdatedpersonname}
            </span>
            <span className="d-block">
              {row.original.tasklogupdatedpersonemail}
            </span>
          </p>
          <button
            onClick={() => {
              createTaskChatHandler(row.original._id);
            }}
            className={
              !row.original.logupdatedby ||
              row.original.logupdatedby === userInfo._id
                ? "d-none"
                : "d-flex align-items-center ms-auto border-0 bg-light"
            }
          >
            <span className="d-block material-symbols-outlined icon-md text-primary">
              chat
            </span>
          </button>
        </div>
      ),
      // Filter: globalFilterTextHandler,
    },
    {
      accessorKey: "tasklogupdatedon",
      header: t("Log updated on"),
      // Filter: globalFilterTextHandler,
    },
    {
      accessorKey: "leadtitle",
      header: t("Lead"),
      // Filter: globalFilterTextHandler,
      size: 200,
    },
    {
      accessorKey: "contactname",
      header: t("Contact"),
      // Filter: globalFilterTextHandler,
      size: 200,
    },
    {
      accessorKey: "tagstring",
      header: t("Label"),
      Cell: ({ row }) => (
        <div className="label border-bottom-0">
          {row.original.tagstring != "" && (
            <ul className="d-flex flex-wrap gap-2 fs-xs">
              {row.original.tagstring.split(" , ").map((tag, index) => {
                return (
                  <li
                    key={index}
                    className="px-2 py-1 gradient-light rounded-5"
                  >
                    {tag}
                  </li>
                );
              })}
            </ul>
          )}
        </div>
      ),
      // Filter: globalFilterTextHandler,
    },
  ];

  //* Material React Table Column and States */
  const columns = useMemo(
    () => initialTableColumns,
    // [taskList, columnFilterValues]
    [taskList, i18n.language]
  );

  /* Updates the column visibility state by toggling the visibility of the column with the given accessor key.*/
  const initialVisibilityState = initialTableColumns.reduce((acc, column) => {
    acc[column.accessorKey] = true;
    return acc;
  }, {});

  /* Represents the state of column visibility in the table.*/
  const [visibleColoumns, setVisibleColoumns] = useState(
    initialVisibilityState
  );

  /* Updates the column visibility state based on the new column state.*/
  const onColumnVisiblityHandler = (newColumnState) => {
    if (typeof newColumnState === "function") {
      const newColumnStateName = newColumnState();
      setVisibleColoumns((prev) => ({ ...prev, ...newColumnStateName }));
    } else {
      setVisibleColoumns(newColumnState);
    }
  };

  //initialize the column order
  const initialColumnOrder = [
    "#",
    "mrt-row-select",
    ...columns.map((c) => c.accessorKey),
  ]; //array of column ids (Initializing is optional as of v2.10.0)

  const [columnOrder, setColumnOrder] = useState(initialColumnOrder);

  //function for change column order
  const changeColumnOrderHandler = (changedOrder) => {
    setColumnOrder(changedOrder);
  };

  useEffect(() => {
    if (reloadData) {
      setIsFilterReset(true);
      getAllTask();
      setReloadData(false);
    }
  }, [reloadData]);

  useEffect(() => {
    const selectedIdsArray = Object.keys(rowSelection).filter(
      (key) => rowSelection[key]
    );

    if (selectedIdsArray.length > 0) {
      setSelectedTaskIds(selectedIdsArray);
    } else {
      setSelectedTaskIds([]);
    }
  }, [rowSelection]);

  // useEffect(() => {
  //   console.log(taskList);
  // }, [taskList]);

  // save the last pagination
  useEffect(() => {
    if (pagination.pageIndex >= 0 || pagination.pageSize >= 10) {
      setLastPagination({
        pageIndex: pagination.pageIndex,
        pageSize: pagination.pageSize,
      });
    }
  }, [pagination.pageIndex, pagination.pageSize]);

  //refresh icon function
  const refreshRecords = () => {
    resetFilterData();
    setVisibleColoumns(initialVisibilityState);
    setColumnOrder(initialColumnOrder);
  };

  // const initialGlobalFilter = Object.entries(columnFilterValues).map(
  //   ([id, value]) => ({ id, value })
  // );

  // const toggleColumnFiltersChange = () => {
  //   setShowGlobalFilters(!showGlobalFilters);
  // };

  // const testingFunction = () => {
  //   console.log("testing");
  // };

  // useEffect(() => {
  //   console.log(columnFilterValues);
  // }, [columnFilterValues]);

  const onAlertClose = () => {
    // Set the state variable 'showAlert' to false to hide the alert.
    setShowAlert(false);

    // Clear the alert message by setting the state variable 'alertMessage' to an empty string.
    setAlertMessage("");

    // Clear the alert type by setting the state variable 'messageType' to an empty string.
    setMessageType("");
  };

  //download as excel
  const downloadAsExcel = () => {
    if (taskList.length > 0) {
      const createRowData = (apiData) => [
        apiData.customid,
        apiData.title,
        apiData.rank,
        apiData.taskstatus,
        apiData.completedpercent,
        apiData.projectname,
        apiData.taskpriority,
        apiData.hours,
        apiData.loggedhours,
        apiData.balancehours,
        apiData.startdate,
        apiData.enddate,
        apiData.tasknumberofdaysleft,
        apiData.moderatorname,
        apiData.assignedusername,
        apiData.followersnames,
        apiData.projectsectionname,
        apiData.tasklogupdatedperson,
        apiData.tasklogupdatedon,
        apiData.leadtitle,
        apiData.contactname,
        apiData.tagstring,
      ];

      const filteredList =
        selectedTaskIds.length > 0
          ? taskList.filter((item) => selectedTaskIds.includes(item._id))
          : taskList;

      filteredList.map((apiData) => {
        const rowData = createRowData(apiData);
        setExcelData((prevState) => [...prevState, rowData]);
      });
    }
  };

  const createExcel = () => {
    const fileName = "tasks";

    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";

    const fileExtension = ".xlsx";

    const Heading = [
      [
        "ID",
        "Title",
        "Rank",
        "Task Status",
        "Complete %",
        "Project",
        "Priority",
        "Planned Hours",
        "Dedicated Hours",
        "Balance Hours",
        "Start Date",
        "End Date",
        "Days Left",
        "Assigned By",
        "Assigned To",
        "Followers",
        "Section",
        "Log Updated By",
        "Log Updated On",
        "Lead",
        "Contact",
        "Label",
      ],
    ];

    const ws = XLSX.utils.json_to_sheet(excelData, {
      origin: "A2",
      skipHeader: true,
    });

    XLSX.utils.sheet_add_aoa(ws, Heading, { origin: "A1" });

    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };

    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });

    const filedata = new Blob([excelBuffer], { type: fileType });

    FileSaver.saveAs(filedata, fileName + fileExtension);
  };

  useEffect(() => {
    // console.log("excelData", excelData);

    if (excelData.length > 0) {
      createExcel();
      setExcelData([]);
    }
  }, [excelData]);

  // download excel ends

  useEffect(() => {
    if (afterSavedTaskData) {
      // console.log("afterSavedTaskData---->", afterSavedTaskData);

      setTaskList((prevTasks) => {
        const existingIndex = taskList.findIndex(
          (task) => task._id.toString() === afterSavedTaskData._id.toString()
        );

        if (existingIndex !== -1) {
          // task exists, update the existing record
          const updatedTasks = [...prevTasks];
          updatedTasks[existingIndex] = afterSavedTaskData;
          return updatedTasks;
        } else {
          // Roll number does not exist, add new student at index 0
          return [afterSavedTaskData, ...prevTasks];
        }
      });

      setAfterSavedTaskData(null);
    }
  }, [afterSavedTaskData]);

  if (
    userInfo.role.slug === "ADMIN" ||
    userInfo.role.slug === "SUPER_ADMIN" ||
    moduleAccess.includes("MOD_TASK")
  ) {
    return (
      <div id="content_wrapper">
        <section className="survey-wrapper bg-white pb-5">
          <div className="container-fluid px-lg-5">
            {/* -------- header area ---------- */}
            <MyTaskListHeader
              userInfo={userInfo}
              reloadList={refreshRecords}
              updateBulkTaskHandler={updateBulkTaskHandler}
              setselectedTaskModerator={setselectedTaskModerator}
              updateScheduleStateHandler={updateBulkTaskScheduleStateHandler}
              downloadAsExcel={downloadAsExcel}
              taskList={taskList}
              delayedTaskCount={delayedTaskCount}
              followingTaskCount={followingTaskCount}
              inProgressTaskCount={inProgressTaskCount}
              testingTaskCount={testingTaskCount}
              pendingTaskCount={pendingTaskCount}
              completedTaskCount={completedTaskCount}
              totalTaskHours={totalTaskHours}
              totalTaskLogHours={totalTaskLogHours}
            />

            {isLoading ? (
              <div className="placeholder-glow d-flex flex-column gap-4">
                <span className="placeholder placeholder-lg bg-secondary col-12"></span>
                <span className="placeholder placeholder-lg bg-secondary col-8"></span>
                <span className="placeholder placeholder-lg bg-secondary col-4"></span>
              </div>
            ) : (
              <div className="table-wrapper">
                {/* <div className="d-flex align-items-center gap-2">
                  <button className="btn btn-primary" onClick={testingFunction}>
                    Testing Button
                  </button>
                </div> */}
                <MaterialReactTable
                  columns={columns} // map columns to be displayed with api data,
                  data={taskList} // data from api to be displayed
                  enableGrouping
                  enableRowSelection // enable showing checkbox
                  enableColumnOrdering={true}
                  getRowId={(row) => row._id} // map which value to select with row checkbox
                  onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
                  state={{
                    rowSelection,
                    pagination: lastPagination,
                    columnVisibility: visibleColoumns,
                    columnOrder: columnOrder,
                    // filters: initialGlobalFilter,
                    // showColumnFilters: showGlobalFilters,
                  }} //pass our managed row selection state to the table to use
                  onPaginationChange={setPagination} // set pagination
                  initialState={{
                    rowSelection,
                    pagination: lastPagination,
                    columnVisibility: visibleColoumns,
                    columnOrder: columnOrder,
                    // filters: initialGlobalFilter,
                    // showColumnFilters: showGlobalFilters,
                  }}
                  onColumnVisibilityChange={onColumnVisiblityHandler}
                  onColumnOrderChange={changeColumnOrderHandler}
                  // onShowColumnFiltersChange={toggleColumnFiltersChange}
                  defaultColumn={{
                    minSize: 20,
                    maxSize: 300,
                    size: 50, //make columns wider by default
                  }}
                  muiTableContainerProps={{
                    sx: {
                      maxHeight: "60vh",
                    },
                  }}
                  enableStickyHeader
                />
              </div>
            )}
          </div>
        </section>

        {/* ========= popups and modals area ======== */}
        {/* -------- add label modal ---------- */}
        <AddLabelModal
          moduleName="task"
          selectedIds={selectedTaskIds}
          afterTagModalClose={getAllTask}
          setShowAlert={setShowAlert}
          setAlertMessage={setAlertMessage}
          setMessageType={setMessageType}
        />

        {/* ---------- filter popup ---------- */}
        <MyTaskFilterPopup
          visibleColoumns={visibleColoumns}
          setVisibleColoumns={setVisibleColoumns}
          columnOrder={columnOrder}
          setColumnOrder={setColumnOrder}
          columnFilterValues={columnFilterValues}
          setColumnFilterValues={setColumnFilterValues}
          showGlobalFilters={showGlobalFilters}
          setShowGlobalFilters={setShowGlobalFilters}
          isFilterReset={isFilterReset}
          setIsFilterReset={setIsFilterReset}
          filterTaskDateRangeType={filterTaskDateRangeType}
          setFilterTaskDateRangeType={setFilterTaskDateRangeType}
          filterTaskFromDate={filterTaskFromDate}
          setfilterTaskFromDate={setfilterTaskFromDate}
          filterTaskToDate={filterTaskToDate}
          setfilterTaskToDate={setfilterTaskToDate}
          filterTaskCustomIds={filterTaskCustomIds}
          setfilterTaskCustomIds={setfilterTaskCustomIds}
          filterTaskTitle={filterTaskTitle}
          setfilterTaskTitle={setfilterTaskTitle}
          filterTaskProjects={filterTaskProjects}
          setfilterTaskProjects={setfilterTaskProjects}
          filterTaskAssignedByUsers={filterTaskAssignedByUsers}
          setfilterTaskAssignedToUsers={setfilterTaskAssignedToUsers}
          filterTaskAssignedToUsers={filterTaskAssignedToUsers}
          setfilterTaskAssignedByUsers={setfilterTaskAssignedByUsers}
          filterTaskStatus={filterTaskStatus}
          setfilterTaskStatus={setfilterTaskStatus}
          filterTaskPriorities={filterTaskPriorities}
          setfilterTaskPriorities={setfilterTaskPriorities}
          filterTaskLabels={filterTaskLabels}
          setfilterTaskLabels={setfilterTaskLabels}
          moduleName={params.userid ? `mytask${params.userid}` : "mytask"}
          setIsNoDefaultFilter={setIsNoDefaultFilter}
        />

        {/* ---------- add project popup ------------ */}

        <TaskManagementPopup
          selectedModuleSlug=""
          moduleName={"mytask"}
          leadId={null}
          contactId={null}
          sectionId={null}
          projectId={null}
          selectedTaskId={selectedTaskId}
          selectedTaskModerator={selectedTaskModerator}
          isProjectModerator={false}
          projectValue={null}
          selectedSectionValue={null}
          leadValue={null}
          contactValue={null}
          onPopUpClose={() => {
            setSelectedTaskId(null);
            setselectedTaskModerator(null);
          }}
          getTaskList={(data) => {
            if (data) {
              setTimeout(() => {
                setAfterSavedTaskData(data);
              }, 1500);
            } else {
              setAfterSavedTaskData(null);
            }
          }}
          setShowAlert={setShowAlert}
          setAlertMessage={setAlertMessage}
          setMessageType={setMessageType}
        />

        <AssignBulkTaskModal
          moduleName={"mytask"}
          userInfo={userInfo}
          afterCloseModalHandler={afterCloseModalHandler}
          selectedTasks={selectedTaskIds}
          setBulkSavedTaskList={setBulkSavedTaskList}
          setShowAlert={setShowAlert}
          setAlertMessage={setAlertMessage}
          setMessageType={setMessageType}
        />

        <AddAlertModal
          selectedIds={selectedTaskIds}
          afterModalClose={() => {
            setSelectedTaskIds([]);
            setRowSelection({});
          }}
          moduleName={"task"}
        />

        <SuccessBulkTaskModal
          bulkSavedTaskList={bulkSavedTaskList}
          afterModalClose={afterCloseModalHandler}
        />

        <DeleteTaskWarningModal
          onCancelDelete={() => {
            setRowSelection({});
            setSelectedTaskIds([]);
          }}
          onDelete={deActivateBulkTaskHandler}
        />

        <AddFollowerModal
          moduleName="task"
          setShowAlert={setShowAlert}
          setAlertMessage={setAlertMessage}
          setMessageType={setMessageType}
          selectedIds={selectedTaskIds}
          afterModalClose={() => {
            setTimeout(() => {
              getAllTask();
            }, 2500);
          }}
        />

        <UpdateTaskSchedulePopup
          updateScheduleTaskUserList={updateScheduleTaskUserList}
          setUpdateScheduleTaskUserList={setUpdateScheduleTaskUserList}
          reloadList={() => {
            setTimeout(() => {
              getAllTask();
            }, 2500);
          }}
          closePopupHandler={() => {
            setRowSelection({});
            setSelectedTaskIds([]);
          }}
          setShowAlert={setShowAlert}
          setAlertMessage={setAlertMessage}
          setMessageType={setMessageType}
        />

        {showAlert && (
          <AlertNotification
            showAlert={showAlert}
            message={alertMessage}
            alertType={messageType}
            onClose={onAlertClose}
          />
        )}
      </div>
    );
  } else {
    return (
      <div id="content_wrapper">
        <section className="crm-wrapper bg-white pb-5">
          <div className="empty_access text-center">
            <div className="empty_pic mb-4">
              {" "}
              <img src={assetImages.emptyVector} alt="" />
            </div>
            <div className="empty_text">
              <p className="fs-lg text-gray fw-semibold mb-4">
                {t("Sorry....! You don't have privilege to see this content")}
              </p>
            </div>
          </div>
        </section>
      </div>
    );
  }
};

export default MyTaskListBody;
