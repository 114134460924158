import React, { useEffect } from "react";
import Header from "components/Common/Header/Header";
import CategoryTreeBody from "components/AdministrationComponents/CategoryComponents/CategoryTreeBody/CategoryTreeBody";

const CategoryTree = () => {
  useEffect(() => {
    document.title = "Administration | Categories";
  }, []);

  return (
    <main id="app">
      <Header moduleName="administration" />
      <CategoryTreeBody />
    </main>
  );
};

export default CategoryTree;
