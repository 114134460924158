/* eslint-disable */
import { useState, useEffect, useRef } from "react";
import { useHistory, useParams } from "react-router-dom";

import { useTranslation } from "react-i18next";

// Material UI table
import { MaterialReactTable } from "material-react-table";

/*------ import url and method ------*/
import * as url from "helper/UrlHelper";
import { postData, getData, putData } from "utils/Gateway";

import TabsHeader from "components/Common/TabsHeader/TabsHeader";
import { productDetailsHeaderLinks } from "helper/ProductHelper/ProductHelper";
import ProductOfferHeader from "./Header/ProductOffersHeader";
import SaveOffersPopup from "./Popup/SaveOffersPopup";
import AlertNotification from "components/Common/AlertNotification/AlertNotification";
import OfferFilterPopup from "./Popup/OfferFilterPopup";

const ProductOffersBody = () => {
  const { t } = useTranslation();

  const params = useParams();
  const history = useHistory();

  const token = localStorage.getItem("token");

  const commonHeaderObject = productDetailsHeaderLinks(params.id, t);

  const [name, setname] = useState("");

  const [offers, setOffers] = useState([]);
  const [loading, setLoading] = useState(false);

  const [rowSelection, setRowSelection] = useState({});
  const [selectedOfferIds, setSelectedOfferIds] = useState([]);
  const [selectedOfferId, setSelectedOfferId] = useState(null);

  //alert requirements
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [messageType, setMessageType] = useState("");

  //function for get product details
  const getProductDetails = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_PRODUCT_DETAILS +
        `/${params.id}?token=${token}`;

      const response = await getData(requestUrl);

      if (response.status) {
        const data = response.data;

        setname(data.name);
      } else {
        setAlertMessage(response.message);
        setMessageType("error");
        setShowAlert(true);
      }
    } catch (error) {
      setAlertMessage(error.message);
      setMessageType("error");
      setShowAlert(true);
    }
  };

  //function for get all offers
  const getAllProductPricings = async () => {
    try {
      setLoading(true);

      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_ALL_PRODUCT_PRICINGS +
        `?token=${token}&productid=${params.id}`;

      const response = await getData(requestUrl);

      setLoading(false);

      if (response.status) {
        setMessageType("success");
        setOffers(response.data);
      } else {
        setMessageType("error");
      }
      setAlertMessage(response.message);
    } catch (error) {
      setAlertMessage(error.message);
      setMessageType("error");
    }
    setShowAlert(true);
  };

  useEffect(() => {
    if (params.id) {
      getProductDetails();
      getAllProductPricings();
    }
  }, [params.id]);

  const columns = [
    {
      accessorKey: "#",
      header: t("Actions"),
      Cell: ({ row }) => (
        <button
          className="action_btn_mui"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasSaveOffer"
          aria-controls="offcanvasSaveOffer"
          onClick={() => {
            setSelectedOfferId(row.original._id);
          }}
        >
          <span className="d-block material-symbols-outlined horz_icon">
            more_horiz
          </span>
        </button>
      ),
      enableColumnActions: false, // Hides the column action icon
      enableColumnDragging: false, // Hides the move icon
      enableSorting: false,
    },
    {
      accessorKey: "variant", // use accessorKey instead of field
      header: t("Offer Name"),
    },
    {
      accessorKey: "price",
      header: t("price"),
    },
    {
      accessorKey: "discountprice",
      header: t("Discount"),
    },
    {
      accessorKey: "pricingstartdate",
      header: t("Valid From"),
    },
    {
      accessorKey: "pricingenddate",
      header: t("Valid To"),
    },
    {
      accessorKey: "activestatus",
      header: t("Status"),
    },
  ];

  //initialize the column order
  const initialColumnOrder = [
    "#",
    "mrt-row-select",
    ...columns.map((c) => c.accessorKey),
  ]; //array of column ids (Initializing is optional as of v2.10.0)

  useEffect(() => {
    const selectedIdsArray = Object.keys(rowSelection).filter(
      (key) => rowSelection[key]
    );

    if (selectedIdsArray.length > 0) {
      setSelectedOfferIds(selectedIdsArray);
    } else {
      setSelectedOfferIds([]);
    }
  }, [rowSelection]);

  const onAlertClose = () => {
    setShowAlert(false);
    setAlertMessage("");
    setMessageType("");
  };

  return (
    <div id="content_wrapper">
      <section className="marketplace-wrapper bg-white">
        {params.id && (
          <TabsHeader
            commonHeaderObject={commonHeaderObject}
            activeOption={t("Offers")}
          />
        )}

        <div className="container-fluid px-lg-5">
          <ProductOfferHeader name={name} reloadList={getAllProductPricings} />

          {/* table */}
          {loading ? (
            <div className="placeholder-glow d-flex flex-column gap-4">
              <span className="placeholder placeholder-lg bg-secondary col-12"></span>
              <span className="placeholder placeholder-lg bg-secondary col-8"></span>
              <span className="placeholder placeholder-lg bg-secondary col-4"></span>
            </div>
          ) : (
            <div className="table-wrapper">
              <MaterialReactTable
                columns={columns} // map columns to be displayed with api data,
                data={offers} // data from api to be displayed
                enableGrouping // to enable grouping of column
                enableRowSelection // enable showing checkbox
                getRowId={(row) => row._id} // map which value to select with row checkbox
                onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
                state={{ rowSelection, columnOrder: initialColumnOrder }} //pass our managed row selection state to the table to use
                initialState={{
                  rowSelection,
                  columnOrder: initialColumnOrder,
                }}
                defaultColumn={{
                  minSize: 20,
                  maxSize: 300,
                  size: 50, //make columns wider by default
                }}
                muiTableContainerProps={{
                  sx: {
                    maxHeight: "60vh",
                  },
                }}
                enableStickyHeader
              />
            </div>
          )}
        </div>
      </section>

      <SaveOffersPopup
        afterPopupCLose={getAllProductPricings}
        setPricingId={setSelectedOfferId}
        pricingId={selectedOfferId}
        setShowAlert={setShowAlert}
        setAlertMessage={setAlertMessage}
        setMessageType={setMessageType}
      />

      <OfferFilterPopup />

      {showAlert && (
        <AlertNotification
          showAlert={showAlert}
          message={alertMessage}
          alertType={messageType}
          onClose={onAlertClose}
        />
      )}
    </div>
  );
};

export default ProductOffersBody;
