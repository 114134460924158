/* eslint-disable */
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { useTranslation } from "react-i18next";

//**------ import url and method ------*/
import * as url from "helper/UrlHelper";
import { getData, postData, putData } from "utils/Gateway";

// Import CKEditor here (both required).
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const AiSuggestWinnerModal = ({
  jobDetails,
  selectedResponseIds,
  askAiSuggest,
  setAskAiSuggest,
}) => {
  const { t } = useTranslation(); // for translations

  const loadingCircle = [1, 2, 3, 4, 5];

  const token = localStorage.getItem("token");

  /*---- CKEditor classic editor configuration start ----- */
  const editorConfig = {
    toolbar: ["bold", "italic", "link", "bulletedList", "numberedList"],
    ckfinder: {
      // Upload the images to the server using the CKFinder QuickUpload command.
      //   uploadUrl: url.URL_TEXT_EDITOR_IMAGE_UPLOAD_2,
    },
    placeholder: t("Type details here..."),
  };

  const [aiProcessing, setAiProcessing] = useState(false);
  const [askReEvaluate, setAskReEvaluate] = useState(false);
  const [candidateData, setCandidateData] = useState([]);
  const [isSaving, setIsSaving] = useState(false);

 

  useEffect(() => {

    console.log('useEffect selectedResponseIds', selectedResponseIds, askAiSuggest);

    if (selectedResponseIds.length > 0 && askAiSuggest) {
      setCandidateData([])

      getAiResponseRanking();
    }
  }, [askAiSuggest]);


  //getAiResponseRanking
  // run a loop of selectedResponseIds and getChallengeResponseDetails for each records
  // next send data from each response to getAiSuggest
  const getAiResponseRanking = async () => {
    try {
      setAiProcessing(true);
      let candidates = [];

      for (let i = 0; i < selectedResponseIds.length; i++) {
        const responseDetails = await getChallengeResponseDetails(selectedResponseIds[i]); // Await the response
        console.log("responseDetails", responseDetails);

        if (responseDetails) {
          let candidateName = responseDetails.name;
          let candidateEmail = responseDetails.email;

          let cvDetails = responseDetails.cvdetails;

          const aiResponse = await getAiSuggest(jobDetails, cvDetails);

          console.log("aiResponse", aiResponse);

          let score = aiResponse.score ? aiResponse.score : 'N/A';
          let comments = aiResponse.finalComment ? aiResponse.finalComment : 'We are processing .......';

          // Add candidate data dynamically
          candidates.push({ candidateName, candidateEmail, score, comments });

          // Update state dynamically
          setCandidateData([...candidates]);
        }
      }

      setAiProcessing(false);
    } catch (error) {
      console.log(error.message);
      setAiProcessing(false);
    }
  };

  // send a post request to the server to save the AI suggestion
    const getAiSuggest = async (jobDetails, cvDetails) => {
      try {
         
        const promptData = {
          jobDetails: jobDetails,
          cvText: cvDetails,
        };
  
        let requestUrl = url.API_BASE_URL;
  
        requestUrl += url.API_CHALLENGE_MULTI_RESPONSE_AI_SUGGEST + `?token=${token}`;
  
        console.log("API_CHALLENGE_MULTI_RESPONSE_AI_SUGGEST", requestUrl);
  
        const response = await postData(requestUrl, promptData);

        console.log("AI API_CHALLENGE_MULTI_RESPONSE_AI_SUGGEST Response", response);
  
        return response;
  
      } catch (err) {
        console.log(err);
      }
    };



  // get challenge response details
  const getChallengeResponseDetails = async (challengeResponseId) => {
      //setIsLoading(true);

      const cadidateCvDetails = {
        name: "",
        email: "",
        cvdetails: "",
      };

      try {
        let requestUrl =
          url.API_BASE_URL +
          url.API_GET_CHALLENGE_RESPONSE_CV_DETAILS +
          `/${challengeResponseId}?token=${token}`;

        console.log('cv details url request', requestUrl)
  
        const response = await getData(requestUrl);
  
        console.log("challenge response CV Details", response);
  
        if (response.status && response.data) {
          
          cadidateCvDetails.name = response.candidatename;
          cadidateCvDetails.email = response.candidateemail;
          cadidateCvDetails.cvdetails = response.cvdetails;
  
          //console.log("cadidateCvDetails", cadidateCvDetails);
  
          return cadidateCvDetails;

        }else{
          return null;
        }
  
        //setIsLoading(false);
      } catch (error) {
        console.log(error.message);

        return null;
      }
  };

  const resetHandler = () => {
    setAskAiSuggest(false);
    setCandidateData([])
  }


  return (
    <div
      className="modal fade"
      id="aisuggestreport_modal"
      tabIndex="-1"
      aria-labelledby="aisuggestreport_modal"
      aria-hidden="true"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
    >
      <div className="modal-dialog modal-xl">
        <div className="modal-content">
          <div className="modal-header">
            <h3 className="modal-title">AI Suggest Report</h3>

            <button
              type="button"
              className="btn-close p-0 bg-gray-200 rounded-circle shadow-none m-0"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => {                
                resetHandler()
              }}
            ></button>
          </div>
          <div className="modal-body">
            {aiProcessing ? (
              <div className="d-flex align-items-center mb-3">
                <div className="mx-2 spinner-border text-primary" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
                <h6 className="text text-primary mt-1 d-flex align-items-center">
                  Please wait while we evaluate the candidate ....
                </h6>
              </div>
            ) : null}


            {/* AI Report Table */}
              <table className="table table-bordered mt-3">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Email</th>
                    <th>AI Score (0-10)</th>
                    <th>Suggestion</th>
                  </tr>
                </thead>
                <tbody>
                  {candidateData.map((candidate, index) => (
                    <tr key={index}>
                      <td>{candidate.candidateName}</td>
                      <td>{candidate.candidateEmail}</td>
                      <td>{candidate.score}</td>
                      <td>{candidate.comments}</td>
                    </tr>
                  ))}
                </tbody>
              </table>

            
          </div>
        </div>
      </div>
    </div>
  );
};

export default AiSuggestWinnerModal;
