//function common header crm links
export const productDetailsHeaderLinks = (id, t) => {
  return [
    { title: t("Vital Info"), link: `/admin/marketplace/product/details/${id}` },
    { title: t("Category"), link: `/admin/marketplace/product/category/${id}` },
    { title: t("Variants"), link: `/admin/marketplace/product/variants/${id}` },
    { title: t("Offers"), link: `/admin/marketplace/product/offers/${id}` },
    { title: t("Gallery"), link: `/admin/marketplace/product/gallery/${id}` },
    { title: t("SEO"), link: `#`},
  ];
};
