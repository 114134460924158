/* eslint-disable */
import Header from "components/Common/Header/Header";
import ProjectTaxonomyBody from "components/ProjectManagementComponents/ProjectDetailsComponents/ProjectTaxonomy/ProjectTaxonomyBody";
import React, { useEffect } from "react";

const ProjectTaxonomies = () => {
  // -------- for title ------------
  useEffect(() => {
    document.title = "Project Management";
  }, []);

  return (
    <main id="app">
      {/* ---- common header ---- */}
      <Header moduleName="projects" />

      <ProjectTaxonomyBody />
    </main>
  );
};

export default ProjectTaxonomies;
