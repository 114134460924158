/* eslint-disable */
import Header from "components/Common/Header/Header";
import ProductGalleryBody from "components/MarketplaceComponents/ProductComponents/ProductDetails/ProductGallery/ProductGalleryBody";
import { useEffect } from "react";

const ProductGallery = () => {
  useEffect(() => {
    document.title = "Marketplace";
  }, []);

  return (
    <main id="app">
      {/* ---- common header ---- */}
      <Header moduleName="marketplace" />
      <ProductGalleryBody />
    </main>
  );
};

export default ProductGallery;
