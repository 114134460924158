import BreadCrumb from "components/Common/BreadCrumb/BreadCrumb";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const FaqListHeader = ({
  refreshHandler = () => {},
  updateBulkHandler = () => {},
  deleteMassiveHandler = () => {},
}) => {
  const { t } = useTranslation(); //for translation
  /* ---- bread crumb text ---- */
  const breadcrumbText = [{ title: t("Faq") }];
  return (
    <div className="filter-container py-3">
      <div className="row align-items-center">
        {/* ----- search by text section start ------ */}
        <div className="col-md-5 col-lg-6 d-none d-md-block mb-3 mb-md-0">
          <BreadCrumb breadCrumbText={breadcrumbText} />
        </div>
        {/* ----- search by text section end ------ */}

        {/* ----- refresh,action dropdown,filter and add new icon section start ------ */}
        <div className="col-md-7 col-lg-6 d-flex justify-content-end gap-1 gap-sm-2">
          {/* ------ refresh icon start ------ */}
          <Link
            to="#"
            className="btn btn-gray d-flex align-items-center"
            onClick={refreshHandler}
          >
            <span className="d-block material-symbols-outlined icon-md">
              refresh
            </span>
          </Link>
          {/* ------ refresh icon end ------ */}

          {/* ----- add label,delete action dropdown section start ------ */}
          <div className="dropdown flex-fill flex-grow-sm-0">
            <button
              className="btn btn-gray text-start w-100 dropdown-toggle"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {t("Action")}
            </button>

            <ul className="dropdown-menu w-100 bg-white fs-sm border-0 rounded-10 shadow-sm">
              {/* ----- add tag modal section start -----  */}
              <li>
                <Link
                  to="#"
                  className="dropdown-item d-flex align-items-center gap-1"
                  data-bs-toggle="modal"
                  data-bs-target="#addLabelModal"
                >
                  <span className="d-block material-symbols-outlined icon-sm">
                    label
                  </span>
                  <span className="d-block">{t("Add Label")}</span>
                </Link>
              </li>
              {/* ----- add tag modal section end -----  */}

              {/* ----- update rank section start -----  */}
              <li>
                <Link
                  to="#"
                  className="dropdown-item d-flex align-items-center gap-1"
                  onClick={updateBulkHandler}
                >
                  <span className="d-block material-symbols-outlined icon-sm">
                    progress_activity
                  </span>
                  <span className="d-block">{t("Update Rank")}</span>
                </Link>
              </li>
              {/* ----- update rank section end -----  */}

              <li>
                <Link
                  to="#"
                  className="dropdown-item d-flex align-items-center gap-1"
                  onClick={() => {
                    updateBulkHandler("approve");
                  }}
                >
                  <span className="d-block material-symbols-outlined icon-sm">
                    book
                  </span>
                  <span className="d-block">{t("Approve to publish")}</span>
                </Link>
              </li>

              {/* ----- delete status section start -----  */}
              <li>
                <Link
                  to="#"
                  className="dropdown-item d-flex align-items-center gap-1"
                  data-bs-toggle="modal"
                  data-bs-target="#deletewarning"
                >
                  <span className="d-block material-symbols-outlined icon-sm">
                    delete
                  </span>
                  <span className="d-block">{t("Delete")}</span>
                </Link>
              </li>
              {/* ----- delete status section end -----  */}
            </ul>
          </div>
          {/* ----- add label,delete action dropdown section end ------ */}

          {/* ------ show search filter link start ------ */}
          <Link
            to="#"
            className="btn btn-gray d-flex align-items-center gap-1"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasFaqFilter"
            aria-controls="offcanvasFaqFilter"
          >
            <span className="d-block material-symbols-outlined icon-md">
              tune
            </span>
            <span className="d-block">{t("Filter")}</span>
          </Link>
          {/* ------ show search filter link end ------ */}

          {/* ------ add new blog link start ------ */}
          <Link
            to="#"
            data-bs-toggle="offcanvas"
            data-bs-target="#saveFaqOffcanvas"
            aria-controls="saveFaqOffcanvas"
            className="btn btn-primary d-flex align-items-center gap-1"
          >
            <span className="d-block material-symbols-outlined icon-md">
              add
            </span>
            <span className="d-block">{t("Add New")}</span>
          </Link>
          {/* ------ add new blog link end ------ */}
        </div>
        {/* ----- refresh,action dropdown,filter and add new icon section end ------ */}
      </div>
    </div>
  );
};

export default FaqListHeader;
