export const taxonomyCommonHeaderLinkLists = (t) => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo") || "{}");
  const currentUserRole = userInfo?.role?.slug || "USER";
  const adminRoles = new Set(["ADMIN", "SUPER_ADMIN"]);

  if (adminRoles.has(currentUserRole)) {
    return [
      {
        title: t("Project Type"),
        link: "/admin/administration/taxonomytype/project",
      },
      //add next tabs
    ];
  }

  return [];
};
