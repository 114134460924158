import Header from "components/Common/Header/Header";
import OpportunityKanbanKitBody from "components/CRMComponents/CrmOpportunity/OpportunityKanbanKit/OpportunityKanbanKitBody";
import React, { useEffect } from "react";

const CrmOpportunityKanbanKit = () => {
  useEffect(() => {
    document.title = "CRM Opportunity";
  }, []);

  return (
    <main id="app">
      {/* ---- header component ---- */}
      <Header moduleName="crm" />

      {/* --- body section --- */}
      <OpportunityKanbanKitBody />
    </main>
  );
};

export default CrmOpportunityKanbanKit;
