/* eslint-disable */
import { useState, useEffect, useRef } from "react";
import { Link, useHistory, useParams } from "react-router-dom";

import { useTranslation } from "react-i18next";

/*------ import url and method ------*/
import * as url from "helper/UrlHelper";
import { getData, putData, uploadMultipleFile } from "utils/Gateway";

import TabsHeader from "components/Common/TabsHeader/TabsHeader";
import { productDetailsHeaderLinks } from "helper/ProductHelper/ProductHelper";

import { assetImages } from "constants";
import AlertNotification from "components/Common/AlertNotification/AlertNotification";
import DeleteModal from "./Modal/DeleteModal";

const ProductCategoriesBody = () => {
  const { t } = useTranslation(); //for translation
  const params = useParams();

  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const moduleAccess = localStorage.getItem("moduleaccess");

  const commonHeaderObject = productDetailsHeaderLinks(params.id, t);

  const [isLoading, setIsLoading] = useState(false);
  const [galleryImages, setGalleryImages] = useState([]);

  const [uploadedPreviewImages, setUploadedPreviewImages] = useState([]);
  const [uploadedImageIds, setUploadedImageIds] = useState([]); // use state for uploaded image id
  const [isUploading, setIsUploading] = useState(false);

  const [deleteImageId, setDeleteImageId] = useState(null);

  const [isSaving, setIsSaving] = useState(false);

  //* alert requirements
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [messageType, setMessageType] = useState("");

  //function for image upload
  const imageUploadHandler = async (e) => {
    const file = e.target.files;
    setIsUploading(true);
    try {
      const requestUrl =
        url.API_BASE_URL + url.API_MULTIPLE_FILE_UPLOAD + `?token=${token}`;

      const response = await uploadMultipleFile(requestUrl, file);

      // console.log("upload response-------->", response);

      setIsUploading(false);
      resetGalleryImageFile();
      if (response.status) {
        setUploadedImageIds(response.data._id);

        /*----- assign uploaded files with path for display start ------*/
        const uploadedImageValues = response.data.map(
          ({ name, path, _id, ...other }) => ({
            name,
            path: url.SERVER_URL + path,
            _id,
            isCover: false,
          })
        );

        //check old file exist or not if exist then new images will concat with old images
        if (uploadedPreviewImages.length == 0) {
          setUploadedPreviewImages(uploadedImageValues);
        } else {
          let fileArr = uploadedPreviewImages.concat(uploadedImageValues);
          setUploadedPreviewImages(fileArr);
        }
        /*----- assign uploaded images with path for display end ------*/

        /*----- assign uploaded file ids start ------*/
        const fileIds = response.data.map(({ _id, ...other }) => ({ _id }));

        //check old file ids are exist or not.If exist then new id will concat with old id
        if (uploadedImageIds.length == 0) {
          setUploadedImageIds(fileIds);
        } else {
          let fileArr = uploadedImageIds.concat(fileIds);
          setUploadedImageIds(fileArr);
        }
        /*----- assign uploaded file ids end ------*/
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  //function for close image file
  const closeGalleryImageFileHandler = (index) => {
    const closedFile = [...uploadedPreviewImages];
    const removedFileId = [...uploadedImageIds];

    closedFile.splice(index, 1);
    removedFileId.splice(index, 1);

    setUploadedPreviewImages(closedFile);
    setUploadedImageIds(removedFileId);

    resetGalleryImageFile();
  };

  // function for clear file value
  const resetGalleryImageFile = () => {
    const file = document.getElementById("uploadedGalleryImageFile");
    if (file) {
      file.value = null;
    } else {
      return;
    }
  };

  //function for delete image from gallery
  const deleteImageHandler = async () => {
    if (deleteImageId) {
      try {
        const removedFileIds = [...uploadedImageIds];

        removedFileIds.splice(deleteImageId, 1);

        let productData = {
          gallery: removedFileIds,
        };

        let requestUrl =
          url.API_BASE_URL +
          url.API_UPDATE_PRODUCT +
          `/${params.id}?token=${token}`;

        const response = await putData(requestUrl, productData);

        console.log(response);

        if (response.status) {
          setAlertMessage("Image deleted successfully.");
          setMessageType("success");
          setShowAlert(true);

          setTimeout(() => {
            getProductDetails();
          }, 1000);
        }
      } catch (error) {
        console.log(error.message);
      }
    }
  };

  //checked preview image handler
  const checkedPreviewImageHandler = (checked, index) => {
    const updatedPreviewImages = [...uploadedPreviewImages];
    updatedPreviewImages[index].isCover = !updatedPreviewImages[index].isCover;
    setUploadedPreviewImages(updatedPreviewImages);
  };

  //checked gallery image handler
  const checkedGalleryImageHandler = (checked, index) => {
    const updatedGalleryImages = [...galleryImages];
    updatedGalleryImages[index].isCover = !updatedGalleryImages[index].isCover;
    setGalleryImages(updatedGalleryImages);
  };

  //function for get product details
  const getProductDetails = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_PRODUCT_DETAILS +
        `/${params.id}?token=${token}`;

      const response = await getData(requestUrl);

      console.log("response in product details------>", response);

      if (response.status) {
        const data = response.data;

        if (data.galleryimages) {
          const updatedGalleryImages = data.galleryimages.map((image) => {
            const isCover =
              image._id.toString() === data.productimage.toString()
                ? true
                : false;

            return {
              name: image.name,
              path: url.SERVER_URL + image.path,
              _id: image._id,
              isCover,
            };
          });

          const updatedGalleryImageIds = data.galleryimages.map((image) => ({
            _id: image._id,
          }));

          setGalleryImages(updatedGalleryImages);
          setUploadedImageIds(updatedGalleryImageIds);
        }
      } else {
        setAlertMessage(response.message);
        setMessageType("error");
        setShowAlert(true);
      }
    } catch (error) {
      setAlertMessage(error.message);
      setMessageType("error");
      setShowAlert(true);
    }
  };

  //function for save variant block
  const saveProductGalleryHandler = async () => {
    try {
      setIsSaving(true);

      const concatedImages = [...galleryImages, ...uploadedPreviewImages];

      let filterCoverImages = [];

      if (concatedImages.length > 0) {
        filterCoverImages = concatedImages.filter((image) => image.isCover);
      }

      let productData = {
        gallery: uploadedImageIds,
      };

      if (filterCoverImages.length > 0) {
        productData.productimage = filterCoverImages[0]._id;
      }

      let requestUrl = url.API_BASE_URL;

      let response = {};

      if (params.id) {
        requestUrl += url.API_UPDATE_PRODUCT + `/${params.id}?token=${token}`;
        response = await putData(requestUrl, productData);
      }

      setIsSaving(false);

      if (response.status) {
        setMessageType("success");
        resetHandler();
        setTimeout(() => {
          getProductDetails();
        }, 1000);
      } else {
        setMessageType("error");
      }
      setAlertMessage(response.message);
    } catch (error) {
      setAlertMessage(error.message);
      setMessageType("error");
    }
    setShowAlert(true);
  };

  const resetHandler = () => {
    setUploadedPreviewImages([]);
  };

  useEffect(() => {
    if (params.id) {
      getProductDetails();
    }
  }, [params.id]);

  const onAlertClose = () => {
    setShowAlert(false);
    setAlertMessage("");
    setMessageType("");
  };

  return (
    <div id="content_wrapper">
      <section className="marketplace-wrapper bg-white">
        {params.id && (
          <TabsHeader
            commonHeaderObject={commonHeaderObject}
            activeOption={t("Gallery")}
          />
        )}

        <div className="container-fluid px-lg-5 py-4 py-lg-5">
          <div className="p-3 p-lg-4 pb-1 pb-lg-2 border border-gray-300 rounded-10 shadow-sm">
            <div className="row gx-3">
              {galleryImages.map((image, index) => {
                return (
                  <div className="col-md-6 col-lg-3 mb-3" key={index}>
                    <div className="file border border-gray-300 rounded-10 overflow-hidden">
                      <div className="img-container ratio ratio-4x3">
                        <img
                          src={image.path}
                          alt="Uploaded Image"
                          className="img-fluid"
                        />
                      </div>
                      <div className="desc p-3 d-flex align-items-center justify-content-between gap-2">
                        <div className="form-check d-flex align-items-center gap-2 fs-md fw-semibold p-0 m-0">
                          <input
                            type="checkbox"
                            id="uploaded1"
                            className="form-check-input bg-transparent border-2 border-gray-600 shadow-none m-0"
                            checked={image.isCover}
                            onChange={(e) =>
                              checkedGalleryImageHandler(
                                e.target.checked,
                                index
                              )
                            }
                          />
                          <label
                            className="form-check-label cursor-pointer"
                            htmlFor="uploaded1"
                          >
                            Make Cover
                          </label>
                        </div>
                        <Link
                          to="#"
                          data-bs-toggle="modal"
                          data-bs-target="#deleteGalleryModal"
                          onClick={() => {
                            setDeleteImageId(image._id);
                          }}
                        >
                          <span className="d-block material-symbols-outlined">
                            delete
                          </span>
                        </Link>
                      </div>
                    </div>
                  </div>
                );
              })}

              {uploadedPreviewImages.map((image, index) => {
                return (
                  <div className="col-md-6 col-lg-3 mb-3" key={index}>
                    <div className="file border border-gray-300 rounded-10 overflow-hidden">
                      <div className="img-container ratio ratio-4x3">
                        <img
                          src={image.path}
                          alt="Uploaded Image"
                          className="img-fluid"
                        />
                      </div>
                      <div className="desc p-3 d-flex align-items-center justify-content-between gap-2">
                        <div className="form-check d-flex align-items-center gap-2 fs-md fw-semibold p-0 m-0">
                          <input
                            type="checkbox"
                            id="uploaded1"
                            className="form-check-input bg-transparent border-2 border-gray-600 shadow-none m-0"
                            checked={image.isCover}
                            onChange={(e) =>
                              checkedPreviewImageHandler(
                                e.target.checked,
                                index
                              )
                            }
                          />
                          <label
                            className="form-check-label cursor-pointer"
                            htmlFor="uploaded1"
                          >
                            Make Cover
                          </label>
                        </div>
                        <Link
                          to="#"
                          onClick={() => {
                            closeGalleryImageFileHandler(index);
                          }}
                          //   data-bs-toggle="modal"
                          //   data-bs-target="#deleteModal"
                        >
                          <span className="d-block material-symbols-outlined">
                            delete
                          </span>
                        </Link>
                      </div>
                    </div>
                  </div>
                );
              })}

              <div className="col-md-6 col-lg-3 mb-3">
                <div className="ratio ratio-4x3 h-100">
                  <label
                    htmlFor="uploadFile1"
                    className="file h-100 d-flex flex-column align-items-center justify-content-center p-3 border border-dashed rounded-8 cursor-pointer"
                  >
                    <p className="text-center">
                      <span className="d-block material-symbols-outlined">
                        upload
                      </span>
                      <span className="d-block fw-semibold">Upload</span>
                    </p>
                    <span className="fs-xs">Max 2mb jpg</span>
                    <input
                      type="file"
                      id="uploadFile1"
                      className="d-none"
                      multiple
                      onChange={(e) => {
                        imageUploadHandler(e);
                      }}
                    />
                  </label>
                </div>
              </div>
            </div>

            <div className="mt-3 d-flex justify-content-end">
              <button
                type="button"
                className="btn btn-primary"
                style={{
                  cursor: isSaving ? "not-allowed" : "pointer",
                }}
                disabled={isSaving ? true : false}
                onClick={saveProductGalleryHandler}
              >
                Save Images
                {isSaving && (
                  <div
                    className="mx-2 spinner-border spinner-border-sm"
                    role="status"
                  >
                    <span className="visually-hidden">Loading...</span>
                  </div>
                )}
              </button>
            </div>
          </div>
        </div>

        {/* === modals and popup section === */}
        <DeleteModal
          onDelete={deleteImageHandler}
          onCancelDelete={() => {
            setDeleteImageId(null);
          }}
        />

        {showAlert && (
          <AlertNotification
            showAlert={showAlert}
            message={alertMessage}
            alertType={messageType}
            onClose={onAlertClose}
          />
        )}
      </section>
    </div>
  );
};

export default ProductCategoriesBody;
