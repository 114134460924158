/* eslint-disable */
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";

/*import url and gateway methods */
import { getData, postData, putData } from "utils/Gateway";
import * as url from "helper/UrlHelper";
import { useTranslation } from "react-i18next";

const AddLabelModal = ({
  moduleName = "",
  afterTagModalClose = () => {},
  selectedIds = [],
  setShowAlert = () => {},
  setAlertMessage = () => {},
  setMessageType = () => {},
}) => {
  const token = localStorage.getItem("token");
  /* ------------- Language translation imports starts here ------------- */
  const { t } = useTranslation();

  const [tagName, setTagName] = useState("");

  const [tagList, setTagList] = useState([]);
  const [sharedTags, setsharedTags] = useState([]);

  const [tagWarning, setTagWarning] = useState(false);
  const [selectedTags, setSelectedTags] = useState([]);
  const [isUpdating, setIsUpdating] = useState(false);

  const [errorResponseMessage, setErrorResponseMessage] = useState("");

  // console.log(moduleName);

  //function for create new tag
  const createNewTagHandler = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL + url.API_CREATE_NEW_TAG + `?token=${token}`;

      let tagData = {
        title: tagName,
        slug: tagName.toUpperCase(),
        isglobal: false,
      };

      const response = await postData(requestUrl, tagData);

      // console.log(response);

      if (response.status) {
        setMessageType("success");
        setTagName("");
        getAllTags();
      } else {
        setMessageType("error");
        setErrorResponseMessage(response.message);
      }
      setAlertMessage(response.message);
    } catch (error) {
      setMessageType("error");
      setAlertMessage(error.message);
    }

    setShowAlert(true);
  };

  //function for get all tags
  const getAllTags = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL + url.API_GET_ALL_TAGS + `?token=${token}`;

      const response = await getData(requestUrl);

      console.log(response);

      if (response.status) {
        setTagList(response.data);
        setsharedTags(response.sharedtags);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  // function for set tags
  const tagselectedHandler = (data) => {
    let prevTags = selectedTags;

    if (prevTags.includes(data.value)) {
      let selectedTagItem = document.getElementById("tagdata-" + data.value);
      selectedTagItem.classList.remove("active");
      // exist in array, thus remove
      let key = prevTags.indexOf(data.value);
      prevTags.splice(key, 1);
    } else {
      let selectedTagItem = document.getElementById("tagdata-" + data.value);
      selectedTagItem.classList.add("active");
      // not exist in array, thus add
      prevTags.push(data.value);
    }

    setSelectedTags(prevTags);
  };

  //function for save tag to user
  const addRemoveTagHandler = async (action) => {
    if (selectedIds.length > 0) {
      try {
        let requestUrl = url.API_BASE_URL;

        // select request url based on which module this label component is working on
        if (moduleName === "crmleadlist") {
          requestUrl = requestUrl + url.API_ADD_REMOVE_TAG_LEAD;
        } else if (moduleName === "blog") {
          requestUrl = requestUrl + url.API_ADD_REMOVE_TAG_BLOG;
        } else if (moduleName === "event") {
          requestUrl = requestUrl + url.API_ADD_REMOVE_TAG_EVENT;
        } else if (moduleName === "eventmemeber") {
          requestUrl = requestUrl + url.API_ADD_REMOVE_TAG_EVENT_MEMBER;
        } else if (moduleName === "successstory") {
          requestUrl = requestUrl + url.API_ADD_REMOVE_TAG_SUCCESS_STORY;
        } else if (moduleName === "appuser") {
          requestUrl = requestUrl + url.API_ADD_REMOVE_TAG_APP_USER;
        } else if (moduleName === "crmcontact") {
          requestUrl = requestUrl + url.API_ADD_REMOVE_LABEL_CONTACT;
        } else if (moduleName === "crmtemplate") {
          requestUrl = requestUrl + url.API_ADD_TAG_TO_TEMPLATE;
        } else if (moduleName === "companylist") {
          requestUrl = requestUrl + url.API_ADD_REMOVE_TAG_COMPANY;
        } else if (moduleName === "companymember") {
          requestUrl = requestUrl + url.API_ADD_REMOVE_TAG_COMPANY_MEMBER;
        } else if (moduleName == "group") {
          requestUrl = requestUrl + url.API_ADD_REMOVE_TAG_GROUP;
        } else if (moduleName == "survey") {
          requestUrl = requestUrl + url.API_ADD_REMOVE_TAG_SURVEY;
        } else if (moduleName == "project") {
          requestUrl = requestUrl + url.API_ADD_REMOVE_TAG_PROJECT;
        } else if (moduleName == "projectmemeber") {
          requestUrl = requestUrl + url.API_UPDATE_TAG_PROJECT_MEMBER;
        } else if (moduleName == "tasklog") {
          requestUrl = requestUrl + url.API_ADD_TAG_TASK_LOG;
        } else if (moduleName == "conversation") {
          requestUrl = requestUrl + url.API_ADD_TAG_MAIL;
        } else if (moduleName == "list") {
          requestUrl = requestUrl + url.API_ADD_REMOVE_TAG_LIST;
        } else if (moduleName == "task") {
          requestUrl = requestUrl + url.API_ADD_TAG_TASK;
        } else if (moduleName == "challenge") {
          requestUrl = requestUrl + url.API_ADD_LABEL_CHALLENGE;
        } else if (moduleName == "eventagenda") {
          requestUrl = requestUrl + url.API_ADD_REMOVE_TAG_AGENDA;
        } else if (moduleName == "challengemember") {
          requestUrl = requestUrl + url.API_ADD_REMOVE_TAG_JOB_MEMBER;
        } else if (moduleName == "challengeresponse") {
          requestUrl = requestUrl + url.API_ADD_REMOVE_TAG_CHALLENGE_RESPONSE;
        } else if (moduleName == "surveyinvite") {
          requestUrl = requestUrl + url.API_ADD_REMOVE_TAG_SURVEY_INVITE;
        } else if (moduleName == "invoice") {
          requestUrl = requestUrl + url.API_ADD_REMOVE_TAG_INVOICE;
        } else if (moduleName == "course") {
          requestUrl = requestUrl + url.API_ADD_REMOVE_TAG_COURSE;
        } else if (moduleName == "invoicedoc") {
          requestUrl = requestUrl + url.API_ADD_REMOVE_TAG_INVOICE_DOC;
        } else if (moduleName == "crmnote") {
          requestUrl = requestUrl + url.API_ADD_REMOVE_TAG_NOTE;
        } else if (moduleName == "directaccess") {
          requestUrl = requestUrl + url.API_ADD_REMOVE_TAG_DIRECT_ACCESS;
        } else if (moduleName == "product") {
          requestUrl = requestUrl + url.API_ADD_REMOVE_TAG_PRODUCT;
        } else if (moduleName == "inventory") {
          requestUrl = requestUrl + url.API_ADD_REMOVE_TAG_INVENTORY;
        } else if (moduleName == "faq") {
          requestUrl = requestUrl + url.API_ADD_REMOVE_TAG_FAQ;
        } else {
          alert("error in module selection. please refresh once");
        }

        setIsUpdating(true);

        // run a loop for all selected components or checkboxId
        for (let selectedId of selectedIds) {
          let tagUpdateUrl =
            requestUrl +
            `/${selectedId}` +
            `?token=${token}` +
            `&action=${action}`;

          console.log("tagUpdateUrl --------->", tagUpdateUrl);

          console.log("selectedTags --------->", selectedTags);

          const response = await putData(tagUpdateUrl, selectedTags);

          console.log("response in add remove tag-------->", response);

          if (response.status) {
            setMessageType("success");
          } else {
            setMessageType("error");
          }

          setAlertMessage(response.message);
          setShowAlert(true);
        }

        setIsUpdating(false);
        closeModalhandler();

        // close the modal of addLabel
        let tagModal = document.querySelector("#addLabelModal");
        let modal = bootstrap.Modal.getInstance(tagModal);
        modal.hide();
        afterTagModalClose();
      } catch (error) {
        setShowAlert(true);
        setAlertMessage(error.message);
        setMessageType("error");
      }
    } else {
      setShowAlert(true);
      setAlertMessage(`Please select data for label execution`);
      setMessageType("error");
    }
  };

  //function for remove active class for selected tags
  const removeActiveClass = () => {
    const liElements = document.querySelectorAll(".tags li a");
    liElements.forEach((li) => {
      li.classList.remove("active");
    });
  };

  // remove active class after close modal
  const resetSelectTags = () => {
    let resetBlock = document.getElementsByClassName("tagblock");
    for (let i = 0; i < resetBlock.length; i++) {
      resetBlock[i].classList.remove("active");
    }
  };

  //function for close modal
  const closeModalhandler = () => {
    setTagName("");
    setSelectedTags([]);
    removeActiveClass();
    resetSelectTags();
    setTagWarning(false);
    setErrorResponseMessage("");
    // afterTagModalClose();
  };

  useEffect(() => {
    getAllTags();
  }, []);

  return (
    <div
      className="modal fade"
      id="addLabelModal"
      tabIndex="-1"
      aria-labelledby="addLabelModalLabel"
      aria-hidden="true"
      data-bs-backdrop="static"
    >
      <div className="modal-dialog modal-lg modal-dialog-centered">
        <div className="modal-content bg-white border-0 rounded-15">
          <div className="modal-header p-4 pb-0 border-0">
            <h2 className="fw-bold mb-0" id="addLabelModalLabel">
              {t("Tags")}
            </h2>
            <button
              type="button"
              className="btn-close p-0 bg-gray-200 rounded-circle shadow-none m-0"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={closeModalhandler}
            ></button>
          </div>
          <div className="modal-body p-4">
            <form onSubmit={(e) => e.preventDefault()}>
              {/* ----- create new tag section start ------ */}
              <div
                className={`form-group field-with-icon icon-right position-relative ${
                  errorResponseMessage === "" ? "mb-4" : ""
                }`}
              >
                <input
                  type="text"
                  className="form-control shadow-none"
                  placeholder={t("Enter to create new tag")}
                  value={tagName}
                  onChange={(e) => {
                    setErrorResponseMessage("");
                    setTagName(e.target.value);
                  }}
                />
                <button
                  onClick={createNewTagHandler}
                  type="button"
                  className="icon text-white p-0 bg-primary border-0 rounded-circle position-absolute top-50 end-0 translate-middle-y me-3"
                >
                  <span className="d-block material-symbols-outlined icon-sm">
                    add
                  </span>
                </button>
              </div>

              {errorResponseMessage === "" ? null : (
                <div className="error-message my-2">
                  <p className="d-flex align-items-center gap-1 text-danger">
                    <span className="material-symbols-outlined">warning</span>
                    <span>{errorResponseMessage}!</span>
                  </p>
                </div>
              )}
              {/* ----- create new tag section end ------ */}

              {tagList.length === 0 ? (
                <h5 className="fw-semibold d-flex text-gray">
                  Add tag above and click
                  <span className="d-block material-symbols-outlined icon-sm text-danger ms-1 me-1 mt-1 fw-bold">
                    add
                  </span>
                  to save and use
                </h5>
              ) : (
                <h5 className="fw-semibold">My Tags</h5>
              )}

              <ul
                className="tags fs-sm d-flex flex-wrap gap-1 mysavedtags"
                style={{ maxHeight: "200px", overflowY: "auto" }}
              >
                {tagList.map((tag, index) => {
                  if (tagName.length > 0) {
                    return (
                      tag.label
                        .toLowerCase()
                        .includes(tagName.toLowerCase()) && (
                        <li className="tag" key={index}>
                          <Link
                            to="#"
                            onClick={() => tagselectedHandler(tag)}
                            id={"tagdata-" + tag.value}
                            className="tagblock d-block px-3 py-2 gradient-light cursor-pointer rounded-90"
                          >
                            <span>{tag.label}</span>
                          </Link>
                        </li>
                      )
                    );
                  } else {
                    return (
                      <li className="tag" key={index}>
                        <Link
                          to="#"
                          onClick={() => tagselectedHandler(tag)}
                          id={"tagdata-" + tag.value}
                          className="tagblock d-block px-3 py-2 gradient-light cursor-pointer rounded-90"
                        >
                          <span>{tag.label}</span>
                        </Link>
                      </li>
                    );
                  }
                })}
              </ul>
              {/* ----- tag list section end ------ */}

              {/* <!-- ========== Start sharedTags Section ========== --> */}
              {sharedTags.length > 0 ? (
                <h5 className="mt-4 fw-semibold">Process Shared With Me </h5>
              ) : null}
              {sharedTags.map((tag, index) => {
                return (
                  <div key={index} className="mt-3">
                    <p className="mt-1 fw-semibold text-primary">
                      {tag.processtitle}
                      {" : "}
                      <span className="text-secondary">{tag.owner}</span>
                    </p>
                    <ul className="tags fs-sm d-flex flex-wrap gap-1 mt-2 sharedtags">
                      {tag.tags.map((tag, index) => {
                        return (
                          <li className="tag" key={index}>
                            <Link
                              to="#"
                              onClick={() => tagselectedHandler(tag)}
                              id={"tagdata-" + tag.value}
                              className="tagblock d-block px-3 py-2 gradient-light cursor-pointer rounded-90"
                            >
                              <span>{tag.label}</span>
                            </Link>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                );
              })}
              {/* <!-- ========== End sharedTags Section ========== --> */}
            </form>
          </div>
          <div className="modal-footer p-4 border-0 ">
            <div className="d-flex w-100 gap-2 align-items-center">
              <button
                type="button"
                className="btn btn-primary w-100"
                onClick={() => {
                  addRemoveTagHandler("1");
                }}
              >
                {t("Add Label")}
              </button>
              <button
                type="button"
                className="btn btn-primary w-100"
                onClick={() => {
                  addRemoveTagHandler("0");
                }}
              >
                {t("Remove Label")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddLabelModal;
