/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import * as url from "helper/UrlHelper";
import { getData } from "utils/Gateway";

//import translation function for language translation
import { useTranslation } from "react-i18next";

import NoteSection from "components/Common/NoteComponent/NoteSection";

const SaveOrderPopup = ({
  selectedInvoiceId,
  setSelectedInvoiceId,
  moudleSlug = "",
  reloadList,
  setShowAlert,
  setAlertMessage,
  setMessageType,
}) => {
  const token = localStorage.getItem("token");
  const { t, i18n } = useTranslation();

  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const moduleAccess = localStorage.getItem("moduleaccess");

  const [shippingAddressData, setShippingAddressData] = useState({});
  const [orderNo, setorderNo] = useState("");

  const [orderDate, setorderDate] = useState("2025-02-26"); //editable

  const [paymentStatus, setpaymentStatus] = useState("Pending");
  const [paymentDate, setpaymentDate] = useState("05-03-2025");

  const [totalTax, setTotalTax] = useState(0);
  const [totalDiscount, setTotalDiscount] = useState(0);
  const [payableAmount, setPayableAmount] = useState(0);

  const [invoiceItemsList, setInvoiceItemsList] = useState([]);

  //function for get invoice details
  const getInvoiceDetails = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_INVOICE_DETAILS +
        `/${selectedInvoiceId}` +
        "?token=" +
        token;

      const response = await getData(requestUrl);

      console.log(response);

      if (response.status) {
        setShippingAddressData(response.data.address);

        setorderNo(response.data.orderid);

        setorderDate(response.data.createddate);

        setpaymentStatus(response.data.paymentstatus);

        setpaymentDate(response.data.paymentdate);

        setInvoiceItemsList(response.data.invoiceitems);

        setTotalDiscount(response.data.totaldiscount);

        setTotalTax(response.data.totaltax);

        setPayableAmount(response.data.totalprice);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const resetHandler = () => {
    setSelectedInvoiceId(null);
  };

  useEffect(() => {
    if (selectedInvoiceId) {
      getInvoiceDetails();
    }
  }, [selectedInvoiceId]);

  return (
    <div
      className="offcanvas invoice_w offcanvas-end bg-white border-0"
      tabIndex="-1"
      id="viewPurchaseOrder"
      aria-labelledby="viewPurchaseOrder"
    >
      <div className="offcanvas-header p-4 pb-0">
        <h3 className="offcanvas-title" id="offcanvasLabelDetailsLabel">
          Purchase Order - {orderNo}
        </h3>
        <button
          type="button"
          className="btn-close p-0 bg-gray-200 rounded-circle shadow-none m-0"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          onClick={resetHandler}
        ></button>
      </div>
      <div className="offcanvas-body p-4">
        <div className="row">
          <div className="col-lg-8">
            <div className="p-2 p-md-2 border border-gray-300 rounded-10 shadow-sm">
              <form
                onSubmit={(e) => e.preventDefault()}
                className="d-flex flex-column h-100"
              >
                <div className="fields-container flex-fill">
                  {/* Invoice sender and basic info */}
                  <div className="invoice mt-4">
                    <div className="d-flex align-items-center justify-content-between gap-3 mb-1">
                      <h5 className="fw-semibold mb-2 mb-md-3">
                        {t("Shipping Address")}
                      </h5>

                      <div className="d-flex align-items-center gap-3">
                        <Link to="#" className="text-black">
                          <span className="d-block material-symbols-outlined">
                            print
                          </span>
                        </Link>
                        <Link to="#" className="text-black">
                          <span className="d-block material-symbols-outlined">
                            email
                          </span>
                        </Link>
                      </div>
                    </div>
                    <div className="d-flex flex-column flex-sm-row">
                      <p className="fs-md flex-fill mb-3">
                        <span className="d-block text-uppercase">
                          {shippingAddressData.name}
                        </span>
                        <span className="d-block text-black">
                          {shippingAddressData.address}
                        </span>
                        <span className="d-block text-black">
                          {shippingAddressData.city} {shippingAddressData.state}
                          , {shippingAddressData.country}
                        </span>
                        <span className="d-block text-black">
                          {shippingAddressData.phone}
                        </span>
                      </p>

                      <ul className="flex-fill h3 d-flex flex-column gap-3 fw-semibold">
                        <li className="d-flex align-items-center gap-3">
                          <span className="d-block">
                            {t("Invoice Number")}:{" "}
                          </span>
                          <span className="d-block text-primary">
                            {orderNo}
                          </span>
                        </li>

                        <li className="d-flex align-items-center gap-3">
                          <span className="d-block">{t("Store")}: </span>
                          <span className="d-block text-primary">
                            Saludti Marketplace
                          </span>
                        </li>

                        <li className="d-flex align-items-center gap-3">
                          <span className="d-block">
                            {t("Amount Payable")}:{" "}
                          </span>
                          <span className="d-block text-primary">
                            {payableAmount} €
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>

                  {/* Invoice details */}
                  <div className="p-3 border border-gray-300 rounded-8 mb-3">
                    <div className="row">
                      {/* Client or customer details */}
                      <div className="col-md mb-4 mb-md-0">
                        <h5 className="fw-semibold mb-2 mb-md-3">
                          {t("Seller Information")}
                        </h5>

                        <p className="fs-md flex-fill mb-3">
                          <span className="d-block">
                            MpForAll Business and Collaboration
                          </span>
                          <span className="d-block">
                            Padre Piquer, 14, 28024, Madrid, Espafia
                          </span>
                          <span className="d-block text-black">
                            support@lobees.com
                          </span>
                          <span className="d-block text-black">
                            (+34) 615 43 94 22
                          </span>
                          <span className="d-block text-black">
                            www.lobees.com
                          </span>
                        </p>
                      </div>

                      {/* Order or Invoice info */}
                      <div className="col-md mb-4 mb-md-0">
                        <h5 className="fw-semibold mb-2 mb-md-3">
                          {t("Order Information")}
                        </h5>
                        <div className="form-group d-flex align-items-center mb-2">
                          <label
                            htmlFor=""
                            className="d-block fs-sm fw-semibold mb-2"
                            style={{ minWidth: "6.25rem" }}
                          >
                            {t("Order No")}:
                          </label>

                          <p className="form-control px-3 py-2 shadow-none">
                            {orderNo}
                          </p>
                        </div>
                        <div className="form-group d-flex align-items-center mb-2">
                          <label
                            for=""
                            className="d-block fs-sm fw-semibold mb-2"
                            style={{ minWidth: "6.25rem" }}
                          >
                            {t("Order Date")}:
                          </label>
                          <p className={`form-control px-3 py-2 shadow-none`}>
                            {orderDate}
                          </p>
                        </div>
                      </div>

                      {/* Payment info */}
                      <div className="col-md">
                        <h5 className="fw-semibold mb-2 mb-md-3">
                          {t("Payment Information")}
                        </h5>
                        <div className="form-group d-flex align-items-center mb-2">
                          <label
                            for=""
                            className="d-block fs-sm fw-semibold"
                            style={{ minWidth: "6.25rem" }}
                          >
                            {t("Status")}:
                          </label>
                          <span className="d-block">{paymentStatus}</span>
                        </div>
                        <div className="form-group d-flex align-items-center my-4">
                          <label
                            for=""
                            className="d-block fs-sm fw-semibold"
                            style={{ minWidth: "6.25rem" }}
                          >
                            {t("Amount")}:
                          </label>
                          <span className="d-block">{payableAmount}</span>
                        </div>
                        <div className="form-group d-flex align-items-center mt-2">
                          <label
                            for=""
                            className="d-block fs-sm fw-semibold"
                            style={{ minWidth: "6.25rem" }}
                          >
                            {t("Payment Date")}:
                          </label>
                          <span className="d-block">{paymentDate}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Invoice Items */}
                  <div className="table-wrapper pb-5 mb-5 border-bottom border-gray">
                    {/* <!-- ========== Start invoice item table ========== --> */}
                    <div className="table-responsive">
                      <table className="table align-middle fs-sm text-nowrap border-0 mb-0">
                        <thead>
                          <tr>
                            <th
                              width="40%"
                              className="description bg-transparent fs-md fw-semibold border-0"
                            >
                              Description
                            </th>
                            <th className="quantity bg-transparent fs-md fw-semibold border-0">
                              Quantity
                            </th>
                            <th className="amt bg-transparent fs-md fw-semibold border-0">
                              Price
                            </th>
                            <th className="taxes bg-transparent fs-md fw-semibold border-0">
                              Tax (VAT %)
                            </th>
                            <th className="pay-amt bg-transparent fs-md fw-semibold border-0">
                              Total Amount
                            </th>
                          </tr>
                        </thead>
                        <tbody style={{ height: "200px" }}>
                          {invoiceItemsList.map((invoiceItem, index) => {
                            return (
                              <tr key={index} style={{ verticalAlign: "top" }}>
                                <td className="description border-bottom-0 p-1">
                                  <p
                                    className={`form-control bg-white shadow-none `}
                                  >
                                    {invoiceItem.description}
                                  </p>
                                </td>
                                <td className="quantity border-bottom-0 p-1">
                                  <p
                                    className={`form-control bg-white shadow-none `}
                                  >
                                    {invoiceItem.unit}
                                  </p>
                                </td>
                                <td className="amt border-bottom-0 p-1">
                                  <p
                                    className={`form-control bg-white shadow-none `}
                                  >
                                    {invoiceItem.price}
                                  </p>
                                </td>
                                <td className="taxes border-bottom-0 p-1">
                                  <p
                                    className={`form-control bg-white shadow-none `}
                                  >
                                    {invoiceItem.taxpercent}
                                  </p>
                                </td>
                                <td className="pay-amt border-bottom-0 p-1">
                                  <p
                                    className={`form-control bg-white shadow-none `}
                                  >
                                    {invoiceItem.totalprice}
                                  </p>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                    {/* <!-- ========== End invoice item table ========== --> */}
                  </div>

                  {/* Invoice final amount */}
                  <div className="table-wrapper pb-3 mb-3 border-bottom border-gray">
                    <div className="table-responsive">
                      <table className="table align-middle fs-sm text-nowrap border-0 mb-0">
                        <tbody>
                          <tr>
                            <td className="p-0 fw-semibold bg-transparent border-0">
                              Total Discount
                            </td>
                            <td className="p-0 bg-transparent text-end border-0">
                              {totalDiscount}€
                            </td>
                          </tr>
                          <tr>
                            <td className="p-0 fw-semibold bg-transparent border-0">
                              Total Tax (VAT)
                            </td>
                            <td className="p-0 bg-transparent text-end border-0">
                              {totalTax}€
                            </td>
                          </tr>
                          <tr>
                            <td className="p-0 fs-lg fw-semibold bg-transparent border-0">
                              Total Payable Amount
                            </td>
                            <td className="p-0 fs-lg fw-semibold text-success bg-transparent text-end border-0">
                              {payableAmount}€
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>

          <div className="col-lg-4">
            {" "}
            <NoteSection
              noteAccessUserList={[]}
              invoiceId={selectedInvoiceId}
              moduleName="Order"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SaveOrderPopup;
