import * as url from "helper/UrlHelper";
import { getData } from "utils/Gateway";

const token = localStorage.getItem("token");
const userInfo = JSON.parse(localStorage.getItem("userInfo"));

export const appUserHeaderLinks = (id, t) => {
  return [
    { title: t("Profile info"), link: `/admin/appuser/profile/${id}` },
    { title: t("CV"), link: `/admin/appuser/cv/${id}` },
    { title: t("Privacy"), link: `/admin/appuser/privacy/${id}` },
    { title: t("Password"), link: `/admin/appuser/password/${id}` },
    { title: t("Availability"), link: `/admin/appuser/availability/${id}` },
    { title: t("Settings"), link: `/admin/appuser/settings/${id}` },
    { title: t("Survey Invites"), link: `/admin/appuser/surveyinvites/${id}` },
  ];
};

//create chat
export const createContactChatHandler = async (
  userId = null,
  componentSlug = "",
  componentId = null,
  componentTitle = ""
) => {
  // 1. Check if "userId" ≠ "userInfo._id" (No action if they match)
  if (userId && userId.toString() !== userInfo._id.toString()) {
    try {
      // 2. Cleaner API URL using template strings
      const requestURL = `${url.API_BASE_URL}${url.API_SEARCH_CONTACT}?token=${token}&member=${userId}`;
      // console.log("Request URL:", requestURL);

      const response = await getData(requestURL);
      // console.log("API Response Arrived:", response);

      if (response.status) {
        // 3. Dynamic URL path builder (no messy if-else chains!)
        const pathParts = [];
        if (componentSlug) pathParts.push(componentSlug); // Add if truthy
        if (componentId !== null) pathParts.push(componentId); // Skip only null
        if (componentTitle) pathParts.push(componentTitle);

        // 4. One-liner URL with template + ternary magic
        const externalUrl = `${url.FRONTEND_BASE_URL}/conversation/contact/${
          response.data._id
        }${pathParts.length ? `/${pathParts.join("/")}` : ""}`;

        window.open(externalUrl, "_blank", "noopener,noreferrer");
      }
    } catch (error) {
      console.error("Error occurred:", error.message); // Red alerts for errors
    }
  }
  // Empty else? No problem—less code, more clarity!
};
