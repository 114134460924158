/* eslint-disable */
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";

/*import url and gateway methods */
import { getData, postData, putData } from "utils/Gateway";
import * as url from "helper/UrlHelper";

const CategoryFilterPopup = ({
  setFilterCategoryName,
  setFilterCategorySlug,
  setfilterParents,
  setfilterFeatured,
  setFilterIsRoot,
  isReload = false,
}) => {
  const { t } = useTranslation(); //for translation

  const ecosystemSlug = localStorage.getItem("ecosystemslug");

  const [parentCategories, setParentCategories] = useState([]);

  const [name, setname] = useState("");
  const [slug, setslug] = useState("");
  const [parentValue, setparentValue] = useState(null);
  const [parentIds, setparentIds] = useState([]);
  const [isFeatured, setIsFeatured] = useState(false);
  const [isRoot, setIsRoot] = useState(false);

  //function for get all tags
  const getAllParentCategories = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_CATEGORIES +
        `?ecosystemslug=${ecosystemSlug}`;

      const response = await getData(requestUrl);

      // console.log(response);

      if (response.status) {
        setParentCategories(
          response.data.map((item) => {
            return {
              ...item,
              label:
                item.parentname === ""
                  ? item.name
                  : `${item.parentname} - ${item.name}`,
            };
          })
        );
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const parentSelectionHandler = (val) => {
    setparentValue(val);
    setparentIds(val.map((item) => item.value));
  };

  //function for assign search data for filter and close pop up
  const searchHandler = () => {
    setFilterCategoryName(name);
    setFilterCategorySlug(slug);
    setfilterParents(parentIds);
    setfilterFeatured(isFeatured);
    setFilterIsRoot(isRoot);

    // close the offcanvas of filter
    let offCanvasPopup = document.querySelector("#categoryFilter");
    let offCanvas = bootstrap.Offcanvas.getInstance(offCanvasPopup);
    offCanvas.hide();
    resetHandler();
  };

  //function for reset search
  const resetHandler = () => {
    //     removeActiveClass();
    setname("");
    setslug("");
    setparentValue(null);
    setparentIds([]);
    setIsFeatured(false);
    setIsRoot(false);
  };

  useEffect(() => {
    if (isReload) {
      getAllParentCategories();
    }
  }, [isReload]);

  useEffect(() => {
    getAllParentCategories();
  }, []);

  return (
    <div
      className="offcanvas offcanvas-end bg-white border-0"
      tabIndex="-1"
      id="categoryFilter"
      aria-labelledby="offcanvasFilterLabel"
    >
      <div className="offcanvas-header justify-content-end p-4 pb-0">
        <button
          type="button"
          className="btn-close p-0 bg-gray-200 rounded-circle shadow-none m-0"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          onClick={resetHandler}
        ></button>
      </div>
      <div className="offcanvas-body p-4">
        <form onSubmit={(e) => e.preventDefault()}>
          <div className="accordion" id="accordionPanelsStayOpenExample">
            <div className="accordion-item bg-transparent border-0">
              <h3 className="accordion-header">
                <button
                  className="accordion-button bg-transparent p-0 shadow-none"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#panelsStayOpen-collapseOne"
                  aria-expanded="true"
                  aria-controls="panelsStayOpen-collapseOne"
                >
                  {t("Name")}
                </button>
              </h3>
              <div
                id="panelsStayOpen-collapseOne"
                className="accordion-collapse collapse show"
              >
                <div className="accordion-body p-0 pt-4">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control fs-sm shadow-none"
                      placeholder={t("Enter")}
                      value={name}
                      onChange={(e) => {
                        setname(e.target.value);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="accordion-item bg-transparent border-0">
              <h3 className="accordion-header">
                <button
                  className="accordion-button bg-transparent p-0 shadow-none"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#panelsStayOpen-collapseTwo"
                  aria-expanded="true"
                  aria-controls="panelsStayOpen-collapseTwo"
                >
                  {t("Slug")}
                </button>
              </h3>
              <div
                id="panelsStayOpen-collapseTwo"
                className="accordion-collapse collapse show"
              >
                <div className="accordion-body p-0 pt-4">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control fs-sm shadow-none"
                      placeholder={t("Enter")}
                      value={slug}
                      onChange={(e) => {
                        setslug(e.target.value);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="accordion-item bg-transparent border-0">
              <h3 className="accordion-header">
                <button
                  className="accordion-button bg-transparent p-0 shadow-none"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#panelsStayOpen-collapseThree"
                  aria-expanded="true"
                  aria-controls="panelsStayOpen-collapseThree"
                >
                  {t("Parent")}
                </button>
              </h3>
              <div
                id="panelsStayOpen-collapseThree"
                className="accordion-collapse collapse show"
              >
                <div className="accordion-body p-0 pt-4">
                  <div className="form-group">
                    <Select
                      placeholder={t("Select Parent Category")}
                      isMulti
                      options={parentCategories}
                      value={parentValue}
                      onChange={(val) => parentSelectionHandler(val)}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="accordion-item bg-transparent border-0">
              <h3 className="accordion-header">
                <button
                  className="accordion-button bg-transparent p-0 shadow-none"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#panelsStayOpen-collapseFour"
                  aria-expanded="true"
                  aria-controls="panelsStayOpen-collapseFour"
                >
                  {t("Is Root")}
                </button>
              </h3>
              <div
                id="panelsStayOpen-collapseFour"
                className="accordion-collapse collapse show"
              >
                <div className="accordion-body p-0 pt-4">
                  <div className="form-check d-flex align-items-center gap-2 fs-sm p-0 m-0 mb-4">
                    <input
                      className="form-check-input bg-transparent border-2 border-gray-600 shadow-none m-0"
                      type="checkbox"
                      checked={isRoot}
                      onChange={(e) => {
                        setIsRoot(e.target.checked);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="accordion-item bg-transparent border-0">
              <h3 className="accordion-header">
                <button
                  className="accordion-button bg-transparent p-0 shadow-none"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#panelsStayOpen-collapseFive"
                  aria-expanded="true"
                  aria-controls="panelsStayOpen-collapseFive"
                >
                  {t("Is Featured")}
                </button>
              </h3>
              <div
                id="panelsStayOpen-collapseFive"
                className="accordion-collapse collapse show"
              >
                <div className="accordion-body p-0 pt-4">
                  <div className="form-check d-flex align-items-center gap-2 fs-sm p-0 m-0 mb-4">
                    <input
                      className="form-check-input bg-transparent border-2 border-gray-600 shadow-none m-0"
                      type="checkbox"
                      checked={isFeatured}
                      onChange={(e) => {
                        setIsFeatured(e.target.checked);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <button
            onClick={searchHandler}
            type="button"
            className="btn btn-primary fs-sm fw-normal w-100 mt-4"
          >
            {t("Search")}
          </button>
        </form>
      </div>
    </div>
  );
};

export default CategoryFilterPopup;
