/* eslint-disable */
import { useState, useEffect, useRef } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import CreatableSelect from "react-select/creatable";
import { useTranslation } from "react-i18next";

/*------ import url and method ------*/
import * as url from "helper/UrlHelper";
import { getData, putData } from "utils/Gateway";

import TabsHeader from "components/Common/TabsHeader/TabsHeader";
import { productDetailsHeaderLinks } from "helper/ProductHelper/ProductHelper";
import AlertNotification from "components/Common/AlertNotification/AlertNotification";

const ProductCategoriesBody = () => {
  const { t } = useTranslation(); //for translation

  const params = useParams();
  const history = useHistory();

  const commonHeaderObject = productDetailsHeaderLinks(params.id, t);

  const token = localStorage.getItem("token");

  const variantTemplate = {
    name: "",
    optionValue: null,
    options: [],
  };

  const [variantBlock, setVariantBlock] = useState([]);
  const [deletedVariantBlock, setDeletedVariantBlock] = useState([]);

  const [isSaving, setIsSaving] = useState(false);

  //alert requirements
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [messageType, setMessageType] = useState("");

  const addTemplateBlock = () => {
    setVariantBlock([...variantBlock, variantTemplate]);
  };

  //** Function for delete variant block */
  const onDeleteBlock = (index, block) => {
    const filteredBlock = [...variantBlock];
    filteredBlock.splice(index, 1);
    setVariantBlock(filteredBlock);

    if (block._id) {
      setDeletedVariantBlock((prev) => [...prev, block._id]);
    }
  };

  //** Function for other input field */
  const changeInputs = (index, field, value) => {
    const updatedFormValues = variantBlock.map((block, i) =>
      index == i ? Object.assign(block, { [field]: value }) : block
    );
    setVariantBlock(updatedFormValues);
  };

  //** Function for change select */
  const changeInputSelect = (index, field, field2, value) => {
    const updatedFormValues = variantBlock.map((block, i) =>
      index == i
        ? Object.assign(block, {
            [field]: value,
            [field2]: value.map((value) => value.value),
          })
        : block
    );
    setVariantBlock(updatedFormValues);
  };

  //function for get product details
  const getProductDetails = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_PRODUCT_DETAILS +
        `/${params.id}?token=${token}`;

      const response = await getData(requestUrl);

      // console.log(response);

      if (response.status) {
        const data = response.data;

        if (data.productvarations) {
          const updatedProductVariations = data.productvarations.map(
            (productVariation) => {
              const optionValue = productVariation.options.map((option) => ({
                label: option,
                value: option,
              }));
              return {
                _id: productVariation._id,
                name: productVariation.name,
                options: productVariation.options,
                optionValue,
              };
            }
          );

          if (updatedProductVariations.length > 0) {
            setVariantBlock(updatedProductVariations);
          }
        }
      } else {
        setAlertMessage(response.message);
        setMessageType("error");
        setShowAlert(true);
      }
    } catch (error) {
      setAlertMessage(error.message);
      setMessageType("error");
      setShowAlert(true);
    }
  };

  //function for save variant block
  const saveProductVariantHandler = async () => {
    try {
      setIsSaving(true);

      let productData = {
        variations: variantBlock.map((block) => ({
          _id: block._id ? block._id : null,
          name: block.name,
          options: block.options,
        })),
        deletedvariations: deletedVariantBlock,
      };

      console.log(productData);

      let requestUrl = url.API_BASE_URL;

      let response = {};

      if (params.id) {
        requestUrl += url.API_UPDATE_PRODUCT + `/${params.id}?token=${token}`;

        response = await putData(requestUrl, productData);
      }

      setIsSaving(false);

      if (response.status) {
        setDeletedVariantBlock([]);
        setMessageType("success");
        setTimeout(() => {
          getProductDetails();
        }, 1000);
      } else {
        setMessageType("error");
      }
      setAlertMessage(response.message);
    } catch (error) {
      setAlertMessage(error.message);
      setMessageType("error");
    }
    setShowAlert(true);
  };

  useEffect(() => {
    if (params.id) {
      getProductDetails();
    }
  }, [params.id]);

  useEffect(() => {
    if (variantBlock.length === 0) {
      addTemplateBlock();
    }
  }, []);

  const onAlertClose = () => {
    setShowAlert(false);
    setAlertMessage("");
    setMessageType("");
  };

  return (
    <div id="content_wrapper">
      <section className="marketplace-wrapper bg-white">
        {/* ---- event details common header ---- */}
        {params.id && (
          <TabsHeader
            commonHeaderObject={commonHeaderObject}
            activeOption={t("Variants")}
          />
        )}

        <div className="container-fluid px-lg-5 py-4 py-lg-5">
          <div className="p-3 p-lg-4 border border-gray-300 rounded-10 shadow-sm">
            <form onSubmit={(e) => e.preventDefault()}>
              {variantBlock.map((block, index) => {
                return (
                  <div className="form-group mb-3 mb-sm-4" key={index}>
                    <div className="row">
                      <div className="col-lg-3 mb-3 mb-sm-4 mb-lg-0">
                        <label
                          htmlFor="attributeName1"
                          className="d-block fs-sm fw-semibold mb-2"
                        >
                          Attribute Name
                        </label>
                        <div className="field-container position-relative">
                          <input
                            type="text"
                            id="attributeName1"
                            className="form-control pe-5 fs-sm shadow-none"
                            placeholder="Name"
                            value={block.name}
                            onChange={(e) => {
                              changeInputs(index, "name", e.target.value);
                            }}
                          />
                          <Link
                            to="#"
                            className="text-gray position-absolute top-50 end-0 translate-middle-y me-3"
                            onClick={() => onDeleteBlock(index, block)}
                          >
                            <span className="d-block material-symbols-outlined icon-lg">
                              delete
                            </span>
                          </Link>
                        </div>
                      </div>
                      <div className="col-lg-9">
                        <label
                          htmlFor="variations1"
                          className="d-block fs-sm fw-semibold mb-2"
                        >
                          Variations
                        </label>
                        <CreatableSelect
                          placeholder="Type Variant"
                          isMulti
                          value={block.optionValue}
                          onChange={(value) => {
                            changeInputSelect(
                              index,
                              "optionValue",
                              "options",
                              value
                            );
                          }}
                        />
                      </div>
                    </div>
                  </div>
                );
              })}

              <div className="action d-flex flex-wrap gap-3 align-items-center justify-content-between">
                <Link
                  to="#"
                  className="btn btn-outline-primary"
                  onClick={addTemplateBlock}
                >
                  Add Another
                </Link>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={saveProductVariantHandler}
                  style={{
                    cursor: isSaving ? "not-allowed" : "pointer",
                  }}
                  disabled={isSaving ? true : false}
                >
                  Save
                  {isSaving && (
                    <div
                      className="mx-2 spinner-border spinner-border-sm"
                      role="status"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>

        {showAlert && (
          <AlertNotification
            showAlert={showAlert}
            message={alertMessage}
            alertType={messageType}
            onClose={onAlertClose}
          />
        )}
      </section>
    </div>
  );
};

export default ProductCategoriesBody;
