/* eslint-disable */
import Header from "components/Common/Header/Header";
import InvoiceListBody from "components/MarketplaceComponents/InvoicesComponents/InvoiceListBody/InvoiceListBody";
import { useEffect } from "react";

const InvoiceList = () => {
  useEffect(() => {
    document.title = "Invoice List";
  }, []);

  return (
    <main id="app">
      {/* ---- common header ---- */}
      <Header moduleName="marketplace" />

      <InvoiceListBody />
    </main>
  );
};

export default InvoiceList;
