/* eslint-disable */
import Header from "components/Common/Header/Header";
import ProductListBody from "components/MarketplaceComponents/ProductComponents/ProductList/ProductListBody/ProductListBody";
import { useEffect } from "react";

const ProductList = () => {
  useEffect(() => {
    document.title = "Marketplace";
  }, []);

  return (
    <main id="app">
      {/* ---- common header ---- */}
      <Header moduleName="marketplace" />

      <ProductListBody />
    </main>
  );
};

export default ProductList;
