/* eslint-disable */
import React, { useState, useEffect, useMemo } from "react";
import { Link, useParams, useHistory } from "react-router-dom";

// Material UI table
import { MaterialReactTable } from "material-react-table";

import BreadCrumb from "components/Common/BreadCrumb/BreadCrumb";

import {
  getData,
  uploadSingleFile,
  uploadMultipleFile,
  postData,
  putData,
} from "utils/Gateway";
import * as url from "helper/UrlHelper";

//import images
import { assetImages } from "constants";
import { ecosystemSlug } from "Config/Config";
import { appUserHeaderLinks } from "helper/AppUserHelper/AppUserHelper";

import TabsHeader from "components/Common/TabsHeader/TabsHeader";

import SurveyResponseFilterPopup from "../Popup/SurveyResponseFilterPopup";
import ViewResponsePopup from "../Popup/ViewResponsePopup";
import AddLabelModal from "components/Common/Modal/AddLabelModal";
import AppUserSurveyHeader from "./Header/AppUserSurveyHeader";

import { useTranslation } from "react-i18next";
import { getPublicIP } from "helper/AuthHelper/AuthHelper";

const AppUserSurveyInviteBody = () => {
  const params = useParams();
  const history = useHistory();

  const { t, i18n } = useTranslation(); //for translation

  const moduleAccess = localStorage.getItem("moduleaccess");

  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const commonHeaderObject = appUserHeaderLinks(params.id, t);

  const [name, setName] = useState("");
  const [surName, setSurName] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [inviteList, setinviteList] = useState([]);

  const [rowSelection, setRowSelection] = useState({});
  const [selectedInviteIds, setSelectedInviteIds] = useState([]);

  const [viewInviteId, setViewInviteId] = useState(null);

  /* ---- bread crumb text ---- */
  const breadcrumbText = [
    { title: t("App User"), link: "/admin/appuser/list" },
    { title: t("Survey Invites") },
  ];

  //* function for get user details
  const getAppUserDetails = async () => {
    const userPublicIp = await getPublicIP();

    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_USER_DETAILS +
        `/${params.id}` +
        `?token=${token}&userip=${userPublicIp}`;

      const response = await getData(requestUrl);

      console.log(response);

      if (response.status && response.data) {
        const data = response.data;
        setName(data.name || "");
        setSurName(data.surname || "");
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //* function for get all invites
  const getAllInvites = async () => {
    setRowSelection({});
    setSelectedInviteIds([]);

    try {
      setIsLoading(true);
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_SURVEY_INVITES +
        `?token=${token}&filteruserid=${params.id}`;

      const response = await getData(requestUrl);

      console.log(response);
      setIsLoading(false);
      if (response.status) {
        setinviteList(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    if (params.id) {
      getAppUserDetails();
      getAllInvites();
    }
  }, [params.id]);

  /*
   * Material React Table Column and States -----------------------------------------------------------
   *---------------------------------------------------------------------------------------------------
   */
  const columns = useMemo(
    () => [
      {
        accessorKey: "surveyname",
        header: t("Survey"),
      },
      {
        accessorKey: "totalquestioncount",
        header: t("Survey Question Count"),
      },
      {
        accessorKey: "questionsubmitted",
        header: t("Question Attended"),
      },
      {
        accessorKey: "invitedate",
        header: t("Date"),
      },
      {
        accessorKey: "tags",
        header: t("Tags"),
        Cell: ({ row }) => (
          <div className="label border-bottom-0">
            {row.original.tagstring != "" && (
              <ul className="d-flex flex-wrap gap-2 fs-xs">
                {row.original.tagstring.split(" , ").map((tag, index) => {
                  return (
                    <li
                      key={index}
                      className="px-2 py-1 gradient-light rounded-5"
                    >
                      {tag}
                    </li>
                  );
                })}
              </ul>
            )}
          </div>
        ),
      },
      {
        accessorKey: "#",
        header: t("Action"),
        Cell: ({ row }) => (
          <div className="action border-bottom-0">
            <Link
              to="#"
              className="d-flex align-items-center gap-1 fs-md fw-semibold text-primary"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasViewResponse"
              aria-controls="offcanvasViewResponse"
              onClick={() => {
                setViewInviteId(row.original._id);
              }}
            >
              <span className="d-block">View</span>
              <span className="d-block material-symbols-outlined">
                chevron_right
              </span>
            </Link>
          </div>
        ),
      },
    ],
    [i18n.language]
  );

  useEffect(() => {
    const selectedIdsArray = Object.keys(rowSelection).filter(
      (key) => rowSelection[key]
    );

    if (selectedIdsArray.length > 0) {
      setSelectedInviteIds(selectedIdsArray);
    } else {
      setSelectedInviteIds([]);
    }
  }, [rowSelection]);

  if (
    userInfo.role.slug === "ADMIN" ||
    userInfo.role.slug === "SUPER_ADMIN" ||
    moduleAccess.includes("MOD_SURVEY")
  ) {
    return (
      <div id="content_wrapper">
        <section className="survey-wrapper bg-white pt-2 pt-lg-0 pb-5">
          {/* {params.id && <ExpertDetailsCommonHeader moduleName="cv" />} */}
          {params.id && (
            <TabsHeader
              commonHeaderObject={commonHeaderObject}
              activeOption={t("Survey Invites")}
            />
          )}

          {/* ---- body section start ---- */}
          <div className="container-fluid px-lg-5 pt-4 pt-md-0">
            <AppUserSurveyHeader
              reloadList={getAllInvites}
              breadCrumbText={breadcrumbText}
              displayName={`${name} ${surName}`}
            />

            {isLoading ? (
              <div className="placeholder-glow d-flex flex-column gap-4">
                <span className="placeholder placeholder-lg bg-secondary col-12"></span>
                <span className="placeholder placeholder-lg bg-secondary col-8"></span>
                <span className="placeholder placeholder-lg bg-secondary col-4"></span>
              </div>
            ) : (
              <div className="table-wrapper">
                <MaterialReactTable
                  columns={columns} // map columns to be displayed with api data,
                  data={inviteList} // data from api to be displayed
                  enableGrouping
                  enableRowSelection // enable showing checkbox
                  getRowId={(row) => row._id} // map which value to select with row checkbox
                  onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
                  state={{ rowSelection }} //pass our managed row selection state to the table to use
                  muiTableContainerProps={{
                    sx: {
                      maxHeight: "60vh",
                    },
                  }}
                  enableStickyHeader
                />
              </div>
            )}
          </div>
          {/* ---- body section end ---- */}
        </section>

        {/* === modals and popup area === */}
        {/* ---- add label popup --- */}
        <AddLabelModal
          moduleName="surveyinvite"
          selectedIds={selectedInviteIds}
          afterTagModalClose={getAllInvites}
        />

        {/* --- Filter popup --- */}
        <SurveyResponseFilterPopup />

        {/* --- view response popup --- */}
        <ViewResponsePopup
          viewInviteId={viewInviteId}
          setViewInviteId={setViewInviteId}
        />
      </div>
    );
  } else {
    return (
      <div id="content_wrapper">
        <section className="crm-wrapper bg-white pb-5">
          <div className="empty_access text-center">
            <div className="empty_pic mb-4">
              {" "}
              <img src={assetImages.emptyVector} alt="" />
            </div>
            <div className="empty_text">
              <p className="fs-lg text-gray fw-semibold mb-4">
                {t("Sorry....! You don't have privilege to see this content")}
              </p>
            </div>
          </div>
        </section>
      </div>
    );
  }
};

export default AppUserSurveyInviteBody;
