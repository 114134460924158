/* eslint-disable */
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { useTranslation } from "react-i18next";

//**------ import url and method ------*/
import * as url from "helper/UrlHelper";
import { getData, postData, putData } from "utils/Gateway";

// Import CKEditor here (both required).
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const AiSuggestModal = ({
  challengeResponseDetails = {},
  aiSuggest = "",
  setAiSuggest,
  cvDetails,
  jobDetails,

  askAiSuggest,
  setAskAiSuggest,
  setReloadResponseDetails,
}) => {
  const { t } = useTranslation(); // for translations

  const loadingCircle = [1, 2, 3, 4, 5];

  const token = localStorage.getItem("token");

  /*---- CKEditor classic editor configuration start ----- */
  const editorConfig = {
    toolbar: ["bold", "italic", "link", "bulletedList", "numberedList"],
    ckfinder: {
      // Upload the images to the server using the CKFinder QuickUpload command.
      //   uploadUrl: url.URL_TEXT_EDITOR_IMAGE_UPLOAD_2,
    },
    placeholder: t("Type details here..."),
  };

  const [aiProcessing, setAiProcessing] = useState(false);
  const [askReEvaluate, setAskReEvaluate] = useState(false);

  const [isSaving, setIsSaving] = useState(false);

  // if aiSuggest is empty, then use useEffect to get the AI suggestion when the modal is opened
  useEffect(() => {
    if (askAiSuggest) {
      if (aiSuggest === "") {
        getAiSuggest();
      }
      setAskAiSuggest(false);
    }
  }, [askAiSuggest, aiSuggest]);

  useEffect(() => {
    if (askReEvaluate) {
      getAiSuggest();
      setAskReEvaluate(false);
    }
  }, [askReEvaluate]);

  // send a post request to the server to save the AI suggestion
  const getAiSuggest = async () => {
    try {
      // console.log("jobDetails", jobDetails);
      // console.log("cvDetails", cvDetails);

      // if jobDetails or cvDetails length is < 5, then return
      if (jobDetails.length < 5 || cvDetails.length < 5) {
        return;
      }
      setAiProcessing(true);

      const promptData = {
        jobDetails: jobDetails,
        cvText: cvDetails,
      };

      let requestUrl = url.API_BASE_URL;

      requestUrl += url.API_CHALLENGE_RESPONSE_AI_SUGGEST + `?token=${token}`;

      console.log("reqUrl", requestUrl);

      const response = await postData(requestUrl, promptData);

      setAiProcessing(false);

      // console.log("ai response >>", response);

      if (response.data) {
        setAiSuggest(formatResponse(response.data));
      }
    } catch (err) {
      console.log(err);
    }
  };

  //save ai suggest
  const saveAiSuggestHandler = async () => {
    try {
      setIsSaving(true);

      const aiData = {
        aisuggest: aiSuggest,
      };

      let requestURL =
        url.API_BASE_URL +
        url.API_UPDATE_CHALLENGE_RESPONSE +
        `/${challengeResponseDetails._id}?token=${token}`;

      const response = await putData(requestURL, aiData);

      console.log(response);

      setIsSaving(false);

      if (response.status) {
        // close the modal of addLabel
        let aiModal = document.querySelector("#aisuggest_modal");
        let modal = bootstrap.Modal.getInstance(aiModal);
        modal.hide();

        setReloadResponseDetails(true);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const formatResponse = (text) => {
    return text.replace(/\n/g, "<br>"); // Convert newlines to HTML <br>
  };

  return (
    <div
      className="modal fade"
      id="aisuggest_modal"
      tabIndex="-1"
      aria-labelledby="aisuggest"
      aria-hidden="true"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
    >
      <div className="modal-dialog modal-xl">
        <div className="modal-content">
          <div className="modal-header">
            <h3 className="modal-title">AI Suggestion</h3>

            <button
              type="button"
              className="btn-close p-0 bg-gray-200 rounded-circle shadow-none m-0"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => {
                setAskAiSuggest(false);
              }}
            ></button>
          </div>
          <div className="modal-body">
            {aiProcessing ? (
              <div className="d-flex align-items-center mb-3">
                <div className="mx-2 spinner-border text-primary" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
                <h6 className="text text-primary mt-1 d-flex align-items-center">
                  Please wait while we evaluate the candidate ....
                </h6>
              </div>
            ) : null}

            <form onSubmit={(e) => e.preventDefault()}>
              <div className="form_innr">
                <div className="form-group summery_textbx">
                  <CKEditor
                    className="form-control"
                    editor={ClassicEditor}
                    config={editorConfig}
                    data={aiSuggest ? aiSuggest : ""}
                    onReady={(editor) => {
                      editor.editing.view.change((writer) => {
                        writer.setStyle(
                          "height",
                          "300px",
                          editor.editing.view.document.getRoot()
                        );
                      });
                    }}
                    onChange={(event, editor) => {
                      let editorContent = editor.getData();
                      setAiSuggest(editorContent);
                    }}
                  />
                </div>
              </div>
              <div className="process_btns_outer d-flex align-items-center justify-content-between mt-3">
                <button
                  data-bs-dismiss="modal"
                  className="btn btn-outline-primary"
                  onClick={() => {
                    setAskAiSuggest(false);
                  }}
                >
                  Cancel
                </button>

                <div className="d-flex align-items-center gap-2">
                  <button
                    className={`${
                      aiProcessing ? "d-none" : "btn btn-outline-primary"
                    }`}
                    onClick={() => {
                      setAskReEvaluate(true);
                    }}
                  >
                    Ask for Evaluate
                  </button>
                  <button
                    className={`${aiProcessing ? "d-none" : "btn btn-primary"}`}
                    disabled={isSaving ? true : false}
                    style={{
                      cursor: isSaving ? "not-allowed" : "pointer",
                    }}
                    onClick={saveAiSuggestHandler}
                  >
                    Save
                    {isSaving && (
                      <div
                        className="mx-2 spinner-border spinner-border-sm"
                        role="status"
                      >
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    )}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AiSuggestModal;
