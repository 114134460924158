/* eslint-disable */
import { getData } from "utils/Gateway";

const SaveAddressModal = ({
  addressIndex,
  addressData,
  setAddressData,
  addressBlock,
  setaddressBlock,
  setaddressIndex,
  setValidation,
}) => {
  //function for add edcuation block
  const addAddressBlockHandler = () => {
    setValidation((prevState) => ({
      ...prevState,
      addressBlockWarning: false,
    }));
    setaddressBlock([...addressBlock, addressData]);
    closeModalHandler();
  };

  //function for edit education block
  const editAddressBlockHandler = () => {
    setValidation((prevState) => ({
      ...prevState,
      addressBlockWarning: false,
    }));
    const updatedList = [...addressBlock];
    updatedList[addressIndex] = addressData;
    setaddressBlock(updatedList);
    closeModalHandler();
  };

  //close modal handler
  const closeModalHandler = () => {
    setAddressData({
      name: "",
      address: "",
      city: "",
      state: "",
      country: "",
      zipcode: "",
      mapurl: "",
    });
    setaddressIndex(null);
  };

  /* function to fetch location from zip code */
  const getLocationFromZip = async (e) => {
    e.preventDefault();
    if (addressData.zipcode !== "") {
      try {
        const url = `https://maps.googleapis.com/maps/api/geocode/json?components=postal_code:${addressData.zipcode}&region=es&key=AIzaSyDt4dbtdHCHLj8grTzTVxpztvqUGDkvkaI`;
        const response = await getData(url);
        if (response.status === "OK") {
          const results = response.results;
          if (results.length > 0) {
            results[0]?.address_components.map((item) => {
              // console.log(`item`, JSON.stringify(item));
              if (item.types[0] === "country") {
                setAddressData((prevData) => ({
                  ...prevData,
                  country: item?.long_name,
                }));
              }
              if (item.types[0] === "locality") {
                setAddressData((prevData) => ({
                  ...prevData,
                  city: item?.long_name,
                }));
              }
              if (item.types[0] === "administrative_area_level_1") {
                setAddressData((prevData) => ({
                  ...prevData,
                  state: item?.long_name,
                }));
              }
            });

            setAddressData((prevData) => ({
              ...prevData,
              mapurl: `https://maps.google.com/maps?q=${results[0]?.geometry?.location?.lat},${results[0]?.geometry?.location?.lng}`,
            }));
          } else {
            console.log("No results found.");
          }
        } else {
          console.log("Request failed with status:", response.status);
        }
      } catch (error) {
        console.error("Error:", error.message);
      }
    }
  };

  return (
    <div
      className="modal fade"
      id="saveAddress"
      tabIndex="-1"
      aria-labelledby="saveAddressLabel"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content bg-white border-0 rounded-15">
          {/* ------ Modal Header ------ */}
          <div className="modal-header p-4 pb-0 border-0">
            <h2 className="fw-bold mb-0" id="saveAddressLabel">
              Save Address
            </h2>
            <button
              type="button"
              className="btn-close p-0 bg-gray-200 rounded-circle shadow-none m-0"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={closeModalHandler}
            ></button>
          </div>

          {/* ------ Modal Body ------ */}
          <div className="modal-body p-4">
            <form onSubmit={(e) => e.preventDefault()}>
              {/* ------ Name ------ */}
              <div className="form-group mb-4">
                <label className="d-block fs-sm fw-semibold mb-2">Name</label>
                <input
                  type="text"
                  className="form-control fs-sm shadow-none"
                  value={addressData.name}
                  onChange={(e) => {
                    setAddressData((prevData) => ({
                      ...prevData,
                      name: e.target.value,
                    }));
                  }}
                />
              </div>

              {/* ------ Address ------ */}
              <div className="form-group mb-4">
                <label className="d-block fs-sm fw-semibold mb-2">
                  Address
                </label>
                <input
                  type="text"
                  className="form-control fs-sm shadow-none"
                  value={addressData.address}
                  onChange={(e) => {
                    setAddressData((prevData) => ({
                      ...prevData,
                      address: e.target.value,
                    }));
                  }}
                />
              </div>

              {/* ------ Zipcode + Button ------ */}
              <div className="row mb-4">
                <div className="col-md-6">
                  <label className="d-block fs-sm fw-semibold mb-2">
                    Zipcode
                  </label>
                  <input
                    type="text"
                    className="form-control fs-sm shadow-none"
                    value={addressData.zipcode}
                    onChange={(e) => {
                      setAddressData((prevData) => ({
                        ...prevData,
                        zipcode: e.target.value,
                      }));
                    }}
                  />
                </div>
                <div className="col-md-6 d-flex align-items-end">
                  <button
                    type="button"
                    className="btn btn-outline-primary w-100"
                    onClick={getLocationFromZip}
                  >
                    Fetch location by zipcode
                  </button>
                </div>
              </div>

              {/* ------ City & State in Same Row ------ */}
              <div className="row mb-4">
                <div className="col-md-6 mb-3">
                  <label className="d-block fs-sm fw-semibold mb-2">City</label>
                  <input
                    type="text"
                    className="form-control fs-sm shadow-none"
                    value={addressData.city}
                    onChange={(e) => {
                      setAddressData((prevData) => ({
                        ...prevData,
                        city: e.target.value,
                      }));
                    }}
                  />
                </div>
                <div className="col-md-6 mb-3">
                  <label className="d-block fs-sm fw-semibold mb-2">
                    State
                  </label>
                  <input
                    type="text"
                    className="form-control fs-sm shadow-none"
                    value={addressData.state}
                    onChange={(e) => {
                      setAddressData((prevData) => ({
                        ...prevData,
                        state: e.target.value,
                      }));
                    }}
                  />
                </div>
              </div>

              {/* ------ Country ------ */}
              <div className="form-group mb-4">
                <label className="d-block fs-sm fw-semibold mb-2">
                  Country
                </label>
                <input
                  type="text"
                  className="form-control fs-sm shadow-none"
                  value={addressData.country}
                  onChange={(e) => {
                    setAddressData((prevData) => ({
                      ...prevData,
                      country: e.target.value,
                    }));
                  }}
                />
              </div>

              {/* ------ Map URL ------ */}
              <div className="form-group mb-4">
                <label className="d-block fs-sm fw-semibold mb-2">
                  Map URL
                </label>
                <input
                  type="text"
                  className="form-control fs-sm shadow-none"
                  value={addressData.mapurl}
                  onChange={(e) => {
                    setAddressData((prevData) => ({
                      ...prevData,
                      mapurl: e.target.value,
                    }));
                  }}
                />
              </div>

              {/* ------ Submit Button ------ */}
              <div className="action">
                <button
                  type="button"
                  className="btn btn-primary"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={
                    addressIndex != null
                      ? editAddressBlockHandler
                      : addAddressBlockHandler
                  }
                >
                  Save Address
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SaveAddressModal;
