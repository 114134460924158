/* eslint-disable */
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";

import { getData, postData, putData, deleteData } from "utils/Gateway";
import * as url from "helper/UrlHelper";

import { useTranslation } from "react-i18next";

const InvoiceListFIlterPopup = ({
  componentName,
  moduleType,

  isReset,
  setIsReset,

  setfilterDateRangeType,
  setfilterStartDate,
  setfilterEndDate,

  setfilterStartingPrice,
  setfilterEndingPrice,
  filterOwners,
  setfilterOwners,
  filterFollowers,
  setfilterFollowers,
  setfilterTags,
  setfilterPromisePercent,
  setfilterTitle,

  setIsNoDefaultFilter,
}) => {
  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  /**
   * Language translation imports starts here
   */
  const { t } = useTranslation();
  const ecosystemSlug = localStorage.getItem("ecosystemslug");

  const [tagList, setTagList] = useState([]);

  const [ownersInput, setownersInput] = useState("");
  const [ownersOption, setownersOption] = useState([]);

  const [followersInput, setFollowersInput] = useState("");
  const [followersOptions, setFollowersOptions] = useState([]);

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [startPrice, setstartPrice] = useState("");
  const [endPrice, setendPrice] = useState("");
  const [title, settitle] = useState("");
  const [promisePercent, setpromisePercent] = useState("");
  const [ownerValue, setownerValue] = useState(null);
  const [ownerIds, setownerIds] = useState([]);
  const [followerValue, setfollowerValue] = useState(null);
  const [followerIds, setfollowerIds] = useState([]);
  const [tags, setTags] = useState([]);
  const [errorMessage, seterrorMessage] = useState("");

  const [filterList, setFilterList] = useState([]);

  const [filterValue, setFilterValue] = useState(null);
  const [filterId, setFilterId] = useState(null);
  const [filterName, setFilterName] = useState("");
  const [isDefaultFilter, setIsDefaultFilter] = useState(false);

  const [filterValidError, setFilterValidError] = useState(false);

  const [filterIsSaving, setFilterIsSaving] = useState(false);
  const [filterSavingErrorMessage, setFilterSavingErrorMessage] = useState("");

  const dateSelectOptions = [
    { label: t("Search by Added Date"), value: "created" },
    { label: t("Search by Updated Date"), value: "updated" },
  ];
  const [dateRangeTypeValue, setdateRangeTypeValue] = useState("");

  const dateRangeSelectionHandler = (val) => {
    setfilterDateRangeType(val.value)
    setdateRangeTypeValue(val);
  };

  // function to filter tags by search term
  const [searchTagTerm, setSearchTagTerm] = useState("");
  const showFilteredTags =
    searchTagTerm.length > 0
      ? tagList.filter((tag) =>
          tag.label.toLowerCase().includes(searchTagTerm.toLowerCase())
        )
      : tagList;


  /* hook for validation */
  const [validation, setValidation] = useState({
    dateWarning: false,
    priceWarning: false,
  });

  /* function to validate form */
  const validationHandler = () => {
    let isValid = true;
    if (
      (startDate !== "" && endDate === "") ||
      (endDate !== "" && endDate < startDate)
    ) {
      setValidation((prevState) => ({ ...prevState, dateWarning: true }));
      isValid = false;
    }

    if (startPrice !== "" && endPrice === "") {
      setValidation((prevState) => ({ ...prevState, priceWarning: true }));
      isValid = false;
    }

    return isValid;
  };

  const onOwnersInputChange = (val) => {
    setownersInput(val);
  };

  const getOwnersOptions = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_USERLIST_OPTIONS +
        `?token=${token}&userstringinput=${ownersInput}`;

      console.log("url of owner------>", requestUrl);

      const response = await getData(requestUrl);

      console.log("response in owner------>", response);

      if (response.status) {
        setownersOption(response.data);
      }
    } catch (error) {}
  };

  const onFollowersInputChange = (val) => {
    setFollowersInput(val);
  };

  const getFollowersOptions = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_USERLIST_OPTIONS +
        `?token=${token}&userstringinput=${followersInput}`;

      console.log("url of follower------>", requestUrl);

      const response = await getData(requestUrl);

      console.log("response in follower------>", response);

      if (response.status) {
        setFollowersOptions(response.data);
      }
    } catch (error) {}
  };

  //function for get all tags
  const getAllTags = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL + url.API_GET_ALL_TAGS + `?token=${token}`;

      const response = await getData(requestUrl);

      console.log(response);

      if (response.status) {
        setTagList(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //** Function for select filter */
  const filterSelectionHandler = (val) => {
    setFilterSavingErrorMessage("");
    setFilterValidError(false);
    if (val) {
      setFilterValue(val);
      setFilterName(val.label);
      setFilterId(val.value);
      setIsDefaultFilter(val.isdefault);

      const jsonFilterData = JSON.parse(val.parameters);

      // console.log(jsonFilterData);

      //assign startdate
      if (jsonFilterData.startDate) {
        setStartDate(jsonFilterData.startDate);
      } else {
        setStartDate("");
      }

      //assign enddate
      if (jsonFilterData.endDate) {
        setEndDate(jsonFilterData.endDate);
      } else {
        setEndDate("");
      }

      //assign startprice
      if (jsonFilterData.startPrice) {
        setstartPrice(jsonFilterData.startPrice);
      } else {
        setstartPrice("");
      }

      //assign endprice
      if (jsonFilterData.endPrice) {
        setendPrice(jsonFilterData.endPrice);
      } else {
        setendPrice("");
      }

      //assign promisepercent
      if (jsonFilterData.promisePercent) {
        setpromisePercent(jsonFilterData.promisePercent);
      } else {
        setpromisePercent("");
      }

      //assign title
      if (jsonFilterData.title) {
        settitle(jsonFilterData.title);
      } else {
        settitle("");
      }

      //assign owners
      if (jsonFilterData.ownerValue) {
        setownerValue(jsonFilterData.ownerValue);
      } else {
        setownerValue(null);
      }

      if (jsonFilterData.ownerIds) {
        setownerIds(jsonFilterData.ownerIds);
      } else {
        setownerIds([]);
      }

      //assign followers
      if (jsonFilterData.followerValue) {
        setfollowerValue(jsonFilterData.followerValue);
      } else {
        setfollowerValue(null);
      }

      if (jsonFilterData.followerIds) {
        setfollowerIds(jsonFilterData.followerIds);
      } else {
        setfollowerIds([]);
      }

      //assign tags
      if (jsonFilterData.tags && jsonFilterData.tags.length > 0) {
        // Filtering the skills options based on values present in the json array
        const filteredTags = tagList.filter((item) =>
          jsonFilterData.tags.includes(item.value)
        );

        //run loop
        filteredTags.forEach((item) => {
          tagSelectionHandler(item);
        });
      } else {
        setTags([]);
        setfilterTags([]);
        removeActiveClass();
      }
    } else {
      setFilterValue(null);
      setFilterName("");
      setFilterId(null);
      setIsDefaultFilter(false);
    }
  };

  //select owner
  const ownerSelectionHandler = (val) => {
    setownerValue(val);
    setownerIds(val.map((item) => item.value));
  };

  //select followers
  const followerSelectionHandler = (val) => {
    setfollowerValue(val);
    setfollowerIds(val.map((item) => item.value));
  };

  // function for set tags
  const tagSelectionHandler = (data) => {
    let prevTags = tags;

    if (prevTags.includes(data.value)) {
      let selectedTagItem = document.getElementById("tagfilter-" + data.value);

      console.log('selectedTagItem',selectedTagItem);

      selectedTagItem.classList.remove("active");
      // exist in array, thus remove
      let key = prevTags.indexOf(data.value);
      prevTags.splice(key, 1);
    } else {

      console.log('data.value',data.value); 

      let selectedTagItem = document.getElementById("tagfilter-" + data.value);

      console.log('selectedTagItem',selectedTagItem);

      selectedTagItem.classList.add("active");
      // not exist in array, thus add
      prevTags.push(data.value);
    }
    setTags(prevTags);
  };

  //function for remove active class for selected tags
  const removeActiveClass = () => {
    const liElements = document.querySelectorAll(".tags li a");
    liElements.forEach((li) => {
      li.classList.remove("active");
    });
  };

  // reset selected tags
  const resetSelectTags = () => {
    let resetBlock = document.getElementsByClassName("tagblock");
    for (let i = 0; i < resetBlock.length; i++) {
      resetBlock[i].classList.remove("active");
    }
  };

  //function for search
  const searchHandler = () => {
    if (validationHandler()) {
      seterrorMessage("");

      setfilterStartDate(startDate);
      setfilterEndDate(endDate);
      setfilterStartingPrice(startPrice);
      setfilterEndingPrice(endPrice);

      if (promisePercent !== "") {
        setfilterPromisePercent(promisePercent);
      } else {
        setfilterPromisePercent("");
      }

      if (title !== "") {
        setfilterTitle(title);
      } else {
        setfilterTitle("");
      }

      if (ownerIds.length > 0) {
        setfilterOwners(ownerIds);
      } else {
        setfilterOwners(filterOwners);
      }

      if (followerIds.length > 0) {
        setfilterFollowers(followerIds);
      } else {
        setfilterFollowers(filterFollowers);
      }

      if (tags.length > 0) {
        setfilterTags(tags);
      } else {
        setfilterTags([]);
      }

      // close the offcanvas of filter
      let offCanvasPopup = document.querySelector(
        "#offcanvasInvoiceListFilter"
      );

      let offCanvas = bootstrap.Offcanvas.getInstance(offCanvasPopup);
      offCanvas.hide();

      // resetHandler();
    } else {
      seterrorMessage("Please fill the required fields");
    }
  };

  //** Function for save filter */
  const saveFilterHanlder = async () => {
    if (filterName === "") {
      setFilterValidError(true);
    } else {
      setFilterIsSaving(true);

      if (validationHandler()) {
        //set filter data for saving
        let filterData = {
          name: filterName,
          parameters: JSON.stringify({
            startDate,
            endDate,
            startPrice,
            endPrice,
            promisePercent,
            title,
            ownerValue,
            ownerIds,
            followerValue,
            followerIds,
            tags,
          }),
          moduleslug: componentName,
          isdefault: isDefaultFilter,
        };

        console.log(`filterData ==========>>>`, filterData);

        try {
          let requestUrl = url.API_BASE_URL;

          //check condition
          if (filterId) {
            requestUrl =
              requestUrl +
              url.API_UPDATE_FILTER +
              `/${filterId}` +
              `?token=${token}`;
          } else {
            requestUrl =
              requestUrl + url.API_ADD_NEW_FILTER + `?token=${token}`;
          }

          const response = filterId
            ? await putData(requestUrl, filterData)
            : await postData(requestUrl, filterData);

          setFilterIsSaving(false);

          console.log(`filterData response ==========>>>`, response);

          if (response.status) {
            searchHandler();
            if (filterId) {
              getAllFilters(false);
            } else {
              getAllFilters(true);
            }
          } else {
            setFilterSavingErrorMessage(response.message);
          }
        } catch (error) {
          console.log(error.message);
        }
      } else {
        seterrorMessage("Please fill the required fields");
      }
    }
  };

  //** Function for get all saved filters
  const getAllFilters = async (isLastSaved) => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_ALL_FILTER +
        `?token=${token}&isselect=${true}&modulename=${componentName}`;

      // console.log("filter request url >>", requestUrl);

      const response = await getData(requestUrl);

      // console.log("all filter list reseponse >>>", response);

      if (response.status) {
        if (response.data.length > 0) {
          setFilterList(response.data);

          if (isLastSaved) {
            const lastSavedFilter = response.data[response.data.length - 1];

            setFilterValue(lastSavedFilter);
            setFilterName(lastSavedFilter.label);
            setFilterId(lastSavedFilter.value);
          }
        }
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  // delete selected filter from filter select
  const deleteSelectedFilter = async () => {
    if (filterId && filterName !== "") {
      setFilterValidError(true);
      try {
        let requestUrl =
          url.API_BASE_URL +
          url.API_DELETE_FILTER +
          `/${filterId}` +
          `?token=${token}`;

        const response = await deleteData(requestUrl);

        // console.log(`filterData response ==========>>>`, response);

        if (response.status) {
          getAllFilters(false);
          resetHandler();
        }
      } catch (error) {
        console.error(error.message);
      }
    } // resetHandler();
  };

  //function for default filter
  const getDefaultFilter = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_DEFAULT_FILTER +
        `?token=${token}&modulename=${componentName}`;

      // console.log("filter request url >>", requestUrl);

      const response = await getData(requestUrl);

      console.log("default filter response >>>", response);

      if (response.status) {
        if (response.data) {
          setIsNoDefaultFilter(false);
          let filterData = response.data;

          if (filterData.isdefault) {
            filterSelectionHandler(filterData);

            const jsonFilterData = JSON.parse(filterData.parameters);

            console.log(jsonFilterData);

            //assign startdate
            if (jsonFilterData.startDate) {
              setfilterStartDate(jsonFilterData.startDate);
            }

            //assign enddate
            if (jsonFilterData.endDate) {
              setfilterEndDate(jsonFilterData.endDate);
            }

            //assign startprice
            if (jsonFilterData.startPrice) {
              setfilterStartingPrice(jsonFilterData.startPrice);
            }

            //assign endprice
            if (jsonFilterData.endPrice) {
              setfilterEndingPrice(jsonFilterData.endPrice);
            }

            //assign promisepercent
            if (jsonFilterData.promisePercent) {
              setfilterPromisePercent(jsonFilterData.promisePercent);
            }

            //assign title
            if (jsonFilterData.title) {
              setfilterTitle(jsonFilterData.title);
            }

            //assign owners
            if (jsonFilterData.ownerIds) {
              setfilterOwners(jsonFilterData.ownerIds);
            }

            //assign followers
            if (jsonFilterData.followerIds) {
              setfilterFollowers(jsonFilterData.followerIds);
            }

            //assign tags
            if (jsonFilterData.tags) {
              setfilterTags(jsonFilterData.tags);
            }
          }
        } else {
          setIsNoDefaultFilter(true);
        }
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for reset
  const resetHandler = () => {
    setStartDate("");
    setEndDate("");
    setstartPrice("");
    setendPrice("");
    settitle("");
    setpromisePercent("");
    setownersInput("");
    setownersOption([]);
    setownerValue(null);
    setownerIds([]);
    setFollowersInput("");
    setFollowersOptions([]);
    setfollowerValue(null);
    setfollowerIds([]);
    setTags([]);
    removeActiveClass();
    resetSelectTags();

    setFilterValue(null);
    setFilterId(null);
    setFilterName("");
    setIsDefaultFilter(false);

    setFilterValidError(false);
    setFilterSavingErrorMessage("");
  };

  useEffect(() => {
    if (ownersInput.length > 0) {
      getOwnersOptions();
    }
  }, [ownersInput]);

  useEffect(() => {
    if (followersInput.length > 0) {
      getFollowersOptions();
    }
  }, [followersInput]);

  useEffect(() => {
    if (isReset) {
      resetHandler();
      setIsReset(false);
    }
  }, [isReset]);

  useEffect(() => {
    getAllTags();
    getAllFilters(false);
    getDefaultFilter();
  }, []);

  //options for company list in select
  const ownersOptionsToShow = ownersOption.length > 0 ? ownersOption : [];

  //options for company list in select
  const followersOptionsToShow =
    followersOptions.length > 0 ? followersOptions : [];

  return (
    <div
      className="offcanvas offcanvas-end bg-white border-0"
      tabIndex="-1"
      id="offcanvasInvoiceListFilter"
      aria-labelledby="offcanvasInvoiceListFilter"
    >
      <div className="offcanvas-header justify-content-end p-4 pb-0">
        {/* <!-- ========== Start popup close button Section ========== --> */}
        <button
          type="button"
          className="btn-close p-0 bg-gray-200 rounded-circle shadow-none m-0"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          // onClick={resetHandler}
        ></button>
        {/* <!-- ========== End popup close button Section ========== --> */}
      </div>
      <div className="offcanvas-body p-4">
        <form onSubmit={(e) => e.preventDefault()}>
          {/* <!-- ========== Start select from saved filter Section ========== --> */}
          <div className="form-group mb-4">
            <label htmlFor="Name" className="d-block fs-sm fw-semibold mb-2">
              <h3>{t("Select from saved filter")}</h3>{" "}
            </label>
            {/* <!-- ========== Start filter select Section ========== --> */}
            <div className="row gx-2 mb-2">
              <Select
                isClearable
                placeholder={t("Select from saved filter")}
                options={filterList}
                value={filterValue}
                onChange={(val) => {
                  filterSelectionHandler(val);
                  setFilterSavingErrorMessage("");
                }}
              />
            </div>
            {/* <!-- ========== End filter select Section ========== --> */}
          </div>
          {/* <!-- ========== End select from saved filter Section ========== --> */}

          {/* <!-- ========== Start date select Section ========== --> */}
          <div className="form-group mb-4">
            <label htmlFor="Name" className="d-block fs-sm fw-semibold mb-2">
              <h3>{t("Date")}</h3>{" "}
            </label>
            {/* <!-- ========== Start date type select Section ========== --> */}
            <div className="row gx-2 mb-2">
              <Select
                placeholder={t("Select Date Range")}
                options={dateSelectOptions}
                value={dateRangeTypeValue}
                onChange={(val) => {
                  dateRangeSelectionHandler(val);
                }}
              />
            </div>
            {/* <!-- ========== End date type select Section ========== --> */}

            {/* <!-- ========== Start date input Section ========== --> */}
            <div className="row gx-2">
              <div className="col-lg-6 mb-3 mb-lg-0">
                <input
                  type="date"
                  className="form-control fs-sm shadow-none"
                  placeholder="Start date"
                  value={startDate}
                  onChange={(e) => {
                    setStartDate(e.target.value);
                    setValidation((prevState) => ({
                      ...prevState,
                      dateWarning: false,
                    }));
                    seterrorMessage("");
                  }}
                />
              </div>
              <div className="col-lg-6 mb-3 mb-lg-0">
                <input
                  type="date"
                  className="form-control fs-sm shadow-none"
                  placeholder="End date"
                  value={endDate}
                  onChange={(e) => {
                    setEndDate(e.target.value);
                    setValidation((prevState) => ({
                      ...prevState,
                      dateWarning: false,
                    }));
                    seterrorMessage("");
                  }}
                />
              </div>
            </div>
            {validation.dateWarning && (
              <div className="error-message mt-3">
                <p className="d-flex align-items-center gap-1 text-danger">
                  <span className="material-symbols-outlined">warning</span>
                  <span>Please enter end date!</span>
                </p>
              </div>
            )}
            {/* <!-- ========== End date input Section ========== --> */}
          </div>
          {/* <!-- ========== End date select Section ========== --> */}





          {/* <!-- ========== Start date range Section ========== --> */}
          {/* <div className="form-group mb-4">
            <label htmlFor="Name" className="d-block fs-sm fw-semibold mb-2">
              <h3>{t("Dates")}</h3>{" "}
            </label>
            <div className="d-flex gap-2">
              <input
                type="date"
                className="form-control fs-sm shadow-none"
                placeholder="Start date"
                value={startDate}
                onChange={(e) => {
                  setStartDate(e.target.value);
                  setValidation((prevState) => ({
                    ...prevState,
                    dateWarning: false,
                  }));
                  seterrorMessage("");
                }}
              />
              <input
                type="date"
                className="form-control fs-sm shadow-none"
                placeholder="End date"
                value={endDate}
                onChange={(e) => {
                  setEndDate(e.target.value);
                  setValidation((prevState) => ({
                    ...prevState,
                    dateWarning: false,
                  }));
                  seterrorMessage("");
                }}
              />
            </div>
            {validation.dateWarning && (
              <div className="error-message mt-3">
                <p className="d-flex align-items-center gap-1 text-danger">
                  <span className="material-symbols-outlined">warning</span>
                  <span>Please enter end date!</span>
                </p>
              </div>
            )}
          </div> */}
          {/* <!-- ========== End date range Section ========== --> */}

          {/* <!-- ========== Start price range Section ========== --> */}
          <div className="form-group mb-4">
            <label htmlFor="Name" className="d-block fs-sm fw-semibold mb-2">
              <h3>{t("Price range")}</h3>{" "}
            </label>
            <div className="d-flex gap-2">
              <input
                type="number"
                className="form-control fs-sm shadow-none"
                placeholder={t("Starting Price")}
                value={startPrice}
                onChange={(e) => {
                  setstartPrice(e.target.value);
                  setValidation((prevState) => ({
                    ...prevState,
                    priceWarning: false,
                  }));
                  seterrorMessage("");
                }}
              />
              <input
                type="number"
                className="form-control fs-sm shadow-none"
                placeholder={t("End price")}
                value={endPrice}
                onChange={(e) => {
                  setendPrice(e.target.value);
                  setValidation((prevState) => ({
                    ...prevState,
                    priceWarning: false,
                  }));
                  seterrorMessage("");
                }}
              />
            </div>
            {validation.priceWarning && (
              <div className="error-message mt-3">
                <p className="d-flex align-items-center gap-1 text-danger">
                  <span className="material-symbols-outlined">warning</span>
                  <span>{t("Please enter ending price")}!</span>
                </p>
              </div>
            )}
          </div>
          {/* <!-- ========== End price range Section ========== --> */}

          {/* <!-- ========== Start name Section ========== --> */}
          <div className="form-group mb-4">
            <label htmlFor="Name" className="d-block fs-sm fw-semibold mb-2">
              <h3>{t("Title")}</h3>{" "}
            </label>
            <input
              type="text"
              className="form-control"
              placeholder={t("Title")}
              value={title}
              onChange={(e) => settitle(e.target.value)}
            />
          </div>
          {/* <!-- ========== End name Section ========== --> */}

          {/* <!-- ========== Start email Section ========== --> */}
          <div className="form-group mb-4">
            <label htmlFor="email" className="d-block fs-sm fw-semibold mb-2">
              <h3>{t("Promise Percent")}</h3>{" "}
            </label>
            <input
              type="text"
              className="form-control fs-sm shadow-none"
              placeholder={t("Promise Percent")}
              value={promisePercent}
              onChange={(e) => setpromisePercent(e.target.value)}
            />
          </div>
          {/* <!-- ========== End email Section ========== --> */}

          {/* <!-- ========== Start owner Section ========== --> */}
          {componentName && componentName === "crmopportunity" ? (
            <div className="form-group mb-4">
              <label htmlFor="Owner" className="d-block fs-sm fw-semibold mb-2">
                <h3>{t("Owner")}</h3>{" "}
              </label>
              <Select
                placeholder={t("Type name or email")}
                isMulti
                options={ownersOptionsToShow}
                value={ownerValue}
                onChange={(val) => {
                  ownerSelectionHandler(val);
                }}
                onInputChange={(val) => {
                  onOwnersInputChange(val);
                }}
              />
            </div>
          ) : null}
          {/* <!-- ========== End owner Section ========== --> */}

          {/* <!-- ========== Start follower Section ========== --> */}

          <div className="form-group mb-4">
            <label htmlFor="Owner" className="d-block fs-sm fw-semibold mb-2">
              <h3>{t("Follower")}</h3>{" "}
            </label>
            <Select
              placeholder={t("Type name or email")}
              isMulti
              options={followersOptionsToShow}
              value={followerValue}
              onChange={(val) => {
                followerSelectionHandler(val);
              }}
              onInputChange={(val) => {
                onFollowersInputChange(val);
              }}
            />
          </div>

          {/* <!-- ========== End follower Section ========== --> */}

          {/* <!-- ========== Start label Section ========== --> */}
                
          <div className="accordion-item bg-transparent border-0">
            <h3 className="accordion-header">
              <button
                className="accordion-button bg-transparent p-0 shadow-none"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#panelsStayOpen-collapseFive"
                aria-expanded="true"
                aria-controls="panelsStayOpen-collapseFive"
              >
                {t("Labels")}
              </button>
            </h3>
            <div
              id="panelsStayOpen-collapseFive"
              className="accordion-collapse collapse show"
            >
              <div className="accordion-body p-0 pt-4">
                <input
                  type="text"
                  className="form-control mb-3"
                  placeholder="Search tags..."
                  value={searchTagTerm}
                  onChange={(e) => setSearchTagTerm(e.target.value)}
                />
                <ul className="tags d-flex flex-wrap gap-2 fs-sm fw-semibold">
                  {showFilteredTags.map((tagData, index) => {
                    return (
                      <li className="tag" key={index}>
                        <Link
                          to="#"
                          onClick={() => tagSelectionHandler(tagData)}
                          id={"tagfilter-" + tagData.value}
                          className="skillblock d-block px-3 py-2 gradient-light cursor-pointer rounded-90"
                        >
                          <span>{tagData.label}</span>
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
      
          {/* <!-- ========== End label Section ========== --> */}    


          <div className="accordion" id="accordionPanelsStayOpenExample">
            {/* <!-- ========== Start label/tag accordion Section ========== --> */}
            {moduleType && moduleType === "kanban" ? null : (
              <div className="accordion-item bg-transparent border-0">
                <h3 className="accordion-header">
                  <button
                    className="accordion-button bg-transparent p-0 shadow-none"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#panelsStayOpen-collapselabels"
                    aria-expanded="true"
                    aria-controls="panelsStayOpen-collapselabels"
                  >
                    {t("Labels")}
                  </button>
                </h3>
                <div
                  id="panelsStayOpen-collapselabels"
                  className="accordion-collapse collapse show"
                >
                  <div className="accordion-body p-0 pt-4">
                    <input
                      type="text"
                      className="form-control mb-3"
                      placeholder="Search tags..."
                      value={searchTagTerm}
                      onChange={(e) => setSearchTagTerm(e.target.value)}
                    />

                    <ul className="tags d-flex flex-wrap gap-2 fs-sm fw-semibold">
                      {showFilteredTags.map((tag, index) => {
                        return (
                          <li className="tag" key={index}>
                            <Link
                              to="#"
                              onClick={() => tagSelectionHandler(tag)}
                              id={"tagfilter-" + tag.value}
                              className="tagblock d-block px-3 py-2 gradient-light cursor-pointer rounded-90"
                            >
                              <span>{tag.label}</span>
                            </Link>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>
            )}
            {/* <!-- ========== End label/tag accordion Section ========== --> */}
          </div>

          {/* <!-- ========== Start filter title input Section ========== --> */}
          <div className="form-group mt-4">
            <label
              htmlFor="inputtitle"
              className="d-block fs-sm fw-semibold mb-2"
            >
              <h3>{t("Save as new filter")}</h3>{" "}
            </label>
            <div className="d-flex align-items-center gap-1">
              <input
                type="text"
                className="form-control fs-sm shadow-none"
                placeholder={t("Save as new filter")}
                value={filterName}
                onChange={(e) => {
                  setFilterName(e.target.value);
                  setFilterValidError(false);
                  setFilterSavingErrorMessage("");
                }}
              />

              <Link to="#" onClick={deleteSelectedFilter}>
                <span className="d-block material-symbols-outlined">
                  delete
                </span>
              </Link>
            </div>

            {filterValidError && (
              <div className="error-message mt-2">
                <p className="d-flex align-items-center gap-1 text-danger">
                  <span className="material-symbols-outlined">warning</span>
                  <span>{t("Please enter a title or select a filter")}!</span>
                </p>
              </div>
            )}
          </div>
          {/* <!-- ========== End filter title input Section ========== --> */}

          {/* <!-- ========== Start is default checkbox Section ========== --> */}
          <div className="form-check d-flex align-items-center gap-2 cursor-pointer p-0 m-0 mt-4">
            <input
              className="form-check-input d-block bg-transparent border-2 border-gray-600 shadow-none m-0"
              type="checkbox"
              id="agree"
              checked={isDefaultFilter}
              onChange={(e) => {
                setIsDefaultFilter(e.target.checked);
                setFilterSavingErrorMessage("");
              }}
            />
            <label
              className="form-check-label fs-lg fw-semibold"
              htmlFor="agree"
            >
              {t("Is default filter")}
            </label>
          </div>
          {/* <!-- ========== End is default checkbox Section ========== --> */}

          {/* <!-- ========== Start error message Section ========== --> */}
          {errorMessage === "" ? null : (
            <div className="error-message mt-3">
              <p className="d-flex align-items-center gap-1 text-danger">
                <span className="material-symbols-outlined">warning</span>
                <span>{errorMessage}!</span>
              </p>
            </div>
          )}
          {/* <!-- ========== End error message Section ========== --> */}

          {/* <!-- ========== Start filter saving error message Section ========== --> */}
          {filterSavingErrorMessage === "" ? null : (
            <div className="error-message mt-4">
              <p className="d-flex align-items-center gap-1 text-danger">
                <span className="material-symbols-outlined">warning</span>
                <span>{filterSavingErrorMessage}!</span>
              </p>
            </div>
          )}
          {/* <!-- ========== End filter saving error message Section ========== --> */}

          {/* <!-- ========== Start apply button Section ========== --> */}
          <div className="action d-flex gap-2 mt-4">
            <button
              type="button"
              className="btn btn-primary flex-fill"
              // data-bs-dismiss="offcanvas"
              aria-label="Close"
              onClick={searchHandler}
            >
              {t("Apply Filter")}
            </button>

            <button
              type="button"
              className="btn btn-primary flex-fill"
              // data-bs-dismiss="offcanvas"
              aria-label="Close"
              onClick={saveFilterHanlder}
              disabled={filterIsSaving ? true : false}
              style={{
                cursor: filterIsSaving ? "not-allowed" : "pointer",
              }}
            >
              {t("Save and Apply Filter")}
              {filterIsSaving && (
                <div
                  className="mx-2 spinner-border spinner-border-sm"
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              )}
            </button>
          </div>
          {/* <!-- ========== End apply button Section ========== --> */}
        </form>
      </div>
    </div>
  );
};

export default InvoiceListFIlterPopup;
