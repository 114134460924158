/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";

/*import url and gateway methods */
import { getData, postData, putData } from "utils/Gateway";
import * as url from "helper/UrlHelper";
import { useTranslation } from "react-i18next";

const AllProjectMemberFilterPopup = ({
  setFilterTaskDateRangeType = () => {},
  setfilterTaskFromDate = () => {},
  setfilterTaskToDate = () => {},
  setfilterProjects = () => {},
  setfilterOwners = () => {},
  setfilterProviders = () => {},
}) => {
  const token = localStorage.getItem("token");

  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const { t } = useTranslation(); //for translation

  const dateSelectOptions = [
    { label: t("Search by End Date Between"), value: "enddate" },
    { label: t("Search by Start Date Between"), value: "startdate" },
    { label: t("Search Task in this Date Range"), value: "daterange" },
  ];

  const [projectInput, setProjectInput] = useState("");
  const [ownerInput, setOwnerInput] = useState("");
  const [providerInput, setProviderInput] = useState("");

  const [projectList, setprojectList] = useState([]);
  const [ownerList, setOwnerList] = useState([]);
  const [providerList, setproviderList] = useState([]);
  const [tagList, setTagList] = useState([]);

  //date range
  const [dateRangeTypeValue, setdateRangeTypeValue] = useState(null);
  const [dateRangeType, setdateRangeType] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [projectValue, setprojectValue] = useState(null);
  const [selectedProjects, setselectedProjects] = useState([]);
  const [ownerValue, setownerValue] = useState(null);
  const [selectedOwners, setselectedOwners] = useState([]);
  const [providerValue, setproviderValue] = useState(null);
  const [selectedProviders, setselectedProviders] = useState([]);
  const [selectedTags, setselectedTags] = useState([]);

  const [filterList, setFilterList] = useState([]);

  const [filterValue, setFilterValue] = useState(null);
  const [filterId, setFilterId] = useState(null);
  const [filterName, setFilterName] = useState("");
  const [isDefaultFilter, setIsDefaultFilter] = useState(false);

  const [filterValidError, setFilterValidError] = useState(false);

  const [filterIsSaving, setFilterIsSaving] = useState(false);
  const [filterSavingErrorMessage, setFilterSavingErrorMessage] = useState("");

  //** Function for select filter */
  const filterSelectionHandler = (val) => {
    setFilterSavingErrorMessage("");
    setFilterValidError(false);
  };

  //function for get project list
  const getProjectList = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_PROJECTLIST_BY_SEARCH +
        `?token=${token}&searchtitle=${projectInput}&modulefrom=backend`;

      console.log(requestUrl);

      const response = await getData(requestUrl);

      console.log(response);

      if (response.status) {
        if (response.data.length > 0) {
          setprojectList(response.data);
        }
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for get owner list
  const getOwnerList = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_USERLIST_OPTIONS +
        `?token=${token}&userstringinput=${ownerInput}&modulefrom=backend`;

      console.log(requestUrl);

      const response = await getData(requestUrl);

      console.log(response);

      if (response.status) {
        if (response.data.length > 0) {
          setOwnerList(response.data);
        }
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for get provider list
  const getProviderList = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_USERLIST_OPTIONS +
        `?token=${token}&userstringinput=${providerInput}&modulefrom=backend`;

      console.log(requestUrl);

      const response = await getData(requestUrl);

      console.log(response);

      if (response.status) {
        if (response.data.length > 0) {
          setproviderList(response.data);
        }
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for get all tags
  const getAllTags = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL + url.API_GET_ALL_TAGS + `?token=${token}`;

      const response = await getData(requestUrl);

      console.log(response);

      if (response.status) {
        setTagList(response.data);
      }
    } catch (error) {
      setAlertMessage(error.message);
      setMessageType("error");
      setShowAlert(true);
    }
  };

  // function to filter tags by search term
  const [searchTagTerm, setSearchTagTerm] = useState("");

  const showFilteredTags =
    searchTagTerm.length > 0
      ? tagList.filter((tag) =>
          tag.label.toLowerCase().includes(searchTagTerm.toLowerCase())
        )
      : tagList;

  //select date range type
  const dateRangeSelectionHandler = (val) => {
    if (val) {
      setdateRangeTypeValue(val);
      setdateRangeType(val.value);
    } else {
      setdateRangeTypeValue(null);
      setdateRangeType("");
    }
  };

  //function for select project
  const projectSelectionHandler = (data) => {
    setprojectValue(data);
    setselectedProjects(data.map((item) => item.value));
  };

  //function for select owner
  const ownerSelectionHandler = (data) => {
    setownerValue(data);
    setselectedOwners(data.map((item) => item.value));
  };

  //function for select provider
  const providerSelectionHandler = (data) => {
    setproviderValue(data);
    setselectedProviders(data.map((item) => item.value));
  };

  //function for assign search data for filter and close pop up
  const searchHandler = () => {
    if (dateRangeType != "") {
      setFilterTaskDateRangeType(dateRangeType);
    } else {
      setFilterTaskDateRangeType("");
    }

    //check start date and end date are not empty.If end date empty then only search by start date
    if (fromDate != "") {
      setfilterTaskFromDate(fromDate);
    } else {
      setfilterTaskFromDate("");
    }

    if (toDate != "") {
      setfilterTaskToDate(toDate);
    } else {
      setfilterTaskToDate("");
    }

    setfilterProjects(selectedProjects);
    setfilterOwners(selectedOwners);
    setfilterProviders(selectedProviders);

    // close the offcanvas of filter
    let offCanvasPopup = document.querySelector(
      "#offcanvasAllMemberProjectFilter"
    );
    let offCanvas = bootstrap.Offcanvas.getInstance(offCanvasPopup);
    offCanvas.hide();
    resetHandler();
  };

  //function for reset all states
  const resetHandler = () => {
    setProjectInput("");
    setOwnerInput("");
    setProviderInput("");
    dateRangeSelectionHandler({
      label: t("Search Task in this Date Range"),
      value: "daterange",
    });
    setFromDate("");
    setToDate("");
    setprojectValue(null);
    setselectedProjects([]);
    setownerValue(null);
    setselectedOwners([]);
    setproviderValue(null);
    setselectedProviders([]);
    setselectedTags([]);
  };

  useEffect(() => {
    getAllTags();
    dateRangeSelectionHandler({
      label: t("Search Task in this Date Range"),
      value: "daterange",
    });
  }, []);

  useEffect(() => {
    if (projectInput.length > 1) {
      getProjectList();
    }
  }, [projectInput]);

  useEffect(() => {
    if (ownerInput.length > 1) {
      getOwnerList();
    }
  }, [ownerInput]);

  useEffect(() => {
    if (providerInput.length > 1) {
      getProviderList();
    }
  }, [providerInput]);

  return (
    <div
      className="offcanvas offcanvas-end bg-white border-0"
      tabIndex="-1"
      id="offcanvasAllMemberProjectFilter"
      aria-labelledby="offcanvasAllMemberProjectFilterLabel"
    >
      {/* -------- select filter ---------- */}
      <div className="offcanvas-header justify-content-end p-4 pb-0">
        <button
          type="button"
          className="btn-close p-0 bg-gray-200 rounded-circle shadow-none m-0"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          onClick={resetHandler}
        ></button>
      </div>
      <div className="offcanvas-body p-4">
        <form
          onSubmit={(e) => e.preventDefault()}
          className="d-flex flex-column h-100"
        >
          {/* <!-- ========== Start date select Section ========== --> */}
          <div className="form-group mb-4">
            <label htmlFor="Name" className="d-block fs-sm fw-semibold mb-2">
              <h3>{t("Date")}</h3>{" "}
            </label>
            {/* <!-- ========== Start date type select Section ========== --> */}
            <div className="row gx-2 mb-2">
              <Select
                placeholder={t("Select Date Range")}
                options={dateSelectOptions}
                value={dateRangeTypeValue}
                onChange={(val) => {
                  dateRangeSelectionHandler(val);
                }}
              />
            </div>
            {/* <!-- ========== End date type select Section ========== --> */}

            {/* <!-- ========== Start date input Section ========== --> */}
            <div className="row gx-2">
              <div className="col-lg-6 mb-3 mb-lg-0">
                <input
                  type="date"
                  className="form-control fs-sm shadow-none"
                  placeholder={t("From date")}
                  value={fromDate}
                  onChange={(e) => setFromDate(e.target.value)}
                />
              </div>
              <div className="col-lg-6 mb-3 mb-lg-0">
                <input
                  type="date"
                  className="form-control fs-sm shadow-none"
                  placeholder={t("To date")}
                  value={toDate}
                  onChange={(e) => setToDate(e.target.value)}
                />
              </div>
            </div>
            {/* <!-- ========== End date input Section ========== --> */}
          </div>
          {/* <!-- ========== End date select Section ========== --> */}

          {/* --------- select project ---------- */}
          <div className="form-group mb-4">
            <label
              htmlFor="moderator"
              className="d-block fs-sm fw-semibold mb-2"
            >
              <h3>{t("Projects")}</h3>{" "}
            </label>

            <Select
              isMulti
              placeholder={t("Type project name")}
              options={projectList}
              value={projectValue}
              onChange={projectSelectionHandler}
              onInputChange={(val) => {
                setProjectInput(val);
              }}
            />
          </div>

          {/* --------- select owner ---------- */}
          <div className="form-group mb-4">
            <label
              htmlFor="moderator"
              className="d-block fs-sm fw-semibold mb-2"
            >
              <h3>{t("Owners")}</h3>{" "}
            </label>

            <Select
              isMulti
              placeholder={t("Type owner name")}
              options={ownerList}
              value={ownerValue}
              onChange={ownerSelectionHandler}
              onInputChange={(val) => {
                setOwnerInput(val);
              }}
            />
          </div>

          {/* --------- select provider ---------- */}
          <div className="form-group mb-4">
            <label
              htmlFor="moderator"
              className="d-block fs-sm fw-semibold mb-2"
            >
              <h3>{t("Providers")}</h3>{" "}
            </label>

            <Select
              isMulti
              placeholder={t("Type provider name")}
              options={providerList}
              value={providerValue}
              onChange={providerSelectionHandler}
              onInputChange={(val) => {
                setProviderInput(val);
              }}
            />
          </div>

          {/* --------- save filter / apply filter ------------ */}
          <div className="action d-flex gap-2 mt-4">
            {/* <Link
              to="#"
              className="btn btn-outline-primary flex-fill"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            >
              Save Filter
            </Link> */}
            <button
              type="button"
              className="btn btn-primary flex-fill"
              // data-bs-dismiss="offcanvas"
              aria-label="Close"
              onClick={searchHandler}
            >
              {t("Apply Filter")}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AllProjectMemberFilterPopup;
