/* eslint-disable */
import { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

/*------ import url and method ------*/
import * as url from "helper/UrlHelper";
import { postData, getData, putData } from "utils/Gateway";

import BreadCrumb from "components/Common/BreadCrumb/BreadCrumb";
import { getAllCategories } from "helper/CategoryHelper/CategoryHelper";
import AlertNotification from "components/Common/AlertNotification/AlertNotification";

const TreeView = () => {
  const { t } = useTranslation();

  const params = useParams();

  const token = localStorage.getItem("token");
  const ecosystemSlug = localStorage.getItem("ecosystemslug");

  const [name, setname] = useState("");

  const [parentbaseCategories, setParentBaseCategories] = useState([]);
  const [levelTwoCategories, setLevelTwoCategories] = useState([]);
  const [levelThreeCategories, setLevelThreeCategories] = useState([]);
  const [levelFourCategories, setLevelFourCategories] = useState([]);
  const [levelFiveCategories, setLevelFiveCategories] = useState([]);
  const [levelSixCategories, setLevelSixCategories] = useState([]);

  const [selectedBaseCategoryId, setselectedBaseCategoryId] = useState(null);
  const [selectedLevelTwoCategoryId, setselectedLevelTwoCategoryId] =
    useState(null);
  const [selectedLevelThreeCategoryId, setselectedLevelThreeCategoryId] =
    useState(null);
  const [selectedLevelFourCategoryId, setselectedLevelFourCategoryId] =
    useState(null);
  const [selectedLevelFiveCategoryId, setselectedLevelFiveCategoryId] =
    useState(null);
  const [selectedLevelSixCategoryId, setselectedLevelSixCategoryId] =
    useState(null);

  const [isSaving, setIsSaving] = useState(false);

  /* hook for validation */
  const [validation, setValidation] = useState({
    categoryWarning: false,
  });

  //alert requirements
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [messageType, setMessageType] = useState("");

  const breadcrumbText = [
    { title: t("Marketplace"), link: "/admin/marketplace/products/list" },
    { title: name },
  ];

  /* function to validate form */
  const validationHandler = () => {
    let isValid = true;

    if (selectedBaseCategoryId === null) {
      setValidation((prevState) => ({ ...prevState, categoryWarning: true }));
      isValid = false;
    }

    return isValid;
  };

  //function for get product details
  const getProductDetails = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_PRODUCT_DETAILS +
        `/${params.id}?token=${token}`;

      const response = await getData(requestUrl);

      console.log("response of product details------>", response);

      if (response.status) {
        const data = response.data;

        setname(data.name);

        if (data.categoryidlist && data.categoryidlist.length > 0) {
          const baseResponse = await getAllCategories(ecosystemSlug, "PRODUCT");

          if (baseResponse.length > 0) {
            const matchingBaseCategories = baseResponse.filter((category) =>
              data.categoryids.includes(category._id)
            );

            if (matchingBaseCategories.length > 0) {
              parentSelectionHandler(
                matchingBaseCategories[0].slug,
                matchingBaseCategories[0]._id
              );

              const levelTwoResponse = await getAllCategories(
                ecosystemSlug,
                matchingBaseCategories[0].slug
              );

              if (levelTwoResponse.length > 0) {
                const matchingLevelTwoCategories = levelTwoResponse.filter(
                  (category) => data.categoryids.includes(category._id)
                );

                if (matchingLevelTwoCategories.length > 0) {
                  childOneSelectionHandler(
                    matchingLevelTwoCategories[0].slug,
                    matchingLevelTwoCategories[0]._id
                  );

                  const levelThreeResponse = await getAllCategories(
                    ecosystemSlug,
                    matchingLevelTwoCategories[0].slug
                  );

                  if (levelThreeResponse.length > 0) {
                    const matchingLevelThreeCategories =
                      levelThreeResponse.filter((category) =>
                        data.categoryids.includes(category._id)
                      );

                    if (matchingLevelThreeCategories.length > 0) {
                      childTwoSelectionHandler(
                        matchingLevelThreeCategories[0].slug,
                        matchingLevelThreeCategories[0]._id
                      );

                      const levelFourResponse = await getAllCategories(
                        ecosystemSlug,
                        matchingLevelThreeCategories[0].slug
                      );

                      if (levelFourResponse.length > 0) {
                        const matchingLevelFourCategories =
                          levelFourResponse.filter((category) =>
                            data.categoryids.includes(category._id)
                          );

                        if (matchingLevelFourCategories.length > 0) {
                          childThreeSelectionHandler(
                            matchingLevelFourCategories[0].slug,
                            matchingLevelFourCategories[0]._id
                          );

                          const levelFiveResponse = await getAllCategories(
                            ecosystemSlug,
                            matchingLevelFourCategories[0].slug
                          );

                          if (levelFiveResponse.length > 0) {
                            const matchingLevelFiveCategories =
                              levelFiveResponse.filter((category) =>
                                data.categoryids.includes(category._id)
                              );

                            if (matchingLevelFiveCategories.length > 0) {
                              childFourSelectionHandler(
                                matchingLevelFiveCategories[0].slug,
                                matchingLevelFiveCategories[0]._id
                              );

                              const levelSixResponse = await getAllCategories(
                                ecosystemSlug,
                                matchingLevelFiveCategories[0].slug
                              );

                              if (levelSixResponse.length > 0) {
                                const matchingLevelSixCategories =
                                  levelSixResponse.filter((category) =>
                                    data.categoryids.includes(category._id)
                                  );

                                if (matchingLevelSixCategories.length > 0) {
                                  childFiveSelectionHandler(
                                    matchingLevelSixCategories[0].slug,
                                    matchingLevelSixCategories[0]._id
                                  );
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for get all base categories
  const getAllCategoryList = async (
    parentSlug = "PRODUCT",
    parentColumnSlug = ""
  ) => {
    try {
      const response = await getAllCategories(ecosystemSlug, parentSlug);

      console.log(response);

      if (response) {
        if (parentSlug === "PRODUCT" && parentColumnSlug === "") {
          setParentBaseCategories(
            response.sort(
              (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
            )
          );
        } else if (parentSlug !== "PRODUCT" && parentColumnSlug === "BASE") {
          setLevelTwoCategories(
            response.sort(
              (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
            )
          );
        } else if (parentSlug !== "PRODUCT" && parentColumnSlug === "LEVEL2") {
          setLevelThreeCategories(
            response.sort(
              (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
            )
          );
        } else if (parentSlug !== "PRODUCT" && parentColumnSlug === "LEVEL3") {
          setLevelFourCategories(
            response.sort(
              (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
            )
          );
        } else if (parentSlug !== "PRODUCT" && parentColumnSlug === "LEVEL4") {
          setLevelFiveCategories(
            response.sort(
              (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
            )
          );
        } else if (parentSlug !== "PRODUCT" && parentColumnSlug === "LEVEL5") {
          setLevelSixCategories(
            response.sort(
              (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
            )
          );
        }
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const CategoryColumn = ({ items = {}, selected, onSelect, isActive }) => (
    <div className="col-lg border-0 border-bottom border-bottom-lg-0 border-lg-end border-solid border-gray-300">
      {items && (
        <ul className="fs-md fw-semibold py-3">
          {items.map((item, index) => (
            <li key={index}>
              <Link
                to="#"
                className={`text-gray py-2 d-flex align-items-center gap-3 justify-content-between ${
                  selected === item._id ? "text-primary" : ""
                }`}
                onClick={() => onSelect(item.slug, item._id)}
              >
                <span className="d-block">{item.name}</span>
                <span className="d-block material-symbols-outlined">
                  chevron_right
                </span>
              </Link>
            </li>
          ))}
        </ul>
      )}
    </div>
  );

  const parentSelectionHandler = (slug, id) => {
    setLevelTwoCategories([]);
    setLevelThreeCategories([]);
    setLevelFourCategories([]);
    setLevelFiveCategories([]);
    setLevelSixCategories([]);
    setselectedBaseCategoryId(id);
    setselectedLevelTwoCategoryId(null);
    setselectedLevelThreeCategoryId(null);
    setselectedLevelFourCategoryId(null);
    setselectedLevelFiveCategoryId(null);
    setselectedLevelSixCategoryId(null);
    getAllCategoryList(slug, "BASE");
  };

  const childOneSelectionHandler = (slug, id) => {
    setLevelThreeCategories([]);
    setLevelFourCategories([]);
    setLevelFiveCategories([]);
    setLevelSixCategories([]);
    setselectedLevelTwoCategoryId(id);
    setselectedLevelThreeCategoryId(null);
    setselectedLevelFourCategoryId(null);
    setselectedLevelFiveCategoryId(null);
    setselectedLevelSixCategoryId(null);
    getAllCategoryList(slug, "LEVEL2");
  };

  const childTwoSelectionHandler = (slug, id) => {
    setLevelFourCategories([]);
    setLevelFiveCategories([]);
    setLevelSixCategories([]);
    setselectedLevelThreeCategoryId(id);
    setselectedLevelFourCategoryId(null);
    setselectedLevelFiveCategoryId(null);
    setselectedLevelSixCategoryId(null);
    getAllCategoryList(slug, "LEVEL3");
  };

  const childThreeSelectionHandler = (slug, id) => {
    setLevelFiveCategories([]);
    setLevelSixCategories([]);
    setselectedLevelFourCategoryId(id);
    setselectedLevelFiveCategoryId(null);
    setselectedLevelSixCategoryId(null);
    getAllCategoryList(slug, "LEVEL4");
  };

  const childFourSelectionHandler = (slug, id) => {
    setLevelSixCategories([]);
    setselectedLevelFiveCategoryId(id);
    setselectedLevelSixCategoryId(null);
    getAllCategoryList(slug, "LEVEL5");
  };

  const childFiveSelectionHandler = (slug, id) => {
    setselectedLevelSixCategoryId(id);
  };

  //reset all parent categories
  const resetAlllevelHandler = () => {
    setselectedBaseCategoryId(null);
    setselectedLevelTwoCategoryId(null);
    setselectedLevelThreeCategoryId(null);
    setselectedLevelFourCategoryId(null);
    setselectedLevelFiveCategoryId(null);
    setselectedLevelSixCategoryId(null);
  };

  //function for save product info
  const saveProductInfoHandler = async () => {
    if (validationHandler()) {
      setIsSaving(true);
      try {
        let categoryIds = [];

        if (selectedBaseCategoryId) {
          categoryIds.push(selectedBaseCategoryId);
        }

        if (selectedLevelTwoCategoryId) {
          categoryIds.push(selectedLevelTwoCategoryId);
        }

        if (selectedLevelThreeCategoryId) {
          categoryIds.push(selectedLevelThreeCategoryId);
        }

        if (selectedLevelFourCategoryId) {
          categoryIds.push(selectedLevelFourCategoryId);
        }

        if (selectedLevelFiveCategoryId) {
          categoryIds.push(selectedLevelFiveCategoryId);
        }

        if (selectedLevelSixCategoryId) {
          categoryIds.push(selectedLevelSixCategoryId);
        }

        let productData = {
          categoryids: categoryIds,
        };

        let requestUrl =
          url.API_BASE_URL +
          url.API_UPDATE_PRODUCT +
          `/${params.id}?token=${token}`;

        const response = await putData(requestUrl, productData);

        setIsSaving(false);

        if (response.status) {
          setShowAlert(true);
          setAlertMessage(t("Category updated successfully"));
          setMessageType("success");

          setTimeout(() => {
            getProductDetails();
          }, 1500);
        }
      } catch (error) {
        setAlertMessage(error.message);
        setShowAlert(true);
        setMessageType("error");
      }
    }
  };

  useEffect(() => {
    if (params.id) {
      getAllCategoryList("PRODUCT", "");
      getProductDetails();
    }
  }, [params.id]);

  const onAlertClose = () => {
    setShowAlert(false);
    setAlertMessage("");
    setMessageType("");
  };

  return (
    <div className="container-fluid px-lg-5">
      <div className="filter-container py-3">
        <div className="d-flex align-items-center justify-content-between">
          <BreadCrumb breadCrumbText={breadcrumbText} />
          <div className="action d-flex flex-wrap gap-3 justify-content-between">
            <button
              className="btn btn-primary"
              onClick={saveProductInfoHandler}
              style={{
                cursor: isSaving ? "not-allowed" : "pointer",
              }}
              disabled={isSaving ? true : false}
            >
              Save
              {isSaving && (
                <div
                  className="mx-2 spinner-border spinner-border-sm"
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              )}
            </button>
          </div>
        </div>
      </div>

      <div className="table-wrapper">
        <div className="categories-container px-4 border border-gray-300 rounded-10 shadow-sm mb-3">
          <div className="row gx-5">
            {/* Parent Categories */}
            <CategoryColumn
              items={parentbaseCategories}
              onSelect={parentSelectionHandler}
              selected={selectedBaseCategoryId}
            />

            {/* Child1 Categories */}
            <CategoryColumn
              items={levelTwoCategories}
              onSelect={childOneSelectionHandler}
              selected={selectedLevelTwoCategoryId}
            />

            {/* Child2 Categories */}
            <CategoryColumn
              items={levelThreeCategories}
              onSelect={childTwoSelectionHandler}
              selected={selectedLevelThreeCategoryId}
            />

            {/* Child3 Categories */}
            <CategoryColumn
              items={levelFourCategories}
              onSelect={childThreeSelectionHandler}
              selected={selectedLevelFourCategoryId}
            />

            {/* Child4 Categories */}
            <CategoryColumn
              items={levelFiveCategories}
              onSelect={childFourSelectionHandler}
              selected={selectedLevelFiveCategoryId}
            />

            {/* Child5 Categories */}
            <CategoryColumn
              items={levelSixCategories}
              onSelect={childFiveSelectionHandler}
              selected={selectedLevelSixCategoryId}
            />

            {showAlert && (
              <AlertNotification
                showAlert={showAlert}
                message={alertMessage}
                alertType={messageType}
                onClose={onAlertClose}
              />
            )}
          </div>
        </div>
        {validation.categoryWarning && (
          <div className="error-message mt-2">
            <p className="d-flex align-items-center gap-1 text-danger">
              <span className="material-symbols-outlined">warning</span>
              <span>{t("Please select at least one category")}!</span>
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default TreeView;
