/* eslint-disable */
import React, { useEffect } from "react";
import Header from "components/Common/Header/Header";
import ProjectTypeTaxonomyBody from "components/AdministrationComponents/TaxonomyTypes/ProjectType/ProjectTypeTaxonomyBody";

const ProjectTypeTaxonomy = () => {
  useEffect(() => {
    document.title = "Administration";
  }, []);

  return (
    <main id="app">
      <Header moduleName="administration" />
      <ProjectTypeTaxonomyBody />
    </main>
  );
};

export default ProjectTypeTaxonomy;
