/* eslint-disable */
import React, { useContext, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";

// Material UI table
import { MaterialReactTable } from "material-react-table";
//Material UI components for rendring menu and others
import { MenuItem, Box } from "@mui/material";

//import context
import { GlobalProvider } from "context/GlobalContext";

/*import url and gateway methods */
import { getData, putData } from "utils/Gateway";
import * as url from "helper/UrlHelper";

//import images
import CommonHeader from "components/MarketplaceComponents/MarketplaceHeader/CommonHeader";
import { assetImages } from "constants";
import InventoryHeader from "../Header/InventoryHeader";
import { useTranslation } from "react-i18next";
import DeleteWarningModal from "components/Common/Modal/DeleteWarningModal";

import AddLabelModal from "components/Common/Modal/AddLabelModal";
import InventoryFilterPopup from "../Popup/InventoryFilterPopup";
import SaveInventoryPopup from "../Popup/SaveInventoryPopup";
import AlertNotification from "components/Common/AlertNotification/AlertNotification";

const InventoryListBody = () => {
  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const moduleAccess = localStorage.getItem("moduleaccess");
  const { t, i18n } = useTranslation(); //for translation

  const [inventoryList, setinventoryList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [filterFromDate, setfilterFromDate] = useState("");
  const [filterToDate, setfilterToDate] = useState("");
  const [filterProductIds, setfilterProductIds] = useState([]);
  const [filterAddressIds, setfilterAddressIds] = useState([]);
  const [filterSku, setfilterSku] = useState("");
  const [filterSupplier, setfilterSupplier] = useState("");
  const [filterStartStock, setfilterStartStock] = useState("");
  const [filterEndStock, setfilterEndStock] = useState("");
  const [filterTags, setfilterTags] = useState([]);

  const [reloadList, setReloadList] = useState(false);
  const [isFilterReset, setIsFilterReset] = useState(false);

  const [rowSelection, setRowSelection] = useState({});
  const [selectedInventories, setSelectedInventories] = useState([]);
  const [selectedInventory, setselectedInventory] = useState(null);

  //alert requirements
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [messageType, setMessageType] = useState("");

  //get all inventories
  const getAllInventories = async () => {
    setRowSelection({});
    setSelectedInventories([]);
    try {
      setIsLoading(true);

      let requestUrl =
        url.API_BASE_URL + url.API_GET_ALL_INVENTORIES + `?token=${token}`;

      if (filterFromDate != "" || filterToDate != "") {
        requestUrl =
          requestUrl +
          `&filterfromdate=${filterFromDate}&filtertodate=${filterToDate}`;
      }

      if (filterProductIds.length > 0) {
        requestUrl = requestUrl + `&filterproductids=${filterProductIds}`;
      }

      if (filterAddressIds.length > 0) {
        requestUrl = requestUrl + `&filteraddressids=${filterAddressIds}`;
      }

      if (filterSku != "") {
        requestUrl = requestUrl + `&filtersku=${filterSku}`;
      }

      if (filterSupplier != "") {
        requestUrl = requestUrl + `&filtersupplier=${filterSupplier}`;
      }

      if (filterStartStock != "" && filterEndStock != "") {
        requestUrl =
          requestUrl +
          `&filterstartstock=${filterStartStock}&filterendstock=${filterEndStock}`;
      }

      if (filterTags.length > 0) {
        requestUrl = requestUrl + `&filtertags=${filterTags}`;
      }

      const response = await getData(requestUrl);

      setIsLoading(false);

      if (response.status) {
        setinventoryList(response.data);
      }
    } catch (error) {}
  };

  //function for delete bulk
  const deleteBulkInventories = async () => {
    if (selectedInventories.length > 0) {
      try {
        const listToBeUpdated = inventoryList.filter((item) =>
          selectedInventories.includes(item._id)
        );

        const apiData = {
          inventories: listToBeUpdated,
        };

        let requestURL =
          url.API_BASE_URL + url.API_DELETE_INVENTORY + `?token=${token}`;

        const response = await putData(requestURL, apiData);

        if (response.status) {
          setMessageType("success");

          setTimeout(() => {
            getAllInventories();
          }, 1500);
        } else {
          setMessageType("error");
        }

        setAlertMessage(response.message);
      } catch (error) {
        setAlertMessage(error.message);
        setMessageType("error");
      }
    } else {
      setAlertMessage("Please select at least one inventory.");
      setMessageType("error");
    }
    setShowAlert(true);
  };

  //reset filter
  const resetFilter = () => {
    setfilterFromDate("");
    setfilterToDate("");
    setfilterProductIds([]);
    setfilterAddressIds([]);
    setfilterSku("");
    setfilterSupplier("");
    setfilterStartStock("");
    setfilterEndStock("");
    setfilterTags([]);

    setReloadList(true);
    setIsFilterReset(true);
  };

  useEffect(() => {
    if (
      filterFromDate !== "" ||
      filterToDate !== "" ||
      filterProductIds.length > 0 ||
      filterAddressIds.length > 0 ||
      filterSku !== "" ||
      filterSupplier !== "" ||
      filterStartStock !== "" ||
      filterEndStock !== "" ||
      filterTags.length > 0
    ) {
      getAllInventories();
    }
  }, [
    filterFromDate,
    filterToDate,
    filterProductIds,
    filterAddressIds,
    filterSku,
    filterSupplier,
    filterStartStock,
    filterEndStock,
    filterTags,
  ]);

  useEffect(() => {
    if (reloadList) {
      getAllInventories();
      setReloadList(false);
    }
  }, [reloadList]);

  useEffect(() => {
    getAllInventories();
  }, []);

  const columns = useMemo(
    () => [
      {
        accessorKey: "#",
        header: t("Actions"),
        Cell: ({ row }) => (
          <button
            className="action_btn_mui"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasSaveInventory"
            aria-controls="offcanvasSaveInventory"
            onClick={() => {
              setselectedInventory(row.original._id);
            }}
          >
            <span className="d-block material-symbols-outlined horz_icon">
              more_horiz
            </span>
          </button>
        ),
        enableColumnActions: false, // Hides the column action icon
        enableColumnDragging: false, // Hides the move icon
        enableSorting: false,
      },
      { accessorKey: "stockedon", header: "Stocked On" },
      { accessorKey: "sku", header: "SKU" },
      { accessorKey: "productname", header: "Product Name" },
      { accessorKey: "currentstock", header: "Current Stock" },
      { accessorKey: "stockadded", header: "Added Stock" },
      { accessorKey: "wearhousename", header: "Warehouse" },
      { accessorKey: "supplier", header: "Supplier" },
      {
        accessorKey: "tagstring",
        header: t("Label"),

        Cell: ({ row }) => (
          <div className="label border-bottom-0">
            {row.original.labels && (
              <ul className="d-flex flex-wrap gap-2 fs-xs">
                {row.original.labels.map((tag, index) => {
                  return (
                    <li
                      key={index}
                      className="px-2 py-1 gradient-light rounded-5"
                    >
                      {tag.title}
                    </li>
                  );
                })}
              </ul>
            )}
          </div>
        ),
      },
    ],
    []
  );

  //initialize the column order
  const initialColumnOrder = [
    "#",
    "mrt-row-select",
    ...columns.map((c) => c.accessorKey),
  ]; //array of column ids (Initializing is optional as of v2.10.0)

  useEffect(() => {
    const selectedIdsArray = Object.keys(rowSelection).filter(
      (key) => rowSelection[key]
    );

    if (selectedIdsArray.length > 0) {
      setSelectedInventories(selectedIdsArray);
    } else {
      setSelectedInventories([]);
    }
  }, [rowSelection]);

  /* Closes the alert by updating the state variables.*/
  const onAlertClose = () => {
    setShowAlert(false);
    setAlertMessage("");
    setMessageType("");
  };

  if (
    userInfo.role.slug === "ADMIN" ||
    userInfo.role.slug === "SUPER_ADMIN" ||
    moduleAccess.includes("MOD_MARKETPLACE")
  ) {
    return (
      <div id="content_wrapper">
        <section className="crm-wrapper bg-white pb-5">
          {/* ---- common header ---- */}
          <CommonHeader activeOption="Inventory" />

          <div className="container-fluid px-lg-5">
            {/* header */}
            <InventoryHeader reloadList={resetFilter} />

            {/* table */}
            {isLoading ? (
              <div className="placeholder-glow d-flex flex-column gap-4">
                <span className="placeholder placeholder-lg bg-secondary col-12"></span>
                <span className="placeholder placeholder-lg bg-secondary col-8"></span>
                <span className="placeholder placeholder-lg bg-secondary col-4"></span>
              </div>
            ) : (
              <div className="table-wrapper">
                <MaterialReactTable
                  columns={columns} // map columns to be displayed with api data,
                  data={inventoryList} // data from api to be displayed
                  enableGrouping // to enable grouping of column
                  enableRowSelection // enable showing checkbox
                  getRowId={(row) => row._id} // map which value to select with row checkbox
                  onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
                  state={{ rowSelection, columnOrder: initialColumnOrder }} //pass our managed row selection state to the table to use
                  initialState={{
                    rowSelection,
                    columnOrder: initialColumnOrder,
                  }}
                  defaultColumn={{
                    minSize: 20,
                    maxSize: 300,
                    size: 50, //make columns wider by default
                  }}
                  muiTableContainerProps={{
                    sx: {
                      maxHeight: "60vh",
                    },
                  }}
                  enableStickyHeader
                />
              </div>
            )}
          </div>

          {/* ----- all pop up render here ----- */}

          <SaveInventoryPopup
            inventoryId={selectedInventory}
            setInventoryId={setselectedInventory}
            afterPopupCLose={() => {
              setTimeout(() => {
                getAllInventories();
              }, 1000);
            }}
          />

          {/* ----------- delete warning modal ------------ */}
          <DeleteWarningModal
            onCancelDelete={() => {
              setRowSelection({});
              setSelectedInventories([]);
            }}
            onDelete={deleteBulkInventories}
            moduleName={t("inventory")}
          />

          <AddLabelModal
            moduleName="inventory"
            afterTagModalClose={() => {
              setTimeout(() => {
                getAllInventories();
              }, 2500);
            }}
            selectedIds={selectedInventories}
            setShowAlert={setShowAlert}
            setAlertMessage={setAlertMessage}
            setMessageType={setMessageType}
          />

          <InventoryFilterPopup
            setfilterFromDate={setfilterFromDate}
            setfilterToDate={setfilterToDate}
            setfilterProductIds={setfilterProductIds}
            setfilterAddressIds={setfilterAddressIds}
            setfilterSku={setfilterSku}
            setfilterSupplier={setfilterSupplier}
            setfilterStartStock={setfilterStartStock}
            setfilterEndStock={setfilterEndStock}
            setfilterTags={setfilterTags}
            setIsFilterReset={setIsFilterReset}
            isFilterReset={isFilterReset}
          />

          {showAlert && (
            <AlertNotification
              showAlert={showAlert}
              message={alertMessage}
              alertType={messageType}
              onClose={onAlertClose}
            />
          )}
        </section>
      </div>
    );
  } else {
    return (
      <div id="content_wrapper">
        <section className="crm-wrapper bg-white pb-5">
          <div className="empty_access text-center">
            <div className="empty_pic mb-4">
              {" "}
              <img src={assetImages.emptyVector} alt="" />
            </div>
            <div className="empty_text">
              <p className="fs-lg text-gray fw-semibold mb-4">
                {t("Sorry....! You don't have privilege to see this content")}
              </p>
            </div>
          </div>
        </section>
      </div>
    );
  }
};

export default InventoryListBody;
