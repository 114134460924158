/* eslint-disable */
import React, { useContext, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";

// Material UI table
import { MaterialReactTable } from "material-react-table";
//Material UI components for rendring menu and others
import { MenuItem, Box } from "@mui/material";

//import context
import { GlobalProvider } from "context/GlobalContext";

/*import url and gateway methods */
import { getData, putData } from "utils/Gateway";
import * as url from "helper/UrlHelper";

//import images
import CommonHeader from "components/MarketplaceComponents/MarketplaceHeader/CommonHeader";
import { assetImages } from "constants";
import OrderHeader from "../Header/OrderHeader";
import { useTranslation } from "react-i18next";
import DeleteWarningModal from "components/Common/Modal/DeleteWarningModal";

import ordersList from "data/Marketplace/Orders.json";
import SaveOrderPopup from "../Popup/SaveOrderPopup";
import OrderFilterPopup from "../Popup/OrderFilterPopup";
import AddLabelModal from "components/Common/Modal/AddLabelModal";

const OrdersListBody = () => {
  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const moduleAccess = localStorage.getItem("moduleaccess");

  const { t, i18n } = useTranslation(); //for translation

  const [tagList, setTagList] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [orderList, setOrderList] = useState([]);

  //optionally, you can manage the row selection state yourself
  const [rowSelection, setRowSelection] = useState({});
  const [selectedInvoiceIds, setSelectedInvoiceIds] = useState([]);

  const [selectedInvoiceId, setSelectedInvoiceId] = useState(null);

  //function for get all tags
  const getAllTags = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL + url.API_GET_ALL_TAGS + `?token=${token}`;

      const response = await getData(requestUrl);

      console.log(response);

      if (response.status) {
        setTagList(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for get all contacts
  const getInvoiceList = async () => {
    setRowSelection({});
    setSelectedInvoiceIds([]);

    try {
      setIsLoading(true);

      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_MY_INVOICES +
        `?token=${token}&moduleslug=order`;

      //   if (filterCompanyName != "") {
      //     requestUrl = requestUrl + `&filtername=${filterCompanyName}`;
      //   }

      //   if (filterCompanyTypes.length > 0) {
      //     requestUrl = requestUrl + `&filtertypes=${filterCompanyTypes}`;
      //   }

      //   if (filterCompanyTags.length > 0) {
      //     requestUrl = requestUrl + `&filterlabels=${filterCompanyTags}`;
      //   }

      console.log("request url------>", requestUrl);

      const response = await getData(requestUrl);

      console.log("response ------>", response);

      setIsLoading(false);

      if (response.status) {
        setOrderList(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    getInvoiceList();
    getAllTags();
  }, []);

  /*
   * Material React Table Column and States -----------------------------------------------------------
   *---------------------------------------------------------------------------------------------------
   */
  const columns = useMemo(
    () => [
      {
        accessorKey: "#",
        header: t("Actions"),
        Cell: ({ row }) => (
          <button
            className="action_btn_mui"
            data-bs-toggle="offcanvas"
            data-bs-target="#viewPurchaseOrder"
            aria-controls="viewPurchaseOrder"
            onClick={() => {
              setSelectedInvoiceId(row.original._id);
            }}
          >
            <span className="d-block material-symbols-outlined horz_icon">
              more_horiz
            </span>
          </button>
        ),
        enableColumnActions: false, // Hides the column action icon
        enableColumnDragging: false, // Hides the move icon
        enableSorting: false,
      },
      { accessorKey: "orderid", header: "Order Id" },
      { accessorKey: "clientfullname", header: "Customer" },
      { accessorKey: "clientemail", header: "Email" },
      { accessorKey: "invoicedate", header: "Order Date" },
      { accessorKey: "amount", header: "Amount" },
      {
        accessorKey: "itemnames",
        header: "Items",
        size: 300, // Adjust the width as needed
        Cell: ({ row }) => (
          <ul>
            {row.original.invoiceitems.map((item, index) => (
              <li key={index}>
                {item.description} (Qty: {item.unit}, Price: ${item.totalprice})
              </li>
            ))}
          </ul>
        ),
      },
      // { accessorKey: "paymentstatus", header: "Payment Status" },
    ],
    []
  );

  //initialize the column order
  const initialColumnOrder = [
    "#",
    "mrt-row-select",
    ...columns.map((c) => c.accessorKey),
  ]; //array of column ids (Initializing is optional as of v2.10.0)

  useEffect(() => {
    const selectedIdsArray = Object.keys(rowSelection).filter(
      (key) => rowSelection[key]
    );

    if (selectedIdsArray.length > 0) {
      setSelectedInvoiceIds(selectedIdsArray);
    } else {
      setSelectedInvoiceIds([]);
    }
  }, [rowSelection]);

  if (
    userInfo.role.slug === "ADMIN" ||
    userInfo.role.slug === "SUPER_ADMIN" ||
    moduleAccess.includes("MOD_MARKETPLACE")
  ) {
    return (
      <div id="content_wrapper">
        <section className="crm-wrapper bg-white pb-5">
          {/* ---- common header ---- */}
          <CommonHeader activeOption="Orders" />

          <div className="container-fluid px-lg-5">
            {/* header */}
            <OrderHeader reloadList={getInvoiceList} />

            {/* table */}
            {isLoading ? (
              <div className="placeholder-glow d-flex flex-column gap-4">
                <span className="placeholder placeholder-lg bg-secondary col-12"></span>
                <span className="placeholder placeholder-lg bg-secondary col-8"></span>
                <span className="placeholder placeholder-lg bg-secondary col-4"></span>
              </div>
            ) : (
              <div className="table-wrapper">
                <MaterialReactTable
                  columns={columns} // map columns to be displayed with api data,
                  data={orderList} // data from api to be displayed
                  enableGrouping // to enable grouping of column
                  enableRowSelection // enable showing checkbox
                  getRowId={(row) => row._id} // map which value to select with row checkbox
                  onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
                  state={{ rowSelection, columnOrder: initialColumnOrder }} //pass our managed row selection state to the table to use
                  initialState={{
                    rowSelection,
                    columnOrder: initialColumnOrder,
                  }}
                  defaultColumn={{
                    minSize: 20,
                    maxSize: 300,
                    size: 50, //make columns wider by default
                  }}
                  muiTableContainerProps={{
                    sx: {
                      maxHeight: "60vh",
                    },
                  }}
                  enableStickyHeader
                />
              </div>
            )}
          </div>

          {/* ----- all pop up render here ----- */}
          {/* ----------- delete warning modal ------------ */}
          <DeleteWarningModal
            // onCancelDelete={() => {
            //   setRowSelection({});
            //   setSelectedBlogIds([]);
            // }}
            // onDelete={changeStatusHandler}
            moduleName={t("product")}
          />
          {/* ------ add label modal render here ----- */}

          <SaveOrderPopup
            selectedInvoiceId={selectedInvoiceId}
            setSelectedInvoiceId={setSelectedInvoiceId}
          />

          <AddLabelModal
            moduleName="product"
            afterTagModalClose={() => {
              setTimeout(() => {
                //getAllEvents();
              }, 2500);
            }}
            // selectedIds=""
            // setShowAlert={setShowAlert}
            // setAlertMessage={setAlertMessage}
            // setMessageType={setMessageType}
          />

          <OrderFilterPopup tagList={tagList} />
        </section>
      </div>
    );
  } else {
    return (
      <div id="content_wrapper">
        <section className="crm-wrapper bg-white pb-5">
          <div className="empty_access text-center">
            <div className="empty_pic mb-4">
              {" "}
              <img src={assetImages.emptyVector} alt="" />
            </div>
            <div className="empty_text">
              <p className="fs-lg text-gray fw-semibold mb-4">
                {t("Sorry....! You don't have privilege to see this content")}
              </p>
            </div>
          </div>
        </section>
      </div>
    );
  }
};

export default OrdersListBody;
