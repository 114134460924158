/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";

/*------ import url and method ------*/
import * as url from "helper/UrlHelper";
import { getData } from "utils/Gateway";
import { useTranslation } from "react-i18next";
import { ecosystemSlug } from "Config/Config";

const InventoryFilterPopup = ({
  setfilterFromDate,
  setfilterToDate,
  setfilterProductIds,
  setfilterAddressIds,
  setfilterSku,
  setfilterSupplier,
  setfilterStartStock,
  setfilterEndStock,
  setfilterTags,
  setIsFilterReset,
  isFilterReset,
}) => {
  const { t } = useTranslation(); //for translation
  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const [productInput, setproductInput] = useState("");
  const [productList, setproductList] = useState([]);
  const [addressList, setaddressList] = useState([]);

  const [tagList, setTagList] = useState([]);

  const [fromDate, setfromDate] = useState("");
  const [toDate, settoDate] = useState("");
  const [productValue, setproductValue] = useState(null);
  const [selectedProductIds, setSelectedProductIds] = useState([]);
  const [sku, setSku] = useState("");
  const [supplier, setsupplier] = useState("");
  const [addressValue, setAddressValue] = useState(null);
  const [addressIds, setAddressIds] = useState([]);
  const [startStock, setstartStock] = useState("");
  const [endStock, setendStock] = useState("");
  const [selectedTags, setselectedTags] = useState([]);

  /* hook for validation */
  const [validation, setValidation] = useState({
    dateWarning: false,
    stockWarning: false,
  });

  // function to filter tags by search term
  const [searchTagTerm, setSearchTagTerm] = useState("");

  const showFilteredTags =
    searchTagTerm.length > 0
      ? tagList.filter((tag) =>
          tag.label.toLowerCase().includes(searchTagTerm.toLowerCase())
        )
      : tagList;

  /* function to validate form */
  const validationHandler = () => {
    let isValid = true;

    if (fromDate !== "" && toDate === "") {
      setValidation((prevState) => ({ ...prevState, dateWarning: true }));
      isValid = false;
    }

    if (startStock !== "" && endStock === "") {
      setValidation((prevState) => ({ ...prevState, stockWarning: true }));
      isValid = false;
    }

    return isValid;
  };

  //function for product list
  const getProductList = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_ALL_PRODUCTS_LIST +
        `?token=${token}&title=${productInput}`;

      const response = await getData(requestUrl);

      console.log("response in product list------>", response);

      if (response.status) {
        setproductList(response.data);
      }
    } catch (error) {}
  };

  //fucntion for get addresslist
  const getAddressList = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL + url.API_GET_WAREHOUSE_ADDRESS + `?token=${token}`;

      const response = await getData(requestUrl);

      if (response.status) {
        setaddressList(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for get all tags
  const getAllTags = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL + url.API_GET_ALL_TAGS + `?token=${token}`;

      const response = await getData(requestUrl);

      console.log(response);

      if (response.status) {
        setTagList(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //select product
  const productSelectionHandler = (val) => {
    setproductValue(val);
    setSelectedProductIds(val.map((item) => item.value));
  };

  //select address
  const addressSelectionHandler = (val) => {
    setAddressValue(val);
    setAddressIds(val.map((item) => item.value));
  };

  // function for set tags
  const tagSelectionHandler = (data) => {
    let prevTags = selectedTags;
    if (prevTags.includes(data.value)) {
      let selectedTagItem = document.getElementById("tagfilter-" + data.value);
      selectedTagItem.classList.remove("active");
      // exist in array, thus remove
      let key = prevTags.indexOf(data.value);
      prevTags.splice(key, 1);
    } else {
      let selectedTagItem = document.getElementById("tagfilter-" + data.value);
      selectedTagItem.classList.add("active");
      // not exist in array, thus add
      prevTags.push(data.value);
    }
    setselectedTags(prevTags);
  };

  //function for remove active class for selected tags
  const removeActiveClass = () => {
    const liElements = document.querySelectorAll(".tags li a");
    liElements.forEach((li) => {
      li.classList.remove("active");
    });
  };

  // reset selected type
  const resetSelectTypes = () => {
    let resetBlock = document.getElementsByClassName("typeblock");
    for (let i = 0; i < resetBlock.length; i++) {
      resetBlock[i].classList.remove("active");
    }
  };

  // reset selected tags
  const resetSelectTags = () => {
    let resetBlock = document.getElementsByClassName("tagblock");
    for (let i = 0; i < resetBlock.length; i++) {
      resetBlock[i].classList.remove("active");
    }
  };

  //function for search
  const searchHandler = () => {
    if (validationHandler()) {
      setfilterFromDate(fromDate);
      setfilterToDate(toDate);
      setfilterProductIds(selectedProductIds);
      setfilterAddressIds(addressIds);
      setfilterSku(sku);
      setfilterSupplier(supplier);
      setfilterStartStock(startStock);
      setfilterEndStock(endStock);
      setfilterTags(selectedTags);

      // close the offcanvas of filter
      let offCanvasPopup = document.querySelector("#offcanvasInventoryFilter");

      let offCanvas = bootstrap.Offcanvas.getInstance(offCanvasPopup);
      offCanvas.hide();
    }
  };

  //function for reset search
  const resetHandler = () => {
    setproductInput("");
    setproductList([]);
    setfromDate("");
    settoDate("");

    // Reset selected values
    setproductValue(null);
    setSelectedProductIds([]);
    setAddressValue(null);
    setAddressIds([]);

    // Reset filter values
    setSku("");
    setsupplier("");
    setstartStock("");
    setendStock("");
    setselectedTags([]);
    resetSelectTypes();
    resetSelectTags();
    removeActiveClass();

    setValidation({
      priceWarning: false,
      stockWarning: false,
    });
  };

  useEffect(() => {
    if (isFilterReset) {
      resetHandler();
      setIsFilterReset(false);
    }
  }, [isFilterReset]);

  useEffect(() => {
    if (productInput.length > 1) {
      getProductList();
    }
  }, [productInput]);

  useEffect(() => {
    getAddressList();
    getAllTags();
  }, []);

  return (
    <div
      className="offcanvas offcanvas-end bg-white border-0"
      tabIndex="-1"
      id="offcanvasInventoryFilter"
      aria-labelledby="offcanvasInventoryFilterLabel"
    >
      <div className="offcanvas-header justify-content-end p-4 pb-0">
        <button
          type="button"
          className="btn-close p-0 bg-gray-200 rounded-circle shadow-none m-0"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          onClick={resetHandler}
        ></button>
      </div>
      <div className="offcanvas-body p-4">
        <form onSubmit={(e) => e.preventDefault()}>
          {/* date  */}
          <div className="form-group mb-4">
            <label
              htmlFor="Subscribe"
              className="d-block fs-sm fw-semibold mb-2"
            >
              <h3>{t("Stocked Dates")}</h3>{" "}
            </label>

            <div className=" d-flex gap-2">
              <input
                type="date"
                className="form-control fs-sm shadow-none"
                placeholder={t("From date")}
                value={fromDate}
                onChange={(e) => {
                  setfromDate(e.target.value);
                  setValidation((prevState) => ({
                    ...prevState,
                    dateWarning: false,
                  }));
                }}
              />
              <input
                type="date"
                className="form-control fs-sm shadow-none"
                placeholder={t("To date")}
                value={toDate}
                onChange={(e) => {
                  settoDate(e.target.value);
                  setValidation((prevState) => ({
                    ...prevState,
                    dateWarning: false,
                  }));
                }}
              />
            </div>
            {validation.dateWarning && (
              <div className="error-message mt-3">
                <p className="d-flex align-items-center gap-1 text-danger">
                  <span className="material-symbols-outlined">warning</span>
                  <span>Please enter end date!</span>
                </p>
              </div>
            )}
          </div>

          {/* ----- title accordion start ----- */}
          <div className="form-group mb-4">
            <label
              htmlFor="Subscribe"
              className="d-block fs-sm fw-semibold mb-2"
            >
              <h3>{t("Select Product")}</h3>{" "}
            </label>
            <Select
              isMulti
              placeholder={t("Type product name")}
              options={productList}
              value={productValue}
              onChange={productSelectionHandler}
              onInputChange={(val) => {
                setproductInput(val);
              }}
            />
          </div>
          {/* ----- title accordion end ----- */}

          {/* wearhouse */}
          <div className="form-group mb-4">
            <label
              htmlFor="Subscribe"
              className="d-block fs-sm fw-semibold mb-2"
            >
              <h3>{t("Select Wearhouses")}</h3>{" "}
            </label>
            <Select
              isMulti
              placeholder={t("Select Address")}
              options={addressList}
              value={addressValue}
              onChange={(val) => {
                addressSelectionHandler(val);
              }}
            />
          </div>

          {/* sku  */}
          <div className="form-group mb-4">
            <label
              htmlFor="Subscribe"
              className="d-block fs-sm fw-semibold mb-2"
            >
              <h3>{t("Sku")}</h3>{" "}
            </label>
            <input
              type="text"
              className="form-control fs-sm shadow-none"
              placeholder={t("Sku")}
              value={sku}
              onChange={(e) => setSku(e.target.value)}
            />
          </div>

          {/* supplier  */}
          <div className="form-group mb-4">
            <label
              htmlFor="Subscribe"
              className="d-block fs-sm fw-semibold mb-2"
            >
              <h3>{t("Supplier")}</h3>{" "}
            </label>
            <input
              type="text"
              className="form-control fs-sm shadow-none"
              placeholder={t("Supplier")}
              value={supplier}
              onChange={(e) => setsupplier(e.target.value)}
            />
          </div>

          {/* stock range  */}
          <div className="form-group mb-4">
            <label
              htmlFor="Subscribe"
              className="d-block fs-sm fw-semibold mb-2"
            >
              <h3>{t("Added Stock")}</h3>{" "}
            </label>

            <div className=" d-flex gap-2">
              <input
                type="number"
                className="form-control fs-sm shadow-none"
                placeholder={t("Lowest Stock")}
                value={startStock}
                onChange={(e) => {
                  setstartStock(e.target.value);
                  setValidation((prevState) => ({
                    ...prevState,
                    stockWarning: false,
                  }));
                }}
              />
              <input
                type="number"
                className="form-control fs-sm shadow-none"
                placeholder={t("Highest Stock")}
                value={endStock}
                onChange={(e) => {
                  setendStock(e.target.value);
                  setValidation((prevState) => ({
                    ...prevState,
                    stockWarning: false,
                  }));
                }}
              />
            </div>
            {validation.stockWarning && (
              <div className="error-message mt-3">
                <p className="d-flex align-items-center gap-1 text-danger">
                  <span className="material-symbols-outlined">warning</span>
                  <span>Please enter end stock!</span>
                </p>
              </div>
            )}
          </div>

          <div className="accordion" id="accordionPanelsStayOpenExample">
            {/* ----- tag accordion start ----- */}
            <div className="accordion-item bg-transparent border-0">
              <h3 className="accordion-header">
                <button
                  className="accordion-button bg-transparent p-0 shadow-none"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#panelsStayOpen-collapseFour"
                  aria-expanded="true"
                  aria-controls="panelsStayOpen-collapseFour"
                >
                  {t("Labels")}
                </button>
              </h3>
              <div
                id="panelsStayOpen-collapseFour"
                className="accordion-collapse collapse show"
              >
                <div className="accordion-body p-0 pt-4">
                  <input
                    type="text"
                    className="form-control mb-3"
                    placeholder="Search tags..."
                    value={searchTagTerm}
                    onChange={(e) => setSearchTagTerm(e.target.value)}
                  />
                  <ul className="tags d-flex flex-wrap gap-2 fs-sm fw-semibold">
                    {showFilteredTags.map((tagData, index) => {
                      return (
                        <li className="tag" key={index}>
                          <Link
                            to="#"
                            onClick={() => tagSelectionHandler(tagData)}
                            id={"tagfilter-" + tagData.value}
                            className="tagblock d-block px-3 py-2 gradient-light cursor-pointer rounded-90"
                          >
                            <span>{tagData.label}</span>
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>
            {/* ----- tag accordion end ----- */}
          </div>
          <button
            type="button"
            className="btn btn-primary fs-sm fw-normal w-100 mt-4"
            onClick={searchHandler}
          >
            {t("Search")}
          </button>
        </form>
      </div>
    </div>
  );
};

export default InventoryFilterPopup;
