/* eslint-disable */
import React, { useRef, useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";

// translation --------------
import { useTranslation } from "react-i18next";

/*------ import url and method ------*/
import * as url from "helper/UrlHelper";

import { postData, getData, putData, uploadMultipleFile } from "utils/Gateway";

import TabsHeader from "components/Common/TabsHeader/TabsHeader";
import BreadCrumb from "components/Common/BreadCrumb/BreadCrumb";

// import Select from "react-select";
import AfterSaveProjectModal from "components/Common/Modal/AfterSaveProjectModal";
import { assetImages } from "constants";
import { projectDetailsHeaderLinks } from "helper/ProjectHelper/ProjectHelper";
import { createdAtToDateConverter } from "helper/Common/CommonHelper";
import AlertNotification from "components/Common/AlertNotification/AlertNotification";

import ProjectForm from "./ProjectForm/ProjectForm";
import ProjectDetails from "./ProjectForm/ProjectDetails";

const ProjectDetailBody = () => {
  const params = useParams();
  const history = useHistory();

  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const moduleAccess = localStorage.getItem("moduleaccess");

  const { t } = useTranslation(); //for translation

  const docFileInputRef = useRef(null); //for file input

  //*------ common header object ------*/
  const commonHeaderObject = projectDetailsHeaderLinks(params.id, t);

  //*------ bread crumb text ------*/
  const breadCrumbText = [
    { title: t("Projects"), link: "/admin/projectmanagement/list" },
    { title: t("Details") },
  ];

  const todayValue = new Date().toISOString().split("T")[0];

  const [isLoading, setisLoading] = useState(false);

  const [projectTypeValue, setProjectTypeValue] = useState(null);
  const [projectTypeName, setprojectTypeName] = useState("");
  const [projectTypeSlug, setprojectTypeSlug] = useState("");
  const [title, settitle] = useState("");
  const [summary, setsummary] = useState("");
  const [details, setdetails] = useState("");
  const [startDate, setstartDate] = useState(todayValue);
  const [endDate, setendDate] = useState(todayValue);
  const [docFileUploading, setDocFileUploading] = useState(false);
  const [uploadedDocFiles, setUploadedDocFiles] = useState([]);
  const [uploadedDocFileIds, setUploadedDocFileIds] = useState([]);
  const [isSaving, setIsSaving] = useState(false);
  const [savedProjectId, setsavedProjectId] = useState(null);

  const [isProjectModerator, setisProjectModerator] = useState(false);
  const [projectModeratorId, setprojectModeratorId] = useState(null);

  const [projectMemberStatus, setProjectMemberStatus] = useState("1");
  const [inviteDate, setinviteDate] = useState("");
  const [loggedInMemberId, setLoggedInMemberId] = useState(null);
  const [isAccepting, setisAccepting] = useState(false);

  //* alert requirements
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [messageType, setMessageType] = useState("");

  //function for select taxonomy type
  const typeSelectionHandler = (val) => {
    if (val) {
      setProjectTypeValue(val);
      setprojectTypeName(val.label);
      setprojectTypeSlug(val.value);
    } else {
      setProjectTypeValue(null);
      setprojectTypeName("");
      setprojectTypeSlug("");
    }
  };

  //function for open doc imput
  const openDocFileInput = () => {
    docFileInputRef.current.click();
  };

  //function for upload doc files
  const docFileUploadHandler = async (e) => {
    try {
      setDocFileUploading(true);

      const docFiles = e.target.files;

      let requestUrl =
        url.API_BASE_URL + url.API_MULTIPLE_FILE_UPLOAD + `?token=${token}`;

      const response = await uploadMultipleFile(requestUrl, docFiles);

      if (response.status) {
        /*----- assign uploaded files with path for display start ------*/
        const uploadedFileValues = response.data.map(
          ({ name, path, _id, ...other }) => ({
            name,
            path,
            _id,
          })
        );

        //check old file exist or not if exist then new file will concat with old file
        if (uploadedDocFiles.length == 0) {
          setUploadedDocFiles(uploadedFileValues);
        } else {
          let fileArr = uploadedDocFiles.concat(uploadedFileValues);
          setUploadedDocFiles(fileArr);
        }
        /*----- assign uploaded files with path for display end ------*/

        setDocFileUploading(false);

        /*----- assign uploaded file ids start ------*/
        const fileIds = response.data.map(({ _id, ...other }) => ({ _id }));

        //check old file ids are exist or not.If exist then new id will concat with old id
        if (uploadedDocFileIds.length == 0) {
          setUploadedDocFileIds(fileIds);
        } else {
          let fileArr = uploadedDocFileIds.concat(fileIds);
          setUploadedDocFileIds(fileArr);
        }
        /*----- assign uploaded file ids end ------*/
      } else {
        setAlertMessage(response.message);
        setMessageType("error");
        setShowAlert(true);
      }
      resetDocInput();
    } catch (error) {
      setAlertMessage(error.message);
      setMessageType("error");
      setShowAlert(true);
    }
  };

  //function for delete file from upload stack
  const closeDocFileHandler = (index) => {
    const closedFile = [...uploadedDocFiles];
    const removedFileId = [...uploadedDocFileIds];

    closedFile.splice(index, 1);
    removedFileId.splice(index, 1);

    setUploadedDocFiles(closedFile);
    setUploadedDocFileIds(removedFileId);

    resetDocInput();
  };

  //function for reset doc inputs
  const resetDocInput = () => {
    const file = document.getElementById("uploadedProjectDoc");
    if (file) {
      file.value = null;
    } else {
      return;
    }
  };

  //function for get details of event member
  const getMemberDetails = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_PROJECT_MEMBER_DETAILS_QUERY +
        `?token=${token}&projectid=${params.id}`;

      const response = await getData(requestUrl);

      console.log(response);

      if (response.status) {
        if (response.data) {
          setLoggedInMemberId(response.data._id);
          setProjectMemberStatus(response.data.memberstatus);
          setinviteDate(createdAtToDateConverter(response.data.createdAt));
        }
      } else {
        console.log(response.message);
      }
    } catch (error) {
      setAlertMessage(error.message);
      setMessageType("error");
      setShowAlert(true);
    }
  };

  //function for get project details
  const getProjectDetails = async () => {
    try {
      setisLoading(true);
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_PROJECT_DETAILS +
        `/${params.id}?token=${token}`;

      const response = await getData(requestUrl);

      setisLoading(false);
      console.log("response", response);

      if (response.status && response.data) {
        const data = response.data;

        setprojectModeratorId(data.moderator._id);
        let isModerator = false;

        if (
          data.moderator._id.toString() === userInfo._id.toString() ||
          userInfo.role.slug === "ADMIN" ||
          userInfo.role.slug === "SUPER_ADMIN"
        ) {
          isModerator = true;
        } else {
          getMemberDetails();
        }

        setisProjectModerator(isModerator);

        settitle(data.title);
        setsummary(data.summary);
        setdetails(data.description);
        setstartDate(data.startdate);
        setendDate(data.enddate);
        //assign uploaded files
        setUploadedDocFiles(response?.data?.uploadedfiles);
        setUploadedDocFileIds(response?.data?.uploadedfileids);

        if (
          response?.data?.projecttypename &&
          response?.data?.projecttypeslug
        ) {
          typeSelectionHandler({
            label: response?.data?.projecttypename,
            value: response?.data?.projecttypeslug,
          });
        }
      } else {
        setAlertMessage(response.message);
        setMessageType("error");
        setShowAlert(true);
      }
    } catch (error) {
      setAlertMessage(error.message);
      setMessageType("error");
      setShowAlert(true);
    }
  };

  //accept invite
  const acceptMemberInviteHandler = async () => {
    try {
      setisAccepting(true);
      let memberProjectData = {
        memberstatus: "1",
      };

      let requestUrl =
        url.API_BASE_URL +
        url.API_ACCEPT_INVITE_PROJECT_MEMBER +
        `/${loggedInMemberId}` +
        `?token=${token}&projectmodid=${projectModeratorId}`;

      const response = await putData(requestUrl, memberProjectData);

      // console.log(response);

      setisAccepting(false);
      if (response.status) {
        getProjectDetails();
      } else {
        setAlertMessage(response.message);
        setMessageType("error");
        setShowAlert(true);
      }
    } catch (error) {
      setAlertMessage(error.message);
      setMessageType("error");
      setShowAlert(true);
    }
  };

  //function for save project
  const saveProjectHandler = async () => {
    try {
      setIsSaving(true);

      let projectData = {
        projecttypename: projectTypeName,
        projecttypeslug: projectTypeSlug,
        title,
        summary,
        description: details,
        startdate: startDate,
        enddate: endDate,
        uploads: uploadedDocFileIds,
      };

      let requestUrl = url.API_BASE_URL;

      let response = {};

      if (params.id) {
        requestUrl =
          requestUrl + url.API_UPDATE_PROJECT + `/${params.id}?token=${token}`;
        response = await putData(requestUrl, projectData);
      } else {
        requestUrl = requestUrl + url.API_ADD_NEW_PROJECT + `?token=${token}`;
        response = await postData(requestUrl, projectData);
      }

      // console.log(response);

      setIsSaving(false);

      if (response.status) {
        setMessageType("success");
        setsavedProjectId(response.data._id);
        const bootstrapModal = new bootstrap.Modal(
          document.getElementById("afterSaveProjectModal")
        );
        bootstrapModal.show();
      } else {
        setMessageType("error");
      }

      setAlertMessage(response.message);
    } catch (error) {
      setAlertMessage(error.message);
      setMessageType("error");
    }

    setShowAlert(true);
  };

  //function for reset
  const resetHandler = () => {
    settitle("");
    setsummary("");
    setdetails("");
    setstartDate("");
    setendDate("");
    setUploadedDocFiles([]);
    setUploadedDocFileIds([]);
  };

  //function for aftermodal close
  const afterSaveModalClose = (pageName) => {
    if (pageName == "details") {
      history.push(`/admin/projectmanagement/details/${savedProjectId}`);
    } else {
      resetHandler();
      history.push("/admin/projectmanagement/list");
    }
  };

  useEffect(() => {
    if (params.id) {
      getProjectDetails();
    }
  }, [params.id]);

  const onAlertClose = () => {
    setShowAlert(false);
    setAlertMessage("");
    setMessageType("");
  };

  if (
    userInfo.role.slug === "ADMIN" ||
    userInfo.role.slug === "SUPER_ADMIN" ||
    moduleAccess.includes("MOD_PROJECT")
  ) {
    return (
      <div id="content_wrapper">
        <section className="event-details-wrapper bg-white pb-5">
          {/* ------ common header -------- */}
          {params.id && (
            <TabsHeader
              commonHeaderObject={commonHeaderObject}
              activeOption={t("Details")}
            />
          )}

          {isLoading ? null : (
            <div className="container-fluid px-lg-5 pt-4 pt-md-0">
              {/* ------- bread crumb -------- */}
              <BreadCrumb
                breadCrumbText={breadCrumbText}
                bottom={true}
                displayName={`${title}`}
              />

              {/* -------- form area ---------- */}
              {params.id ? (
                isProjectModerator ? (
                  <ProjectForm
                    projectTypeValue={projectTypeValue}
                    typeSelectionHandler={typeSelectionHandler}
                    title={title}
                    settitle={settitle}
                    summary={summary}
                    setsummary={setsummary}
                    details={details}
                    setdetails={setdetails}
                    startDate={startDate}
                    setstartDate={setstartDate}
                    endDate={endDate}
                    setendDate={setendDate}
                    openDocFileInput={openDocFileInput}
                    docFileInputRef={docFileInputRef}
                    docFileUploading={docFileUploading}
                    uploadedDocFiles={uploadedDocFiles}
                    docFileUploadHandler={docFileUploadHandler}
                    closeDocFileHandler={closeDocFileHandler}
                    saveProjectHandler={saveProjectHandler}
                    isSaving={isSaving}
                    resetHandler={resetHandler}
                  />
                ) : (
                  <ProjectDetails
                    title={title}
                    summary={summary}
                    details={details}
                    startDate={startDate}
                    endDate={endDate}
                    uploadedDocFiles={uploadedDocFiles}
                    acceptMemberInviteHandler={acceptMemberInviteHandler}
                    inviteDate={inviteDate}
                    isAccepting={isAccepting}
                    projectMemberStatus={projectMemberStatus}
                  />
                )
              ) : (
                <ProjectForm
                  projectTypeValue={projectTypeValue}
                  typeSelectionHandler={typeSelectionHandler}
                  title={title}
                  settitle={settitle}
                  summary={summary}
                  setsummary={setsummary}
                  details={details}
                  setdetails={setdetails}
                  startDate={startDate}
                  setstartDate={setstartDate}
                  endDate={endDate}
                  setendDate={setendDate}
                  openDocFileInput={openDocFileInput}
                  docFileInputRef={docFileInputRef}
                  docFileUploading={docFileUploading}
                  uploadedDocFiles={uploadedDocFiles}
                  docFileUploadHandler={docFileUploadHandler}
                  closeDocFileHandler={closeDocFileHandler}
                  saveProjectHandler={saveProjectHandler}
                  isSaving={isSaving}
                  resetHandler={resetHandler}
                />
              )}
            </div>
          )}
          <AfterSaveProjectModal afterSaveModalClose={afterSaveModalClose} />

          {showAlert && (
            <AlertNotification
              showAlert={showAlert}
              message={alertMessage}
              alertType={messageType}
              onClose={onAlertClose}
            />
          )}
        </section>
      </div>
    );
  } else {
    return (
      <div id="content_wrapper">
        <section className="crm-wrapper bg-white pb-5">
          <div className="empty_access text-center">
            <div className="empty_pic mb-4">
              {" "}
              <img src={assetImages.emptyVector} alt="" />
            </div>
            <div className="empty_text">
              <p className="fs-lg text-gray fw-semibold mb-4">
                {t("Sorry....! You don't have privilege to see this content")}
              </p>
            </div>
          </div>
        </section>
      </div>
    );
  }
};

export default ProjectDetailBody;
