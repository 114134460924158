import React from "react";
import { Link } from "react-router-dom";
import { useDroppable } from "@dnd-kit/core";
import {
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";

import SortableItem from "./ColumnItem";
import { Height } from "@mui/icons-material";

const containerLabelStyle = {
  //marginRight: 5,
  flex: 1,
  minWidth: "350px",
};

const containerStyle = {
  //marginRight: 5,
  flex: 1,
  maxHeight: "500px",
  overflowY: "scroll",
  //minWidth: "350px",
};

const collapsedColumnStyle = {
  minHeight: "400px",
};

const BoardColumn = (props) => {
  const {
    id,
    items,
    columnlabel,
    setReloadData,
    setSelectedComponentId,
    setSelectedTagId,
    collapseColumnTags,
    setCollapseColumnTags,
    selectedItemEmails,
    setSelectedItemEmails,
  } = props;

  const { setNodeRef: droppableRef } = useDroppable({
    id,
  });

  // create new item in the column
  const createNewComponent = (tagid) => {
    console.log("createNewComponent for id", tagid);

    setSelectedTagId(tagid);
    setSelectedComponentId(null);
  };

  // collapse handler, if id is in the list, remove it, otherwise add it
  const collapseHandler = (id) => {
    if (collapseColumnTags.includes(id)) {
      setCollapseColumnTags(collapseColumnTags.filter((item) => item !== id));
    } else {
      setCollapseColumnTags([...collapseColumnTags, id]);
    }
  };

  return (
    <SortableContext
      id={id}
      items={items.map((item) => item.id)} // Pass only the ids to SortableContext
      strategy={verticalListSortingStrategy}
    >
      {collapseColumnTags.includes(id) ? (
        <div className="dev_card_collapsed" style={collapsedColumnStyle}>
          <div className="challenges_collapse p-3 rounded-5 text-center">
            <div className="collapse_arws">
              <Link
                to="#"
                className="bg-gray-300 rounded-5 p-1 d-inline-flex"
                onClick={() => {
                  collapseHandler(id);
                }}
              >
                <span className="material-symbols-outlined fs-lg">
                  chevron_right
                </span>
              </Link>
            </div>
            <div className="roate_text">
              <h5 className="text-uppercase text-black fs-md fw-semibold d-flex align-items-center gap-4 mb-0">
                {columnlabel} <span>{items.length}</span>
              </h5>
            </div>
          </div>
        </div>
      ) : (
        <div
          className=" d-flex flex-column gap-2 boardColumn-js"
          ref={droppableRef}
          style={containerLabelStyle}
        >
          <div className="card-title gradient-light p-3 rounded-10 d-flex justify-content-between align-items-center">
            <h3 className="lh-1 mb-0">{columnlabel}</h3>

            <div className="counting_arws d-flex align-items-center gap-3">
              <h4 className="text-primary fw-semibold fs-md mb-0">
                {items.length}
              </h4>
              <Link
                to="#"
                className="bg-gray-300 rounded-5 p-1"
                data-bs-toggle="offcanvas"
                data-bs-target="#saveLeadPopup"
                aria-controls="saveLeadPopup"
                onClick={() => {
                  createNewComponent(id);
                }}
              >
                <span className="material-symbols-outlined fs-lg">
                  add_circle
                </span>
              </Link>
              <Link
                to="#"
                className="bg-gray-300 rounded-5 p-1"
                onClick={() => {
                  collapseHandler(id);
                }}
              >
                <span className="material-symbols-outlined fs-lg">
                  chevron_left
                </span>
              </Link>
            </div>
          </div>

          <div style={containerStyle}>
            {items.map((item) => (
              <SortableItem
                key={item.id}
                id={item.id}
                item={item}
                setReloadData={setReloadData}
                setSelectedTagId={setSelectedTagId}
                setSelectedComponentId={setSelectedComponentId}
                selectedItemEmails={selectedItemEmails}
                setSelectedItemEmails={setSelectedItemEmails}
              />
            ))}
          </div>
        </div>
      )}
    </SortableContext>
  );
};

export default BoardColumn;
