import { Link } from "react-router-dom";

// translation
import { useTranslation } from "react-i18next";
import { getDateFormatInSpanish } from "helper/Common/CommonHelper";
import downloadFileHandler from "helper/DownloadFile";

const ProjectDetails = ({
  title = "",
  summary = "",
  details = "",
  startDate = "",
  endDate = "",
  uploadedDocFiles = [],
  acceptMemberInviteHandler = () => {},
  inviteDate = "",
  isAccepting = false,
  projectMemberStatus = "",
}) => {
  const { t } = useTranslation(); //for translation

  return (
    <div className="create-lead-container p-3 p-md-4 border border-gray-300 rounded-10 shadow-sm">
      {/* title */}
      <div
        className={`details_info_heading d-flex align-items-center justify-content-between ${
          projectMemberStatus === "1" ? "mb-4" : ""
        }`}
      >
        <h2 className="fw-bold mb-0 text-black">{title}</h2>
        {projectMemberStatus === "1" ? null : (
          <button
            onClick={acceptMemberInviteHandler}
            className="btn btn-primary"
            disabled={isAccepting ? true : false}
            style={{
              cursor: isAccepting ? "not-allowed" : "pointer",
            }}
          >
            {t("Accept Invite")}
            {isAccepting && (
              <div
                className="mx-2 spinner-border spinner-border-sm"
                role="status"
              >
                <span className="visually-hidden">Loading...</span>
              </div>
            )}
          </button>
        )}
      </div>

      {projectMemberStatus === "1" ? null : (
        <div className="d-flex justify-content-end mt-1 mb-4">
          <p className="fs-md mb-0 text-black">
            {t("Invitation sent on")} - {inviteDate}
          </p>
        </div>
      )}

      {/* summary */}
      <div className="descriptiion_bx mb-4">
        <h4 className="fs-sm mb-2 fw-semibold">{t("Summary")}</h4>
        <div className="details_info_paragraph">
          <p className="mb-4 text-black fs-md">{summary}</p>
        </div>
      </div>

      {/* description */}
      <div className="descriptiion_bx mb-4">
        <h4 className="fs-sm mb-2 fw-semibold">{t("Description")}</h4>
        <div className="details_info_paragraph">
          <p
            className="mb-4 text-black fs-md"
            dangerouslySetInnerHTML={{ __html: details }}
          ></p>
        </div>
      </div>

      {/* dates */}
      <div className="descriptiion_bx mb-4">
        <div className="info_date_outer d-flex flex-wrap gap-2 mt-4">
          <div className="info_date_bx body-bg rounded-10 px-3 py-2">
            <label className="mb-1 fs-sm text-black fw-regular">
              {t("Start Date")}
            </label>
            <h5 className="mb-0 text-black fs-sm fw-semibold">
              {getDateFormatInSpanish(startDate)}
            </h5>
          </div>
          <div className="info_date_bx body-bg rounded-10 px-3 py-2">
            <label className="mb-1 fs-sm text-black fw-regular">
              {t("End Date")}
            </label>
            <h5 className="mb-0 text-black fs-sm fw-semibold">
              {getDateFormatInSpanish(endDate)}
            </h5>
          </div>
        </div>
      </div>

      {/* documents */}

      <div className="documents">
        <h4 className="fs-sm mb-2 fw-semibold">{t("Documents")}</h4>
        <ul className="uploaded-files d-flex flex-wrap gap-3 p-0 m-0 mt-3">
          {uploadedDocFiles.map((file, index) => {
            return (
              <li
                className="file d-flex gap-3 align-items-center px-3 py-2 border border-gray rounded-3"
                key={index}
              >
                <p className="fw-medium">{file.name}</p>

                <Link to="#" onClick={() => downloadFileHandler(file)}>
                  <span className="d-block material-symbols-outlined">
                    download
                  </span>
                </Link>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default ProjectDetails;
