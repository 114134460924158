/* eslint-disable */
import { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

/*------ import url and method ------*/
import * as url from "helper/UrlHelper";
import { postData, getData, putData } from "utils/Gateway";

import BreadCrumb from "components/Common/BreadCrumb/BreadCrumb";
import AlertNotification from "components/Common/AlertNotification/AlertNotification";

import CategoryHeader from "../Header/CategoryHeader";
import SaveCategoryPopup from "../Popup/SaveCategoryPopup";
import CategoryFilterPopup from "../Popup/CategoryFilterPopup";
import { assetImages } from "constants";

const CategoryTreeBody = () => {
  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const ecosystemSlug = localStorage.getItem("ecosystemslug");
  const { t, i18n } = useTranslation(); //for translation

  const [parentbaseCategories, setParentBaseCategories] = useState([]);
  const [levelTwoCategories, setLevelTwoCategories] = useState([]);
  const [levelThreeCategories, setLevelThreeCategories] = useState([]);
  const [levelFourCategories, setLevelFourCategories] = useState([]);
  const [levelFiveCategories, setLevelFiveCategories] = useState([]);
  const [levelSixCategories, setLevelSixCategories] = useState([]);

  const [selectedBaseCategoryId, setselectedBaseCategoryId] = useState(null);
  const [selectedLevelTwoCategoryId, setselectedLevelTwoCategoryId] =
    useState(null);
  const [selectedLevelThreeCategoryId, setselectedLevelThreeCategoryId] =
    useState(null);
  const [selectedLevelFourCategoryId, setselectedLevelFourCategoryId] =
    useState(null);
  const [selectedLevelFiveCategoryId, setselectedLevelFiveCategoryId] =
    useState(null);
  const [selectedLevelSixCategoryId, setselectedLevelSixCategoryId] =
    useState(null);

  const [isReload, setIsReload] = useState(false);

  //alert requirements
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [messageType, setMessageType] = useState("");

  //function for get all tags
  const getAllCategories = async (
    isRoot = true,
    parentId = null,
    parentColumnSlug = ""
  ) => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_CATEGORIES +
        `?ecosystemslug=${ecosystemSlug}`;

      if (isRoot) {
        requestUrl = requestUrl + `&filterisroot=${true}`;
      }

      if (parentId) {
        requestUrl = requestUrl + `&filterparents=${parentId}`;
      }

      const response = await getData(requestUrl);

      console.log(response);

      if (response.status) {
        if (isRoot && parentColumnSlug === "") {
          setParentBaseCategories(
            response.data.sort(
              (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
            )
          );
        } else if (isRoot == false && parentColumnSlug === "BASE") {
          setLevelTwoCategories(
            response.data.sort(
              (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
            )
          );
        } else if (isRoot == false && parentColumnSlug === "LEVEL2") {
          setLevelThreeCategories(
            response.data.sort(
              (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
            )
          );
        } else if (isRoot == false && parentColumnSlug === "LEVEL3") {
          setLevelFourCategories(
            response.data.sort(
              (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
            )
          );
        } else if (isRoot == false && parentColumnSlug === "LEVEL4") {
          setLevelFiveCategories(
            response.data.sort(
              (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
            )
          );
        } else if (isRoot == false && parentColumnSlug === "LEVEL5") {
          setLevelSixCategories(
            response.data.sort(
              (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
            )
          );
        }
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const parentSelectionHandler = (item) => {
    setLevelTwoCategories([]);
    setLevelThreeCategories([]);
    setLevelFourCategories([]);
    setLevelFiveCategories([]);
    setLevelSixCategories([]);
    setselectedBaseCategoryId(item._id);
    setselectedLevelTwoCategoryId(null);
    setselectedLevelThreeCategoryId(null);
    setselectedLevelFourCategoryId(null);
    setselectedLevelFiveCategoryId(null);
    setselectedLevelSixCategoryId(null);
    getAllCategories(false, item._id, "BASE");
  };

  const childOneSelectionHandler = (item) => {
    setLevelThreeCategories([]);
    setLevelFourCategories([]);
    setLevelFiveCategories([]);
    setLevelSixCategories([]);
    setselectedLevelTwoCategoryId(item._id);
    setselectedLevelThreeCategoryId(null);
    setselectedLevelFourCategoryId(null);
    setselectedLevelFiveCategoryId(null);
    setselectedLevelSixCategoryId(null);
    getAllCategories(false, item._id, "LEVEL2");
  };

  const childTwoSelectionHandler = (item) => {
    setLevelFourCategories([]);
    setLevelFiveCategories([]);
    setLevelSixCategories([]);
    setselectedLevelThreeCategoryId(item._id);
    setselectedLevelFourCategoryId(null);
    setselectedLevelFiveCategoryId(null);
    setselectedLevelSixCategoryId(null);
    getAllCategories(false, item._id, "LEVEL3");
  };

  const childThreeSelectionHandler = (item) => {
    setLevelFiveCategories([]);
    setLevelSixCategories([]);
    setselectedLevelFourCategoryId(item._id);
    setselectedLevelFiveCategoryId(null);
    setselectedLevelSixCategoryId(null);
    getAllCategories(false, item._id, "LEVEL4");
  };

  const childFourSelectionHandler = (item) => {
    setLevelSixCategories([]);
    setselectedLevelFiveCategoryId(item._id);
    setselectedLevelSixCategoryId(null);
    getAllCategories(false, item._id, "LEVEL5");
  };

  const childFiveSelectionHandler = (item) => {
    setselectedLevelSixCategoryId(item._id);
  };

  const CategoryColumn = ({ items = {}, selected, onSelect, isActive }) => (
    <div className="col-lg border-0 border-bottom border-bottom-lg-0 border-lg-end border-solid border-gray-300">
      {items && (
        <ul className="fs-md fw-semibold py-3">
          {items.map((item, index) => (
            <li key={index}>
              <Link
                to="#"
                className={`text-gray py-2 d-flex align-items-center gap-3 justify-content-between ${
                  selected === item._id ? "text-primary" : ""
                }`}
                onClick={() => onSelect(item)}
              >
                <span className="d-block">{item.name}</span>
                <span className="d-block material-symbols-outlined">
                  chevron_right
                </span>
              </Link>
            </li>
          ))}
        </ul>
      )}
    </div>
  );

  //reset all parent categories
  const resetAlllevelHandler = () => {
    setLevelTwoCategories([]);
    setLevelThreeCategories([]);
    setLevelFourCategories([]);
    setLevelFiveCategories([]);
    setLevelSixCategories([]);
    setselectedBaseCategoryId(null);
    setselectedLevelTwoCategoryId(null);
    setselectedLevelThreeCategoryId(null);
    setselectedLevelFourCategoryId(null);
    setselectedLevelFiveCategoryId(null);
    setselectedLevelSixCategoryId(null);
    setIsReload(true);
  };

  useEffect(() => {
    if (isReload) {
      getAllCategories();
      setIsReload(false);
    }
  }, [isReload]);

  useEffect(() => {
    getAllCategories();
  }, []);

  const onAlertClose = () => {
    setShowAlert(false);
    setAlertMessage("");
    setMessageType("");
  };

  if (userInfo.role.slug === "ADMIN" || userInfo.role.slug === "SUPER_ADMIN") {
    return (
      <div id="content_wrapper">
        <section className="crm-wrapper bg-white pb-5">
          <div className="container-fluid px-lg-5">
            <CategoryHeader reloadLabelList={resetAlllevelHandler} />

            <div className="table-wrapper">
              <div className="categories-container px-4 border border-gray-300 rounded-10 shadow-sm mb-3">
                <div className="row gx-5">
                  {/* Parent Categories */}
                  <CategoryColumn
                    items={parentbaseCategories}
                    onSelect={parentSelectionHandler}
                    selected={selectedBaseCategoryId}
                  />

                  {/* Child1 Categories */}
                  <CategoryColumn
                    items={levelTwoCategories}
                    onSelect={childOneSelectionHandler}
                    selected={selectedLevelTwoCategoryId}
                  />

                  {/* Child2 Categories */}
                  <CategoryColumn
                    items={levelThreeCategories}
                    onSelect={childTwoSelectionHandler}
                    selected={selectedLevelThreeCategoryId}
                  />

                  {/* Child3 Categories */}
                  <CategoryColumn
                    items={levelFourCategories}
                    onSelect={childThreeSelectionHandler}
                    selected={selectedLevelFourCategoryId}
                  />

                  {/* Child4 Categories */}
                  <CategoryColumn
                    items={levelFiveCategories}
                    onSelect={childFourSelectionHandler}
                    selected={selectedLevelFiveCategoryId}
                  />

                  {/* Child5 Categories */}
                  <CategoryColumn
                    items={levelSixCategories}
                    onSelect={childFiveSelectionHandler}
                    selected={selectedLevelSixCategoryId}
                  />

                  {showAlert && (
                    <AlertNotification
                      showAlert={showAlert}
                      message={alertMessage}
                      alertType={messageType}
                      onClose={onAlertClose}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* ==== popup and modal section ==== */}
        <SaveCategoryPopup afterPopupCLose={getAllCategories} />
      </div>
    );
  } else {
    return (
      <div id="content_wrapper">
        <section className="crm-wrapper bg-white pb-5">
          <div className="empty_access text-center">
            <div className="empty_pic mb-4">
              {" "}
              <img src={assetImages.emptyVector} alt="" />
            </div>
            <div className="empty_text">
              <p className="fs-lg text-gray fw-semibold mb-4">
                {t("Sorry....! You don't have privilege to see this content")}
              </p>
            </div>
          </div>
        </section>
      </div>
    );
  }
};

export default CategoryTreeBody;
