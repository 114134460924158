/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";

/*import url and gateway methods */
import { getData, postData, putData } from "utils/Gateway";
import * as url from "helper/UrlHelper";

import { useTranslation } from "react-i18next";

const SaveOffersPopup = ({
  afterPopupCLose = () => {},
  pricingId = null,
  setPricingId = () => {},
  setShowAlert = () => {},
  setAlertMessage = () => {},
  setMessageType = () => {},
}) => {
  const token = localStorage.getItem("token"); // token
  const { t } = useTranslation(); //for translation

  const { id } = useParams();

  const [variant, setVariant] = useState("");
  const [price, setPrice] = useState("");
  const [discountedPrice, setDiscountedPrice] = useState("");
  const [unit, setUnit] = useState("");
  const [sku, setSku] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [isActive, setisActive] = useState(false);

  const [isSaving, setIsSaving] = useState(false);

  /* hook for validation */
  const [validation, setValidation] = useState({
    nameWarning: false,
    skuWarning: false,
    priceWarning: false,
    discountedPriceWarning: false,
    unitWarning: false,
    startDateWarning: false,
    endDateWarning: false,
    dateDiffWarning: false,
  });

  /* function to validate form */
  const validationHandler = () => {
    let isValid = true;

    if (variant === "") {
      setValidation((prevState) => ({ ...prevState, nameWarning: true }));
      isValid = false;
    }

    if (sku === "") {
      setValidation((prevState) => ({ ...prevState, skuWarning: true }));
      isValid = false;
    }

    if (price === "") {
      setValidation((prevState) => ({ ...prevState, priceWarning: true }));
      isValid = false;
    }

    if (discountedPrice === "") {
      setValidation((prevState) => ({
        ...prevState,
        discountedPriceWarning: true,
      }));
      isValid = false;
    }

    if (unit === "") {
      setValidation((prevState) => ({ ...prevState, unitWarning: true }));
      isValid = false;
    }

    if (startDate === "") {
      setValidation((prevState) => ({ ...prevState, startDateWarning: true }));
      isValid = false;
    }

    if (endDate === "") {
      setValidation((prevState) => ({ ...prevState, endDateWarning: true }));
      isValid = false;
    }

    if (startDate !== "" && endDate !== "") {
      if (startDate > endDate) {
        setValidation((prevState) => ({ ...prevState, dateDiffWarning: true }));
        isValid = false;
      }
    }

    return isValid;
  };

  //get details
  const getDetails = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_PRODUCT_PRICING_DETAILS +
        `/${pricingId}` +
        `?token=${token}`;

      const response = await getData(requestUrl);

      console.log(response);

      if (response.status) {
        const data = response.data;

        setVariant(data.variant);
        setPrice(data.price);
        setDiscountedPrice(data.discountprice);
        setUnit(data.stock);
        setSku(data.sku);
        setStartDate(data.startdate);
        setEndDate(data.enddate);
        setisActive(data.isactive);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for create new tag
  const saveOfferHandler = async () => {
    if (validationHandler()) {
      setIsSaving(true);
      try {
        let sendingData = {
          productid: id,
          variant,
          price,
          discountprice: discountedPrice,
          stock: unit,
          sku,
          startdate: startDate,
          enddate: endDate,
          isactive: isActive,
        };

        let requestUrl = url.API_BASE_URL;

        let response = {};

        if (pricingId) {
          requestUrl =
            requestUrl +
            url.API_UPDATE_PRODUCT_PRICING +
            `/${pricingId}` +
            `?token=${token}`;

          response = await putData(requestUrl, sendingData);
        } else {
          requestUrl =
            requestUrl + url.API_ADD_PRODUCT_PRICING + `?token=${token}`;

          response = await postData(requestUrl, sendingData);
        }

        setIsSaving(false);
        console.log(response);

        if (response.status) {
          // close the modal of addLabel
          bootstrap.Offcanvas.getInstance(
            document.querySelector("#offcanvasSaveOffer")
          ).hide();
          resetHandler();
          setMessageType("success");
          setAlertMessage(response.message);
          setTimeout(() => {
            afterPopupCLose();
          }, 1500);
        }
      } catch (error) {
        console.log(error.message);
        setMessageType("error");
        setAlertMessage(error.message);
      }
    } else {
      setMessageType("error");
      setAlertMessage(t("Please fill all the required fields."));
    }
    setShowAlert(true);
  };

  /* reset */
  const resetHandler = () => {
    setVariant("");
    setPrice("");
    setDiscountedPrice("");
    setUnit("");
    setSku("");
    setStartDate("");
    setEndDate("");
    setisActive(false);

    setPricingId(null);

    setValidation({
      nameWarning: false,
      skuWarning: false,
      priceWarning: false,
      discountedPriceWarning: false,
      unitWarning: false,
      startDateWarning: false,
      endDateWarning: false,
      dateDiffWarning: false,
    });
  };

  useEffect(() => {
    if (pricingId) {
      getDetails();
    }
  }, [pricingId]);

  return (
    <div
      className="offcanvas lg offcanvas-end bg-white border-0"
      tabIndex="-1"
      id="offcanvasSaveOffer"
      aria-labelledby="offcanvasSaveOffer"
    >
      <div className="offcanvas-header p-4 pb-0">
        <h3 className="offcanvas-title" id="">
          {t("Save Offers")}
        </h3>
        <button
          type="button"
          className="btn-close p-0 bg-gray-200 rounded-circle shadow-none m-0"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          onClick={resetHandler}
        ></button>
      </div>
      <div className="offcanvas-body p-4">
        <form
          onSubmit={(e) => e.preventDefault()}
          className="d-flex flex-column h-100"
        >
          <div className="fields-container flex-fill">
            <div className="form-group mb-4">
              <label
                htmlFor="labelName"
                className="d-block fs-sm fw-semibold mb-2"
              >
                {t("Variant Name")}
                <span className="text-danger fw-bold">*</span>
              </label>
              <input
                type="text"
                id="labelName"
                className="form-control fs-sm shadow-none"
                placeholder={t("Enter here")}
                value={variant}
                onChange={(e) => {
                  setVariant(e.target.value);
                  setValidation((prevState) => ({
                    ...prevState,
                    nameWarning: false,
                  }));
                }}
              />

              {validation.nameWarning && (
                <div className="error-message mt-2">
                  <p className="d-flex align-items-center gap-1 text-danger">
                    <span className="material-symbols-outlined">warning</span>
                    <span>{t("Please enter name")}!</span>
                  </p>
                </div>
              )}
            </div>

            {/* price */}
            <div className="form-group mb-4">
              <label htmlFor="price" className="d-block fs-sm fw-semibold mb-2">
                {t("Price")} <span className="text-danger fw-bold">*</span>
              </label>
              <input
                min={1}
                type="number"
                className="form-control fs-sm shadow-none"
                placeholder={t("Enter price")}
                value={price}
                onChange={(e) => {
                  setPrice(e.target.value);
                  setValidation((prevState) => ({
                    ...prevState,
                    priceWarning: false,
                  }));
                }}
              />

              {validation.priceWarning && (
                <div className="error-message mt-2">
                  <p className="d-flex align-items-center gap-1 text-danger">
                    <span className="material-symbols-outlined">warning</span>
                    <span>{t("Please enter price")}!</span>
                  </p>
                </div>
              )}
            </div>

            {/* discount price  */}
            <div className="form-group mb-4">
              <label htmlFor="price" className="d-block fs-sm fw-semibold mb-2">
                {t("Discount Price")}{" "}
                <span className="text-danger fw-bold">*</span>
              </label>
              <input
                type="number"
                className="form-control fs-sm shadow-none"
                placeholder={t("Enter discount price")}
                value={discountedPrice}
                onChange={(e) => {
                  setDiscountedPrice(e.target.value);
                  setValidation((prevState) => ({
                    ...prevState,
                    discountedPriceWarning: false,
                  }));
                }}
              />

              {validation.discountedPriceWarning && (
                <div className="error-message mt-2">
                  <p className="d-flex align-items-center gap-1 text-danger">
                    <span className="material-symbols-outlined">warning</span>
                    <span>{t("Please enter discount price")}!</span>
                  </p>
                </div>
              )}
            </div>

            <div className="row  mb-4">
              <div className="col-6">
                <div className="form-group">
                  <label
                    htmlFor="date-range"
                    className="d-block fs-sm fw-semibold mb-2"
                  >
                    {t("Unit")} <span className="text-danger fw-bold">*</span>
                  </label>
                  <input
                    min={1}
                    type="number"
                    id="slug"
                    className="form-control fs-sm shadow-none"
                    placeholder={t("Enter unit")}
                    value={unit}
                    onChange={(e) => {
                      setUnit(e.target.value);
                      setValidation((prevState) => ({
                        ...prevState,
                        unitWarning: false,
                      }));
                    }}
                  />

                  {validation.unitWarning && (
                    <div className="error-message mt-2">
                      <p className="d-flex align-items-center gap-1 text-danger">
                        <span className="material-symbols-outlined">
                          warning
                        </span>
                        <span>{t("Please enter unit")}!</span>
                      </p>
                    </div>
                  )}
                </div>
              </div>
              <div className="col-6">
                <div className="form-group">
                  <label
                    htmlFor="date-range"
                    className="d-block fs-sm fw-semibold mb-2"
                  >
                    {t("Sku")} <span className="text-danger fw-bold">*</span>
                  </label>
                  <input
                    type="text"
                    id="slug"
                    className="form-control fs-sm shadow-none"
                    placeholder={t("Enter sku")}
                    value={sku}
                    onChange={(e) => {
                      setSku(e.target.value);
                      setValidation((prevState) => ({
                        ...prevState,
                        skuWarning: false,
                      }));
                    }}
                  />

                  {validation.skuWarning && (
                    <div className="error-message mt-2">
                      <p className="d-flex align-items-center gap-1 text-danger">
                        <span className="material-symbols-outlined">
                          warning
                        </span>
                        <span>{t("Please enter sku")}!</span>
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="form-group mb-4">
              <label
                htmlFor="date-range"
                className="d-block fs-sm fw-semibold mb-2"
              >
                {t("Date Range")} <span className="text-danger fw-bold">*</span>
              </label>
              <div className="d-flex gap-3">
                <div className="w-50">
                  <input
                    type="date"
                    id="from-date"
                    className="form-control fs-sm shadow-none"
                    placeholder={t("From Date")}
                    value={startDate}
                    onChange={(e) => {
                      setStartDate(e.target.value);
                      setValidation((prevState) => ({
                        ...prevState,
                        startDateWarning: false,
                        dateDiffWarning: false,
                      }));
                    }}
                  />
                  {validation.startDateWarning && (
                    <div className="error-message mt-2">
                      <p className="d-flex align-items-center gap-1 text-danger">
                        <span className="material-symbols-outlined">
                          warning
                        </span>
                        <span>{t("Please enter start date")}!</span>
                      </p>
                    </div>
                  )}
                </div>
                <div className="w-50">
                  <input
                    type="date"
                    id="to-date"
                    className="form-control fs-sm shadow-none"
                    placeholder={t("To Date")}
                    value={endDate}
                    onChange={(e) => {
                      setEndDate(e.target.value);
                      setValidation((prevState) => ({
                        ...prevState,
                        endDateWarning: false,
                        dateDiffWarning: false,
                      }));
                    }}
                  />
                  {validation.skuWarning && (
                    <div className="error-message mt-2">
                      <p className="d-flex align-items-center gap-1 text-danger">
                        <span className="material-symbols-outlined">
                          warning
                        </span>
                        <span>{t("Please enter sku")}!</span>
                      </p>
                    </div>
                  )}
                </div>
              </div>

              {validation.dateDiffWarning && (
                <div className="error-message mt-2">
                  <p className="d-flex align-items-center gap-1 text-danger">
                    <span className="material-symbols-outlined">warning</span>
                    <span>{t("Please enter valid date")}!</span>
                  </p>
                </div>
              )}
            </div>

            {/* is active */}
            <div className="form-check d-flex align-items-center gap-2 fs-sm p-0 m-0 mb-4">
              <input
                className="form-check-input bg-transparent border-2 border-gray-600 shadow-none m-0"
                type="checkbox"
                checked={isActive}
                onChange={(e) => {
                  setisActive(e.target.checked);
                }}
              />
              <label className="form-check-label" htmlFor="private">
                {t("Is Active")}
              </label>
            </div>
          </div>
          <div className="action d-flex justify-content-between gap-3">
            <Link
              to="#"
              className="btn btn-outline-primary"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
              onClick={resetHandler}
            >
              {t("Close")}
            </Link>
            <button
              onClick={saveOfferHandler}
              type="button"
              className="btn btn-primary"
              disabled={isSaving ? true : false}
              style={{
                cursor: isSaving ? "not-allowed" : "pointer",
              }}
            >
              {t("Save")}
              {isSaving && (
                <div
                  className="mx-2 spinner-border spinner-border-sm"
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SaveOffersPopup;
