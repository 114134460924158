/* eslint-disable */
import { useState, useEffect, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Select from "react-select";

//**------ import url and method ------*/
import * as url from "helper/UrlHelper";
import { getData } from "utils/Gateway";

import { reactSelectStyle } from "Config/Config";

const ResponseCommentPopup = ({
  selectedHiringProcessId = null,
  setSelectedHiringProcessId = () => {},
  kpisBlock = [],
  approveComments = "",
  setapproveComments = () => {},
  setKpisBlock = () => {},
  addNewKpisBlock = () => {},
  onDeleteKpisBlock = () => {},
  changeSkillPointInputs = () => {},
  selectKpisSkills = () => {},
  approvalHandler = () => {},
  approvalStatus = "",
}) => {
  const token = localStorage.getItem("token");

  const { t } = useTranslation();

  const [allSkills, setAllSkills] = useState([]);

  //get hiring process details
  const getProcessDetails = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_JOB_PROCESS_DETAILS +
        `/${selectedHiringProcessId}?token=${token}`;

      const response = await getData(requestUrl);

      console.log(response);

      if (response.status && response.data) {
        const data = response.data;

        const kpisOptions = data.kpisarr.map((kpis) => kpis.skillpointvalue);

        setAllSkills(kpisOptions);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const resetHandler = () => {
    setapproveComments("");
    setKpisBlock([]);
    setSelectedHiringProcessId(null);
  };

  useEffect(() => {
    if (selectedHiringProcessId) {
      getProcessDetails();
    }
  }, [selectedHiringProcessId]);

  useEffect(() => {
    resetHandler();
  }, []);

  return (
    <div
      className="offcanvas md offcanvas-end bg-white border-0"
      tabIndex="-1"
      id="offcanvasCommentYourThought"
      aria-labelledby="offcanvasCommentYourThoughtLabel"
      data-bs-backdrop="static"
    >
      <div className="offcanvas-header p-4 pb-0">
        <h5 className="offcanvas-title" id="offcanvasCommentYourThoughtLabel">
          Comment Your Thoughts
        </h5>
        <button
          type="button"
          className="btn-close p-0 bg-gray-200 rounded-circle shadow-none m-0"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          onClick={resetHandler}
        ></button>
      </div>
      <div className="offcanvas-body px-4">
        <form onSubmit={(e) => e.preventDefault()}>
          <div className="form-group mb-4">
            <label htmlFor="comment" className="d-block fs-sm fw-semibold mb-2">
              Add a note about why decided to
              <span className="text-success">
                {" "}
                {approvalStatus === "1" ? "promote" : ""}{" "}
              </span>
              <span className="text-danger">
                {" "}
                {approvalStatus === "2" ? "reject" : ""}{" "}
              </span>
              this applicantion?
            </label>
            <textarea
              name="comment"
              id="comment"
              cols="30"
              rows="5"
              className="form-control shadow-none"
              placeholder="Enter Comments..."
              value={approveComments}
              onChange={(e) => {
                setapproveComments(e.target.value);
              }}
            ></textarea>
          </div>

          {kpisBlock.map((kpisData, index) => {
            return (
              <div
                className="form-group p-3 bg-gray-500 mb-2 action_link_block_bg"
                key={index}
              >
                {/* ------ skill point link close button start ------ */}
                <div className="d-flex align-items-center justify-content-end">
                  <Link
                    to="#"
                    className="text-secondary"
                    onClick={() => {
                      onDeleteKpisBlock(index);
                    }}
                  >
                    <span className="material-symbols-outlined">close</span>
                  </Link>
                </div>
                {/* ------ skill point link close button end ------ */}

                <div className="row">
                  <div className="col-lg-6 mb-4 mb-lg-0">
                    <label
                      htmlFor="kpiName"
                      className="d-block fs-sm fw-semibold mb-2"
                    >
                      {t("Select Skill")}
                    </label>
                    <Select
                      styles={reactSelectStyle("3px", "6px", true, false)}
                      components={{ IndicatorSeparator: () => null }}
                      isClearable
                      placeholder={t("Select skill")}
                      options={allSkills}
                      value={kpisData.skillValue}
                      onChange={(value) => {
                        selectKpisSkills(
                          index,
                          "skillValue",
                          "selectedSkill",
                          value
                        );
                      }}
                    />
                  </div>
                  <div className="col-lg-6 mb-4 mb-lg-0">
                    <label
                      htmlFor="points"
                      className="d-block fs-sm fw-semibold mb-2"
                    >
                      {t("Points")}
                    </label>
                    <div className="field-container d-flex align-items-center body-bg border border-gray-bg rounded-10">
                      <input
                        type="text"
                        className="form-control fs-sm border-0 shadow-none bg-white w-100"
                        placeholder={t("Point")}
                        value={kpisData.point}
                        onChange={(e) => {
                          changeSkillPointInputs(
                            index,
                            "point",
                            e.target.value
                          );
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            );
          })}

          <Link
            to="#"
            className={
              selectedHiringProcessId && allSkills.length > 0
                ? "text-primary d-inline-flex align-items-center gap-1"
                : "d-none"
            }
            onClick={addNewKpisBlock}
          >
            <span className="d-block material-symbols-outlined">add</span>
            <span className="d-block">{t("Add KPIs")}</span>
          </Link>

          <div className="previous-comments">
            <div className="action d-flex justify-content-between flex-wrap gap-3 mt-4">
              <button
                className="btn btn-outline-primary"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
                onClick={resetHandler}
              >
                Cancel
              </button>
              <button
                className="btn btn-primary"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
                onClick={approvalHandler}
              >
                Submit
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ResponseCommentPopup;
