/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";

/*import url and gateway methods */
import { getData, postData, putData } from "utils/Gateway";
import * as url from "helper/UrlHelper";

//import context
import { GlobalProvider } from "context/GlobalContext";
import { useTranslation } from "react-i18next";

const SaveInventoryPopup = ({
  afterPopupCLose = () => {},
  inventoryId = null,
  setInventoryId = () => {},
}) => {
  const token = localStorage.getItem("token"); // token
  const { t } = useTranslation(); //for translation

  const [productInput, setproductInput] = useState("");
  const [productList, setproductList] = useState([]);

  const [addressInput, setaddressInput] = useState("");
  const [addressList, setaddressList] = useState([]);

  const [productValue, setproductValue] = useState(null);
  const [productId, setProductId] = useState(null);
  const [addressvalue, setaddressvalue] = useState(null);
  const [addressId, setaddressId] = useState(null);
  const [stockAdded, setstockAdded] = useState("");
  const [supplier, setsupplier] = useState("");
  const [isSaving, setIsSaving] = useState(false);

  /* hook for validation */
  const [validation, setValidation] = useState({
    productWarning: false,
    addressWarning: false,
    stockWarning: false,
    supplierWarning: false,
  });

  /* function to validate form */
  const validationHandler = () => {
    let isValid = true;

    if (productId === null) {
      setValidation((prevState) => ({ ...prevState, productWarning: true }));
      isValid = false;
    }

    // if (addressId === null) {
    //   setValidation((prevState) => ({ ...prevState, addressWarning: true }));
    //   isValid = false;
    // }

    if (stockAdded === "" || stockAdded === 0) {
      setValidation((prevState) => ({ ...prevState, stockWarning: true }));
      isValid = false;
    }

    // if (supplier === "") {
    //   setValidation((prevState) => ({ ...prevState, supplierWarning: true }));
    //   isValid = false;
    // }

    return isValid;
  };

  //function for product list
  const getProductList = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_ALL_PRODUCTS_LIST +
        `?token=${token}&title=${productInput}`;

      const response = await getData(requestUrl);

      console.log("response in product list------>", response);

      if (response.status) {
        setproductList(response.data);
      }
    } catch (error) {}
  };

  //fucntion for get addresslist
  const getAddressList = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL + url.API_GET_WAREHOUSE_ADDRESS + `?token=${token}`;

      const response = await getData(requestUrl);

      if (response.status) {
        setaddressList(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //select product
  const productSelectionHandler = (val) => {
    setValidation((prevState) => ({ ...prevState, productWarning: false }));
    if (val) {
      setproductValue(val);
      setProductId(val.value);
    } else {
      setproductInput("");
      setproductList([]);
      setproductValue(null);
      setProductId(null);
    }
  };

  //select address
  const addressSelectionHandler = (val) => {
    setValidation((prevState) => ({ ...prevState, addressWarning: false }));
    if (val) {
      setaddressvalue(val);
      setaddressId(val.value);
    } else {
      setaddressvalue(null);
      setaddressId(null);
    }
  };

  //get details
  const getDetails = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_INVENTORY_DETAILS +
        `/${inventoryId}` +
        `?token=${token}`;

      const response = await getData(requestUrl);

      console.log(response);

      if (response.status) {
        const data = response.data;

        if (data.productvalue) {
          productSelectionHandler(data.productvalue);
        }

        if (data.addressvalue) {
          addressSelectionHandler(data.addressvalue);
        }

        setstockAdded(data.stockadded);
        setsupplier(data.supplier);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for create new tag
  const saveInventoryHandler = async () => {
    if (validationHandler()) {
      setIsSaving(true);
      try {
        let sendingData = {
          productid: productId,
          warehouse: addressId,
          supplier,
          stockadded: stockAdded,
        };

        let requestUrl = url.API_BASE_URL;

        let response = {};

        if (inventoryId) {
          requestUrl =
            requestUrl +
            url.API_UPDATE_INVENTORY +
            `/${inventoryId}` +
            `?token=${token}`;

          response = await putData(requestUrl, sendingData);
        } else {
          requestUrl =
            requestUrl + url.API_CREATE_INVENTORY + `?token=${token}`;

          response = await postData(requestUrl, sendingData);
        }

        setIsSaving(false);
        console.log(response);

        if (response.status) {
          // close the modal of addLabel
          bootstrap.Offcanvas.getInstance(
            document.querySelector("#offcanvasSaveInventory")
          ).hide();
          resetHandler();
          afterPopupCLose();
        }
      } catch (error) {
        console.log(error.message);
      }
    }
  };

  /* reset */
  const resetHandler = () => {
    setproductInput("");
    setproductList([]);
    setaddressId(null);
    setaddressvalue(null);
    setProductId(null);
    setproductValue(null);
    setstockAdded("");
    setsupplier("");

    setInventoryId(null);

    setValidation({
      productWarning: false,
      addressWarning: false,
      stockWarning: false,
      supplierWarning: false,
    });
  };

  useEffect(() => {
    if (inventoryId) {
      getDetails();
    }
  }, [inventoryId]);

  useEffect(() => {
    getAddressList();
  }, []);

  useEffect(() => {
    if (productInput.length > 1) {
      getProductList();
    }
  }, [productInput]);

  return (
    <div
      className="offcanvas lg offcanvas-end bg-white border-0"
      tabIndex="-1"
      id="offcanvasSaveInventory"
      aria-labelledby="offcanvasSaveInventory"
    >
      <div className="offcanvas-header p-4 pb-0">
        <h3 className="offcanvas-title" id="">
          {t("Save Stock to Inventory")}
        </h3>
        <button
          type="button"
          className="btn-close p-0 bg-gray-200 rounded-circle shadow-none m-0"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          onClick={resetHandler}
        ></button>
      </div>
      <div className="offcanvas-body p-4">
        <form
          onSubmit={(e) => e.preventDefault()}
          className="d-flex flex-column h-100"
        >
          <div className="form-group mb-4">
            <label
              htmlFor="productName"
              className="d-block fs-sm fw-semibold mb-2"
            >
              {t("Product Name")}
            </label>
            <Select
              isClearable
              placeholder={t("Select Product")}
              options={productList}
              value={productValue}
              onChange={productSelectionHandler}
              onInputChange={(val) => {
                setproductInput(val);
              }}
            />
            {validation.productWarning && (
              <div className="error-message mt-2">
                <p className="d-flex align-items-center gap-1 text-danger">
                  <span className="material-symbols-outlined">warning</span>
                  <span>{t("Please select product")}!</span>
                </p>
              </div>
            )}
          </div>

          <div className="form-group mb-4">
            <label
              htmlFor="warehouse"
              className="d-block fs-sm fw-semibold mb-2"
            >
              {t("Warehouse")}
            </label>
            <Select
              isClearable
              placeholder={t("Select Warehouse")}
              options={addressList}
              value={addressvalue}
              onChange={addressSelectionHandler}
            />
            {validation.addressWarning && (
              <div className="error-message mt-2">
                <p className="d-flex align-items-center gap-1 text-danger">
                  <span className="material-symbols-outlined">warning</span>
                  <span>{t("Please select warehouse")}!</span>
                </p>
              </div>
            )}
          </div>

          <div className="form-group mb-4">
            <label
              htmlFor="currentStock"
              className="d-block fs-sm fw-semibold mb-2"
            >
              {t("Stock Added")}
            </label>
            <input
              type="number"
              id="currentStock"
              className="form-control fs-sm shadow-none"
              value={stockAdded}
              onChange={(e) => {
                setstockAdded(e.target.value);
                setValidation((prevState) => ({
                  ...prevState,
                  stockWarning: false,
                }));
              }}
            />
            {validation.stockWarning && (
              <div className="error-message mt-2">
                <p className="d-flex align-items-center gap-1 text-danger">
                  <span className="material-symbols-outlined">warning</span>
                  <span>{t("Please enter stock")}!</span>
                </p>
              </div>
            )}
          </div>

          <div className="form-group mb-4">
            <label
              htmlFor="supplier"
              className="d-block fs-sm fw-semibold mb-2"
            >
              {t("Supplier")}
            </label>
            <input
              type="text"
              id="currentStock"
              className="form-control fs-sm shadow-none"
              value={supplier}
              onChange={(e) => {
                setsupplier(e.target.value);
                setValidation((prevState) => ({
                  ...prevState,
                  supplierWarning: false,
                }));
              }}
            />
            {validation.supplierWarning && (
              <div className="error-message mt-2">
                <p className="d-flex align-items-center gap-1 text-danger">
                  <span className="material-symbols-outlined">warning</span>
                  <span>{t("Please enter Supplier")}!</span>
                </p>
              </div>
            )}
          </div>

          <div className="action d-flex justify-content-between gap-3">
            <Link
              to="#"
              className="btn btn-outline-primary"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
              onClick={resetHandler}
            >
              {t("Close")}
            </Link>
            <button
              type="button"
              className="btn btn-primary"
              onClick={saveInventoryHandler}
              style={{
                cursor: isSaving ? "not-allowed" : "pointer",
              }}
              disabled={isSaving ? true : false}
            >
              {t("Save")}
              {isSaving && (
                <div
                  className="mx-2 spinner-border spinner-border-sm"
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SaveInventoryPopup;
