/* eslint-disable */
import React from "react";
import { Link } from "react-router-dom";

//* import translations
import { useTranslation, Trans } from "react-i18next";

const CommonHeader = ({ activeOption }) => {
  /* ------------- Language translation imports starts here ------------- */
  const { t, i18n } = useTranslation();

  const commonHeaderObject = [];

  // add product tab
  const productTab = {
    title: t("Products"),
    link: "/admin/marketplace/products/list",
  };
  commonHeaderObject.push(productTab);

  // add inventory tab
  const inventoryTab = {
    title: t("Inventory"),
    link: "/admin/marketplace/inventory/list",
  };
  commonHeaderObject.push(inventoryTab);

  // add orders tab
  const ordersTab = {
    title: t("Orders"),
    link: "/admin/marketplace/orders/list",
  };
  commonHeaderObject.push(ordersTab);

  // add invoices tab
  const invoicesTab = {
    title: t("Invoices"),
    link: "/admin/marketplace/invoices/list",
  };
  commonHeaderObject.push(invoicesTab);

  // add setting tab
  const settingTab = {
    title: t("Settings"),
    link: "/admin/marketplace/settings",
  };
  commonHeaderObject.push(settingTab);

  return (
    <div className="inner-menu-container bg-white shadow-sm">
      <div className="container-fluid px-lg-5">
        <ul className="nav secondary-menu mw-100 flex-nowrap gap-xl-5 flex-fill fs-sm fw-semibold text-center border-0 text-nowrap overflow-auto">
          {/* ------- map loop -------- */}
          {commonHeaderObject.map((item, index) => {
            return (
              <li key={index} className="nav-item flex-fill">
                <Link
                  to={item.link}
                  className={`d-block text-black px-2 px-sm-3 py-3 py-sm-4 position-relative ${
                    item.title === activeOption && "active"
                  } `}
                >
                  {item.title}
                </Link>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default CommonHeader;
