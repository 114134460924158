import * as url from "helper/UrlHelper";

//create chat
export const createTaskChatHandler = async (taskId = null) => {
  try {
    // 1. One-liner URL with template
    const externalUrl = `${url.FRONTEND_BASE_URL}/conversation/task/${taskId}`;

    window.open(externalUrl, "_blank", "noopener,noreferrer");
  } catch (error) {
    console.error("Error occurred:", error.message); // Red alerts for errors
  }
};
