/* eslint-disable */
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import { useTranslation } from "react-i18next";

/*------ import url and method ------*/
import * as url from "helper/UrlHelper";
import { getData, putData } from "utils/Gateway";

import approvalStatusOptions from "data/Prod/FaqApprovalStatusOptions.json";

const FaqFilterpopup = ({
  setfilterQuery = () => {},
  setfilterTags = () => {},
  setfilterStartDate = () => {},
  setfilterEndDate = () => {},
  setfilterAskedBy = () => {},
  setfilterAnsweredBy = () => {},
  setfilterApprovalStatus = () => {},
}) => {
  const token = localStorage.getItem("token");

  const { t, i18n } = useTranslation();

  const [tagList, setTagList] = useState([]);
  const [askedByInput, setaskedByInput] = useState("");
  const [askedByList, setaskedByList] = useState([]);
  const [answeredByInptut, setansweredByInptut] = useState("");
  const [answeredByList, setansweredByList] = useState([]);

  const [searchQuery, setSearchQuery] = useState("");
  const [tagValue, settagValue] = useState(null);
  const [selectedTags, setselectedTags] = useState([]);
  const [askedByvalue, setaskedByvalue] = useState(null);
  const [selectedAskedBy, setselectedAskedBy] = useState([]);
  const [answeredByValue, setansweredByValue] = useState(null);
  const [selectedAnsweredBy, setselectedAnsweredBy] = useState([]);
  const [approvalStatusvalue, setApprovalStatusvalue] = useState(null);
  const [selectedApprovalStatus, setselectedApprovalStatus] = useState("");
  const [startDate, setstartDate] = useState("");
  const [endDate, setendDate] = useState("");

  /* hook for validation */
  const [validation, setValidation] = useState({
    startDateWarning: false,
    endDateWarning: false,
    endDateSmallerWarning: false,
  });

  // function for validation
  const validationHandler = () => {
    let isValid = true; // Initialize the validity flag

    // Check if the start date is provided but the end date is missing
    if (startDate === "" && endDate !== "") {
      setValidation((prevState) => ({
        ...prevState,
        startDateWarning: true,
      }));
      // Indicate that the validation has failed
      isValid = false;
    }
    if (startDate !== "" && endDate === "") {
      setValidation((prevState) => ({ ...prevState, endDateWarning: true }));

      isValid = false;
    }

    if (endDate !== "" && endDate < startDate) {
      setValidation((prevState) => ({
        ...prevState,
        endDateSmallerWarning: true,
      }));
      isValid = false;
    }
    // Return the validity flag
    return isValid;
  };

  //function for get all tags
  const getAllTags = async () => {
    try {
      let requestURL =
        url.API_BASE_URL + url.API_GET_ALL_TAGS + `?token=${token}`;

      const response = await getData(requestURL);

      console.log(response);

      if (response.status) {
        setTagList(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for get all asked By list
  const getAllAskedByList = async () => {
    try {
      let requestURL =
        url.API_BASE_URL +
        url.API_GET_USERLIST_OPTIONS +
        `?token=${token}&userstringinput=${askedByInput}`;

      const response = await getData(requestURL);

      console.log(response);

      if (response.status) {
        setaskedByList(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for get answered by list
  const getAllAnsweredByList = async () => {
    try {
      let requestURL =
        url.API_BASE_URL +
        url.API_GET_USERLIST_OPTIONS +
        `?token=${token}&userstringinput=${answeredByInptut}`;

      const response = await getData(requestURL);

      console.log(response);

      if (response.status) {
        setansweredByList(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for select tags
  const tagSelectionHandler = (val) => {
    settagValue(val);
    setselectedTags(val.map((item) => item.value));
  };

  //function for select asked by
  const askedBySelectionHandler = (val) => {
    setaskedByvalue(val);
    setselectedAskedBy(val.map((item) => item.value));
  };

  //function for select answered by
  const answeredBySelectionHandler = (val) => {
    setansweredByValue(val);
    setselectedAnsweredBy(val.map((item) => item.value));
  };

  //function for select publish
  const approvalStatusSelectionHandler = (val) => {
    if (val) {
      setApprovalStatusvalue(val);
      setselectedApprovalStatus(val.value);
    } else {
      setApprovalStatusvalue(null);
      setselectedApprovalStatus("");
    }
  };

  //function for search filter
  const applyFilterHandler = () => {
    if (validationHandler()) {
      setfilterQuery(searchQuery);
      setfilterTags(selectedTags);
      setfilterStartDate(startDate);
      setfilterEndDate(endDate);
      setfilterAskedBy(selectedAskedBy);
      setfilterAnsweredBy(selectedAnsweredBy);
      setfilterApprovalStatus(selectedApprovalStatus);

      resetHandler();

      let offCanvasPopup = document.querySelector("#offcanvasFaqFilter");
      let offCanvas = bootstrap.Offcanvas.getInstance(offCanvasPopup);
      offCanvas.hide();
    }
  };

  const resetHandler = () => {
    setaskedByInput("");
    setansweredByInptut("");
    setSearchQuery("");
    settagValue(null);
    setselectedTags([]);
    setstartDate("");
    setendDate("");
    setaskedByvalue(null);
    setselectedAskedBy([]);
    setansweredByValue(null);
    setselectedAnsweredBy([]);
    setApprovalStatusvalue(null);
    setselectedApprovalStatus("");

    setValidation({
      startDateWarning: false,
      endDateWarning: false,
      endDateSmallerWarning: false,
    });
  };

  useEffect(() => {
    getAllTags();
  }, []);

  useEffect(() => {
    if (askedByInput !== "" && askedByInput.length > 1) {
      getAllAskedByList();
    }
  }, [askedByInput]);

  useEffect(() => {
    if (answeredByInptut !== "" && answeredByInptut.length > 1) {
      getAllAnsweredByList();
    }
  }, [answeredByInptut]);

  return (
    <div
      className="offcanvas offcanvas-end bg-white border-0"
      tabIndex="-1"
      id="offcanvasFaqFilter"
      aria-labelledby="offcanvasFaqFilter"
    >
      <div className="offcanvas-header p-4 pb-0">
        <h3 className="offcanvas-title" id="offcanvasFaqFilter">
          Filter
        </h3>
        <button
          type="button"
          className="btn-close p-0 bg-gray-200 rounded-circle shadow-none m-0"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          onClick={resetHandler}
        ></button>
      </div>
      <div className="offcanvas-body p-4">
        <form
          onSubmit={(e) => e.preventDefault()}
          className="d-flex flex-column h-100"
        >
          <div className="field-container flex-fill">
            <div className="form-group mb-4">
              <label
                htmlFor="question"
                className="d-block fs-sm fw-semibold mb-2"
              >
                {t(" Has the words")}
              </label>
              <input
                type="text"
                id="question"
                className="form-control fs-sm shadow-none"
                placeholder={t("Enter Question")}
                value={searchQuery}
                onChange={(e) => {
                  setSearchQuery(e.target.value);
                }}
              />
            </div>

            <div className="form-group mb-4">
              <label
                htmlFor="answer"
                className="d-block fs-sm fw-semibold mb-2"
              >
                {t("Tags")}
              </label>
              <Select
                isMulti
                placeholder={t("Select Tags")}
                options={tagList}
                value={tagValue}
                onChange={(val) => {
                  tagSelectionHandler(val);
                }}
              />
            </div>

            <div className="form-group mb-4">
              <label
                htmlFor="question"
                className="d-block fs-sm fw-semibold mb-2"
              >
                {t("Asked By")}
              </label>
              <Select
                isMulti
                placeholder={t("Select Asked By")}
                options={askedByList}
                value={askedByvalue}
                onChange={(val) => {
                  askedBySelectionHandler(val);
                }}
                onInputChange={(val) => {
                  setaskedByInput(val);
                }}
              />
            </div>

            <div className="form-group mb-4">
              <label
                htmlFor="question"
                className="d-block fs-sm fw-semibold mb-2"
              >
                {t("Replied By")}
              </label>
              <Select
                isMulti
                placeholder={t("Select Replied By")}
                options={answeredByList}
                value={answeredByValue}
                onChange={(val) => {
                  answeredBySelectionHandler(val);
                }}
                onInputChange={(val) => {
                  setansweredByInptut(val);
                }}
              />
            </div>

            <div className="form-group mb-4">
              <label
                htmlFor="question"
                className="d-block fs-sm fw-semibold mb-2"
              >
                {t("Approval Status")}
              </label>
              <Select
                options={approvalStatusOptions}
                isClearable
                placeholder={t("Select Status")}
                value={approvalStatusvalue}
                onChange={(val) => {
                  approvalStatusSelectionHandler(val);
                }}
              />
            </div>

            <div className="row mb-3">
              <div className="col-6">
                <label htmlFor="startDate" className="fw-bold mb-2">
                  From Date
                </label>
                <input
                  type="date"
                  name="startdate"
                  id="startDate"
                  className="form-control rounded-0"
                  placeholder="Select Date"
                  value={startDate}
                  onChange={(e) => {
                    setstartDate(e.target.value);
                    setValidation((prevState) => ({
                      ...prevState,
                      dateWarning: false,
                    }));
                  }}
                />
              </div>
              <div className="col-6">
                <label htmlFor="endDate" className="fw-bold mb-2">
                  To Date
                </label>
                <input
                  type="date"
                  name="enddate"
                  id="endDate"
                  className="form-control rounded-0"
                  placeholder="Select Date"
                  value={endDate}
                  onChange={(e) => {
                    setendDate(e.target.value);
                    setValidation((prevState) => ({
                      ...prevState,
                      dateWarning: false,
                    }));
                  }}
                />
              </div>

              {validation.endDateWarning && (
                <div className="error-message mt-3">
                  <p className="d-flex align-items-center gap-1 text-danger">
                    <span className="material-icons-outlined">warning</span>
                    <span>Please enter end date!</span>
                  </p>
                </div>
              )}
              {validation.startDateWarning && (
                <div className="error-message mt-3">
                  <p className="d-flex align-items-center gap-1 text-danger">
                    <span className="material-icons-outlined">warning</span>
                    <span>Please enter start date!</span>
                  </p>
                </div>
              )}
              {validation.endDateSmallerWarning && (
                <div className="error-message mt-3">
                  <p className="d-flex align-items-center gap-1 text-danger">
                    <span className="material-icons-outlined">warning</span>
                    <span>End date should not be greater than start date!</span>
                  </p>
                </div>
              )}
            </div>
          </div>
          <div className="action d-flex flex-wrap gap-3 justify-content-between">
            <button
              type="button"
              className="btn btn-primary flex-fill"
              // data-bs-dismiss="offcanvas"
              aria-label="Close"
              onClick={applyFilterHandler}
            >
              {t("Apply Filter")}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default FaqFilterpopup;
