/* eslint-disable */
import React, { useEffect, useState, useMemo } from "react";
import { Link } from "react-router-dom";

/*import url and gateway methods */
import { getData, postData, putData } from "utils/Gateway";
import * as url from "helper/UrlHelper";

// Material UI table
import { MaterialReactTable } from "material-react-table";

import { assetImages } from "constants";
import { useTranslation } from "react-i18next";

import TabsHeader from "components/Common/TabsHeader/TabsHeader";
import AlertNotification from "components/Common/AlertNotification/AlertNotification";
import { taxonomyCommonHeaderLinkLists } from "helper/TaxonomyHelper/TaxonomyHelper";
import ProjectTypeTaxonomyHeader from "./Header/ProjectTypeTaxonomyHeader";
import SaveProjectTypeTaxonomy from "./Popup/SaveProjectTypeTaxonomy";
import TaxonomyTypeFilter from "./Popup/TaxonomyTypeFilter";

const ProjectTypeTaxonomyBody = () => {
  const token = localStorage.getItem("token");
  const moduleAccess = localStorage.getItem("moduleaccess");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  /* ------------- Language translation imports starts here ------------- */
  const { t, i18n } = useTranslation();
  // common header object
  const commonHeaderObject = taxonomyCommonHeaderLinkLists(t);

  const [isLoading, setisLoading] = useState(false);
  const [typeList, settypeList] = useState([]);

  const [filterLabelName, setFilterLabelName] = useState("");
  const [filterLabelSlug, setFilterLabelSlug] = useState("");

  const [rowSelection, setRowSelection] = useState({});
  const [selectTypeIds, setSelectTypeIds] = useState([]);
  const [selectedTypeId, setSelectedTypeId] = useState(null);

  //* alert requirements
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [messageType, setMessageType] = useState("");

  //function for get all taxonomies
  const getAllTaxonomies = async () => {
    try {
      setisLoading(true);

      let requestUrl =
        url.API_BASE_URL + url.API_GET_ALL_PROJECT_TAXONOMY + `?token=${token}`;

      if (filterLabelName !== "") {
        requestUrl = requestUrl + `&filterlabelname=${filterLabelName}`;
      }

      if (filterLabelSlug !== "") {
        requestUrl = requestUrl + `&filterlabelslug=${filterLabelSlug}`;
      }

      const response = await getData(requestUrl);
      setisLoading(false);
      if (response.status) {
        settypeList(response.data);

        resetFilterData();
      }
    } catch (error) {}
  };

  //function for change status
  const changeStatusHandler = async () => {
    if (selectTypeIds.length > 0) {
      let responseArr = [];

      for (let labelId of selectTypeIds) {
        try {
          let tagData = {
            status: "0",
          };

          let requestUrl =
            url.API_BASE_URL +
            url.API_UPDATE_PROJECT_TAXONOMY +
            `/${labelId}` +
            `?token=${token}`;

          const response = await putData(requestUrl, tagData);

          console.log(response);

          if (response.status) {
            setMessageType("success");
            responseArr.push(true);
          } else {
            setMessageType("error");
          }

          setAlertMessage(response.message);
        } catch (error) {
          setMessageType("error");
          setAlertMessage(error.message);
        }

        setShowAlert(true);
      }

      if (responseArr.length > 0) {
        setTimeout(() => {
          getAllTaxonomies();
        }, 1000);
      }
    } else {
      setMessageType("error");
      setAlertMessage("Please select data for delete");
      setShowAlert(true);
    }
  };

  //function for reset filter
  const resetFilterData = () => {
    setFilterLabelName("");
    setFilterLabelSlug("");
  };

  useEffect(() => {
    if (filterLabelName != "" || filterLabelSlug != "") {
      getAllTaxonomies();
    }
  }, [filterLabelName, filterLabelSlug]);

  useEffect(() => {
    getAllTaxonomies();
  }, []);

  /*
   * Material React Table Column and States -----------------------------------------------------------
   *---------------------------------------------------------------------------------------------------
   */
  const columns = useMemo(
    () => [
      {
        accessorKey: "#",
        header: t("Actions"),
        Cell: ({ row }) => (
          <button
            className="action_btn_mui"
            data-bs-toggle="offcanvas"
            data-bs-target="#projectTaxonomyOffCanvas"
            aria-controls="projectTaxonomyOffCanvas"
            onClick={() => {
              setSelectedTypeId(row.original._id);
            }}
          >
            <span className="d-block material-symbols-outlined horz_icon">
              more_horiz
            </span>
          </button>
        ),
        enableColumnActions: false, // Hides the column action icon
        enableColumnDragging: false, // Hides the move icon
        enableSorting: false,
      },
      {
        accessorKey: "title",
        header: t("Title"),
        size: 200,
      },
      {
        accessorKey: "slug",
        header: t("Slug"),
        size: 200,
      },
      {
        accessorKey: "taxonomytypenames",
        header: t("Taxonomy Types"),
        size: 200,
      },
      {
        accessorKey: "createddate",
        header: t("Date"),
        size: 200,
      },
    ],
    [i18n.language]
  );

  //initialize the column order
  const columnOrder = [
    "#",
    "mrt-row-select",
    ...columns.map((c) => c.accessorKey),
  ]; //array of column ids (Initializing is optional as of v2.10.0)

  useEffect(() => {
    const selectedIdsArray = Object.keys(rowSelection).filter(
      (key) => rowSelection[key]
    );
    if (selectedIdsArray.length > 0) {
      setSelectTypeIds(selectedIdsArray);
    } else {
      setSelectTypeIds([]);
    }
  }, [rowSelection]);

  const onAlertClose = () => {
    setShowAlert(false);
    setAlertMessage("");
    setMessageType("");
  };

  if (
    userInfo.role.slug === "ADMIN" ||
    userInfo.role.slug === "SUPER_ADMIN" ||
    (moduleAccess.includes("MOD_CRM") && moduleAccess.includes("MOD_LABEL"))
  ) {
    return (
      <div id="content_wrapper">
        <section className="crm-wrapper bg-white pb-5">
          <TabsHeader
            commonHeaderObject={commonHeaderObject}
            activeOption={t("Project Type")}
          />

          <div className="container-fluid px-lg-5">
            <ProjectTypeTaxonomyHeader
              reloadList={getAllTaxonomies}
              changeStatusHandler={changeStatusHandler}
            />

            {isLoading ? (
              <div className="placeholder-glow d-flex flex-column gap-4">
                <span className="placeholder placeholder-lg bg-secondary col-12"></span>
                <span className="placeholder placeholder-lg bg-secondary col-8"></span>
                <span className="placeholder placeholder-lg bg-secondary col-4"></span>
              </div>
            ) : (
              <div className="table-wrapper">
                <MaterialReactTable
                  columns={columns} // map columns to be displayed with api data,
                  data={typeList} // data from api to be displayed
                  positionActionsColumn="last"
                  enableGrouping // to enable grouping of column
                  enableRowSelection // enable showing checkbox
                  getRowId={(row) => row._id} // map which value to select with row checkbox
                  onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
                  state={{ rowSelection, columnOrder }} //pass our managed row selection state to the table to use
                  defaultColumn={{
                    minSize: 20,
                    maxSize: 200,
                    size: 50, //make columns wider by default
                  }}
                  muiTableContainerProps={{
                    sx: {
                      maxHeight: "60vh",
                    },
                  }}
                  enableStickyHeader
                />
              </div>
            )}
          </div>

          <SaveProjectTypeTaxonomy
            selectedTypeId={selectedTypeId}
            setSelectedTypeId={setSelectedTypeId}
            afterPopupClose={getAllTaxonomies}
          />

          <TaxonomyTypeFilter
            setFilterLabelName={setFilterLabelName}
            setFilterLabelSlug={setFilterLabelSlug}
          />

          {showAlert && (
            <AlertNotification
              showAlert={showAlert}
              message={alertMessage}
              alertType={messageType}
              onClose={onAlertClose}
            />
          )}
        </section>
      </div>
    );
  } else {
    return (
      <div id="content_wrapper">
        <section className="crm-wrapper bg-white pb-5">
          <div className="empty_access text-center">
            <div className="empty_pic mb-4">
              {" "}
              <img src={assetImages.emptyVector} alt="" />
            </div>
            <div className="empty_text">
              <p className="fs-lg text-gray fw-semibold mb-4">
                {t("Sorry....! You don't have privilege to see this content")}
              </p>
            </div>
          </div>
        </section>
      </div>
    );
  }
};

export default ProjectTypeTaxonomyBody;
