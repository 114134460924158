/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";

//import translation function for language translation
import { useTranslation } from "react-i18next";

/*import url and gateway methods */
import { getData, postData, putData } from "utils/Gateway";
import * as url from "helper/UrlHelper";

import projectTypeOptions from "data/Prod/ProjectTypeOptions.json";
import taxonomyOptions from "data/Prod/TaxonomyOptions.json";

const SaveProjectTypeTaxonomy = ({
  selectedTypeId,
  setSelectedTypeId = () => {},
  setShowAlert = () => {},
  setAlertMessage = () => {},
  setMessageType = () => {},
  afterPopupClose = () => {},
}) => {
  const token = localStorage.getItem("token"); // token

  /* ------------- Language translation imports starts here ------------- */
  const { t } = useTranslation();

  const [projectTypeValue, setProjectTypeValue] = useState(null);
  const [projectTypeName, setprojectTypeName] = useState("");
  const [projectTypeSlug, setprojectTypeSlug] = useState("");

  const [allowedTaxonomyValue, setallowedTaxonomyValue] = useState(null);
  const [allowedTaxonomyList, setallowedTaxonomyList] = useState([]);

  const [isSaving, setIsSaving] = useState(false);

  /* hook for validation */
  const [validation, setValidation] = useState({
    typeWarning: false,
    allowedTaxonomyWarning: false,
  });

  /* function to validate form */
  const validationHandler = () => {
    let isValid = true;

    if (projectTypeValue === null) {
      setValidation((prevState) => ({ ...prevState, typeWarning: true }));
      isValid = false;
    }

    if (allowedTaxonomyValue === null) {
      setValidation((prevState) => ({
        ...prevState,
        allowedTaxonomyWarning: true,
      }));

      isValid = false;
    }

    return isValid;
  };

  //function for select taxonomy type
  const typeSelectionHandler = (val) => {
    if (val) {
      setValidation((prevState) => ({
        ...prevState,
        typeWarning: false,
      }));
      setProjectTypeValue(val);
      setprojectTypeName(val.label);
      setprojectTypeSlug(val.value);
    } else {
      setProjectTypeValue(null);
      setprojectTypeName("");
      setprojectTypeSlug("");
    }
  };

  //function for select allowed taxonomy
  const allowedTaxonomySelectionHandler = (val) => {
    setValidation((prevState) => ({
      ...prevState,
      allowedTaxonomyWarning: false,
    }));
    setallowedTaxonomyValue(val);
    setallowedTaxonomyList(
      val.map((item) => ({ title: item.label, slug: item.value }))
    );
  };

  //function for get details
  const getTaxonomyDetails = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_PROJECT_TAXONOMY_DETAILS +
        `/${selectedTypeId}` +
        `?token=${token}`;

      const response = await getData(requestUrl);

      console.log(response);

      if (response.status) {
        typeSelectionHandler({
          label: response.data.title,
          value: response.data.slug,
        });
        allowedTaxonomySelectionHandler(
          response.data.taxonomytypes.map((item) => ({
            label: item.title,
            value: item.slug,
          }))
        );
      }
    } catch (error) {}
  };

  //function for create new tag
  const saveTypeHandler = async () => {
    if (validationHandler()) {
      setIsSaving(true);
      try {
        let typeData = {
          title: projectTypeName,
          slug: projectTypeSlug,
          taxonomytypes: allowedTaxonomyList,
        };

        let requestUrl = url.API_BASE_URL;

        let response = {};

        if (selectedTypeId) {
          requestUrl =
            requestUrl +
            url.API_UPDATE_PROJECT_TAXONOMY +
            `/${selectedTypeId}` +
            `?token=${token}`;
          response = await putData(requestUrl, typeData);
        } else {
          requestUrl =
            requestUrl + url.API_CREATE_PROJECT_TAXONOMY + `?token=${token}`;
          response = await postData(requestUrl, typeData);
        }

        setIsSaving(false);

        console.log(response);

        if (response.status) {
          setMessageType("success");
          // close the modal of addLabel
          bootstrap.Offcanvas.getInstance(
            document.querySelector("#projectTaxonomyOffCanvas")
          ).hide();
          resetHandler();
          afterPopupClose();
        } else {
          setMessageType("error");
          setErrorResponseMessage(response.message);
        }

        setAlertMessage(response.message);
      } catch (error) {
        console.log(error.message);
        setAlertMessage(error.message);
        setMessageType("error");
      }
    } else {
      setAlertMessage("Please fill up all required fields");
      setMessageType("error");
    }
    setShowAlert(true);
  };

  const resetHandler = () => {
    setProjectTypeValue(null);
    setprojectTypeName("");
    setprojectTypeSlug("");
    setallowedTaxonomyValue(null);
    setallowedTaxonomyList([]);
    setSelectedTypeId(null);

    setValidation({
      typeWarning: false,
      allowedTaxonomyWarning: false,
    });
  };

  useEffect(() => {
    if (selectedTypeId) {
      getTaxonomyDetails();
    }
  }, [selectedTypeId]);

  return (
    <div
      className="offcanvas md offcanvas-end bg-white border-0"
      tabIndex="-1"
      id="projectTaxonomyOffCanvas"
      aria-labelledby="projectTaxonomyOffCanvasLabel"
    >
      <div className="offcanvas-header p-4 pb-0">
        <h3 className="offcanvas-title" id="offcanvasLabelDetailsLabel">
          {t("Save Taxonomy")}
        </h3>
        <button
          type="button"
          className="btn-close p-0 bg-gray-200 rounded-circle shadow-none m-0"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          onClick={resetHandler}
        ></button>
      </div>
      <div className="offcanvas-body p-4">
        <form
          onSubmit={(e) => e.preventDefault()}
          className="d-flex flex-column h-100"
        >
          <div className="fields-container flex-fill">
            <div className="form-group mb-4">
              <label
                htmlFor="labelName"
                className="d-block fs-sm fw-semibold mb-2"
              >
                {t("Select Type")}
              </label>
              <Select
                isClearable
                placeholder={t("Select Type")}
                options={projectTypeOptions}
                value={projectTypeValue}
                onChange={typeSelectionHandler}
              />
              {/* level warning */}
              {validation.typeWarning && (
                <div className="error-message mt-2">
                  <p className="d-flex align-items-center gap-1 text-danger">
                    <span className="material-symbols-outlined">warning</span>
                    <span>{t("Please select type")}!</span>
                  </p>
                </div>
              )}
            </div>
            <div className="form-group mb-4">
              <label
                htmlFor="slugID"
                className="d-block fs-sm fw-semibold mb-2"
              >
                {t("Select Allowed Taxonomy")}
              </label>
              <Select
                isMulti
                placeholder={t("Select Allowed Taxonomy")}
                options={taxonomyOptions}
                value={allowedTaxonomyValue}
                onChange={allowedTaxonomySelectionHandler}
              />

              {/* level warning */}
              {validation.allowedTaxonomyWarning && (
                <div className="error-message mt-2">
                  <p className="d-flex align-items-center gap-1 text-danger">
                    <span className="material-symbols-outlined">warning</span>
                    <span>{t("Please select allowed taxonomy")}!</span>
                  </p>
                </div>
              )}
            </div>
          </div>
          <div className="action d-flex justify-content-between gap-3">
            <Link
              to="#"
              className="btn btn-outline-primary"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
              onClick={resetHandler}
            >
              {t("Close")}
            </Link>
            <button
              type="button"
              className="btn btn-primary"
              disabled={isSaving ? true : false}
              style={{
                cursor: isSaving ? "not-allowed" : "pointer",
              }}
              onClick={saveTypeHandler}
            >
              {t("Save")}
              {isSaving && (
                <div
                  className="mx-2 spinner-border spinner-border-sm"
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SaveProjectTypeTaxonomy;
