/* eslint-disable */
import React, { useRef, useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import CreatableSelect from "react-select/creatable";

// translation --------------
import { useTranslation } from "react-i18next";

/*------ import url and method ------*/
import * as url from "helper/UrlHelper";

import { postData, getData, putData, uploadMultipleFile } from "utils/Gateway";

import TabsHeader from "components/Common/TabsHeader/TabsHeader";
import BreadCrumb from "components/Common/BreadCrumb/BreadCrumb";

import { projectDetailsHeaderLinks } from "helper/ProjectHelper/ProjectHelper";
import AfterSaveProjectModal from "components/Common/Modal/AfterSaveProjectModal";
import AlertNotification from "components/Common/AlertNotification/AlertNotification";

const ProjectTaxonomyBody = () => {
  const params = useParams();
  const history = useHistory();

  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const moduleAccess = localStorage.getItem("moduleaccess");

  const { t } = useTranslation(); //for translation

  //*------ common header object ------*/
  const commonHeaderObject = projectDetailsHeaderLinks(params.id, t);

  //*------ bread crumb text ------*/
  const breadCrumbText = [
    { title: t("Projects"), link: "/admin/projectmanagement/list" },
    { title: t("Taxonomies") },
  ];

  const [title, settitle] = useState("");
  const [isProjectModerator, setisProjectModerator] = useState(false);
  const [projectModeratorId, setprojectModeratorId] = useState(null);

  const [taxonomies, setTaxonomies] = useState([]);

  const [isSaving, setIsSaving] = useState(false);

  //* alert requirements
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [messageType, setMessageType] = useState("");

  //function for get project details
  const getProjectDetails = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_PROJECT_DETAILS +
        `/${params.id}?token=${token}`;

      const response = await getData(requestUrl);

      console.log("response", response);

      if (response.status && response.data) {
        const data = response.data;

        setprojectModeratorId(data.moderator._id);
        let isModerator = false;

        if (
          data.moderator._id.toString() === userInfo._id.toString() ||
          userInfo.role.slug === "ADMIN" ||
          userInfo.role.slug === "SUPER_ADMIN"
        ) {
          isModerator = true;
        } else {
          //   getMemberDetails();
        }

        setisProjectModerator(isModerator);

        settitle(data.title);
        setTaxonomies(data.taxonomies);
      } else {
        setAlertMessage(response.message);
        setMessageType("error");
        setShowAlert(true);
      }
    } catch (error) {
      setAlertMessage(error.message);
      setMessageType("error");
      setShowAlert(true);
    }
  };

  //function for get details of event member
  //   const getMemberDetails = async () => {
  //     try {
  //       let requestUrl =
  //         url.API_BASE_URL +
  //         url.API_GET_PROJECT_MEMBER_DETAILS_QUERY +
  //         `?token=${token}&projectid=${params.id}`;

  //       const response = await getData(requestUrl);

  //       console.log(response);

  //       if (response.status) {
  //         if (response.data) {
  //           setLoggedInMemberId(response.data._id);
  //           setProjectMemberStatus(response.data.memberstatus);
  //           setinviteDate(createdAtToDateConverter(response.data.createdAt));
  //         }
  //       } else {
  //         console.log(response.message);
  //       }
  //     } catch (error) {
  //       setAlertMessage(error.message);
  //       setMessageType("error");
  //       setShowAlert(true);
  //     }
  //   };

  //function for select taxonomy value
  const taxonomyValueSelectionHandler = (selectedOptions, index) => {
    const updatedTaxonomies = [...taxonomies];

    // Convert selected options to array of strings
    updatedTaxonomies[index].values = selectedOptions
      ? selectedOptions.map((option) => option.value)
      : [];

    setTaxonomies(updatedTaxonomies);
  };

  //function for save project
  const saveProjectHandler = async () => {
    try {
      setIsSaving(true);

      let projectData = {
        taxonomies,
      };

      let requestUrl = url.API_BASE_URL;

      let response = {};

      if (params.id) {
        requestUrl =
          requestUrl + url.API_UPDATE_PROJECT + `/${params.id}?token=${token}`;
        response = await putData(requestUrl, projectData);
      }
      // console.log(response);

      setIsSaving(false);

      if (response.status) {
        setMessageType("success");

        const bootstrapModal = new bootstrap.Modal(
          document.getElementById("afterSaveProjectModal")
        );
        bootstrapModal.show();
      } else {
        setMessageType("error");
      }

      setAlertMessage(response.message);
    } catch (error) {
      setAlertMessage(error.message);
      setMessageType("error");
    }

    setShowAlert(true);
  };

  //function for aftermodal close
  const afterSaveModalClose = (pageName) => {
    if (pageName == "details") {
      getProjectDetails();
    } else {
      history.push("/admin/projectmanagement/list");
    }
  };

  useEffect(() => {
    if (params.id) {
      getProjectDetails();
    }
  }, [params.id]);

  const onAlertClose = () => {
    setShowAlert(false);
    setAlertMessage("");
    setMessageType("");
  };

  if (
    userInfo.role.slug === "ADMIN" ||
    userInfo.role.slug === "SUPER_ADMIN" ||
    moduleAccess.includes("MOD_PROJECT")
  ) {
    return (
      <div id="content_wrapper">
        <section className="event-details-wrapper bg-white pb-5">
          {/* ------ common header -------- */}
          {params.id && (
            <TabsHeader
              commonHeaderObject={commonHeaderObject}
              activeOption={t("Taxonomies")}
            />
          )}

          <div className="container-fluid px-lg-5 pt-4 pt-md-0">
            {/* ------- bread crumb -------- */}
            <BreadCrumb
              breadCrumbText={breadCrumbText}
              bottom={true}
              displayName={`${title}`}
            />

            {/* -------- table section ---------- */}

            <div className="p-3 p-lg-4 border border-gray-300 rounded-10 shadow-sm">
              {taxonomies.map((taxonomy, index) => {
                return (
                  <div
                    className="form-group pb-3 pb-sm-4 mb-3 mb-sm-4 "
                    key={index}
                  >
                    <div className="row align-items-center">
                      <div className="col-lg-2 mb-3 mb-sm-4 mb-lg-0">
                        <p className="fs-md fw-semibold">{t(taxonomy.title)}</p>
                      </div>
                      <div className="col-lg-10">
                        <CreatableSelect
                          isMulti
                          placeholder={t("Type Values")}
                          value={taxonomy.values.map((val) => ({
                            value: val,
                            label: val,
                          }))}
                          onChange={(val) =>
                            taxonomyValueSelectionHandler(val, index)
                          }
                        />
                      </div>
                    </div>
                  </div>
                );
              })}

              <div className="action">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={saveProjectHandler}
                  disabled={isSaving ? true : false}
                  style={{
                    cursor: isSaving ? "not-allowed" : "pointer",
                  }}
                >
                  {t("Save Taxonomies")}
                  {isSaving && (
                    <div
                      className="mx-2 spinner-border spinner-border-sm"
                      role="status"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  )}
                </button>
              </div>
            </div>
          </div>

          <AfterSaveProjectModal afterSaveModalClose={afterSaveModalClose} />

          {showAlert && (
            <AlertNotification
              showAlert={showAlert}
              message={alertMessage}
              alertType={messageType}
              onClose={onAlertClose}
            />
          )}
        </section>
      </div>
    );
  } else {
    return (
      <div id="content_wrapper">
        <section className="crm-wrapper bg-white pb-5">
          <div className="empty_access text-center">
            <div className="empty_pic mb-4">
              {" "}
              <img src={assetImages.emptyVector} alt="" />
            </div>
            <div className="empty_text">
              <p className="fs-lg text-gray fw-semibold mb-4">
                {t("Sorry....! You don't have privilege to see this content")}
              </p>
            </div>
          </div>
        </section>
      </div>
    );
  }
};

export default ProjectTaxonomyBody;
