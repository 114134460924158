/* eslint-disable */
import React, { useEffect, useState, useMemo } from "react";
import { Link, useParams } from "react-router-dom";

// Material UI table
import { MaterialReactTable } from "material-react-table";
//Material UI components for rendring menu and others
import { MenuItem } from "@mui/material";

/*import url and gateway methods */
import { getData, postData, putData } from "utils/Gateway";
import * as url from "helper/UrlHelper";

import {
  challengeHeaderLinks,
  challnegeBreadCrumbText,
  getJobResponseStatus,
} from "helper/ChallengeHelper/ChallengeHelper";

import {
  createdAtToDateConverter,
  getDateFormatInSpanish,
} from "helper/Common/CommonHelper";

import { assetImages } from "constants";
import TabsHeader from "components/Common/TabsHeader/TabsHeader";
import AddLabelModal from "components/Common/Modal/AddLabelModal";

import ChallengeResponseFilterPopup from "./Popup/ChallengeResponseFilterPopup";
import ChallengeResponseHeader from "./Header/ChallengeResponseHeader";
import ResponseDetailsPopup from "../Common/Popup/ResponseDetailsPopup";
import ApproveRejectDetailsPopup from "../Common/Popup/ApproveRejectDetailsPopup";
import ResponseCommentPopup from "../Common/Popup/ResponseCommentPopup";
import { useTranslation } from "react-i18next";
import DeleteWarningModal from "components/Common/Modal/DeleteWarningModal";
import AiSuggestWinnerModal from "./Modal/AiSuggestWinnerModal";
import { createContactChatHandler } from "helper/AppUserHelper/AppUserHelper";

const ChallengeResponsesListBody = () => {
  const params = useParams();

  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const moduleAccess = localStorage.getItem("moduleaccess");

  const { t, i18n } = useTranslation(); //for translation

  // common header object
  const commonHeaderObject = challengeHeaderLinks(params.id, t);

  const [responseList, setResponseList] = useState([]);

  //optionally, you can manage the row selection state yourself
  const [rowSelection, setRowSelection] = useState({});
  const [selectedResponseIds, setSelectedResponseIds] = useState([]);

  const [respId, setRespId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [title, setTitle] = useState("");

  const [approvalStatus, setapprovalStatus] = useState("");
  const [selectedHiringProcessId, setSelectedHiringProcessId] = useState(null);
  const [approveComments, setapproveComments] = useState("");

  const [jobDetails, setJobDetails] = useState(null);
  const [askAiSuggest, setAskAiSuggest] = useState(false);

  /* ---- bread crumb text ---- */
  const breadcrumbText = challnegeBreadCrumbText(t("Response"), title, t);

  //function for get project details
  const getJobDetails = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_CHALLENGE_DETAILS +
        `/${params.id}?token=${token}`;

      const response = await getData(requestUrl);

      console.log("job details in list", response);
      if (response.status && response.data) {
        const data = response.data;
        setTitle(data.name);

        setJobDetails(data.description);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for get response
  const getAllChallengeResponse = async () => {
    setRowSelection({});
    setSelectedResponseIds([]);

    try {
      setIsLoading(true);
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_ALL_CHALLENGE_RESPONSE +
        `?token=${token}&challenge=${params.id}`;

      console.log("response requestUrl", requestUrl);

      const response = await getData(requestUrl);

      console.log(response);
      setIsLoading(false);
      if (response.status) {
        setResponseList(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for change status
  const changeStatusHandler = async (e) => {
    e.preventDefault();

    let responseArr = [];

    for (let memberId of selectedResponseIds) {
      try {
        let eventData = {
          status: "0",
        };
        let requestUrl =
          url.API_BASE_URL +
          url.API_UPDATE_JOB_MEMBER +
          `/${memberId}` +
          `?token=${token}`;

        const response = await putData(requestUrl, eventData);

        if (response.status) {
          responseArr.push(response.status);
        }
      } catch (error) {
        console.log(error.message);
      }
    }

    getAllEventMembers();
  };

  //approval action
  const approvalHandler = async () => {
    try {
      let approvalData = {
        approvalstatus: approvalStatus,
        hiringprocessid: selectedHiringProcessId || null,
        comments: approveComments,
      };

      console.log(approvalData);

      let requestUrl =
        url.API_BASE_URL +
        url.API_CHANGE_APPROVAL_STATUS +
        `/${respId}?token=${token}`;

      const response = await putData(requestUrl, approvalData);

      console.log(response);

      if (response.status) {
        getAllChallengeResponse();
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    if (params.id) {
      getJobDetails();
      getAllChallengeResponse();
    }
  }, [params.id]);

  /*
   * Material React Table Column and States -----------------------------------------------------------
   *---------------------------------------------------------------------------------------------------
   */
  const columns = useMemo(
    () => [
      {
        accessorKey: "applicantname",
        header: t("Provider/Applicant"),
        Cell: ({ row }) => (
          <div className="title border-bottom-0">
            <div className="profile d-flex align-items-center gap-2">
              <div
                className="avatar rounded-circle overflow-hidden"
                style={{ width: "35px", height: "35px" }}
              >
                <img
                  src={
                    row.original.applicantimage == ""
                      ? assetImages.defaultUser
                      : url.SERVER_URL + row.original.applicantimage
                  }
                  alt="member"
                  className="w-100 h-100 object-fit-cover object-center"
                />
              </div>
              <div>
                <p className="fs-md fw-semibold mb-0">
                  {row.original.applicantname}
                </p>
                <Link to="#" className="d-block lh-1">
                  {row.original.applicantemail}
                </Link>
              </div>
            </div>
          </div>
        ),
      },
      {
        accessorKey: "leadname",
        header: t("Lead"),
        Cell: ({ row }) => (
          <div className="title border-bottom-0">
            <div className="profile d-flex align-items-center gap-2">
              <div>
                <p className="fs-md fw-semibold mb-0">
                  {row.original.leadname}
                </p>
                <Link to="#" className="d-block lh-1">
                  {row.original.leademail}
                </Link>
              </div>
            </div>
          </div>
        ),
      },
      {
        accessorKey: "submittedbyname",
        header: t("Submitted By"),

        Cell: ({ row }) => (
          <div className="title border-bottom-0">
            <div className="profile d-flex align-items-center gap-2">
              <div
                className="avatar rounded-circle overflow-hidden"
                style={{ width: "35px", height: "35px" }}
              >
                <img
                  src={
                    row.original.submittedbyimage == ""
                      ? assetImages.defaultUser
                      : url.SERVER_URL + row.original.submittedbyimage
                  }
                  alt="member"
                  className="w-100 h-100 object-fit-cover object-center"
                />
              </div>
              <div>
                <p className="fs-md fw-semibold mb-0">
                  {row.original.submittedbyname}
                </p>
                <Link to="#" className="d-block lh-1">
                  {row.original.submittedbyemail}
                </Link>
              </div>
              <button
                onClick={() => {
                  createContactChatHandler(
                    row.original.submittedbyid,
                    "MOD_CHALLENGE",
                    params.id,
                    row.original.challengename
                  );
                }}
                className={
                  row.original.submittedbyid.toString() ===
                  userInfo._id.toString()
                    ? "d-none"
                    : "d-flex align-items-center ms-auto border-0 bg-light"
                }
              >
                <span className="d-block material-symbols-outlined icon-md text-primary">
                  chat
                </span>
              </button>
            </div>
          </div>
        ),
      },
      {
        accessorKey: "cvcandidatename",
        header: t("Candidate"),
        Cell: ({ row }) => (
          <div className="title border-bottom-0">
            <div className="profile d-flex align-items-center gap-2">
              <div>
                <p className="fs-md fw-semibold mb-0">
                  {row.original.cvcandidatename}
                </p>
                <Link to="#" className="d-block lh-1">
                  {row.original.cvcandidateemail}
                </Link>
              </div>
            </div>
          </div>
        ),
      },

      {
        accessorKey: "providerbudget",
        header: t("Price"),
      },
      {
        accessorKey: "responsecode",
        header: t("Response code"),
      },
      {
        accessorKey: "hiringprocessname",
        header: t("Hiring Process"),
      },
      {
        accessorKey: "winnerstatus",
        header: t("Is Winner"),
      },
      {
        accessorKey: "approvalresponsestatus",
        header: t("Status"),
        Cell: ({ row }) => (
          <div className="status border-bottom-0">
            <p
              className={`d-flex align-items-center gap-2 fw-semibold ${
                row.original.approvalstatus === "0" ||
                row.original.approvalstatus === "2"
                  ? "text-primary"
                  : "text-success"
              } `}
            >
              <span className="d-block material-symbols-outlined icon-lg">
                {row.original.approvalstatus === "0"
                  ? "schedule"
                  : row.original.approvalstatus === "2"
                  ? "cancel"
                  : "check_circle"}
              </span>
              <span className="d-block">
                {row.original.approvalresponsestatus}
              </span>
            </p>
          </div>
        ),
      },
      {
        accessorKey: "tagstring",
        header: t("Labels"),
        Cell: ({ row }) => (
          <div className="label border-bottom-0">
            {row.original.tagstring != "" && (
              <ul className="d-flex flex-wrap gap-2 fs-xs">
                {row.original.tagstring.split(" , ").map((tag, index) => {
                  return (
                    <li
                      key={index}
                      className="px-2 py-1 gradient-light rounded-5"
                    >
                      {tag}
                    </li>
                  );
                })}
              </ul>
            )}
          </div>
        ),
      },
    ],
    [i18n.language]
  );

  useEffect(() => {
    const selectedIdsArray = Object.keys(rowSelection).filter(
      (key) => rowSelection[key]
    );

    if (selectedIdsArray.length > 0) {
      setSelectedResponseIds(selectedIdsArray);
    } else {
      setSelectedResponseIds([]);
    }

    console.log("selectedIdsArray", selectedIdsArray);
  }, [rowSelection]);

  // useEffect(() => {
  //   console.log("askAiSuggest", askAiSuggest);
  // }, [askAiSuggest]);

  if (
    userInfo.role.slug === "ADMIN" ||
    userInfo.role.slug === "SUPER_ADMIN" ||
    moduleAccess.includes("MOD_CHALLENGE")
  ) {
    return (
      <div id="content_wrapper">
        <section className="event-details-wrapper bg-white pb-5">
          {/* ---- common header ---- */}
          {params.id && (
            <TabsHeader
              commonHeaderObject={commonHeaderObject}
              activeOption={t("Responses")}
            />
          )}

          <div className="container-fluid px-lg-5 pt-4 pt-md-0">
            {/* -------- header section ----------- */}
            <ChallengeResponseHeader
              breadcrumbText={breadcrumbText}
              ModuleName={"List"}
              reloadList={getAllChallengeResponse}
              setAskAiSuggest={setAskAiSuggest}
            />

            {isLoading ? (
              <div className="placeholder-glow d-flex flex-column gap-4">
                <span className="placeholder placeholder-lg bg-secondary col-12"></span>
                <span className="placeholder placeholder-lg bg-secondary col-8"></span>
                <span className="placeholder placeholder-lg bg-secondary col-4"></span>
              </div>
            ) : (
              <div className="table-wrapper">
                <MaterialReactTable
                  columns={columns} // map columns to be displayed with api data,
                  data={responseList} // data from api to be displayed
                  enableGrouping
                  enableRowSelection // enable showing checkbox
                  getRowId={(row) => row._id} // map which value to select with row checkbox
                  onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
                  state={{ rowSelection }} //pass our managed row selection state to the table to use
                  enableRowActions // show the action column
                  renderRowActionMenuItems={({ row }) => [
                    <MenuItem key="details">
                      <Link
                        className="dropdown-item"
                        to={`/admin/challengeresponse/details/${params.id}/${row.id}`}
                      >
                        View Details
                      </Link>
                    </MenuItem>,
                    <MenuItem key="feedback">
                      <Link
                        className="dropdown-item"
                        target="_blank"
                        to={`/admin/customerfeedback/${row.id}`}
                      >
                        Customer Feedback
                      </Link>
                    </MenuItem>,
                  ]} // action columns menu items
                  muiTableContainerProps={{
                    sx: {
                      maxHeight: "60vh",
                    },
                  }}
                  enableStickyHeader
                />
              </div>
            )}
          </div>
        </section>

        {/* ------------- add label modal ----------- */}
        <AddLabelModal
          moduleName="challengeresponse"
          selectedIds={selectedResponseIds}
          afterTagModalClose={getAllChallengeResponse}
        />

        {/* ------------ filter popup -------------- */}
        <ChallengeResponseFilterPopup />

        {/* ------------ response details popup ---------------  */}
        <ResponseDetailsPopup
          setRespId={setRespId}
          respId={respId}
          setapprovalStatus={setapprovalStatus}
          setSelectedHiringProcessId={setSelectedHiringProcessId}
        />

        <ApproveRejectDetailsPopup
          setRespId={setRespId}
          respId={respId}
          setapprovalStatus={setapprovalStatus}
          setSelectedHiringProcessId={setSelectedHiringProcessId}
        />

        <ResponseCommentPopup
          setapproveComments={setapproveComments}
          approveComments={approveComments}
          approvalHandler={approvalHandler}
        />

        {/* ----------- delete warning modal ------------ */}
        <DeleteWarningModal
          // onCancelDelete={() => {
          //   setRowSelection({});
          //   setSelectedBlogIds([]);
          // }}
          // onDelete={changeStatusHandler}
          moduleName={t("challenge")}
        />

        <AiSuggestWinnerModal
          jobDetails={jobDetails}
          selectedResponseIds={selectedResponseIds}
          askAiSuggest={askAiSuggest}
          setAskAiSuggest={setAskAiSuggest}
        />
      </div>
    );
  } else {
    return (
      <div id="content_wrapper">
        <section className="crm-wrapper bg-white pb-5">
          <div className="empty_access text-center">
            <div className="empty_pic mb-4">
              {" "}
              <img src={assetImages.emptyVector} alt="" />
            </div>
            <div className="empty_text">
              <p className="fs-lg text-gray fw-semibold mb-4">
                {t("Sorry....! You don't have privilege to see this content")}
              </p>
            </div>
          </div>
        </section>
      </div>
    );
  }
};

export default ChallengeResponsesListBody;
