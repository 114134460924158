/* eslint-disable */
import React, { useEffect, useState, useMemo } from "react";
import { Link } from "react-router-dom";

/*import url and gateway methods */
import { getData, postData, putData } from "utils/Gateway";
import * as url from "helper/UrlHelper";

// Material UI table
import { MaterialReactTable } from "material-react-table";

import CategoryHeader from "../Header/CategoryHeader";
import SaveCategoryPopup from "../Popup/SaveCategoryPopup";
import CategoryFilterPopup from "../Popup/CategoryFilterPopup";
import { assetImages } from "constants";
import { useTranslation } from "react-i18next";

const CategoryListBody = () => {
  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const ecosystemSlug = localStorage.getItem("ecosystemslug");

  const { t, i18n } = useTranslation(); //for translation

  const [categoryList, setCategoryList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [filterCategoryName, setFilterCategoryName] = useState("");
  const [filterCategorySlug, setFilterCategorySlug] = useState("");
  const [filterIsRoot, setFilterIsRoot] = useState(false);
  const [filterParents, setfilterParents] = useState([]);
  const [filterFeatured, setfilterFeatured] = useState(false);

  const [isReload, setIsReload] = useState(false);

  //optionally, you can manage the row selection state yourself
  const [rowSelection, setRowSelection] = useState({});
  const [selectCategoryIds, setSelectCategoryIds] = useState([]);
  const [categoryId, setCategoryId] = useState(null);

  //function for get all tags
  const getAllCategories = async () => {
    setRowSelection({});
    setSelectCategoryIds([]);
    try {
      setIsLoading(true);
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_CATEGORIES +
        `?ecosystemslug=${ecosystemSlug}`;

      if (filterCategoryName !== "") {
        requestUrl = requestUrl + `&filtername=${filterCategoryName}`;
      }

      if (filterCategorySlug !== "") {
        requestUrl = requestUrl + `&filterslug=${filterCategorySlug}`;
      }

      if (filterParents.length > 0) {
        requestUrl = requestUrl + `&filterparents=${filterParents}`;
      }

      if (filterFeatured === true) {
        requestUrl = requestUrl + `&filterfeatured=${filterFeatured}`;
      }

      if (filterIsRoot === true) {
        requestUrl = requestUrl + `&filterisroot=${filterIsRoot}`;
      }

      const response = await getData(requestUrl);

      console.log(response);

      setIsLoading(false);
      if (response.status) {
        setCategoryList(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for change status
  const changeStatusHandler = async () => {
    for (let CategoryId of selectCategoryIds) {
      try {
        let tagData = {
          status: "0",
        };
        let requestUrl =
          url.API_BASE_URL +
          url.API_UPDATE_CATEGORY +
          `/${CategoryId}` +
          `?token=${token}`;

        const response = await putData(requestUrl, tagData);

        if (response.status) {
        }
      } catch (error) {
        console.log(error.message);
      }
    }

    getAllCategories();
  };

  //function for reset filter
  const resetFilterData = () => {
    setFilterCategoryName("");
    setFilterCategorySlug("");
    setfilterParents([]);
    setfilterFeatured(false);
    setFilterIsRoot(false);
    setIsReload(true);
  };

  useEffect(() => {
    if (
      filterCategoryName != "" ||
      filterCategorySlug != "" ||
      filterParents.length > 0 ||
      filterFeatured === true ||
      filterIsRoot === true
    ) {
      getAllCategories();
    }
  }, [
    filterCategoryName,
    filterIsRoot,
    filterCategorySlug,
    filterParents,
    filterFeatured,
  ]);

  useEffect(() => {
    if (isReload) {
      getAllCategories();
      setIsReload(false);
    }
  }, [isReload]);

  useEffect(() => {
    getAllCategories();
  }, []);

  /*
   * Material React Table Column and States -----------------------------------------------------------
   *---------------------------------------------------------------------------------------------------
   */
  const columns = useMemo(
    () => [
      {
        accessorKey: "#",
        header: t("Actions"),
        Cell: ({ row }) => (
          <button
            className="action_btn_mui"
            data-bs-toggle="offcanvas"
            data-bs-target="#saveCategory"
            aria-controls="saveCategory"
            onClick={() => {
              setCategoryId(row.original.value);
            }}
          >
            <span className="d-block material-symbols-outlined horz_icon">
              more_horiz
            </span>
          </button>
        ),
        enableColumnActions: false, // Hides the column action icon
        enableColumnDragging: false, // Hides the move icon
        enableSorting: false,
      },
      {
        accessorKey: "label",
        header: t("Name"),
        size: 200,
      },
      {
        accessorKey: "slug",
        header: t("Slug"),
        size: 200,
      },
      {
        accessorKey: "parentname",
        header: t("Parent"),
        size: 200,
        Cell: ({ row }) => (
          <p>
            {row.original.parentname === "" ? "-" : row.original.parentname}
          </p>
        ),
      },
      {
        accessorKey: "feturedstring",
        header: t("Is Featured"),
      },
    ],
    [i18n.language]
  );

  useEffect(() => {
    const selectedIdsArray = Object.keys(rowSelection).filter(
      (key) => rowSelection[key]
    );

    if (selectedIdsArray.length > 0) {
      setSelectCategoryIds(selectedIdsArray);
    } else {
      setSelectCategoryIds([]);
    }
  }, [rowSelection]);

  //initialize the column order
  const initialColumnOrder = [
    "#",
    "mrt-row-select",
    ...columns.map((c) => c.accessorKey),
  ]; //array of column ids (Initializing is optional as of v2.10.0)

  if (userInfo.role.slug === "ADMIN" || userInfo.role.slug === "SUPER_ADMIN") {
    return (
      <div id="content_wrapper">
        <section className="crm-wrapper bg-white pb-5">
          <div className="container-fluid px-lg-5">
            <CategoryHeader
              reloadLabelList={resetFilterData}
              changeStatusHandler={changeStatusHandler}
            />

            {isLoading ? (
              <div className="placeholder-glow d-flex flex-column gap-4">
                <span className="placeholder placeholder-lg bg-secondary col-12"></span>
                <span className="placeholder placeholder-lg bg-secondary col-8"></span>
                <span className="placeholder placeholder-lg bg-secondary col-4"></span>
              </div>
            ) : (
              <div className="table-wrapper">
                <MaterialReactTable
                  columns={columns} // map columns to be displayed with api data,
                  data={categoryList} // data from api to be displayed
                  enableGrouping // to enable grouping of column
                  enableRowSelection // enable showing checkbox
                  getRowId={(row) => row._id} // map which value to select with row checkbox
                  onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
                  state={{ rowSelection, columnOrder: initialColumnOrder }} //pass our managed row selection state to the table to use
                  initialState={{
                    rowSelection,
                    columnOrder: initialColumnOrder,
                  }}
                  defaultColumn={{
                    minSize: 20,
                    maxSize: 300,
                    size: 50, //make columns wider by default
                  }}
                  muiTableContainerProps={{
                    sx: {
                      maxHeight: "60vh",
                    },
                  }}
                  enableStickyHeader
                />
              </div>
            )}
          </div>
        </section>

        {/* ==== popup and modal section ==== */}
        <SaveCategoryPopup
          afterPopupCLose={resetFilterData}
          categroyId={categoryId}
          setCategoryId={setCategoryId}
        />

        <CategoryFilterPopup
          isReload={isReload}
          setFilterCategoryName={setFilterCategoryName}
          setFilterCategorySlug={setFilterCategorySlug}
          setFilterIsRoot={setFilterIsRoot}
          setfilterParents={setfilterParents}
          setfilterFeatured={setfilterFeatured}
        />
      </div>
    );
  } else {
    return (
      <div id="content_wrapper">
        <section className="crm-wrapper bg-white pb-5">
          <div className="empty_access text-center">
            <div className="empty_pic mb-4">
              {" "}
              <img src={assetImages.emptyVector} alt="" />
            </div>
            <div className="empty_text">
              <p className="fs-lg text-gray fw-semibold mb-4">
                {t("Sorry....! You don't have privilege to see this content")}
              </p>
            </div>
          </div>
        </section>
      </div>
    );
  }
};

export default CategoryListBody;
