/* eslint-disable */
import React, { useEffect, useState, useMemo } from "react";
import { useParams, Link } from "react-router-dom";

// Material UI table
import { MaterialReactTable } from "material-react-table";
//Material UI components for rendring menu and others
import { MenuItem, Box } from "@mui/material";

/*------ import url and method ------*/
import * as url from "helper/UrlHelper";
import {
  postData,
  getData,
  putData,
  uploadSingleFile,
  uploadMultipleFile,
} from "utils/Gateway";

import TaskStatusOptions from "data/Prod/TaskStatus.json";

import ProjectLogHeader from "../ProjectLogHeader/ProjectLogHeader";
import AddLabelModal from "components/Common/Modal/AddLabelModal";
import LogDetailsModal from "../Modals/LogDetailsModal";
import ProjectLogFilterPopup from "../Popups/ProjectLogFilterPopup";
import TabsHeader from "components/Common/TabsHeader/TabsHeader";
import { projectDetailsHeaderLinks } from "helper/ProjectHelper/ProjectHelper";
import { assetImages } from "constants";
import { useTranslation } from "react-i18next";
import { createContactChatHandler } from "helper/AppUserHelper/AppUserHelper";

const ProjectLogBody = () => {
  const params = useParams();
  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const moduleAccess = localStorage.getItem("moduleaccess");
  const { t, i18n } = useTranslation(); //for translation
  // -------- tabs header object --------------------
  const commonHeaderObject = projectDetailsHeaderLinks(params.id, t);

  // -------- breadcrumb text --------
  const breadCrumbText = [
    { title: t("Projects"), link: "/admin/projectmanagement/list" },
    { title: t("Details") },
  ];

  const [title, settitle] = useState("");

  const [tagList, setTagList] = useState([]);
  const [taskList, settaskList] = useState([]);
  const [sectionOptions, setSectionOptions] = useState([]);
  const [memberList, setMemberList] = useState([]);

  const [isLoading, setisLoading] = useState(false);
  const [logList, setLogList] = useState([]);
  const [selectLogIds, setSelectLogIds] = useState([]);
  const [selectedLogId, setSelectedLogId] = useState(null);

  const [isProjectModerator, setisProjectModerator] = useState(false);

  //optionally, you can manage the row selection state yourself
  const [rowSelection, setRowSelection] = useState({});

  const [filterDateRange, setFilterDateRange] = useState("");
  const [filterTitle, setFilterTitle] = useState("");
  const [filterTags, setFilterTags] = useState([]);
  const [filterTaskIds, setfilterTaskIds] = useState([]);
  const [filterSectionIds, setfilterSectionIds] = useState([]);
  const [filterUserIds, setfilterUserIds] = useState([]);

  const [projectMemberStatus, setProjectMemberStatus] = useState("");
  const [projectModStatus, setprojectModStatus] = useState("");

  //function for get all tags
  const getAllTags = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL + url.API_GET_ALL_TAGS + `?token=${token}`;

      const response = await getData(requestUrl);

      console.log(response);

      if (response.status) {
        setTagList(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for get all task
  const getAllTasks = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_ALL_TASKS +
        `?token=${token}&projectid=${params.id}`;

      const response = await getData(requestUrl);

      console.log(response);

      if (response.status) {
        settaskList(
          response.data.map((taskData) => ({
            label: taskData.title,
            value: taskData._id,
          }))
        );
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //get all section with task
  const getAllSections = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_ALL_PROJECT_SECTION +
        `?token=${token}&projectid=${params.id}`;

      const response = await getData(requestUrl);

      setSectionOptions(
        response.data.map((sectionData) => ({
          label: sectionData.title,
          value: sectionData._id,
          startdate: sectionData.startdate,
          enddate: sectionData.enddate,
        }))
      );
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for get all event members
  const getAllProjectMembers = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_ALL_PROJECT_MEMBER +
        `?token=${token}&project=${params.id}`;

      const response = await getData(requestUrl);

      console.log(response);

      if (response.status) {
        setMemberList(
          response.data.map((memberInfo) => ({
            label: memberInfo.memebername,
            value: memberInfo.user._id,
          }))
        );
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for get project details
  const getProjectDetails = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_PROJECT_DETAILS +
        `/${params.id}?token=${token}`;

      const response = await getData(requestUrl);

      console.log(response);

      if (response.status && response.data) {
        let isModerator = false;

        if (
          response.data.moderator._id.toString() === userInfo._id.toString() ||
          userInfo.role.slug === "ADMIN" ||
          userInfo.role.slug === "SUPER_ADMIN"
        ) {
          isModerator = true;
        } else {
          getMemberDetails();
        }

        getTaskLogs(isModerator);
        setisProjectModerator(isModerator);
        settitle(response.data.title);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for get details of event member
  const getMemberDetails = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_PROJECT_MEMBER_DETAILS_QUERY +
        `?token=${token}&projectid=${params.id}`;

      const response = await getData(requestUrl);
      console.log(response);

      if (response.status) {
        setProjectMemberStatus(response.data.memberstatus);
        setprojectModStatus(response.data.moderatorstatus);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //get task logs
  const getTaskLogs = async (isModerator) => {
    setRowSelection({});
    setSelectLogIds([]);

    try {
      setisLoading(true);

      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_PROJECT_TASK_LOGS +
        `?token=${token}&project=${params.id}&ismoderator=${isModerator}`;

      if (filterDateRange != "") {
        requestUrl = requestUrl + `&filterdates=${filterDateRange}`;
      }

      if (filterTitle != "") {
        requestUrl = requestUrl + `&filtertitle=${filterTitle}`;
      }

      if (filterTags.length > 0) {
        requestUrl = requestUrl + `&filtertags=${filterTags}`;
      }

      if (filterTaskIds.length > 0) {
        requestUrl = requestUrl + `&filtertasks=${filterTaskIds}`;
      }

      if (filterSectionIds.length > 0) {
        requestUrl = requestUrl + `&filtersections=${filterSectionIds}`;
      }

      if (filterUserIds.length > 0) {
        requestUrl = requestUrl + `&filterlogusers=${filterUserIds}`;
      }

      console.log("get logs url", requestUrl);

      const response = await getData(requestUrl);

      setisLoading(false);

      console.log("get logs response", response);

      if (response.status) {
        resetFilterData();
        setLogList(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for change status
  const changeStatusHandler = async () => {
    for (let selectedId of selectLogIds) {
      try {
        let statusData = {
          status: "0",
        };
        let requestUrl =
          url.API_BASE_URL +
          url.API_UPDATE_TASK_LOG +
          `/${selectedId}` +
          `?token=${token}`;

        const response = await putData(requestUrl, statusData);

        if (response.status) {
        }
      } catch (error) {
        console.log(error.message);
      }
    }

    getTaskLogs();
  };

  //function for reset filter
  const resetFilterData = () => {
    setFilterDateRange("");
    setFilterTitle("");
    setFilterTags([]);
    setfilterTaskIds([]);
    setfilterSectionIds([]);
    setfilterUserIds([]);
  };

  useEffect(() => {
    if (
      filterDateRange != "" ||
      filterTitle != "" ||
      filterTags.length > 0 ||
      filterTaskIds.length > 0 ||
      filterSectionIds.length > 0 ||
      filterUserIds.length > 0
    ) {
      getTaskLogs();
    }
  }, [
    filterDateRange,
    filterTitle,
    filterTaskIds,
    filterSectionIds,
    filterUserIds,
    filterTags,
  ]);

  useEffect(() => {
    if (params.id) {
      getAllTags();
      getAllTasks();
      getAllSections();
      getAllProjectMembers();
      getProjectDetails();
    }
  }, [params.id]);

  /*
   * Material React Table Column and States -----------------------------------------------------------
   *---------------------------------------------------------------------------------------------------
   */
  const columns = useMemo(
    () => [
      {
        accessorKey: "#",
        header: t("Actions"),
        Cell: ({ row }) => (
          <button
            className="action_btn_mui"
            data-bs-toggle="modal"
            data-bs-target="#logDetails"
            aria-controls="logDetails"
            onClick={() => {
              setSelectedLogId(row.original._id);
            }}
          >
            <span className="d-block material-symbols-outlined horz_icon">
              more_horiz
            </span>
          </button>
        ),
        enableColumnActions: false, // Hides the column action icon
        enableColumnDragging: false, // Hides the move icon
        enableSorting: false,
      },
      {
        accessorKey: "taskcustomid",
        header: t("Task Id"),
        size: 80,
      },
      {
        accessorKey: "tasktitle",
        header: t("Task"),
        size: 250,
      },
      {
        accessorKey: "username",
        header: t("Log Added By"),
        Cell: ({ row }) => (
          <Box className="title border-bottom-0">
            <div className="d-flex align-items-center gap-4">
              <Link
                to={
                  row.original.logownerid
                    ? `/admin/mytasklist/${row.original.logownerid}`
                    : "#"
                }
                className="d-flex align-items-center gap-1 fs-md"
              >
                {row.original.username}
              </Link>
              <button
                onClick={() => {
                  createContactChatHandler(
                    row.original.logownerid,
                    "MOD_LOG",
                    row.original._id,
                    row.original.tasktitle.slice(0, 20)
                  );
                }}
                className={
                  !row.original.logownerid ||
                  row.original.logownerid === userInfo._id
                    ? "d-none"
                    : "d-flex align-items-center ms-auto border-0 bg-light"
                }
              >
                <span className="d-block material-symbols-outlined icon-md text-primary">
                  chat
                </span>
              </button>
            </div>
          </Box>
        ),
      },
      {
        accessorKey: "startdate",
        header: t("From Date"),
      },
      {
        accessorKey: "enddate",
        header: t("To Date"),
      },
      {
        accessorKey: "hours",
        header: t("Hours"),
      },
      {
        accessorKey: "tagstring",
        header: t("Label"),
        Cell: ({ row }) => (
          <div className="label border-bottom-0">
            {row.original.tagstring != "" && (
              <ul className="d-flex flex-wrap gap-2 fs-xs">
                {row.original.tagstring.split(" , ").map((tag, index) => {
                  return (
                    <li
                      key={index}
                      className="px-2 py-1 gradient-light rounded-5"
                    >
                      {tag}
                    </li>
                  );
                })}
              </ul>
            )}
          </div>
        ),
      },
      // {
      //   accessorKey: "#",
      //   header: "Action",
      //   Cell: ({ row }) => (
      //     <div className="action border-bottom-0">
      //       <Link
      //         to="#"
      //         className="d-flex align-items-center gap-1 fs-md fw-semibold text-primary"
      //         data-bs-toggle="modal"
      //         data-bs-target="#logDetails"
      //         onClick={() => {
      //           setSelectedLogId(row.original._id);
      //         }}
      //       >
      //         <span className="d-block">Details</span>
      //         <span className="d-block material-symbols-outlined">
      //           chevron_right
      //         </span>
      //       </Link>
      //     </div>
      //   ),
      // },
    ],
    [i18n.language]
  );

  //initialize the column order
  const columnOrder = [
    "#",
    "mrt-row-select",
    ...columns.map((c) => c.accessorKey),
  ]; //array of column ids (Initializing is optional as of v2.10.0)

  useEffect(() => {
    const selectedIdsArray = Object.keys(rowSelection).filter(
      (key) => rowSelection[key]
    );

    if (selectedIdsArray.length > 0) {
      setSelectLogIds(selectedIdsArray);
    } else {
      setSelectLogIds([]);
    }
  }, [rowSelection]);

  if (
    userInfo.role.slug === "ADMIN" ||
    userInfo.role.slug === "SUPER_ADMIN" ||
    moduleAccess.includes("MOD_PROJECT")
  ) {
    return (
      <div id="content_wrapper">
        <section className="event-details-wrapper bg-white pb-5">
          {/* ------ common header -------- */}
          {params.id && (
            <TabsHeader
              commonHeaderObject={commonHeaderObject}
              activeOption={t("Logs")}
            />
          )}

          <div className="container-fluid px-lg-5 pt-4 pt-md-0">
            {/* ------- bread crumb -------- */}

            {/* ------- filter area --------- */}
            <ProjectLogHeader
              breadCrumbText={breadCrumbText}
              reloadList={getTaskLogs}
              changeStatusHandler={changeStatusHandler}
              isProjectModerator={isProjectModerator}
              projectMemberStatus={projectMemberStatus}
              projectModStatus={projectModStatus}
              title={title}
            />

            {isLoading ? (
              <div className="placeholder-glow d-flex flex-column gap-4">
                <span className="placeholder placeholder-lg bg-secondary col-12"></span>
                <span className="placeholder placeholder-lg bg-secondary col-8"></span>
                <span className="placeholder placeholder-lg bg-secondary col-4"></span>
              </div>
            ) : isProjectModerator ||
              (projectMemberStatus === "1" && projectModStatus === "1") ? (
              <div className="table-wrapper">
                <MaterialReactTable
                  columns={columns} // map columns to be displayed with api data,
                  data={logList} // data from api to be displayed
                  enableGrouping
                  enableRowSelection // enable showing checkbox
                  getRowId={(row) => row._id} // map which value to select with row checkbox
                  onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
                  state={{ rowSelection, columnOrder }} //pass our managed row selection state to the table to use
                  defaultColumn={{
                    minSize: 20,
                    maxSize: 300,
                    size: 50, //make columns wider by default
                  }}
                  // enableRowActions // show the action column
                  // renderRowActionMenuItems={({ row }) => [
                  //   <MenuItem key="edit">
                  //     <Link className="dropdown-item" to="#">
                  //       View Details
                  //     </Link>
                  //   </MenuItem>,
                  //   <MenuItem key="delete">
                  //     <Link className="dropdown-item" to="#">
                  //       Update
                  //     </Link>
                  //   </MenuItem>,
                  //   <MenuItem key="delete">
                  //     <Link className="dropdown-item" to="#">
                  //       Add to Challenge
                  //     </Link>
                  //   </MenuItem>,
                  //   <MenuItem key="delete">
                  //     <Link
                  //       className="dropdown-item"
                  //       to="#"
                  //       data-bs-toggle="modal"
                  //       data-bs-target="#addLabelModal"
                  //     >
                  //       Add to Tag
                  //     </Link>
                  //   </MenuItem>,
                  //   <MenuItem key="delete">
                  //     <Link className="dropdown-item" to="#">
                  //       Add to List
                  //     </Link>
                  //   </MenuItem>,
                  // ]} // action columns menu items
                  muiTableContainerProps={{
                    sx: {
                      maxHeight: "60vh",
                    },
                  }}
                  enableStickyHeader
                />
              </div>
            ) : projectMemberStatus === "" && projectModStatus === "" ? null : (
              <div className="table-wrapper">
                <div className="challenges_empty text-center">
                  <div className="empty_pic mb-4">
                    {" "}
                    <img src={assetImages.emptyVector} alt="" />
                  </div>
                  <div className="empty_text">
                    <p className="fs-lg text-gray fw-semibold mb-4">
                      {t(
                        "Sorry....! You don't have privilege to see this content"
                      )}
                    </p>
                  </div>
                </div>
              </div>
            )}
          </div>
        </section>

        {/* ========= popups and modals area ========= */}
        {/* --------- Add label modal ---------- */}
        <AddLabelModal
          moduleName="tasklog"
          selectedIds={selectLogIds}
          afterTagModalClose={getTaskLogs}
        />

        {/* ----- log details modal ------- */}
        <LogDetailsModal
          selectedLogId={selectedLogId}
          onModalClose={() => {
            setSelectedLogId(null);
          }}
        />

        {/* ---------- filter popup --------- */}
        <ProjectLogFilterPopup
          memberList={memberList}
          taskList={taskList}
          sectionOptions={sectionOptions}
          tagList={tagList}
          setFilterTitle={setFilterTitle}
          setFilterDateRange={setFilterDateRange}
          setFilterTags={setFilterTags}
          setfilterTaskIds={setfilterTaskIds}
          setfilterSectionIds={setfilterSectionIds}
          setfilterUserIds={setfilterUserIds}
        />
      </div>
    );
  } else {
    return (
      <div id="content_wrapper">
        <section className="crm-wrapper bg-white pb-5">
          <div className="empty_access text-center">
            <div className="empty_pic mb-4">
              {" "}
              <img src={assetImages.emptyVector} alt="" />
            </div>
            <div className="empty_text">
              <p className="fs-lg text-gray fw-semibold mb-4">
                {t("Sorry....! You don't have privilege to see this content")}
              </p>
            </div>
          </div>
        </section>
      </div>
    );
  }
};

export default ProjectLogBody;
