import React from "react";
import { Link } from "react-router-dom";

import { assetImages } from "constants";

import * as url from "helper/UrlHelper";
import { createContactChatHandler } from "helper/AppUserHelper/AppUserHelper";
// import { getCurrentDateInString } from "helper/getcurrentdatestring";
// import { formatNumber } from "helper/Common/CommonHelper";

const KanbanCardElem = ({
  columnData = {},
  provided = {},
  leadItem = {},
  isProcessOwner = false,
  // setSelectedLeadId = () => {},
  deleteLeadHandler = () => {},
}) => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  return (
    <div
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      ref={provided.innerRef}
      className={
        leadItem?.ownerid?.toString() === userInfo._id.toString() ||
        isProcessOwner
          ? "single-card p-3 border border-gray-300 rounded-10 mb-2"
          : "d-none"
      }
      style={{
        ...provided.draggableProps.style,
      }}
    >
      <div className="title d-flex align-items-center gap-3 justify-content-between">
        <div className="d-flex align-items-center gap-2">
          <p className="fs-md fw-semibold mb-0">{leadItem.fullname}</p>
        </div>

        {/* <button className="d-flex align-items-center ms-auto border-0 bg-light">
          <span className="d-block material-symbols-outlined icon-md text-primary">
            chat
          </span>
        </button> */}

        <div className="dropdown d-flex">
          <button
            className="p-0 bg-transparent border-0 dropdown-toggle rounded-circle"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <span className="d-block material-symbols-outlined icon-md">
              more_vert
            </span>
          </button>
          <ul className="dropdown-menu dropdown-menu-end bg-white fs-sm border-0 rounded-10 shadow-sm">
            <li>
              <Link
                className="dropdown-item"
                to={`/admin/crm/lead/information/${leadItem._id}`}
                target="_blank"
                // to="#"
                // data-bs-toggle="offcanvas"
                // data-bs-target="#saveInvoice"
                // aria-controls="saveInvoice"
                // onClick={() => {
                //   setSelectedLeadId(leadItem._id);
                // }}
              >
                View Details
              </Link>
            </li>
            <li>
              <Link
                className="dropdown-item"
                to="#"
                onClick={() => {
                  deleteLeadHandler(leadItem, columnData.tagid);
                }}
              >
                Delete Lead
              </Link>
            </li>
          </ul>
        </div>
      </div>

      <p className="fs-sm text-gray d-flex align-items-center gap-1 mb-0">
        <span className="d-block material-symbols-outlined icon-sm">
          calendar_month
        </span>
        <span className="d-block">{leadItem.date}</span>
      </p>

      <div className="d-flex align-items-center gap-2 my-1">
        <p className="fs-sm text-info d-flex align-items-center gap-1 mb-0">
          <span className="d-block">Hrs :</span>
          <span className="d-block text-black">
            {leadItem?.componentduration} -{" "}
          </span>
        </p>

        <p className="fs-sm text-info d-flex align-items-center gap-1 mb-0">
          <span className="d-block">GHrs :</span>
          <span className="d-block text-black">
            {leadItem?.elementduration}
          </span>
        </p>
      </div>

      <div className="d-flex align-items-center gap-1 my-1">
        <span className="fw-semibold">M</span> :
        <div className="profile d-flex align-items-center gap-2">
          <div
            className="avatar rounded-circle overflow-hidden"
            style={{
              width: "22px",
              height: "22px",
            }}
          >
            <img
              src={
                leadItem.onwerimage === ""
                  ? assetImages.defaultUser
                  : url.SERVER_URL + leadItem.onwerimage
              }
              alt="User"
              className="w-100 h-100 object-fit-cover object-center"
            />
          </div>
          {leadItem.ownername}

          {leadItem?.ownerid?.toString() === userInfo._id.toString() ? null : (
            <button
              onClick={() => {
                createContactChatHandler(
                  leadItem.ownerid,
                  "MOD_LEAD",
                  leadItem._id,
                  leadItem.fullname
                );
              }}
              className="d-flex align-items-center ms-auto border-0 bg-light"
            >
              <span className="d-block material-symbols-outlined icon-md text-primary">
                chat
              </span>
            </button>
          )}
        </div>
      </div>

      {leadItem.followersarr && leadItem.followersarr.length > 0 ? (
        <div className="d-flex align-items-center gap-2">
          <span className="fw-semibold">F</span> :
          <div>
            <div className="profile d-flex flex-column align-items-center gap-2">
              {leadItem.followersarr.map((follower, indexImg) => {
                return (
                  <div
                    className="d-flex align-items-center gap-1"
                    key={indexImg}
                  >
                    <div
                      className="avatar rounded-circle overflow-hidden"
                      style={{
                        width: "22px",
                        height: "22px",
                      }}
                    >
                      <img
                        src={
                          follower.follwerimage === ""
                            ? assetImages.defaultUser
                            : url.SERVER_URL + follower.follwerimage
                        }
                        alt="User"
                        className="w-100 h-100 object-fit-cover object-center"
                      />
                    </div>
                    {follower.followername}

                    {follower?.followerid?.toString() ===
                    userInfo._id.toString() ? null : (
                      <button
                        onClick={() => {
                          createContactChatHandler(
                            follower?.followerid,
                            "MOD_LEAD",
                            leadItem._id,
                            leadItem.fullname
                          );
                        }}
                        className="d-flex align-items-center ms-auto border-0 bg-light"
                      >
                        <span className="d-block material-symbols-outlined icon-md text-primary">
                          chat
                        </span>
                      </button>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default KanbanCardElem;
