/* eslint-disable */
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const OfferFilterPopup = ({
  setFilterTitle,
  setFilterStartPrice,
  setFilterEndPrice,
  setFilterStartDiscount,
  setFilterEndDiscount,
}) => {
  const { t } = useTranslation();

  const [title, setTitle] = useState("");
  const [startPrice, setStartPrice] = useState("");
  const [endPrice, setEndPrice] = useState("");
  const [startDiscount, setStartDiscount] = useState("");
  const [endDiscount, setEndDiscount] = useState("");

  const searchHandler = () => {
    setFilterTitle(title);
    setFilterStartPrice(startPrice);
    setFilterEndPrice(endPrice);
    setFilterStartDiscount(startDiscount);
    setFilterEndDiscount(endDiscount);

    const offCanvasPopup = document.querySelector("#offerFilter");
    const offCanvas = bootstrap.Offcanvas.getInstance(offCanvasPopup);
    offCanvas.hide();
    resetHandler();
  };

  const resetHandler = () => {
    setTitle("");
    setStartPrice("");
    setEndPrice("");
    setStartDiscount("");
    setEndDiscount("");
  };

  return (
    <div
      className="offcanvas offcanvas-end bg-white border-0"
      tabIndex="-1"
      id="offerFilter"
    >
      <div className="offcanvas-header justify-content-end p-4 pb-0">
        <button
          type="button"
          className="btn-close p-0 bg-gray-200 rounded-circle shadow-none m-0"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          onClick={resetHandler}
        ></button>
      </div>

      <div className="offcanvas-body p-4">
        <form onSubmit={(e) => e.preventDefault()}>
          {/* Title Field */}
          <div className="form-group mb-4">
            <label className="d-block fs-sm fw-semibold mb-2">
              <h3>{t("Title")}</h3>
            </label>
            <input
              type="text"
              className="form-control fs-sm shadow-none"
              placeholder={t("Enter Title")}
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </div>

          {/* Price Range */}
          <div className="form-group mb-4">
            <label className="d-block fs-sm fw-semibold mb-2">
              <h3>{t("Price Range")}</h3>
            </label>
            <div className="row g-3">
              <div className="col-6">
                <input
                  type="number"
                  className="form-control fs-sm shadow-none"
                  placeholder={t("Start Price")}
                  value={startPrice}
                  onChange={(e) => setStartPrice(e.target.value)}
                />
              </div>
              <div className="col-6">
                <input
                  type="number"
                  className="form-control fs-sm shadow-none"
                  placeholder={t("End Price")}
                  value={endPrice}
                  onChange={(e) => setEndPrice(e.target.value)}
                />
              </div>
            </div>
          </div>

          {/* Discount Range */}
          <div className="form-group mb-4">
            <label className="d-block fs-sm fw-semibold mb-2">
              <h3>{t("Discount Range")}</h3>
            </label>
            <div className="row g-3">
              <div className="col-6">
                <input
                  type="number"
                  className="form-control fs-sm shadow-none"
                  placeholder={t("Start Discount")}
                  value={startDiscount}
                  onChange={(e) => setStartDiscount(e.target.value)}
                />
              </div>
              <div className="col-6">
                <input
                  type="number"
                  className="form-control fs-sm shadow-none"
                  placeholder={t("End Discount")}
                  value={endDiscount}
                  onChange={(e) => setEndDiscount(e.target.value)}
                />
              </div>
            </div>
          </div>

          <button
            onClick={searchHandler}
            type="button"
            className="btn btn-primary fs-sm fw-normal w-100 mt-4"
          >
            {t("Search")}
          </button>
        </form>
      </div>
    </div>
  );
};

export default OfferFilterPopup;
