/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";

/*------ import url and method ------*/
import * as url from "helper/UrlHelper";
import { getData } from "utils/Gateway";
import { useTranslation } from "react-i18next";
import { ecosystemSlug } from "Config/Config";

const ProductFilterPopup = ({
  isFilterReset,
  setIsFilterReset,
  setfilterTitle,
  setfilterSku,
  setfilterCompanies,
  setfilterStartPrice,
  setfilterEndPrice,
  setfilterStartStock,
  setfilterEndStock,
  setfilterTypes,
  setfilterTags,
}) => {
  const { t } = useTranslation(); //for translation
  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const [productTypeList, setproductTypeList] = useState([]);
  const [tagList, setTagList] = useState([]);

  const [companyInput, setCompanyInput] = useState("");
  const [companyList, setCompanyList] = useState([]);

  const [title, setTitle] = useState("");
  const [sku, setSku] = useState("");
  const [companyValue, setCompanyValue] = useState(null);
  const [companyIds, setCompanyIds] = useState([]);
  const [startPrice, setstartPrice] = useState("");
  const [endPrice, setendPrice] = useState("");
  const [startStock, setstartStock] = useState("");
  const [endStock, setendStock] = useState("");
  const [selectedTypes, setselectedTypes] = useState([]);
  const [selectedTags, setselectedTags] = useState([]);

  const [fromDate, setfromDate] = useState("");
  const [toDate, settoDate] = useState("");

  /* hook for validation */
  const [validation, setValidation] = useState({
    priceWarning: false,
    stockWarning: false,
  });

  // function to filter tags by search term
  const [searchTagTerm, setSearchTagTerm] = useState("");

  const showFilteredTags =
    searchTagTerm.length > 0
      ? tagList.filter((tag) =>
          tag.label.toLowerCase().includes(searchTagTerm.toLowerCase())
        )
      : tagList;

  /* function to validate form */
  const validationHandler = () => {
    let isValid = true;

    if (startPrice !== "" && endPrice === "") {
      setValidation((prevState) => ({ ...prevState, priceWarning: true }));
      isValid = false;
    }

    if (startStock !== "" && endStock === "") {
      setValidation((prevState) => ({ ...prevState, stockWarning: true }));
      isValid = false;
    }

    return isValid;
  };

  //get all product types
  const getAllProductType = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_ALL_PRODUCT_TYPE +
        `?ecosystemslug=${ecosystemSlug}`;

      const response = await getData(requestUrl);

      // console.log(response);

      if (response.status) {
        setproductTypeList(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for get all tags
  const getAllTags = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL + url.API_GET_ALL_TAGS + `?token=${token}`;

      const response = await getData(requestUrl);

      console.log(response);

      if (response.status) {
        setTagList(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for get all contacts
  const getAllCompanyList = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_ALL_COMPANY_LIST +
        `?token=${token}&filtername=${companyInput}`;

      console.log("url of app user list------>", requestUrl);

      const response = await getData(requestUrl);

      console.log("response in app user list------>", response);

      if (response.status) {
        setCompanyList(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //select company
  const companySelectionHandler = (val) => {
    setCompanyValue(val);
    setCompanyIds(val.map((item) => item.value));
  };

  //function for select type
  const productTypeSelectionHandler = (data) => {
    let prevTypes = selectedTypes;
    if (prevTypes.includes(data.value)) {
      let selectedTagItem = document.getElementById("typefilter-" + data.value);
      selectedTagItem.classList.remove("active");
      // exist in array, thus remove
      let key = prevTypes.indexOf(data.value);
      prevTypes.splice(key, 1);
    } else {
      let selectedTagItem = document.getElementById("typefilter-" + data.value);
      selectedTagItem.classList.add("active");
      // not exist in array, thus add
      prevTypes.push(data.value);
    }
    setselectedTypes(prevTypes);
  };

  // function for set tags
  const tagSelectionHandler = (data) => {
    let prevTags = selectedTags;
    if (prevTags.includes(data.value)) {
      let selectedTagItem = document.getElementById("tagfilter-" + data.value);
      selectedTagItem.classList.remove("active");
      // exist in array, thus remove
      let key = prevTags.indexOf(data.value);
      prevTags.splice(key, 1);
    } else {
      let selectedTagItem = document.getElementById("tagfilter-" + data.value);
      selectedTagItem.classList.add("active");
      // not exist in array, thus add
      prevTags.push(data.value);
    }
    setselectedTags(prevTags);
  };

  //function for remove active class for selected tags
  const removeActiveClass = () => {
    const liElements = document.querySelectorAll(".tags li a");
    liElements.forEach((li) => {
      li.classList.remove("active");
    });
  };

  // reset selected type
  const resetSelectTypes = () => {
    let resetBlock = document.getElementsByClassName("typeblock");
    for (let i = 0; i < resetBlock.length; i++) {
      resetBlock[i].classList.remove("active");
    }
  };

  // reset selected tags
  const resetSelectTags = () => {
    let resetBlock = document.getElementsByClassName("tagblock");
    for (let i = 0; i < resetBlock.length; i++) {
      resetBlock[i].classList.remove("active");
    }
  };

  //function for search
  const searchHandler = () => {
    if (validationHandler()) {
      setfilterTitle(title);
      setfilterSku(sku);
      setfilterCompanies(companyIds);
      setfilterStartPrice(startPrice);
      setfilterEndPrice(endPrice);
      setfilterStartStock(startStock);
      setfilterEndStock(endStock);
      setfilterTypes(selectedTypes);
      setfilterTags(selectedTags);

      // close the offcanvas of filter
      let offCanvasPopup = document.querySelector("#offcanvasProiductFilter");

      let offCanvas = bootstrap.Offcanvas.getInstance(offCanvasPopup);
      offCanvas.hide();
    }
  };

  //function for reset search
  const resetHandler = () => {
    setTitle("");
    setSku("");
    setCompanyValue(null);
    setCompanyIds([]);
    setCompanyInput("");
    setCompanyList([]);
    setstartPrice("");
    setendPrice("");
    setstartStock("");
    setendStock("");
    setselectedTypes([]);
    setselectedTags([]);

    resetSelectTypes();
    resetSelectTags();
    removeActiveClass();

    setValidation({
      priceWarning: false,
      stockWarning: false,
    });
  };

  useEffect(() => {
    if (isFilterReset) {
      resetHandler();
      setIsFilterReset(false);
    }
  }, [isFilterReset]);

  useEffect(() => {
    if (companyInput.length > 1) {
      getAllCompanyList();
    }
  }, [companyInput]);

  useEffect(() => {
    getAllProductType();
    getAllTags();
  }, []);

  return (
    <div
      className="offcanvas offcanvas-end bg-white border-0"
      tabIndex="-1"
      id="offcanvasProiductFilter"
      aria-labelledby="offcanvasProiductFilterLabel"
    >
      <div className="offcanvas-header justify-content-end p-4 pb-0">
        <button
          type="button"
          className="btn-close p-0 bg-gray-200 rounded-circle shadow-none m-0"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
          onClick={resetHandler}
        ></button>
      </div>
      <div className="offcanvas-body p-4">
        <form onSubmit={(e) => e.preventDefault()}>
          {/* date  */}
          {/* <div className="form-group mb-4">
            <label
              htmlFor="Subscribe"
              className="d-block fs-sm fw-semibold mb-2"
            >
              <h3>{t("Dates")}</h3>{" "}
            </label>

            <div className=" d-flex gap-2">
              <input
                type="date"
                className="form-control fs-sm shadow-none"
                placeholder={t("From date")}
                value={fromDate}
                onChange={(e) => setfromDate(e.target.value)}
              />
              <input
                type="date"
                className="form-control fs-sm shadow-none"
                placeholder={t("To date")}
                value={toDate}
                onChange={(e) => settoDate(e.target.value)}
              />
            </div>
          </div> */}

          {/* ----- title accordion start ----- */}
          <div className="form-group mb-4">
            <label
              htmlFor="Subscribe"
              className="d-block fs-sm fw-semibold mb-2"
            >
              <h3>{t("Title")}</h3>{" "}
            </label>
            <input
              type="text"
              className="form-control fs-sm shadow-none"
              placeholder={t("Event title")}
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </div>
          {/* ----- title accordion end ----- */}

          {/* sku  */}
          <div className="form-group mb-4">
            <label
              htmlFor="Subscribe"
              className="d-block fs-sm fw-semibold mb-2"
            >
              <h3>{t("Sku")}</h3>{" "}
            </label>
            <input
              type="text"
              className="form-control fs-sm shadow-none"
              placeholder={t("title")}
              value={sku}
              onChange={(e) => setSku(e.target.value)}
            />
          </div>

          {/* company */}
          <div className="form-group mb-4">
            <label
              htmlFor="Subscribe"
              className="d-block fs-sm fw-semibold mb-2"
            >
              <h3>{t("Company")}</h3>{" "}
            </label>
            <Select
              isMulti
              placeholder={t("Type Company name")}
              options={companyList}
              value={companyValue}
              onChange={(val) => {
                companySelectionHandler(val);
              }}
              onInputChange={(val) => {
                setCompanyInput(val);
              }}
            />
          </div>

          {/* price range  */}
          <div className="form-group mb-4">
            <label
              htmlFor="Subscribe"
              className="d-block fs-sm fw-semibold mb-2"
            >
              <h3>{t("Price")}</h3>{" "}
            </label>

            <div className=" d-flex gap-2">
              <input
                type="number"
                className="form-control fs-sm shadow-none"
                placeholder={t("Lowest Price")}
                value={startPrice}
                onChange={(e) => {
                  setstartPrice(e.target.value);
                  setValidation((prevState) => ({
                    ...prevState,
                    priceWarning: false,
                  }));
                }}
              />
              <input
                type="number"
                className="form-control fs-sm shadow-none"
                placeholder={t("Highest Price")}
                value={endPrice}
                onChange={(e) => {
                  setendPrice(e.target.value);
                  setValidation((prevState) => ({
                    ...prevState,
                    priceWarning: false,
                  }));
                }}
              />
            </div>
            {validation.priceWarning && (
              <div className="error-message mt-3">
                <p className="d-flex align-items-center gap-1 text-danger">
                  <span className="material-symbols-outlined">warning</span>
                  <span>Please enter end price!</span>
                </p>
              </div>
            )}
          </div>

          {/* stock range  */}
          <div className="form-group mb-4">
            <label
              htmlFor="Subscribe"
              className="d-block fs-sm fw-semibold mb-2"
            >
              <h3>{t("Stock")}</h3>{" "}
            </label>

            <div className=" d-flex gap-2">
              <input
                type="number"
                className="form-control fs-sm shadow-none"
                placeholder={t("Lowest Stock")}
                value={startStock}
                onChange={(e) => {
                  setstartStock(e.target.value);
                  setValidation((prevState) => ({
                    ...prevState,
                    stockWarning: false,
                  }));
                }}
              />
              <input
                type="number"
                className="form-control fs-sm shadow-none"
                placeholder={t("Highest Stock")}
                value={endStock}
                onChange={(e) => {
                  setendStock(e.target.value);
                  setValidation((prevState) => ({
                    ...prevState,
                    stockWarning: false,
                  }));
                }}
              />
            </div>
            {validation.stockWarning && (
              <div className="error-message mt-3">
                <p className="d-flex align-items-center gap-1 text-danger">
                  <span className="material-symbols-outlined">warning</span>
                  <span>Please enter end stock!</span>
                </p>
              </div>
            )}
          </div>

          <div className="accordion" id="accordionPanelsStayOpenExample">
            {/* ----- type accordion start ----- */}
            <div className="accordion-item bg-transparent border-0">
              <h3 className="accordion-header">
                <button
                  className="accordion-button bg-transparent p-0 shadow-none"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#panelsStayOpen-collapseOne"
                  aria-expanded="true"
                  aria-controls="panelsStayOpen-collapseOne"
                >
                  {t("Product Type")}
                </button>
              </h3>
              <div
                id="panelsStayOpen-collapseOne"
                className="accordion-collapse collapse show"
              >
                <div className="accordion-body p-0 pt-4">
                  <ul className="tags d-flex flex-wrap gap-2 fs-sm fw-semibold">
                    {productTypeList.map((typeData, index) => {
                      return (
                        <li className="tag" key={index}>
                          <Link
                            to="#"
                            onClick={() =>
                              productTypeSelectionHandler(typeData)
                            }
                            id={"typefilter-" + typeData.value}
                            className="typeblock d-block px-3 py-2 gradient-light cursor-pointer rounded-90"
                          >
                            <span>{typeData.label}</span>
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>
            {/* ----- type accordion end ----- */}

            {/* category  */}
            {/* <div className="accordion-item bg-transparent border-0">
              <h3 className="accordion-header">
                <button
                  className="accordion-button bg-transparent p-0 shadow-none"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#panelsStayOpen-collapseCat"
                  aria-expanded="true"
                  aria-controls="panelsStayOpen-collapseCat"
                >
                  {t("Category")}
                </button>
              </h3>
              <div
                id="panelsStayOpen-collapseCat"
                className="accordion-collapse collapse show"
              >
                <div className="accordion-body p-0 pt-4">
                  <ul className="tags d-flex flex-wrap gap-2 fs-sm fw-semibold">
                    {productTypeList.map((type, index) => {
                      return (
                        <li className="tag" key={index}>
                          <Link
                            to="#"
                            //onClick={() => eventTypeSelectionHandler(eventType)}
                            id={"typefilter-" + type.value}
                            className="skillblock d-block px-3 py-2 gradient-light cursor-pointer rounded-90"
                          >
                            <span>{type.label}</span>
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div> */}

            {/* ----- tag accordion start ----- */}
            <div className="accordion-item bg-transparent border-0">
              <h3 className="accordion-header">
                <button
                  className="accordion-button bg-transparent p-0 shadow-none"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#panelsStayOpen-collapseFour"
                  aria-expanded="true"
                  aria-controls="panelsStayOpen-collapseFour"
                >
                  {t("Labels")}
                </button>
              </h3>
              <div
                id="panelsStayOpen-collapseFour"
                className="accordion-collapse collapse show"
              >
                <div className="accordion-body p-0 pt-4">
                  <input
                    type="text"
                    className="form-control mb-3"
                    placeholder="Search tags..."
                    value={searchTagTerm}
                    onChange={(e) => setSearchTagTerm(e.target.value)}
                  />
                  <ul className="tags d-flex flex-wrap gap-2 fs-sm fw-semibold">
                    {showFilteredTags.map((tagData, index) => {
                      return (
                        <li className="tag" key={index}>
                          <Link
                            to="#"
                            onClick={() => tagSelectionHandler(tagData)}
                            id={"tagfilter-" + tagData.value}
                            className="tagblock d-block px-3 py-2 gradient-light cursor-pointer rounded-90"
                          >
                            <span>{tagData.label}</span>
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>
            {/* ----- tag accordion end ----- */}
          </div>
          <button
            type="button"
            className="btn btn-primary fs-sm fw-normal w-100 mt-4"
            onClick={searchHandler}
          >
            {t("Search")}
          </button>
        </form>
      </div>
    </div>
  );
};

export default ProductFilterPopup;
