/* eslint-disable */
import React, { useState, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

// Material UI table
import { MaterialReactTable } from "material-react-table";

//Material UI components for rendring menu and others
import { Box, MenuItem } from "@mui/material";

/*------ import url and method ------*/
import * as url from "helper/UrlHelper";
import { getData, putData } from "utils/Gateway";

//import images
import { assetImages } from "constants";

import AlertNotification from "components/Common/AlertNotification/AlertNotification";
import { getAllCategories } from "helper/CategoryHelper/CategoryHelper";
import DeleteWarningModal from "components/Common/Modal/DeleteWarningModal";
import AddLabelModal from "components/Common/Modal/AddLabelModal";
import FaqListHeader from "./Header/FaqListHeader";
import SaveFaqPopup from "./Popup/SaveFaqPopup";
import FaqFilterpopup from "./Popup/FaqFilterpopup";
import AssignExpertPopup from "./Popup/AssignExpertPopup";
import AssignContentPopup from "./Popup/AssignContentPopup";
import AssignCoursePopup from "./Popup/AssignCoursePopup";
import GenerateDebatePopup from "./Popup/GenerateDebatePopup";
import AssignCompanyPopup from "./Popup/AssignCompanyPopup";

const FaqListBody = () => {
  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const moduleAccess = localStorage.getItem("moduleaccess");

  const ecosystemSlug = localStorage.getItem("ecosystemslug");

  const { t, i18n } = useTranslation(); //for translation

  const [isLoading, setIsLoading] = useState(false);
  const [faqList, setFaqList] = useState([]);

  const [filterQuery, setfilterQuery] = useState("");
  const [filterTags, setfilterTags] = useState([]);
  const [filterAskedBy, setfilterAskedBy] = useState([]);
  const [filterAnsweredBy, setfilterAnsweredBy] = useState([]);
  const [filterApprovalStatus, setfilterApprovalStatus] = useState("");
  const [filterStartDate, setfilterStartDate] = useState("");
  const [filterEndDate, setfilterEndDate] = useState("");

  const [isRefresh, setIsRefresh] = useState(false);

  //optionally, you can manage the row selection state yourself
  const [rowSelection, setRowSelection] = useState({});
  const [selectedFaqId, setSelectedFaqId] = useState(null);
  const [selectedFaqIds, setSelectedFaqIds] = useState([]);

  const [isUpdating, setIsUpdating] = useState(false);

  //alert requirements
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [messageType, setMessageType] = useState("");

  //function for get all tags
  const getAllFaqs = async () => {
    setRowSelection({});
    setSelectedFaqIds([]);
    try {
      setIsLoading(true);

      let requestURL =
        url.API_BASE_URL +
        url.API_GET_ALL_FAQS +
        `?token=${token}&modulefrom=backend`;

      if (filterQuery != "") {
        requestURL += `&filterquery=${filterQuery}`;
      }

      if (filterTags.length > 0) {
        console.log(filterTags);
        requestURL += `&filtertags=${filterTags.join(",")}`;
      }

      if (filterAskedBy.length > 0) {
        requestURL += `&filteraskedby=${filterAskedBy.join(",")}`;
      }

      if (filterAnsweredBy.length > 0) {
        requestURL += `&filteransweredby=${filterAnsweredBy.join(",")}`;
      }

      if (filterApprovalStatus != "") {
        requestURL += `&filterapprovalstatus=${filterApprovalStatus}`;
      }

      if (filterStartDate !== "" && filterEndDate !== "") {
        requestURL += `&filterstartdate=${filterStartDate}&filterenddate=${filterEndDate}`;
      }

      console.log(requestURL);

      const response = await getData(requestURL);

      console.log(response);

      setIsLoading(false);

      if (response.status) {
        setFaqList(response.data);
        setMessageType("success");
        // resetFilterData();
      } else {
        setMessageType("error");
      }

      setAlertMessage(response.message);
    } catch (error) {
      setAlertMessage(error.message);
      setMessageType("error");
    }

    setShowAlert(true);
  };

  //function for change rank
  const rankInputChangeHandler = (row, value) => {
    const updatedList = [...faqList];

    const rowIndex = updatedList.findIndex(
      (item) => item._id === row.original._id
    );

    if (rowIndex !== -1) {
      updatedList[rowIndex].rank = value;
      setFaqList(updatedList);
    }
  };

  //function for update rank
  const updateBulkHandler = async (action = "") => {
    if (selectedFaqIds.length > 0) {
      setIsUpdating(true);
      try {
        const listToBeUpdated = faqList.filter((item) =>
          selectedFaqIds.includes(item._id)
        );

        let apiData = {
          faqlist: listToBeUpdated,
        };

        if (action === "approve") {
          apiData.approvalstatus = "1";
        }

        let requestURL =
          url.API_BASE_URL + url.API_UPDATE_MASSIVE_FAQ + `?token=${token}`;

        const response = await putData(requestURL, apiData);

        console.log(response);

        setIsUpdating(false);

        if (response.status) {
          setMessageType("success");

          setTimeout(() => {
            getAllFaqs();
          }, 1500);
        } else {
          setMessageType("error");
        }

        setAlertMessage(response.message);
      } catch (error) {
        setAlertMessage(error.message);
        setMessageType("error");
      }
    } else {
      setMessageType("error");
      setAlertMessage("Please select atleast one faq");
    }
    setShowAlert(true);
  };

  //function for delete group
  const deleteMassiveHandler = async () => {
    if (selectedFaqIds.length > 0) {
      const listToBeDelete = faqList.filter((item) =>
        selectedFaqIds.includes(item._id)
      );

      try {
        setIsUpdating(true);

        let apiData = {
          faqlist: listToBeDelete,
        };

        let requestURL =
          url.API_BASE_URL + url.API_DELETE_MASSIVE_FAQ + `?token=${token}`;

        const response = await putData(requestURL, apiData);

        console.log(response);

        setIsUpdating(false);

        if (response.status) {
          setMessageType("success");
          setTimeout(() => {
            getAllFaqs();
          }, 1500);
        } else {
          setMessageType("error");
        }

        setAlertMessage(response.message);
      } catch (error) {
        setAlertMessage(error.message);
        setMessageType("error");
      }

      setShowAlert(true);
    } else {
      setMessageType("error");
      setAlertMessage("Please select atleast one course");
      setShowAlert(true);
    }
  };

  //reset filter
  const resetFilter = () => {
    setfilterQuery("");
    setfilterTags([]);
    setfilterAskedBy([]);
    setfilterAnsweredBy([]);
    setfilterApprovalStatus("");
    setfilterStartDate("");
    setfilterEndDate("");

    setIsRefresh(true);
  };

  //usee effect dependent on filter states
  useEffect(() => {
    if (
      filterQuery !== "" ||
      filterTags.length > 0 ||
      filterAskedBy.length > 0 ||
      filterAnsweredBy.length > 0 ||
      filterStartDate !== "" ||
      filterEndDate !== "" ||
      filterApprovalStatus !== ""
    ) {
      getAllFaqs();
    }
  }, [
    filterQuery,
    filterTags,
    filterAskedBy,
    filterAnsweredBy,
    filterStartDate,
    filterEndDate,
    filterApprovalStatus,
  ]);

  useEffect(() => {
    if (isRefresh) {
      getAllFaqs();
      setIsRefresh(false);
    }
  }, [isRefresh]);

  useEffect(() => {
    getAllFaqs();
  }, []);

  const columns = useMemo(
    () => [
      {
        accessorKey: "#",
        header: t("Action"),
        size: 50,
        Cell: ({ row }) => (
          <div className="d-block">
            <button
              className="action_btn_mui dropdown"
              id="dropdownMenuButton1"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <span className="d-block material-symbols-outlined horz_icon">
                more_horiz
              </span>
            </button>
            <ul
              className="dropdown-menu dropdown-menu-end border-0 rounded-0"
              aria-labelledby="dropdownMenuButton1"
            >
              <li>
                <Link
                  className="dropdown-item"
                  to="#"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#saveFaqOffcanvas"
                  aria-controls="saveFaqOffcanvas"
                  onClick={() => {
                    setSelectedFaqId(row.original._id);
                  }}
                >
                  Add/Edit Response
                </Link>
              </li>
              <li>
                <Link
                  className="dropdown-item"
                  to="#"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasAssignExpert"
                  aria-controls="offcanvasAssignExpert"
                  onClick={() => {
                    setSelectedFaqId(row.original._id);
                  }}
                >
                  Assign Experts
                </Link>
              </li>
              <li>
                <Link
                  className="dropdown-item"
                  to="#"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasAssignContent"
                  aria-controls="offcanvasAssignContent"
                  onClick={() => {
                    setSelectedFaqId(row.original._id);
                  }}
                >
                  Assign Contents
                </Link>
              </li>
              <li>
                <Link
                  className="dropdown-item"
                  to="#"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasAssignCourse"
                  aria-controls="offcanvasAssignCourse"
                  onClick={() => {
                    setSelectedFaqId(row.original._id);
                  }}
                >
                  Assign Courses
                </Link>
              </li>
              <li>
                <Link
                  className="dropdown-item"
                  to="#"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasAssignCompany"
                  aria-controls="offcanvasAssignCompany"
                  onClick={() => {
                    setSelectedFaqId(row.original._id);
                  }}
                >
                  Assign Sponsors
                </Link>
              </li>
              <li>
                <Link
                  className="dropdown-item"
                  to="#"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasForum"
                  aria-controls="offcanvasForum"
                  onClick={() => {
                    setSelectedFaqId(row.original._id);
                  }}
                >
                  Generate Debate
                </Link>
              </li>
            </ul>
          </div>
        ),
        enableColumnActions: false, // Hides the column action icon
        enableColumnDragging: false, // Hides the move icon
        enableSorting: false,
      },
      {
        accessorKey: "customid",
        header: t("Id"),
      },
      {
        accessorKey: "createddate",
        header: t("Date"),
      },
      {
        accessorKey: "rank",
        header: t("Rank"),

        Cell: ({ row }) => (
          <input
            type="number"
            className="form-control rounded-0 shadow-none bg-white"
            value={row.original.rank}
            onChange={(e) => rankInputChangeHandler(row, e.target.value)}
          />
        ),
      },
      {
        accessorKey: "question",
        header: t("Question"),
        size: 200,
        Cell: ({ row }) => {
          const MAX_LENGTH = 25; // Limit length of content

          const truncatedQuestion =
            row.original.question.length > MAX_LENGTH
              ? row.original.question.slice(0, MAX_LENGTH) + "..."
              : row.original.question;

          return (
            <Box sx={{ width: "max-content" }}>
              <p>{truncatedQuestion}</p>
            </Box>
          );
        },
      },
      {
        accessorKey: "answer",
        header: t("Response Answer"),

        Cell: ({ row }) => {
          const MAX_LENGTH = 30; // Limit length of content

          const truncateHTML = (html, maxLength) => {
            const parser = new DOMParser();
            const doc = parser.parseFromString(html, "text/html"); // Parse the HTML content
            const textContent = doc.body.textContent || ""; // Extract text content
            return textContent.length > maxLength
              ? textContent.slice(0, maxLength) + "..."
              : textContent;
          };

          const truncatedContent = truncateHTML(
            row.original.answer,
            MAX_LENGTH
          );

          return (
            <Box sx={{ width: "max-content" }}>
              <p
                dangerouslySetInnerHTML={{
                  __html: truncatedContent,
                }}
              ></p>
            </Box>
          );
        },
      },
      {
        accessorKey: "askedbyname",
        header: t("user Asking"),

        Cell: ({ row }) => (
          <div className="user d-flex align-items-center">
            <div className="profile d-flex align-items-center">
              <div className="img-container me-2">
                <Link to="#">
                  <img
                    height={35}
                    width={35}
                    src={
                      row.original.askedbyimage === ""
                        ? "/images/default-user.png"
                        : url.SERVER_URL + row.original.askedbyimage
                    }
                    className="img-fluid lead-img rounded-circle"
                  />
                </Link>
              </div>

              <p className="name">{row.original.askedbyname}</p>
            </div>
          </div>
        ),
      },
      {
        accessorKey: "answeredbyname",
        header: t("user Responded"),
        Cell: ({ row }) => (
          <div className="user d-flex align-items-center">
            <div className="profile d-flex align-items-center">
              <div className="img-container me-2">
                <Link to="#">
                  {row.original.answeredbyname === "" ? (
                    <span>-</span>
                  ) : (
                    <img
                      height={35}
                      width={35}
                      src={
                        row.original.answeredbyimage === ""
                          ? "/images/default-user.png"
                          : url.SERVER_URL + row.original.answeredbyimage
                      }
                      className="img-fluid lead-img rounded-circle"
                    />
                  )}
                </Link>
              </div>

              <p className="name">{row.original.answeredbyname}</p>
            </div>
          </div>
        ),
      },
      {
        accessorKey: "approvalstatusstring",
        header: t("Status"),
        Cell: ({ row }) => {
          if (row.original.approvalstatus === "1") {
            return (
              <p className="d-flex align-items-center fw-medium approved text-success">
                <i className="material-symbols-outlined me-2 mb-1 fs-5">
                  check_circle_outline
                </i>
                <span>Approved</span>
              </p>
            );
          } else {
            return (
              <p className="d-flex align-items-center fw-medium">
                <i className="material-symbols-outlined me-2 fs-5">cancel</i>
                <span>Pending</span>
              </p>
            );
          }
        },
      },
      {
        accessorKey: "expertsname",
        header: t("Experts"),
        size: 200,
        Cell: ({ row }) => {
          return (
            <div style={{ width: "max-content" }}>
              {" "}
              {row.original.expertlist.map((expert, index) => {
                return (
                  <p className="mb-2" key={index}>
                    <a
                      href={`${url.FRONTEND_BASE_URL}/peoples/details/${expert._id}`}
                      target="_blank"
                    >
                      {expert.fullname} ...
                      <i className="material-symbols-outlined fs-5 ms-1">
                        open_in_new
                      </i>
                    </a>
                  </p>
                );
              })}
            </div>
          );
        },
      },
      {
        accessorKey: "blognames",
        header: t("Posts"),
        size: 200,
        Cell: ({ row }) => {
          return (
            <div style={{ width: "max-content" }}>
              {" "}
              {row.original.bloglist.map((blog, index) => {
                return (
                  <p className="mb-2" key={index}>
                    <a
                      href={`${url.FRONTEND_BASE_URL}/blogdetails/${blog._id}`}
                      target="_blank"
                    >
                      {blog.name.slice(0, 30)} ...
                      <i className="material-symbols-outlined fs-5 ms-1">
                        open_in_new
                      </i>
                    </a>
                  </p>
                );
              })}
            </div>
          );
        },
      },
      {
        accessorKey: "companynames",
        header: t("Sponsors"),
        size: 200,
        Cell: ({ row }) => {
          return (
            <div style={{ width: "max-content" }}>
              {" "}
              {row.original.companylist.map((company, index) => {
                return (
                  <p className="mb-2" key={index}>
                    <a
                      href={`${url.FRONTEND_BASE_URL}/company/details/${company._id}`}
                      target="_blank"
                    >
                      {company.name.slice(0, 50)} ...
                      <i className="material-symbols-outlined fs-5 ms-1">
                        open_in_new
                      </i>
                    </a>
                  </p>
                );
              })}
            </div>
          );
        },
      },
      {
        accessorKey: "forumnames",
        header: t("Debate"),
        size: 300,
        Cell: ({ row }) => {
          return (
            <div style={{ width: "max-content" }}>
              {" "}
              {row.original.forumlist.map((forum, index) => {
                return (
                  <p className="mb-2" key={index}>
                    <a
                      href={`${url.FRONTEND_BASE_URL}/activities/details/${forum._id}`}
                      target="_blank"
                    >
                      {forum.title.slice(0, 30)} ...
                      <i className="material-symbols-outlined fs-5 ms-1">
                        open_in_new
                      </i>
                    </a>
                  </p>
                );
              })}
            </div>
          );
        },
      },
      {
        accessorKey: "coursenames",
        header: t("Course"),
        size: 200,
        Cell: ({ row }) => {
          return (
            <div style={{ width: "max-content" }}>
              {" "}
              {row.original.courselist.map((course, index) => {
                return (
                  <p className="mb-2" key={index}>
                    <a
                      href={`${url.FRONTEND_BASE_URL}/courses/details/${course._id}`}
                      target="_blank"
                    >
                      {course.title} ...
                      <i className="material-symbols-outlined fs-5 ms-1">
                        open_in_new
                      </i>
                    </a>
                  </p>
                );
              })}
            </div>
          );
        },
      },
      {
        accessorKey: "categorynames",
        header: t("Category"),
      },
      {
        accessorKey: "tagstring",
        header: t("Tags"),

        Cell: ({ row }) => (
          <div className="label border-bottom-0">
            {row.original.tagstring != "" && (
              <ul className="d-flex flex-wrap gap-2 fs-xs">
                {row.original.tagstring.split(" , ").map((tag, index) => {
                  return (
                    <li
                      key={index}
                      className="px-2 py-1 gradient-light rounded-5"
                    >
                      {tag}
                    </li>
                  );
                })}
              </ul>
            )}
          </div>
        ),
      },
    ],
    [faqList, i18n.language]
  );

  //initialize the column order
  const columnOrder = [
    "#",
    "mrt-row-select",
    ...columns.map((c) => c.accessorKey),
  ]; //array of column ids (Initializing is optional as of v2.10.0)

  useEffect(() => {
    const selectedIdsArray = Object.keys(rowSelection).filter(
      (key) => rowSelection[key]
    );
    if (selectedIdsArray.length > 0) {
      setSelectedFaqIds(selectedIdsArray);
    } else {
      setSelectedFaqIds([]);
    }
  }, [rowSelection]);

  //close alert
  const onAlertClose = () => {
    setShowAlert(false);
    setAlertMessage("");
    setMessageType("");
  };

  return (
    <div id="content_wrapper">
      <section className="blog-wrapper bg-white pb-5">
        <div className="container-fluid px-lg-5">
          <FaqListHeader
            updateBulkHandler={updateBulkHandler}
            refreshHandler={resetFilter}
          />

          {/* ----- blog list table start ----- */}
          {isLoading ? (
            <div className="placeholder-glow d-flex flex-column gap-4">
              <span className="placeholder placeholder-lg bg-secondary col-12"></span>
              <span className="placeholder placeholder-lg bg-secondary col-8"></span>
              <span className="placeholder placeholder-lg bg-secondary col-4"></span>
            </div>
          ) : (
            <div className="table-wrapper">
              {/* ------ pagination section end ------ */}
              <MaterialReactTable
                columns={columns} // map columns to be displayed with api data,
                data={faqList} // data from api to be displayed
                positionActionsColumn="last"
                enableGrouping // to enable grouping of column
                enableRowSelection // enable showing checkbox
                getRowId={(row) => row._id} // map which value to select with row checkbox
                onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
                state={{ rowSelection, columnOrder }} //pass our managed row selection state to the table to use
                defaultColumn={{
                  minSize: 20,
                  maxSize: 200,
                  size: 50, //make columns wider by default
                }}
                muiTableContainerProps={{
                  sx: {
                    maxHeight: "60vh",
                  },
                }}
                enableStickyHeader
                muiSearchTextFieldProps={{
                  placeholder: t("Search by Label Name"),
                }}
              />
            </div>
          )}
          {/* ----- blog list table end ----- */}
        </div>
      </section>
      {/* ======== all modal and pop up render here ======= */}
      <AddLabelModal
        selectedIds={selectedFaqIds}
        moduleName="faq"
        afterTagModalClose={() => {
          setTimeout(() => {
            getAllFaqs();
          }, 1500);
        }}
        setAlertMessage={setAlertMessage}
        setMessageType={setMessageType}
        setShowAlert={setShowAlert}
      />

      <SaveFaqPopup
        selectedFaqId={selectedFaqId}
        setSelectedFaqId={setSelectedFaqId}
        afterPopupClose={getAllFaqs}
      />

      <FaqFilterpopup
        setfilterQuery={setfilterQuery}
        setfilterTags={setfilterTags}
        setfilterStartDate={setfilterStartDate}
        setfilterEndDate={setfilterEndDate}
        setfilterAskedBy={setfilterAskedBy}
        setfilterAnsweredBy={setfilterAnsweredBy}
        setfilterApprovalStatus={setfilterApprovalStatus}
      />

      <AssignExpertPopup
        setSelectedFaqId={setSelectedFaqId}
        selectedFaqId={selectedFaqId}
        afterPopupClose={getAllFaqs}
      />

      <AssignContentPopup
        setSelectedFaqId={setSelectedFaqId}
        selectedFaqId={selectedFaqId}
        afterPopupClose={getAllFaqs}
      />

      <AssignCoursePopup
        setSelectedFaqId={setSelectedFaqId}
        selectedFaqId={selectedFaqId}
        afterPopupClose={getAllFaqs}
      />

      <AssignCompanyPopup
        setSelectedFaqId={setSelectedFaqId}
        selectedFaqId={selectedFaqId}
        afterPopupClose={getAllFaqs}
      />

      <GenerateDebatePopup
        setSelectedFaqId={setSelectedFaqId}
        selectedFaqId={selectedFaqId}
        afterPopupClose={getAllFaqs}
      />

      {/* ----------- delete warning modal ------------ */}
      <DeleteWarningModal
        onCancelDelete={() => {
          setRowSelection({});
          setSelectedFaqIds([]);
        }}
        onDelete={deleteMassiveHandler}
        moduleName={t("faq")}
      />

      {showAlert && (
        <AlertNotification
          showAlert={showAlert}
          message={alertMessage}
          alertType={messageType}
          onClose={onAlertClose}
        />
      )}
    </div>
  );
};

export default FaqListBody;
