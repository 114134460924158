/* eslint-disable */
import Header from "components/Common/Header/Header";
import InventoryListBody from "components/MarketplaceComponents/InventoryComponents/InventoryListBody/InventoryListBody";
import { useEffect } from "react";

const InventoryList = () => {
  useEffect(() => {
    document.title = "Inventory";
  }, []);

  return (
    <main id="app">
      {/* ---- common header ---- */}
      <Header moduleName="marketplace" />
      <InventoryListBody />
    </main>
  );
};

export default InventoryList;
