/* eslint-disable */
import React, { useContext, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";

// Material UI table
import { MaterialReactTable } from "material-react-table";
//Material UI components for rendring menu and others
import { MenuItem, Box } from "@mui/material";

//import context
import { GlobalProvider } from "context/GlobalContext";

/*import url and gateway methods */
import { getData, putData } from "utils/Gateway";
import * as url from "helper/UrlHelper";

//import images
import CommonHeader from "components/MarketplaceComponents/MarketplaceHeader/CommonHeader";
import { assetImages } from "constants";
import ProductListHeader from "../Header/ProductListHeader";
import { useTranslation } from "react-i18next";
import DeleteWarningModal from "components/Common/Modal/DeleteWarningModal";

import ProductFilterPopup from "../Popup/ProductFilterPopup";
import AddLabelModal from "components/Common/Modal/AddLabelModal";
import AlertNotification from "components/Common/AlertNotification/AlertNotification";

const ProductListBody = () => {
  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const moduleAccess = localStorage.getItem("moduleaccess");

  const { t, i18n } = useTranslation(); //for translation

  const [isLoading, setIsLoading] = useState(false);
  const [productList, setProductList] = useState([]);

  const [filterTitle, setfilterTitle] = useState("");
  const [filterSku, setfilterSku] = useState("");
  const [filterCompanies, setfilterCompanies] = useState([]);
  const [filterStartPrice, setfilterStartPrice] = useState("");
  const [filterEndPrice, setfilterEndPrice] = useState("");
  const [filterStartStock, setfilterStartStock] = useState("");
  const [filterEndStock, setfilterEndStock] = useState("");
  const [filterTypes, setfilterTypes] = useState([]);
  const [filterTags, setfilterTags] = useState([]);

  const [reloadList, setReloadList] = useState(false);
  const [isFilterReset, setIsFilterReset] = useState(false);

  const [selectedProductIds, setSelectedProductIds] = useState([]);
  const [rowSelection, setRowSelection] = useState({});

  //alert requirements
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [messageType, setMessageType] = useState("");

  //function for get all products
  const getAllProducts = async () => {
    setRowSelection({});
    setSelectedProductIds([]);
    setIsLoading(true);
    try {
      let requestURL =
        url.API_BASE_URL + url.API_GET_ALL_PRODUCTS + `?token=${token}`;

      if (filterTitle !== "") {
        requestURL += `&title=${filterTitle}`;
      }

      if (filterSku !== "") {
        requestURL += `&sku=${filterSku}`;
      }

      if (filterCompanies.length > 0) {
        requestURL += `&companies=${filterCompanies}`;
      }

      if (filterStartPrice !== "" && filterEndPrice !== "") {
        requestURL += `&startprice=${filterStartPrice}&endprice=${filterEndPrice}`;
      }

      if (filterStartStock !== "" && filterEndStock !== "") {
        requestURL += `&startstock=${filterStartStock}&endstock=${filterEndStock}`;
      }

      if (filterTypes.length > 0) {
        requestURL += `&producttypes=${filterTypes}`;
      }

      if (filterTags.length > 0) {
        requestURL += `&tags=${filterTags}`;
      }

      console.log("requestURL --------> ", requestURL);

      const response = await getData(requestURL);

      setIsLoading(false);

      if (response.status) {
        setMessageType("success");
        setProductList(response.data);
      } else {
        setMessageType("error");
      }
      setAlertMessage(response.message);
    } catch (error) {
      setAlertMessage(error.message);
      setMessageType("error");
    }
    setShowAlert(true);
  };

  //delete product
  const deleteProductHandler = async () => {
    if (selectedProductIds.length > 0) {
      try {
        const listToBeUpdated = productList.filter((item) =>
          selectedProductIds.includes(item._id)
        );

        const apiData = {
          productlist: listToBeUpdated,
        };

        let requestURL =
          url.API_BASE_URL + url.API_DELETE_BULK_PRODUCT + `?token=${token}`;

        const response = await putData(requestURL, apiData);

        if (response.status) {
          setMessageType("success");

          setTimeout(() => {
            getAllProducts();
          }, 1500);
        } else {
          setMessageType("error");
        }

        setAlertMessage(response.message);
      } catch (error) {
        setAlertMessage(error.message);
        setMessageType("error");
      }
    } else {
      setAlertMessage("Please select at least one product");
      setMessageType("error");
    }
    setShowAlert(true);
  };

  //reset filter
  const resetFilter = () => {
    setfilterTitle("");
    setfilterSku("");
    setfilterCompanies([]);
    setfilterStartPrice("");
    setfilterEndPrice("");
    setfilterStartStock("");
    setfilterEndStock("");
    setfilterTypes([]);
    setfilterTags([]);
    setIsFilterReset(true);
    setReloadList(true);
  };

  useEffect(() => {
    if (
      filterTitle !== "" ||
      filterSku !== "" ||
      filterCompanies.length !== 0 ||
      filterStartPrice !== "" ||
      filterEndPrice !== "" ||
      filterStartStock !== "" ||
      filterEndStock !== "" ||
      filterTypes.length !== 0 ||
      filterTags.length !== 0
    ) {
      getAllProducts();
    }
  }, [
    filterTitle,
    filterSku,
    filterCompanies,
    filterStartPrice,
    filterEndPrice,
    filterStartStock,
    filterEndStock,
    filterTypes,
    filterTags,
  ]);

  useEffect(() => {
    if (reloadList) {
      getAllProducts();
      setReloadList(false);
    }
  }, [reloadList]);

  useEffect(() => {
    getAllProducts();
  }, []);

  const columns = useMemo(
    () => [
      { accessorKey: "sku", header: "SKU" },
      {
        accessorKey: "productimagepath",
        header: "Image",
        Cell: ({ cell, row }) => {
          return (
            <img
              className="rounded"
              src={url.SERVER_URL + row.original.productimagepath}
              alt="Product"
              style={{ width: 50, height: 50 }}
            />
          );
        },
      },
      { accessorKey: "name", header: "Product Name" },
      { accessorKey: "categorynames", header: "Category" },
      { accessorKey: "companyname", header: "Seller" },
      { accessorKey: "price", header: "Price" },
      { accessorKey: "stock", header: "Stock" },
      { accessorKey: "taxpercent", header: "Vat" },
      { accessorKey: "producttypename", header: "Type" },
      { accessorKey: "totalratings", header: "Rating" },
      { accessorKey: "productstatuslabel", header: "Status" },
      {
        accessorKey: "tagstring",
        header: t("Label"),
        Cell: ({ row }) => (
          <div className="label border-bottom-0">
            {row.original.productlabels && (
              <ul className="d-flex flex-wrap gap-2 fs-xs">
                {row.original.productlabels.map((tag, index) => {
                  return (
                    <li
                      key={index}
                      className="px-2 py-1 gradient-light rounded-5"
                    >
                      {tag.title}
                    </li>
                  );
                })}
              </ul>
            )}
          </div>
        ),
      },
    ],
    []
  );

  useEffect(() => {
    const selectedIdsArray = Object.keys(rowSelection).filter(
      (key) => rowSelection[key]
    );

    if (selectedIdsArray.length > 0) {
      setSelectedProductIds(selectedIdsArray);
    } else {
      setSelectedProductIds([]);
    }
  }, [rowSelection]);

  /* Closes the alert by updating the state variables.*/
  const onAlertClose = () => {
    setShowAlert(false);
    setAlertMessage("");
    setMessageType("");
  };

  if (
    userInfo.role.slug === "ADMIN" ||
    userInfo.role.slug === "SUPER_ADMIN" ||
    moduleAccess.includes("MOD_MARKETPLACE")
  ) {
    return (
      <div id="content_wrapper">
        <section className="crm-wrapper bg-white pb-5">
          {/* ---- common header ---- */}
          <CommonHeader activeOption="Products" />

          <div className="container-fluid px-lg-5">
            {/* header */}
            <ProductListHeader reloadList={resetFilter} />

            {/* table */}
            {isLoading ? (
              <div className="placeholder-glow d-flex flex-column gap-4">
                <span className="placeholder placeholder-lg bg-secondary col-12"></span>
                <span className="placeholder placeholder-lg bg-secondary col-8"></span>
                <span className="placeholder placeholder-lg bg-secondary col-4"></span>
              </div>
            ) : (
              <div className="table-wrapper">
                <MaterialReactTable
                  columns={columns} // map columns to be displayed with api data,
                  data={productList} // data from api to be displayed
                  enableGrouping // to enable grouping of column
                  enableRowSelection // enable showing checkbox
                  getRowId={(row) => row._id} // map which value to select with row checkbox
                  onRowSelectionChange={setRowSelection} //connect internal row selection state to your own
                  state={{ rowSelection }} //pass our managed row selection state to the table to use
                  enableRowActions // show the action column
                  renderRowActionMenuItems={({ row }) => [
                    <MenuItem
                      key="edit"
                      //onClick={() => console.info(row.id)}
                    >
                      <Link
                        className="dropdown-item"
                        to={`/admin/marketplace/product/details/${row.id}`}
                      >
                        {t("View Details")}
                      </Link>
                    </MenuItem>,
                    <MenuItem
                      key="category"
                      //onClick={() => console.info(row.id)}
                    >
                      <Link
                        className="dropdown-item"
                        to={`/admin/marketplace/product/category/${row.id}`}
                      >
                        {t("Category")}
                      </Link>
                    </MenuItem>,
                    <MenuItem
                      key="variants"
                      //onClick={() => console.info(row.id)}
                    >
                      <Link
                        className="dropdown-item"
                        to={`/admin/marketplace/product/variants/${row.id}`}
                      >
                        {t("Variants")}
                      </Link>
                    </MenuItem>,
                    <MenuItem
                      key="offers"
                      //onClick={() => console.info(row.id)}
                    >
                      <Link
                        className="dropdown-item"
                        to={`/admin/marketplace/product/offers/${row.id}`}
                      >
                        {t("Offers")}
                      </Link>
                    </MenuItem>,
                    <MenuItem
                      key="gallery"
                      //onClick={() => console.info(row.id)}
                    >
                      <Link
                        className="dropdown-item"
                        to={`/admin/marketplace/product/gallery/${row.id}`}
                      >
                        {t("View Gallery")}
                      </Link>
                    </MenuItem>,
                  ]} // action columns menu items
                  muiTableContainerProps={{
                    sx: {
                      maxHeight: "60vh",
                    },
                  }}
                  enableStickyHeader
                />
              </div>
            )}
          </div>

          {/* ----- all pop up render here ----- */}
          {/* ----------- delete warning modal ------------ */}
          <DeleteWarningModal
            onCancelDelete={() => {
              setRowSelection({});
              setSelectedProductIds([]);
            }}
            onDelete={deleteProductHandler}
            moduleName={t("product")}
          />

          {/* ------ Product Filter --------------- */}
          <ProductFilterPopup
            isFilterReset={isFilterReset}
            setIsFilterReset={setIsFilterReset}
            setfilterTitle={setfilterTitle}
            setfilterSku={setfilterSku}
            setfilterCompanies={setfilterCompanies}
            setfilterStartPrice={setfilterStartPrice}
            setfilterEndPrice={setfilterEndPrice}
            setfilterStartStock={setfilterStartStock}
            setfilterEndStock={setfilterEndStock}
            setfilterTypes={setfilterTypes}
            setfilterTags={setfilterTags}
          />

          <AddLabelModal
            moduleName="product"
            afterTagModalClose={() => {
              setTimeout(() => {
                getAllProducts();
              }, 2500);
            }}
            selectedIds={selectedProductIds}
            setShowAlert={setShowAlert}
            setAlertMessage={setAlertMessage}
            setMessageType={setMessageType}
          />

          {showAlert && (
            <AlertNotification
              showAlert={showAlert}
              message={alertMessage}
              alertType={messageType}
              onClose={onAlertClose}
            />
          )}
        </section>
      </div>
    );
  } else {
    return (
      <div id="content_wrapper">
        <section className="crm-wrapper bg-white pb-5">
          <div className="empty_access text-center">
            <div className="empty_pic mb-4">
              {" "}
              <img src={assetImages.emptyVector} alt="" />
            </div>
            <div className="empty_text">
              <p className="fs-lg text-gray fw-semibold mb-4">
                {t("Sorry....! You don't have privilege to see this content")}
              </p>
            </div>
          </div>
        </section>
      </div>
    );
  }
};

export default ProductListBody;
